   // Header
   .search-container {
      display: block;

      .search-tabs {
         position: sticky;
         top: 64px;
         width: 100%;
         z-index: 100;
         background: #FFF;
         border-bottom: 1px solid rgba(0, 0, 0, 0.10);

         ul {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            align-items: center;
            justify-content: space-between;
            list-style: none;
            padding: 0;
            margin-bottom: 12px;
            max-width: 804px;
            margin: 0 auto;
            overflow-x: auto;
            overflow-y: hidden;

            li {
               width: 100%;
               height: 38px;
               padding: 0 8px;
               cursor: pointer;
               border-bottom: 1px solid transparent;

               a {
                  color: #252A2B;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  display: block;
                  white-space: nowrap;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
               }

            }


            li:hover,
            li.active {
               border-bottom: 1px solid #FF6347;
            }

            li:hover a,
            li.active a {
               color: #FF6347;
            }
         }
      }

      .search-body {
         margin-top: 16px;
      }
   }