@use "sass:map";

.spacet-model-paginate {
   ul {
      margin-bottom: 0;
      li {
         span {
            cursor: pointer;
            color: #8a8a8a;

            &.active,
            &:hover {
               color: map.get($text-colors, "default");
               font-weight: 500;
            }
         }

         &:not(.show-dots) {
            a {
               color: #000;
               font-size: 16px;
               font-weight: 500;
               line-height: 20px; /* 125% */
               text-decoration: none;

               display: flex;
               align-items: center;
               justify-content: center;
               width: 40px;
               height: 40px;
               border-radius: 50%;
               @include media-down(md) {
                  font-size: 14px;
                  width: 26px;
                  height: 26px;
               }

               svg,
               img {
                  width: 100%;
                  height: 100%;
               }

               &.active {
                  color: #fff;
                  border-radius: 40px;
                  background: #ff6347;
               }
            }
         }
         &.show-dots {
            a {
            }
         }

         span {
            &.dots {
               color: #8a8a8a;

               img {
                  opacity: unset;
                  width: 12px;
                  filter: invert(33%) sepia(92%) saturate(935%) hue-rotate(170deg) brightness(102%) contrast(105%);

                  &.prev {
                     transform: rotate(180deg);
                  }
               }
            }
         }

         &.col-auto {
            @include media-down(md) {
               padding-left: 4px;
               padding-right: 4px;
            }
         }
      }
      .prev-icon {
         margin-right: 14px;
      }
      .next-icon {
         margin-left: 14px;
      }
   }

   .arrow-icon {
      // opacity: 0.6;

      &.prev {
         transform: rotate(180deg);
      }
   }
}
