@use 'sass:map';
@import 'styles/variables.scss';


.social-feed {
   background: $cl-line-light-2;
   padding-top: 24px;

   a {
      text-decoration: none;
   }

   .container {
      max-width: 1224px;
   }

   &-item {
      background-color: $cl-white;
      padding: 16px;

      .block-user {
         &__info {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: var(--palette-grey-5);
         }

         &__time {
            font-size: 14px;
            line-height: 16px;
            color: var(--palette-grey-4);
         }

         &__follow {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: var(--palette-grey-3);
         }
      }

      &__image {
         background-color: var(--palette-grey-1);
         max-height: 500px;
         min-height: 300px;
         height: 100%;
         width: 100%;
      }

      .icon-collect-img {
         &::after {
            content: url('/assets/img/icon/multi-images.svg');
            height: 32px;
            width: 32px;
            display: block;
            position: absolute;
            top: 0;
            right: 20px;
         }
      }

      .block-reaction {
         &__icon {
            font-size: 16px;
            line-height: 20px;
            color: $cl-space-t-dark-black;
            margin-right: 12px;
            cursor: pointer;

            &:hover {
               opacity: 0.7;
            }

            &::before {
               display: inline-block;
               content: '';
               width: 26px;
               height: 26px;
               vertical-align: middle;
            }

            &--like {
               &::before {
                  content: url('/assets/img/icon/gray/like.svg');
               }
            }

            &--liked {
               &::before {
                  content: url('/assets/img/icon/orange/like.svg');
               }
            }

            &--comment {
               &::before {
                  content: url('/assets/img/icon/gray/comment.svg');
               }
            }

            &--bookmarked {
               &::before {
                  content: url('/assets/img/icon/active-bookmark.svg');
               }
            }

            &--bookmark {
               &::before {
                  content: url('/assets/img/icon/gray/bookmark.svg');
               }
            }

            &--share {
               &::before {
                  content: url('/assets/img/icon/gray/share.svg');
               }
            }
         }
      }

      .block-description {
         white-space: pre-wrap;
      }

      &__comment-number {
         font-size: 16px;
         line-height: 20px;
         color: var(--palette-grey-4);
      }

      .btn-comment {
         width: 100%;
         border: 1px solid #e8eaed;
         border-radius: 4px;
         height: 38px;
         font-size: 16px;
         line-height: 20px;
         color: var(--palette-grey-3);
         background: $cl-white;
         text-align: left;
      }
   }

   .slider-social-feed {
      .slick {
         &-track {
            display: flex;
         }

         &-slide {
            height: inherit;

            &>div:first-child {
               height: 100%;
            }
         }

         &-arrow {
            visibility: hidden;
            color: $cl-white;
            z-index: 1;
            width: 40px;
            height: 40px;
            padding: 6px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
         }

         &-next {
            right: 20px;
         }

         &-prev {
            left: 20px;
         }

         &-disabled {
            opacity: 0.5;
            cursor: not-allowed;
         }
      }

      .slick-slider:hover {
         .slick-arrow {
            visibility: visible;
         }
      }
   }

   .read-more {
      color: var(--palette-grey-4);
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
      margin-left: 6px;
   }
}

@media (max-width: map-get($grid-breakpoints, md)) {
   .upload-img-wrap {
      margin-bottom: 24px;
   }

   .social-feed {
      &-item {
         &__image {
            min-height: 50px;
         }
      }

      .slider-social-feed {
         .slick {
            &-next {
               right: 5px;
            }

            &-prev {
               left: 5px;
            }
         }
      }
   }
}