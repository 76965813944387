.search-result {

   .search-head {
      height: 45px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 12px 16px;
      border-top: 1px solid #E8EAED;

      .left {
         text-align: left;
         color: #252A2B;
         font-size: 18px;
         font-weight: 700;
         line-height: normal;
         white-space: nowrap;

         display: flex;
         align-items: center;
      }

      .center {
         color: #252A2B;
         text-align: center;
         font-size: 18px;
         font-weight: 700;
         line-height: normal;
      }

      .right {
         color: #05A;
         text-align: end;
         font-size: 16px;
         font-weight: 600;
         line-height: normal;
         display: flex;
         justify-content: flex-end;
         align-items: center;

         cursor: pointer;

         svg {
            width: 100%;
            height: 100%;
         }
      }

      .back-icon {
         text-align: left;
         display: flex;
         align-items: center;
         cursor: pointer;
      }

      .delete-all {
         color: #05A;
         text-align: end;
         font-size: 16px;
         font-weight: 600;
         line-height: normal;
         display: flex;
         justify-content: flex-end;
         align-items: center;
         cursor: pointer;
      }
   }

   .search-content {
      max-height: calc(434px - 45px);
      overflow-y: auto;
      overflow-x: hidden;
   }

   .item {
      cursor: pointer;
      height: 43px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #E8EAED;

      .right-action {
         width: 21px;
         height: 21px;
         cursor: pointer;
      }

      .search-link {
         text-decoration: none;
         overflow: hidden;
         color: #252A2B;
         text-overflow: ellipsis;
         font-size: 16px;
         font-weight: 700;
         line-height: normal;
         max-width: 375px;
         white-space: nowrap;
         padding-right: 4px;
      }

      .text-see-more {
         color: #05A;
         text-align: center;
         font-size: 16px;
         font-weight: 400;
         line-height: normal;
         width: 100%;
         cursor: pointer;
         display: block;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }

      .history-svg {
         width: 16px;
         height: 16px;
         margin-right: 12px;
      }

      .result-empty-link {
         overflow: hidden;
         white-space: nowrap;
         color: #05A;
         text-align: center;
         text-overflow: ellipsis;
         font-size: 16px;
         font-weight: 400;
         line-height: normal;
      }
   }
}