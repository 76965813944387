@use 'sass:map';

.construction-page {
   .experience {
      background: rgba(241, 241, 241, 0.9);
      padding: 100px 0;

      .content {
         .item {
            &:not(:last-child) {
               margin-bottom: 50px;
            }

            .name {
               font-weight: 500;
               font-size: 24px;
               line-height: 28px;
               color: #252A2B;
               opacity: 0.9;
               margin-bottom: 24px;
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .experience {
         padding: 32px 0;

         .header {
            .title {
               margin-bottom: 20px;
               padding: 0 12px;
            }
         }

         .container {
            padding: 0 12px;

         }

         .content {
            .item {
               padding: 20px;
               background: #FFFFFF;
               box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);

               &:not(:last-child) {
                  margin-bottom: 20px;
               }

               .name {
                  text-align: center;
               }
            }
         }
      }
   }

}