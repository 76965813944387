@use 'sass:map';
@import "styles/variables.scss";
.btn-close {
   position: absolute;
   right: 12px;
   top: 12px;
   background: #fff;
   border-radius: 50%;
   cursor: pointer;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 1;
   > * {
      font-size: 20px;
   }
}
.image-tagging {
   width: fit-content;
   height: 100%;
   max-height: 100%;
   position: relative;
   @include media-down(md) {
      width: 100%;
      height: unset;
      display: flex;
      justify-content: center;
   }
   img.image {
      object-fit: contain;
      width: auto;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      @include media-down(md) {
         width: 100%;
         height: auto;
      }
   }
   .show-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      left: 16px;
      bottom: 16px;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;

      svg {
         min-width: 16px;
         min-height: 16px;
      }
   }


   .instruction,
   .btn-tagging {
      z-index: 1;
      position: absolute;
      padding: 7px 10px;
      border-radius: 8px;
      background: #fff;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 4px;
      width: fit-content;
      margin: auto;
   }

   .instruction {
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
      top: 50%;
      left: 0px;
      right: 0px;
   }
   .btn-tagging {
      left: 12px;
      top: 12px;
      cursor: pointer;
   }
   .point-product {
      // position: absolute;
      // display: block;
      // width: 15px;
      // height: 15px;
      // border-radius: 50%;
      // background: #ffffff;

      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ffffff;
      position: absolute;
      z-index: 9;
      background: #ff6347;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &::before {
         content: "";
         width: 8px;
         height: 8px;
         background-color: #fff;
         z-index: 10;
         border-radius: 50%;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }
}

.bg-linear-bottom {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;

   height: 120px;
   border-bottom: 1px solid rgba(255, 255, 255, 0.20);
   background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.81) 100%);
   z-index: 1;
   pointer-events: none;
   .noti {
      z-index: 1;
      position: absolute;
      padding: 7px 10px;
      border-radius: 8px;
      background: #fff;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 4px;
      width: fit-content;
      margin: auto;
   }
   .noti {
      left: 0px;
      right: 0px;
      bottom: 14px;

      color: #fff;
      background-color: transparent;
      // text-shadow: 0px 1px #bebebe;
      svg {
         width: 24px;
         height: 24px;
         margin-right: 8px;
      }
   }
}

.tagging-point {
   width: 14px;
   height: 14px;
   border-radius: 50%;
   background: #ffffff;
   position: absolute;
   z-index: 99;
}
.popover {
   position: absolute;
   background-color: transparent;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   z-index: 100;
   .img-default {
      img {
         height: 30px !important;
         width: 30px !important;
      }
   }
}

