@use "sass:map";
@import "styles/variables.scss";

.select-custom {
   border-radius: 32px;
   border: 1px solid #e2e4eb;
   background: #fff;
   .MuiInputBase-root {
      width: 100%;
   }
   .MuiSelect-select {
      height: 32px;
      min-height: 32px;
      padding: 0 38px 0 16px;
      display: flex;
      align-items: center;
   }
   .MuiOutlinedInput-notchedOutline {
      border: unset;
   }
   input {
      display: none;
   }
   .custom-active-select {
      display: flex;
      align-items: center;
      color: #252a2b;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.28px;
      .default-text {
         color: #9f9f9f;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px; /* 114.286% */
         letter-spacing: -0.28px;
         margin-right: 6px;
      }
   }
   .arrow-down-icon {
      width: 9px;
      height: 6px;

      position: absolute;
      right: 16px;
   }
   .splaceholder {
      color: #9f9f9f;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.28px;
   }
}

// Custom dropdown
.select-custom-dropdown {
   .MuiPopover-paper {
      margin-top: 6px;
      border-radius: 10px;
      border: 1px solid #e2e4eb;
      background: #fff;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
   }
   .MuiList-root {
   }
   li.MuiMenuItem-root {
      color: #252a2b;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
      padding-left: 16px;
      display: flex;
      justify-content: space-between;
      position: relative;

      &::before {
         display: none;
         content: "";
         position: absolute;
         left: 0;
         top: 50%;
         transform: translateY(-50%);

         width: 2px;
         height: 24px;
         border-radius: 0px 16px 16px 0px;
         background: #ff6347;
      }

      .active-icon {
         display: none;
         width: 18px;
         height: 13px;
      }

      &.Mui-selected {
         color: #ff6347;
         font-weight: 400;
         background: transparent;

         .active-icon {
            display: block;
         }
      }

      &:hover {
         background: rgba(226, 228, 235, 0.3);
         &::before {
            display: block;
         }
      }
   }
}
