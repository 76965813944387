@use "sass:map";
@import "styles/variables.scss";

.image-by-space {
   margin: 20px 0px;

   .type-space {
      gap: 8px;

      &-item {
         filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.25));
         cursor: pointer;

         img {
            width: 100%;
            aspect-ratio: 1.5;
         }

         &__info {
            width: 100%;
            height: 54px;
            background-color: #ffffff;
            line-height: 1.2;
            padding: 0px 2px;
         }
      }

      .active {
         .type-space-item__info {
            background-color: map-get($map: $text-colors, $key: orange) !important;
            color: #ffffff;
         }
      }
   }

   // XSmall devices (375px and up)
   @media (max-width: 450px) {
      .item-img {
         height: 135px !important;
      }
   }

   // Small devices (landscape phones, 576px and up)
   @media (max-width: map.get($grid-breakpoints, "sm")) {
      .item-img {
         height: 200px;
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: 6px 0px;

      .title_by_room {
         font-size: 20px;
      }

      .item-img {
         height: 165px;
      }
   }

   // Lg devices (976px and up)
   @media (max-width: 976px) {
      .item-img {
         height: 245px;
      }
   }
}

.component-review {
   margin-bottom: 12px;

   &__avatar {
      min-width: 34px;
      min-height: 34px;
      border-radius: 50%;
      border: 1px solid #f0f0f0;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
   }

   &__title {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 1rem;
      line-height: 19px;
      color: #515151;
      gap: 10px;
      margin-bottom: 10px;

      p {
         margin-bottom: 0;
      }
   }

   &__comment {
      font-weight: 400;
      font-size: 1rem;
      line-height: 20px;
      color: #666666;
      margin-bottom: 12px;
   }

   &__reaction {
      gap: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &-item {
         display: flex;
         cursor: pointer;
         align-items: center;

         .title {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1rem;
            color: #666666;
         }

         .icon {
            gap: 4px;
            display: flex;
            align-items: center;

            span {
               font-weight: 400;
               font-size: 1rem;
               line-height: 1rem;
               color: #666666;
            }

            img {
               width: 16px;
               height: 17px;
               vertical-align: top !important;
               object-fit: contain;
            }
         }
      }
   }

   &__bottom {
      border-bottom: 1px solid #e8eaed;
   }

   .review {
      &__read-more {
         width: max-content;
         margin-top: -10px;
         cursor: pointer;

         p {
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            color: #ff6347;
         }
      }
   }
}

.detail_image_by_space {
   margin-top: 16px;

   @include media-down(md) {
      padding-bottom: $NAVIGATOR-HEIGHT;
   }

   .form {
      position: relative;

      .input,
      .input-custom {
         &.error {
            input {
               border: 1px solid #ff6347;
            }

            span {
               font-size: 0.875rem;
               line-height: 14px;
               color: #ff6347;
               // text-align: justify;
            }
         }

         input {
            width: 100%;
            height: 34px;
            border-radius: 4px;
            padding: 10px;
            border: 1px solid #e8eaed;
            outline: none;
         }

         .btn {
            position: absolute;
            top: 1px;
            right: 1px;
            height: 32px;
            background-color: #fff;
            font-weight: bold;
         }
      }
   }

   .content_image_by_space {
      .top_content {
         display: flex;

         .sliders_image {
            width: calc(70% - 20px);
            margin-right: 20px;
            position: relative;

            .prev_button {
               position: absolute;
               z-index: 100;
               top: calc(50% - 36px);
               left: 0;
               cursor: pointer;
            }

            .next_button {
               position: absolute;
               z-index: 100;
               top: calc(50% - 36px);
               right: 0;
               cursor: pointer;
            }

            .item_image {
               width: 100%;
               // height: 506px;
               object-fit: contain;
               object-position: center;
            }
         }

         .summary {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
               text-align: left;
               font-weight: 500;
               font-size: 18px;
               line-height: 23px;
               color: #000;
               overflow: hidden;
               text-overflow: ellipsis;
               -webkit-line-clamp: 3;
               word-break: break-word;
               -webkit-box-orient: vertical;
               display: -webkit-box;
            }

            .block_items {
               .item {
                  color: #252a2b;
                  opacity: 0.8;
                  margin-bottom: 4px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1;
                  word-break: break-all;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  font-size: 14px;

                  img {
                     margin-right: 4px;
                     width: 18px;
                     height: 18px;
                     object-fit: contain;
                  }
               }

               .item_partner {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  word-break: break-word;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
               }
            }

            .des-intro {
               margin-top: 12px;
               font-size: 16px;
               line-height: 19px;

               .text {
                  word-break: break-word;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  color: #787b7c;
               }

               .btn-more {
                  cursor: pointer;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 14px;
                  color: #515151;
               }
            }

            .relate_topic {
               margin-top: 12px;

               .title {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 18.75px;
               }

               .topic_item {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 12px;
                  gap: 10px 7px;

                  .item {
                     background-color: #f5f5f5;
                     padding: 6px 12px;
                     border-radius: 20px;
                     font-size: 13px;
                     font-weight: 400;
                     cursor: pointer;
                  }
               }
            }

            .banner-cta {
               margin-top: 20px;

               img {
                  width: 100%;
                  border-radius: 6px;
               }
            }
         }

         .btn-wrap {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .btn-like,
            .btn-fb,
            .btn-copy,
            .btn-bm {
               cursor: pointer;
               width: 26px;
               height: 26px;
               margin-right: 20px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
               }
            }

            .btn-like,
            .btn-copy,
            .btn-bm {
               &.active,
               &:hover {
                  img {
                     filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
                  }

                  .des {
                     color: #ff6347;
                  }
               }
            }

            .btn-copy {
               position: relative;

               .popup-copy {
                  position: absolute;
                  visibility: hidden;
                  width: 160px;
                  background-color: #555;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 8px 0;
                  position: absolute;
                  z-index: 1;
                  top: 130%;
                  left: 50%;
                  margin-left: -80px;

                  &.show-popup {
                     visibility: visible !important;
                     -webkit-animation: fadeIn 1s;
                     animation: fadeIn 1s;
                  }

                  &::after {
                     content: "";
                     position: absolute;
                     top: -24%;
                     left: 50%;
                     margin-left: -5px;
                     border-width: 5px;
                     border-style: solid;
                     border-color: transparent transparent #555 transparent;
                  }
               }

               @-webkit-keyframes fadeIn {
                  from {
                     opacity: 0;
                  }

                  to {
                     opacity: 1;
                  }
               }

               @keyframes fadeIn {
                  from {
                     opacity: 0;
                  }

                  to {
                     opacity: 1;
                  }
               }
            }
         }
      }

      .bottom_content {
         margin-top: 20px;

         .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-bottom: 14px;
            display: flex;
            justify-content: space-between;

            .see_more {
               color: #0055aa;
               font-size: 14px;
               line-height: 16px;
               font-weight: 400;
               cursor: pointer;
            }
         }

         .same_article {
            .same_list {
               display: flex;
               width: 100%;
               // flex-wrap: wrap;
               overflow: hidden;
               overflow-x: scroll;

               img {
                  width: 175px;
                  height: 175px;
                  min-width: 175px;
                  object-fit: cover;
                  object-position: center;
                  margin-right: 12px;
                  border-radius: 12px;
                  margin-bottom: 20px;
               }
            }
         }
      }
   }

   .review {
      &__header {
         border-top: 1px solid #e8eaed;
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
         display: flex;
         align-items: center;
         padding: 12px 0;
         gap: 4px;

         &-total {
            color: #ff6347;
         }

         p {
            margin-bottom: 0;
         }
      }

      .avatar {
         min-width: 34px;
         min-height: 34px;
         border: 1px solid #f0f0f0;
         border-radius: 50%;
         background-size: cover !important;
         background-position: center !important;
         background-repeat: no-repeat !important;
      }

      .form {
         position: relative;

         .input {
            &.error {
               // input {
               //    border: 1px solid #ff6347;
               // }

               span {
                  font-size: 0.875rem;
                  line-height: 14px;
                  color: #ff6347;
                  // text-align: justify;
               }
            }

            input {
               width: 100%;
               height: 38px;
               border-radius: 4px;
               padding: 10px;
               // border: 1px solid #e8eaed;
               border: unset;
               outline: none;
            }

            .btn {
               position: absolute;
               top: 1px;
               right: 1px;
               height: 36px;
               background-color: #fff;
               font-weight: bold;
            }
         }
      }
   }

   .suggestions_image_by_space {
      margin-top: 0px;

      .title {
         font-size: 16px;
         font-weight: 700;
         line-height: 19px;
         margin-bottom: 14px;
         display: flex;
         justify-content: space-between;

         h2 {
            margin-bottom: 0px;
         }
      }

      .similar_article {
         // padding-bottom: 20px;

         .similar_list {
            width: 100%;
            display: grid;
            grid-gap: 12px;
            grid-template-columns: repeat(6, 1fr);

            @include media-down(lg) {
               grid-template-columns: repeat(3, 1fr);
            }

            @include media-down(md) {
               grid-template-columns: repeat(3, 1fr);
            }

            @include media-down(sm) {
               grid-template-columns: repeat(2, 1fr);
            }

            .wrapper_image {
               background-color: #dddbdd;
               border-radius: 12px;
               overflow: hidden;

               img {
                  width: 100%;
                  height: 195px;
                  object-fit: cover;
                  object-position: center;
               }
            }
         }

         .see_more {
            color: #666666;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
            cursor: pointer;
            margin-top: 9px;
            margin-bottom: 29px;

            img {
               margin-right: 4px;
            }
         }
      }
   }

   @media (max-width: map.get($grid-breakpoints, "md")) {
      // .container_image_by_space {
      //    padding: 0;
      //    margin: 0;
      // }
      .content_image_by_space {
         display: block;

         .top_content {
            display: block;

            .sliders_image {
               width: unset;
               margin-right: 0;
               // height: 533px;
               margin: 0 -12px;
               background-color: unset;

               .prev_button {
                  width: 48px;
                  height: 48px;
                  top: calc(50% - 24px);

                  img {
                     width: 100%;
                     height: 100%;
                  }
               }

               .next_button {
                  width: 48px;
                  height: 48px;
                  top: calc(50% - 24px);
                  right: 0;

                  img {
                     width: 100%;
                     height: 100%;
                  }
               }
            }

            .summary {
               width: unset;
               padding-top: 8px;
            }
         }
      }

      .suggestions_image_by_space {
         border-top: 8px solid $border;
         padding-top: 16px;
         margin: 0 -16px;
         .similar_article {
            padding: 0 16px;
            .similar_list {
               gap: 10px;
               display: grid;
               grid-template-columns: repeat(2, minmax(0, 1fr));

               .wrapper_image {
                  // width: calc(50% - 5px);

                  img {
                     width: 100%;
                  }
               }
            }
         }
      }
   }
}
