@import "styles/variables.scss";

.modal-auth {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1300;

   .MuiPaper-root {
      width: fit-content;
      max-width: 100%;
      background: unset;
      border-radius: 0;
      background-color: #fafafa;
      border-radius: 10px;
      width: 100%;
      max-width: 1112px;
      overflow-x: hidden;
      overflow-y: auto;

      @include media-down(sm) {
         min-height: 92%;
         margin: 0;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
      }
   }

   // &.full-height {
   //   .MuiPaper-root {
   //     min-height: 90%;
   //   }
   // }

   .MuiDialog-container {
      @include media-down(sm) {
         align-items: end;
         // padding-bottom: 52px;
      }
   }

   &.full-height {
      .MuiPaper-root {
         @include media-down(sm) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
         }
      }
   }

   .img-close {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;

      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #f4f4f4;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 101;

      &:hover {
         background-color: #e1dfdf;
      }

      img {
         width: 12px;
         height: 12px;
         object-fit: cover;
      }
   }

   .back-step-mobile-custom {
      padding: 24px;
      display: none;
      justify-content: space-between;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      @include media-down(sm) {
         display: grid;
      }
      .step-name {
         text-align: center;
         font-size: 16px;
         font-weight: 600;
         line-height: 24px;
      }
      .img-back {
         width: 27px;
         height: 27px;
         min-width: 27px;
      }
   }

   .display-block {
      display: block;
   }

   .display-none {
      display: none;
   }
}

.modal-auth {
   z-index: 1302 !important;
   .modal-content-left {
      height: 100%;

      @media (min-width: 1200px) {
         min-height: 638px;
      }
   }

   .col-md-5_7 {
      @include media-up(md) {
         width: 46.7%;
      }
   }

   .col-md-6_3 {
      @include media-up(md) {
         width: 53.3%;
      }
   }

   // Slider
   .slick-slider {
      height: 100%;
      position: relative;
   }

   .slick-list {
      height: 100%;
   }

   .slick-track {
      height: 100%;
   }

   .slick-slide {
      height: 100%;

      & > div {
         height: 100%;
      }
   }

   .slider-item {
      height: 100%;
   }

   .slick-dots {
      position: absolute;
      bottom: 26px;
      left: 50%;
      transform: translateX(-50%);

      & > li {
         button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.3);

            &::before {
               display: none;
            }
         }

         &.slick-active button {
            background: #ff6347;
            border: 1px solid #ff6347;
         }
      }
   }

   .right {
      height: 100%;
      padding: 24px 15px;
      max-width: 360px;
      margin: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-down(sm) {
         max-width: 100%;
         padding: 80px 24px 24px;
      }
   }

   .title_info {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 28px;
      color: #6a433a;
      margin-bottom: 64px;
   }

   .title_sign_up {
      color: #131313;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 32px;
      text-align: center;

      @include media-down(sm) {
         margin-bottom: 16px;
         font-size: 16px;
         margin-bottom: 32px;
      }
   }

   .mobile_title {
      img {
         margin-bottom: 16px;
      }

      h2 {
         text-align: center;
         font-size: 16px;
         font-weight: 500;
         margin-bottom: 0;
      }

      p {
         font-size: 16px;
         font-weight: 300;
         margin-bottom: 30px;
      }
   }

   .type_sign_up {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 8px;
      border-radius: 32px;
      height: 48px;
      background: #eee;
      border-radius: 32px;
      cursor: pointer;

      position: relative;

      .img {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 8px;

         border-radius: 50%;
         background: #fff;
         width: 32px;
         height: 32px;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            width: 18px;
            height: 18px;
            object-fit: contain;
         }
      }

      .text {
         flex: 1;
         display: flex;
         cursor: pointer;
         justify-content: center;
      }
   }

   .des {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #515151;

      span {
         font-weight: 700;
         font-size: 18px;
         line-height: 21px;
         color: #3d1002;
      }
   }

   .register {
      margin-top: 14px;
      color: #666;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;

      a {
         text-decoration: none;
         color: #0055aa;
      }
   }

   form.form-register {
      .password_input {
         position: relative;

         .icon_eye {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
         }
      }

      .item-form {
         display: flex;
         flex-direction: column;
         margin-bottom: 16px;

         .label {
            font-size: 14px;
            line-height: 20px;
            color: #999;
            margin-bottom: 8px;
         }

         .wrap {
            position: relative;

            .code {
               position: absolute;
               width: max-content;
               height: 100%;
               padding: 9px 14px;
               top: 0;
               right: 0;
               border-radius: 4px;
               background-color: transparent;

               .btn-code {
                  cursor: pointer;
                  background-color: transparent;
                  color: #ff6347;
                  font-size: 16px;

                  &.btn-code-reset {
                     color: #acacac;

                     &.active {
                        color: #ff6347;
                     }
                  }
               }
            }
         }
      }

      .btn {
         height: 40px;
         display: flex;
         justify-content: center;
         align-items: center;
      }

      .gg-fb {
         font-size: 0.75rem;
         line-height: 20px;
         width: 100%;
         color: #acacac;

         .title-gg-fb {
            margin: 43px 0;
            margin-bottom: 34px;
            width: 100%;
            text-align: center;
            position: relative;
            display: flex;
            justify-content: center;

            .text {
               background-color: #fafafa;
               height: 1rem;
               z-index: 100;
               padding: 0 5px;
            }

            &:before {
               position: absolute;
               content: "";
               width: 100%;
               height: 1px;
               background-color: #acacac;
               display: block;
               top: 10px;
               left: 0;
               z-index: 1;
            }
         }

         .btn-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;

            .btn-item {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 64px;
               height: 64px;
               border: 1px solid #e8eaed;
               border-radius: 50%;
               padding: 18px;
               cursor: pointer;

               .img {
                  width: 28px;
                  height: 28px;

                  img {
                     width: 28px;
                     height: 28px;
                     object-fit: cover;
                  }
               }
            }
         }
      }

      .gender-group {
         .custom-check {
            margin-top: 8px;

            input {
               height: 16px;
               width: 16px;
               padding: 0;
            }
         }
      }

      select {
         height: 44px;
         padding: 10px 20px;
      }

      .btn {
         background: map.get($text-colors, "default");
         color: #fff;
         width: 100%;
         padding: 10px;
         font-size: 1rem;
      }

      .btn-register {
         margin-top: 72px;
      }

      .group-link {
         font-size: 1rem;

         .forgot {
            padding-left: 10px;
         }

         a {
            padding-right: 10px;
            text-decoration: none;
         }
      }
   }

   .noti {
      font-size: 10px;
      text-align: center;
      color: #999;
      font-weight: 400;
      margin-top: 40px;

      span {
      }

      a {
         color: #05a;
      }
   }

   .title_signup_form {
      color: #131313;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 40px;
      text-align: center;

      @include media-down(sm) {
         margin-bottom: 16px;
         font-size: 16px;
         margin-bottom: 32px;
      }
   }

   .login-helper {
      color: #666;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-top: 30px;

      div {
         color: #ff6347;
         font-weight: 500;
         cursor: pointer;
      }
   }

   .back-step {
      display: flex;
      justify-content: center;
      margin-top: 36px;

      @include media-down(sm) {
         display: none;
      }

      img {
         width: 56px;
         height: 56px;
         cursor: pointer;
      }
   }

   .register-email-success {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .register-success-img {
      width: 100%;
      text-align: center;
      margin-bottom: 32px;

      img {
         width: 100%;
         max-width: 300px;
      }
   }

   .register-success-title {
      color: #0d120e;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      margin-bottom: 8px;
   }

   .register-success-message {
      color: #9597a6;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.14px;
      margin-bottom: 40px;
   }

   .btn-auth {
      width: 100%;
      color: #fff;
      height: 52px;
      padding: 16px;
      border-radius: 30px;
      background: #ff6347;

      &:active {
         color: #fff;
      }

      &:disabled {
         color: #878787;
         pointer-events: none;
         background: #ededed;
      }
   }

   // signup email
   .signup-email {
      .title_signup_form {
         @include media-down(sm) {
            text-align: start;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            margin-top: -70px;
            margin-bottom: 40px;
         }
      }

      .btn-auth {
         @include media-down(sm) {
            margin-top: 100px;
         }
      }

      .login-helper {
         // @include media-down(sm) {
         //   margin-top: 96%;
         // }
      }
   }

   // Form signup by phone
   .signup-phone {
      .signup-phone-title {
         color: #131313;
         font-size: 24px;
         font-weight: 500;
         line-height: 28px;
         margin-bottom: 8px;
         text-align: center;

         @include media-down(sm) {
            text-align: start;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            margin-top: -70px;
            margin-bottom: 40px;
         }
      }

      .signup-phone-des {
         color: rgba(0, 0, 0, 0.7);
         text-align: center;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 125%;
         max-width: 260px;
         margin: 0 auto;
         margin-bottom: 56px;

         @include media-down(sm) {
            text-align: start;
            font-weight: 500;
            margin-bottom: 40px;
            max-width: unset;
            margin-top: -24px;
         }
      }

      .btn-auth {
         margin-top: 56px;

         @include media-down(sm) {
            margin-top: 100px;
         }
      }
   }

   // signup phone code
   .signup-phone-code {
      .signup-phone-title {
         color: #131313;
         font-size: 24px;
         font-weight: 500;
         line-height: 28px;
         margin-bottom: 8px;
         text-align: center;

         @include media-down(sm) {
            text-align: start;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            margin-top: -70px;
            margin-bottom: 40px;
         }
      }

      .signup-phone-des {
         color: rgba(0, 0, 0, 0.7);
         text-align: center;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 125%;
         max-width: 260px;
         margin: 0 auto;
         margin-bottom: 56px;

         @include media-down(sm) {
            text-align: start;
            font-weight: 500;
            margin-bottom: 40px;
            max-width: unset;
            margin-top: -24px;
         }
      }

      .btn-auth {
         margin-top: 40px;
      }

      .count-down {
         font-size: 14px;
         font-weight: 300;
         text-align: center;
         margin-top: 16px;

         span {
            font-weight: bold;
         }
      }

      .resend-code {
         margin-top: 16px;

         p {
            font-size: 14px;
            font-weight: 300;
            text-align: center;
            margin-bottom: 0;
         }

         span {
            font-size: 14px;
            color: #ff6347;
            font-weight: 700;
            display: block;
            text-align: center;
            cursor: pointer;
         }
      }
   }
}

// signin form
.signin {
   .signin-phone-title {
      color: #131313;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 8px;
      text-align: center;
      margin-bottom: 56px;

      @include media-down(sm) {
         text-align: start;
         font-weight: 500;
         font-size: 24px;
         line-height: 24px;
         margin-top: -70px;
         margin-bottom: 40px;
      }
   }

   .signup-phone-des {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 56px;

      @include media-down(sm) {
         text-align: start;
         font-weight: 500;
         margin-bottom: 40px;
         max-width: unset;
         margin-top: -24px;
      }
   }

   .item-form {
      margin-bottom: 16px;
   }

   .forgot-pass {
      color: #ff6347;
      font-size: 14px;
      text-align: end;
      line-height: 24px;
      font-weight: 300;
      cursor: pointer;
   }

   form.form-signin {
      .password_input {
         position: relative;

         .icon_eye {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
         }
      }

      .item-form {
         display: flex;
         flex-direction: column;
         margin-bottom: 16px;

         .label {
            font-size: 14px;
            line-height: 20px;
            color: #999;
            margin-bottom: 8px;
         }
      }

      .btn {
         height: 40px;
         display: flex;
         justify-content: center;
         align-items: center;
      }

      .gg-fb {
         font-size: 0.75rem;
         line-height: 20px;
         width: 100%;
         color: #acacac;

         .title-gg-fb {
            margin: 32px 0 16px;
            width: 100%;
            text-align: center;
            position: relative;
            display: flex;
            justify-content: center;

            .text {
               background-color: #fafafa;
               height: 1rem;
               z-index: 100;
               padding: 0 5px;
            }

            &:before {
               position: absolute;
               content: "";
               width: 100%;
               height: 1px;
               background-color: #acacac;
               display: block;
               top: 10px;
               left: 0;
               z-index: 1;
            }
         }

         .btn-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-bottom: 32px;

            .btn-item {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 40px;
               height: 40px;
               border: 1px solid #e8eaed;
               border-radius: 8px;
               padding: 18px;
               cursor: pointer;

               .img {
                  width: 22px;
                  height: 22px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                     width: 22px;
                     height: 22px;
                     object-fit: cover;
                  }
               }
            }
         }
      }
   }

   .btn {
      background: map.get($text-colors, "default");
      color: #fff;
      width: 100%;
      padding: 10px;
      font-size: 1rem;
   }

   .btn-auth {
      margin-top: 24px;
   }

   .register-now {
      color: #666;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-top: 50px;
      justify-content: center;

      .no-account {
         color: #ff6347;
         font-weight: 700;
         text-decoration: none;
         cursor: pointer;
      }
   }
}

.forgot-password {
   .signup-phone-title {
      color: #131313;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 8px;
      text-align: center;
      margin-bottom: 56px;

      @include media-down(sm) {
         text-align: start;
         font-weight: 500;
         font-size: 24px;
         line-height: 24px;
         margin-top: -70px;
         margin-bottom: 40px;
      }
   }

   .signup-phone-des {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 56px;

      @include media-down(sm) {
         text-align: start;
         font-weight: 500;
         margin-bottom: 40px;
         max-width: unset;
         margin-top: -24px;
      }
   }

   .btn-auth {
      margin-top: 56px;

      @include media-down(sm) {
         margin-top: 100px;
      }
   }
}

// form-new-password
.form-new-password {
   .signup-phone-title {
      color: #131313;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 8px;
      text-align: center;

      @include media-down(sm) {
         text-align: start;
         font-weight: 500;
         font-size: 24px;
         line-height: 24px;
         margin-top: -70px;
         margin-bottom: 40px;
      }
   }

   .signup-phone-des {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 56px;

      @include media-down(sm) {
         text-align: start;
         font-weight: 500;
         margin-bottom: 40px;
         max-width: unset;
         margin-top: -24px;
      }
   }

   .label {
      font-size: 14px;
      line-height: 20px;
      color: #999;
      margin-bottom: 8px;
   }

   button {
      margin-top: 72px;
   }

   .input-wrap {
      position: relative;

      .prefix {
         width: 20px;
         height: 20px;
      }

      input {
         font-size: 16px;
      }

      .suffix {
         width: 20px;
         height: 20px;
         cursor: pointer;
      }
   }
}
