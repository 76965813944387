@use 'sass:map';

.main-container {
   background: #fafafa;
}

#header {
   z-index: map-get($map: $zindex, $key: 'fixed');
}

.group-chat-fixed {
   position: fixed;
   bottom: 1rem;
   right: 1rem;
   z-index: 1030;

   button {
      border: none;
      padding: 0;
      margin-top: 16px;

      &:hover,
      &:active,
      &:focus {
         box-shadow: none;
         border: none;
      }
   }
}

.consulting-page {
   background: #f5f5f5;
}

.header-image-by-space {
   padding: 0px 22px;
   background-color: #ffffff;
   height: 69px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-bottom: 1px solid #e1e1e1;
   font-weight: 500;
   font-size: 24px;
   line-height: 28px;
   color: rgba(0, 0, 0, 0.5);

   div {
      gap: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
   }

   svg {
      cursor: pointer;
   }
}

.btn-scroll-to-top {
   z-index: map-get($map: $zindex, $key: 'fixed');
   position: fixed;
   display: none;
   right: 16px;
   bottom: 60px !important;
   border: none;
   outline: none;
   width: 55px;
   height: 55px;
   background: #ffffff;
   box-shadow: 0px 2px 7px rgba(102, 102, 102, 0.69);
   border-radius: 100px;

   &:hover,
   &:focus-visible {
      outline: none;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
   .main-container {
      background: #ffffff;
   }

   .consulting-page {
      background: #fff;
   }

   .cta-btn {
      height: 35px;
      right: 16px;
      bottom: 99px;
      padding: 8px 16px;
      background: #ff6347;
      box-shadow: 0px 0px 21px rgba(37, 42, 43, 0.23);
      border-radius: 100px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      border: 0px;
      outline: none;
      position: fixed;
      right: 16px;
      bottom: 16px;
      text-decoration: none;
      z-index: map.get($zindex, 'fixed');
      display: flex;
      align-items: center;

      &:hover,
      &:focus,
      &:focus-visible,
      &:visited {
         color: #ffffff;
      }

      span {
         font-size: 24px;
         margin-right: 8px;
      }
   }

   .header-image-by-space {
      padding: 0px 12px;
      background-color: #ffffff;
      height: 43px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      div {
         gap: 12px;
      }
   }
}