.connect-service-page {
    .free-cost {
        // height: calc(100vh - 116px);
        padding: 70px 60px;
        background-image: url('https://static.spacet.vn/img/service-connect/free_cost_bg_dt.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .free-cost-container {
            display: flex;
            flex-direction: row-reverse;
            .free-cost-content {
                width: 45%;
            }
            .free-cost-image {
                width: 55%;
            }
        }

        .wrapper_title {
            // width: 45%;

            .title_free {
                font-size: 45px;
                font-weight: 700;
                line-height: 54px;
                margin-bottom: 24px;
                max-width: 60%;

                strong {
                    color: #ff6d00;
                }
            }
        }

        .wrapper_slider {
            width: 60%;
            .slick-arrow {
                display: none !important;
            }

            .slider_free_cost {
                img {
                    width: 100%;
                }

                .slick-dots {
                    bottom: -6px;

                    li {
                        margin: 0;

                        button {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #ffffff;
                            opacity: 0.8;
                            padding: 3px;

                            &::before {
                                color: transparent;
                                width: 10px;
                                height: 10px;
                            }
                        }

                        &:hover,
                        &.slick-active {
                            button {
                                background: #ff5d00;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'lg')) {
        .free-cost {
            .wrapper_title {
                width: 100%;
                padding: 0 24px;

                .title_free {
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 40px;
                    margin-bottom: 30px;
                    max-width: 100%;
                }

                .wrapper_button {
                    max-width: 60%;
                    margin-top: 33px;

                    .btn_connect {
                        padding: 27px 20px;
                    }
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'md')) {
        .free-cost {
            padding: 0;
            padding-top: 70px;
            background-image: unset;
            display: block;
            .free-cost-container {
                flex-direction: row;
                .free-cost-content {
                    width: 100%;
                }
                .free-cost-image {
                    width: 100%;
                }
            }
            .wrapper_slider {
                width: 100%;
            }
            .wrapper_slider {
                padding: 40px 24px;
            }
            .wrapper_title {
                width: 100%;
                padding: 0 24px;

                .title_free {
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 50px;
                    margin-bottom: 52px;
                    max-width: 100%;
                    letter-spacing: -3px;

                    strong {
                        color: #ff6d00;
                    }
                }
            }

            .content_free {
                background-image: url('https://static.spacet.vn/img/service-connect/free_cost_bg.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    @media (max-width: 420px) {
        .free-cost {
            .wrapper_title {
                .title_free {
                    margin-bottom: 22px;
                }
            }
        }
    }
}
