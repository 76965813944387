@import "../../../styles/variables.scss";

.review-product-page {
   width: 100%;
   padding-top: 56px;

   .review-header {
      height: 56px;
      min-height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      .review-title {
         color: #252a2b;
         font-size: 18px;
         font-weight: 500;
         line-height: 24px; /* 133.333% */
      }
      button.back-icon {
         padding: 0;
         margin: 0;
         border: unset;
         background-color: unset;
         cursor: pointer;
         position: absolute;
         left: 16px;
         top: 50%;
         transform: translateY(-50%);
         z-index: 1;
         svg {
            width: 24px;
            height: 24px;
         }
      }
   }
   .review-content {
      .wrapper_review {
         position: relative;
         padding-bottom: 73px;
         background-color: #f5f5fa;
         min-height: calc(100vh - 56px);
         background: #fff;
         .with-review {
            padding: 16px;
            display: flex;
            align-items: center;
            border-bottom: 8px solid $border;
            background-color: #fff;
            border-top: 8px solid $border;
            .info-left {
               display: flex;
               align-items: center;
               margin-right: 64px;
               width: 100%;
               margin-right: unset;
               flex-direction: row;
               justify-content: space-between;
               .rating-count {
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  margin-bottom: 8px;
                  @include media-down(md) {
                     justify-content: flex-start;
                  }
                  .real-value {
                     color: #252a2b;
                     font-size: 34px;
                     font-weight: 500;
                     line-height: 34px; /* 58.824% */
                     @include media-down(md) {
                        font-size: 24px;
                        line-height: 24px; /* 66.667% */
                     }
                  }
                  .max-value {
                     color: #9f9f9f;
                     font-size: 24px;
                     font-weight: 700;
                     line-height: 24px; /* 66.667% */
                     @include media-down(md) {
                     }
                  }
               }
            }
            .info-right {
               display: flex;
               flex-direction: column;
               button {
                  color: #252a2b;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 16px; /* 100% */
                  height: 40px;
                  padding: 2px 16px;
                  min-width: 120px;
                  margin-right: 16px;
                  margin-bottom: 16px;
                  border-radius: 8px;
                  border: 1px solid #e2e4eb;
                  background: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &:hover,
                  &.active {
                     color: $primary;
                     border: 1px solid #ff6347;
                     background: #fff9f8;
                  }
               }
            }
         }
         .product-review-empty {
            padding: 24px 0;
            min-height: 144px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
               width: 186px;
               height: auto;
            }
            .text {
               color: #252a2b;
               font-size: 12.64px;
               line-height: 16px; /* 126.582% */
               letter-spacing: -0.126px;
               margin-top: -32px;
            }
         }
         .no-review {
            background: rgba(245, 245, 250, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 42px 16px;
            .noreviewtext {
               color: #252a2b;
               font-size: 16px;
               line-height: 16px; /* 100% */
               letter-spacing: -0.16px;
               margin-bottom: 16px;
               text-align: center;
            }
         }

         .btn-product-review {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;

            width: fit-content;
            min-width: 141px;
            height: 40px;
            border-radius: 32px;
            background: #ff6347;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
               color: $primary;
               border: 1px solid #ff6347;
               background: #fff9f8;
            }
            @include media-down(md) {
               font-size: 12.64px;
               &:hover,
               &:focus {
                  color: #fff;
                  border: unset;
                  background: #ff6347;
               }
            }
         }

         .paginate-review {
            padding: 30px 0;
            margin-bottom: -24px;
         }

         .viewall {
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary;
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px; /* 126.582% */
            letter-spacing: -0.253px;
         }
      }
      .review-list {
         padding: 0 16px 16px;
         background-color: #fff;
         .custom-select {
            width: 100%;
            padding: 16px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .select-check {
               display: flex;
               align-items: center;
               .label {
                  color: #9f9f9f;
                  font-size: 12.64px;
                  line-height: 16px; /* 126.582% */
                  letter-spacing: -0.253px;
                  margin-right: 6px;
               }
               .select-text {
                  color: $primary;
                  font-size: 12.64px;
                  line-height: 16px; /* 126.582% */
                  letter-spacing: -0.253px;
                  margin-right: 6px;
                  svg {
                     display: none;
                  }
               }
            }
         }
         .customer-review-item {
            padding: 24px 0;
            @include media-down(md) {
               padding: 16px 0;
            }
            &:not(:last-child) {
               border-bottom: 1px solid #dddddd;
            }
            .profileInfo {
               display: flex;
               @include media-down(md) {
                  width: fit-content;
               }
               .avatar {
                  width: 40px;
                  height: 40px;
                  min-width: 40px;
                  border-radius: 50%;
                  margin-right: 8px;
                  overflow: hidden;

                  img {
                     width: 100%;
                     height: auto;
                     object-fit: cover;
                     object-position: center;
                  }
               }
               .info {
                  display: flex;
                  flex-direction: column;
                  // margin-bottom: 0;
                  // @include media-down(md) {
                  //    margin-bottom: 8px;
                  // }
                  p.name {
                     color: #252a2b;
                     font-size: 18px;
                     font-weight: 600;
                     line-height: 16px; /* 88.889% */
                     margin-bottom: 8px;
                     @include media-down(md) {
                        font-size: 12.64px;
                     }
                  }
                  .date {
                     color: #9f9f9f;
                     font-size: 14px;
                     line-height: 16px; /* 114.286% */
                     letter-spacing: 0.14px;
                     @include media-down(md) {
                        font-size: 11.24px;
                        line-height: 12px;
                     }
                  }
               }
            }

            .review-like {
               color: #9f9f9f;
               font-size: 14px;
               font-weight: 400;
               line-height: 16px; /* 114.286% */
               letter-spacing: -0.14px;
               display: flex;
               align-items: center;
               &.active {
                  color: $primary;
               }
            }
            .content {
               .review-description {
                  margin-top: 16px;
                  @include media-down(md) {
                     margin-top: 8px;
                  }
               }
               .title {
                  font-weight: 500;
                  font-size: 1.125rem;
                  color: map.get($text-colors, "default");
                  margin-bottom: 12px;
               }
               .des {
                  font-size: 1rem;
                  line-height: 20px;
                  color: map.get($text-colors, "default");
                  @include media-down(md) {
                     font-size: 12.64px;
                     letter-spacing: -0.126px;
                     line-height: 16px;
                  }
               }
               .raiting-by-user {
                  // margin-bottom: 16px;
                  // @include media-down(md) {
                  //    margin-bottom: 0;
                  // }
                  .MuiSvgIcon-root {
                     width: 18px;
                     height: 18px;
                     fill: #ffd12e;
                     @include media-down(md) {
                        width: 12px;
                        height: 12px;
                     }
                  }
               }
               .product-variant {
                  color: #666;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 20px;
                  margin-top: 16px;
                  @include media-down(md) {
                     font-size: 12.64px;
                     line-height: 16px;
                  }
               }
               .review-image-list {
                  display: flex;
                  flex-flow: row wrap;
                  @include media-down(md) {
                     margin-top: 8px;
                  }
                  .image-item {
                     width: 80px;
                     height: 80px;
                     aspect-ratio: 0.5;
                     margin-right: 8px;
                     border-radius: 8px;
                     overflow: hidden;
                  }
               }
            }
            .variant {
               p {
                  font-size: 16px;
                  line-height: 20px;
                  color: #666666;
               }
            }
            .created-at {
               width: 100%;
               text-align: right;
               font-size: 1rem;
               line-height: 20px;
               color: map.get($text-colors, "default");
            }
            .like {
               display: flex;
               align-items: center;
               .btn-like {
                  padding: 0;
                  opacity: 0.4;
               }
               .like-count {
                  font-size: 16px;
                  line-height: 20px;
                  color: #666666;
                  padding-left: 1rem;
               }
            }
         }
      }
      .empty-icon {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         padding-top: 32px;
         .text {
            color: #252a2b;
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px; /* 126.582% */
            letter-spacing: -0.126px;
            margin-top: -20px;
         }
      }
      .wrap-loading-icon {
         margin: 0;
         padding: 16px;
         display: flex;
         padding: 16px;
         justify-content: center;
      }
   }
   .wrap-action {
      position: fixed;
      bottom: 0;
      z-index: 1200;

      display: flex;
      align-items: center;
      gap: 8px;

      width: 100%;
      padding: 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;

      button.bookmark {
         color: #252a2b;
         font-size: 9px;
         line-height: 12px; /* 133.333% */
         letter-spacing: -0.09px;
         white-space: nowrap;

         background: unset;
         border: unset;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         svg {
            margin-bottom: 5px;
         }
      }

      button.btn {
         width: 100%;
         text-align: center;
         font-size: 12.64px;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.253px;
         height: 40px;
         border-radius: 40px;
         border: 1px solid $primary;
         &.btn-add {
            color: $primary;
            font-weight: 400;
            background-color: #fff;
         }
         &.btn-buy {
            color: #fff;
            font-weight: 600;
            background-color: $primary;
         }
      }
   }
}
