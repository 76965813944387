@import "../../../styles/variables.scss";

.DropdownSelect {
   position: relative;

   .wrap-input,
   .wrap-input-1 {
      position: relative;
      input {
         width: 100%;
         padding-left: 14px;
      }
      svg {
         position: absolute;
         top: 50%;
         right: 16px;
         transform: translateY(-50%) rotate(-180deg);
      }
      &.active {
         svg {
            transform: translateY(-50%) rotate(0);
         }
      }
   }

   .wrap-input input {
      padding-left: 0;
   }

   &__select {
      position: absolute;
      top: 44px;
      left: 0;
      right: 0;
      border-radius: 8px;
      border: 1px solid #e2e4eb;
      overflow: hidden;
      z-index: 1;
      .tabs {
         padding: 0 16px;
         display: flex;
         justify-content: space-between;
         border-bottom: 1px solid #e2e4eb;
         background: #fff;
         &-name {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            width: 100%;
            &.active {
               color: #252a2b;
               font-size: 14px;
               font-weight: 600;
               line-height: normal;
               &::after {
                  content: "";
                  position: absolute;
                  bottom: 8px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 16px;
                  height: 3px;
                  background: $primary;
                  border-radius: 4px;
               }
            }
         }
      }
      .dropdown-list {
         padding: 8px 0;
         height: 250px;
         max-height: 250px;
         background: #fff;
         overflow-x: hidden;
         overflow-y: auto;
         position: relative;
         &--item {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            cursor: pointer;

            &::before {
               content: "";
               position: absolute;
               left: 0;
               width: 3px;
               height: 24px;
               border-radius: 0px 30px 30px 0px;
               background: #ff6347;
               display: none;
            }

            .right-icon {
               display: none;
            }

            &.active {
               color: #ff6347;
               .right-icon {
                  display: block;
               }
            }
            &:hover {
               background: linear-gradient(0deg, rgba(226, 228, 235, 0.2) 0%, rgba(226, 228, 235, 0.2) 100%), #fff;
               &::before {
                  display: block;
               }
            }
         }

         .w-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }
}

.dialog-list-address-order {
   overflow: hidden;
   --header-height-dsk: 65px;
   &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: var(--header-height-dsk);
      padding: 16px;
      border-bottom: 1px solid $border;
      background: #fff;
      position: absolute;
      top: 0;
      z-index: 1;
      @include media-down(md) {
         flex-direction: column;
         padding: 0;
         height: unset;
         position: unset;
      }
      .title {
         font-size: 18px;
         font-weight: 500;
         line-height: 24px;
         @include media-down(md) {
            height: 56px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
         }
      }
      button {
         width: fit-content;
         height: 32px;
         border-radius: 8px;
         border: 1px solid $primary;
         @include media-down(md) {
            height: 44px !important;
            border: unset !important;
            border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
         }
      }
   }
   .list-address {
      max-height: 600px;
      padding: var(--header-height) 0 16px;
      overflow-y: auto;
      padding-top: var(--header-height-dsk);
      @include media-down(md) {
         padding: 0;
         max-height: calc(100vh - 56px - 89px - 45px);
      }
      .item {
         width: 100%;
         padding: 16px 24px;
         display: flex;
         @include media-down(md) {
            padding: 16px;
         }
         &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
         }
         .w-checkbox {
            margin-right: 16px;
            @include media-down(md) {
               margin-right: 8px;
            }
         }

         .current-user-address {
            flex: 1 1 auto;
         }
         .w-name {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            .name {
               font-size: 16px;
               font-weight: 600;
               line-height: 16px;
               margin-right: 4px;
               @include media-down(md) {
                  font-size: 12.64px;
               }
            }
            .phone {
               font-size: 16px;
               font-weight: 400;
               line-height: 16px;
               margin-left: 4px;
               @include media-down(md) {
                  font-size: 12.64px;
               }
            }
         }
         .btn-edit {
            color: $primary;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            @include media-down(md) {
               font-size: 12.64px;
            }
         }
         .user-address {
            color: $span;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 8px;
            @include media-down(md) {
               font-size: 12px;
               margin-bottom: 4px;
            }
         }
         .full-address {
            color: $span;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            @include media-down(md) {
               font-size: 12px;
            }
         }
         .tag-default {
            width: fit-content;
            background: $primary;
            border-radius: 3px;
            padding: 2px 8px;
            font-size: 12px;
            color: #fff;
            font-weight: 600;
            margin-top: 8px;
            display: flex;
            align-items: center;
            @include media-down(md) {
               font-size: 10px;
            }
         }
      }
   }

   .wrap-action {
      display: flex;
      justify-content: center;
      padding: 24px 16px;
      gap: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      button {
         max-width: 160px;
      }
   }
}
