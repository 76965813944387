@import "../../styles/variables.scss";

.notifi-overlap {
   position: absolute;
   width: fit-content;
   height: fit-content;
   z-index: 9000;
   transition: 0.3s ease;

   @include media-down(sm) {
      width: 100%;
   }

   &.show {
      opacity: 1;
   }

   &.hide {
      opacity: 0;
   }

   // Position
   &.top {
      top: 16px !important;
      bottom: unset !important;
      left: 50% !important;
      right: unset !important;
      transform: translateX(-50%) !important;
   }

   &.bottom {
      top: unset !important;
      right: unset !important;
      bottom: 16px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
   }

   &.center {
      top: 50% !important;
      left: 50% !important;
      right: unset !important;
      bottom: unset !important;
      transform: translate(-50%, -50%) !important;
   }

   // Type
   &.default {
      position: fixed;
      padding: 0 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .overlap-style {
         width: 100%;
         padding: 12px;
         border-radius: 8px;
         border: 1px solid rgba(255, 255, 255, 0.1);
         background: rgba(111, 111, 111, 0.9);

         display: flex;
         align-items: center;
         flex-direction: column;
         gap: 6px;

         .des {
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            /* 140.647% */
            letter-spacing: -0.142px;
            text-align: center;
         }
      }
   }

   &.addProduct {
      width: fit-content;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .overlap-style {
         width: 100%;
         max-width: 200px;
         height: auto;
         padding: 16px 32px;
         border-radius: 8px;
         border: 1px solid rgba(255, 255, 255, 0.1);
         background: rgba(111, 111, 111, 0.9);
         display: flex;
         flex-direction: column;
         align-items: center;

         @include media-down(md) {
            padding: 12px;
            max-width: 182px;
         }

         svg {
            width: 29px;
            height: 32px;
            margin-bottom: 12px;
         }

         .des {
            color: #fff;
            font-size: 14.22px;
            font-weight: 400;
            line-height: 20px;
            /* 140.647% */
            letter-spacing: -0.142px;
            text-align: center;
         }
      }
   }

   &.addBookmark {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .overlap-style {
         width: 100%;
         max-width: 200px;
         height: auto;
         padding: 16px 32px;
         border-radius: 8px;
         border: 1px solid rgba(255, 255, 255, 0.1);
         background: rgba(111, 111, 111, 0.9);
         display: flex;
         flex-direction: column;
         align-items: center;

         @include media-down(md) {
            padding: 12px;
            max-width: 182px;
         }

         svg {
            width: 29px;
            height: 32px;
            margin-bottom: 12px;
         }

         .des {
            color: #fff;
            font-size: 14.22px;
            font-weight: 400;
            line-height: 20px;
            /* 140.647% */
            letter-spacing: -0.142px;
            text-align: center;
         }
      }
   }

   &.notiBottom {
      position: fixed;
      bottom: 72px;
      left: 50%;
      transform: translateX(-50%);

      .overlap-style {
         width: 100%;
         max-width: 90%;
         margin: 0 auto;
         padding: 12px 16px;
         border-radius: 8px;
         border: 1px solid rgba(255, 255, 255, 0.1);
         background: rgba(111, 111, 111, 0.9);

         .des {
            color: #fff;
            font-size: 14.22px;
            font-weight: 400;
            line-height: 20px;
            /* 140.647% */
            letter-spacing: -0.142px;
            text-align: center;
         }
      }
   }

   &.addDiscount {
      width: fit-content;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .overlap-style {
         width: 100%;
         max-width: 200px;
         height: auto;
         padding: 16px;
         border-radius: 8px;
         border: 1px solid rgba(255, 255, 255, 0.1);
         background: rgba(111, 111, 111, 0.9);
         display: flex;
         flex-direction: column;
         align-items: center;

         @include media-down(md) {
            padding: 12px;
            max-width: 182px;
         }

         svg {
            width: 29px;
            height: 32px;
            margin-bottom: 12px;
         }

         .des {
            color: #fff;
            font-size: 14.22px;
            font-weight: 400;
            line-height: 20px;
            /* 140.647% */
            letter-spacing: -0.142px;
            text-align: center;
         }
      }
   }
}

.dialog-noti {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(241, 241, 241, 0.9);
   z-index: 1350;
   display: none;

   &.open {
      display: block;
   }

   .main {
      position: fixed;
      background: #ffffff;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      max-width: 348px;
      padding: 20px;

      .panel {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         .content {
            color: map.get($text-colors, "default");
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 12px;
            white-space: break-spaces;
         }

         .btn {
            // background: #252525;
            color: #fff;
            min-width: 100px;
            padding: 8px;
         }
      }
   }
}
