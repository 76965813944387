@use "sass:map";
@import "../../../styles/variables.scss";

.spacet-product-detail {
   position: relative;
   background-color: #fff;
   border-top: 1px solid #0000000d;

   @include media-down(md) {
      padding-bottom: 73px;
   }

   .wrap-breadcrumbs {
      padding: 20px 0;

      @include media-down(md) {
         padding: 12px 16px;
      }
   }

   .info_general_product {
      padding: 0;
      background-color: #fff;
      display: flex;
   }

   .container {
      @include media-down(md) {
         padding: 0 !important;
      }
   }

   .product-info-left {
      width: 53%;
      margin-right: 16px;

      @include media-down(md) {
         width: 100%;
      }
   }

   .product-info-right {
      width: 47%;
      margin-left: 16px;

      @include media-down(md) {
         width: 100%;
      }
   }

   .image-slider {
      .main-slide {
         border: 1px solid #e2e4eb;
         position: relative;

         .sale-label {
            position: absolute;
            top: 16px;
            left: 16px;
            z-index: 1;

            color: #fff;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            border-radius: 4px;
            width: fit-content;
            height: fit-content;
            padding: 2px 4px;
            background-color: $primary;
            border: 1px solid var(--Orange-orange-400, #ff826c);

            display: flex;
            align-items: center;

            svg {
               width: 24px;
               height: 24px;
               margin-right: 4px;
            }
         }

         .tag-campain {
            position: absolute;
            top: unset;
            right: unset;
            left: 0;
            bottom: 0;
            z-index: 1;

            width: auto;
            height: 32px;
            object-fit: contain;
         }

         .product-stock-out {
            position: absolute;
            top: 50%;
            left: 50%;
            right: unset;
            bottom: unset;
            transform: translate(-50%, -50%);
            z-index: 1;

            width: 96px;
            height: 96px;
            border-radius: 50%;

            color: #fff;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            background: rgba(37, 42, 43, 0.9);
            display: flex;
            align-items: center;
            justify-content: center;
         }

         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: "";
            // z-index: 101;
            z-index: 2;
         }

         .main-slide-image {
            object-fit: contain;
            opacity: 0;
            transition: 0.4s ease;

            &.active {
               opacity: 1;
               z-index: 1;
            }
         }
      }

      .sub-slide {
         .slick-list {
            margin-left: -8px;
         }

         .slick-slide > div {
            padding: 0 8px;
         }

         .slick-arrow {
            width: 48px;
            height: 48px;
            z-index: 1;
         }

         .slick-prev {
         }

         .slick-disabled {
            opacity: 0.5;
         }

         .slide-item {
         }

         .ratio {
            .cover {
               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
               z-index: 102;
            }
         }

         img {
            background-color: #fff;
         }

         .sub-slide-image {
            object-fit: cover;
            border: 2px solid transparent;
            transition: 0.4s ease;
            cursor: pointer;

            &.active {
               padding: 4px;
               border: 2px solid #252a2b;
               // box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
            }
         }
      }
   }

   .detail {
      .vendor {
         font-size: 1rem;
         font-weight: 400;

         .vendor_name {
            margin-left: 2px;
            color: #0055aa;
         }
      }

      .title_product {
         color: #252a2b;
         font-size: 18px;
         font-weight: 500;
         line-height: 24px;
         /* 133.333% */
         letter-spacing: 0.36px;
      }

      button.btn-share {
         display: flex;
         padding: 0;
         margin: 0;
         background: unset;
         border: unset;
         outline: unset;
      }

      button.add-bookmark {
         // display: flex;
         margin-left: 20px;
         padding: 0;
         border: none;
         outline: none;
         width: max-content;
         background: none;
         transition: box-shadow 0.4s ease;
      }

      .flash-sale {
         .bg {
            padding: 8px 16px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            background-image: url("/assets/img/flash-sale-detail-bg.png");
            background-position: center;
            background-size: cover;
         }

         .name {
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px 1px rgba(31, 31, 31, 0.5);
            font-size: 20px;
            font-weight: 800;
            line-height: 16px;
            /* 80% */
         }

         .flash-sale-end {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            /* 100% */
            margin-right: 6px;
         }

         .countdown {
            display: flex;
            align-items: center;

            & > span {
               font-size: 12px;
               margin: 0 4px;
            }

            & > div {
               color: #ff383b;
               font-size: 14px;
               font-weight: 600;
               line-height: 16px;
               /* 114.286% */
               display: flex;
               align-items: center;
               justify-content: center;
               width: 24px;
               height: 20px;
               border-radius: 2px;
               background: #fff;
            }
         }
      }

      .sku {
         font-size: 1rem;
         opacity: 0.6;
         text-transform: uppercase;
      }

      .product-rating {
         margin-top: 6px;
         display: flex;
         align-items: center;
         color: #252a2bb3;

         .MuiRating-decimal {
            color: #ffd12e;
         }

         .sup {
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            color: #252a2b;
            margin-right: 4px;
            position: relative;

            &.none-line {
               &::before {
                  content: none !important;
               }
            }
         }

         .space {
            width: 4px;
            height: 4px;
            background: #252a2bb3;
            border-radius: 50%;
            opacity: 0.6;
            margin: 0 12px;
         }
      }

      .price {
         font-size: 1.5rem;
         font-weight: 500;
         display: flex;
         flex-wrap: wrap;
         row-gap: 6px;
         align-items: center;
         line-height: 24px;

         .discount-price {
            color: #ff6347;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            margin-right: 8px;

            span {
               text-decoration-line: underline;
               font-weight: 500;
            }
         }

         .real-price {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-decoration: line-through;
            white-space: nowrap;
         }

         .percent-discount {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.42px;
            text-align: center;
            background-color: #ff6347;
            width: max-content;
            height: 20px;
            min-width: 68px;
            border-radius: 4px;
            padding: 0px 4px;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }

      .color {
         position: relative;

         .name-color {
            width: 100%;
            font-size: 1rem;
            color: map.get($text-colors, "default");
         }

         .variant-color {
            width: 30px;
            height: 30px;
            cursor: pointer;
            appearance: none;
            border: 1px solid;
            border-radius: 50%;
            border-color: #000;

            &:checked[type="radio"] {
               border: 1px solid;
               border-radius: 50%;
               border-color: #000;
            }

            &:checked[type="radio"] + .label-color {
               opacity: 1;
            }
         }

         .label-color {
            position: absolute;
            height: 24px;
            width: 24px;
            top: 27px;
            left: 27px;
            opacity: 0;
            background-color: gray;
            border-radius: 50%;
         }
      }

      .wrap-attr {
         margin-bottom: 16px;

         .attr-row {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            &.error {
               margin-left: -16px;
               margin-right: -16px;
               padding: 16px;
               background: rgba(255, 239, 237, 0.5);

               .error-text {
                  color: $primary;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  /* 114.286% */
                  text-align: center;
                  margin-top: 16px;
               }
            }
         }

         .wrap-style {
            display: flex;
            flex-direction: column;
            // margin-bottom: 24px;

            & > div:not(:first-child) {
               margin-top: 32px;
            }
         }

         .attr-label {
            color: rgba(37, 42, 43, 0.7);
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            /* 125% */
            min-width: 120px;
         }

         .color-group {
            display: flex;
            // align-items: center;

            .color-list {
               display: flex;
               flex-flow: row wrap;
               row-gap: 16px;
            }

            .color-item {
               height: fit-content;
               border-radius: 50%;
               margin-right: 16px;
               border: 1px solid transparent;
               padding: 2px;
               position: relative;
               cursor: pointer;

               .disabled-icon {
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  top: -2px;
                  right: -3px;
                  z-index: 1;
                  border: 1px solid #fff;
                  border-radius: 50%;
                  background: #fff;
               }

               .color {
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  border: 1px solid rgba(0, 0, 0, 0.1);
               }

               &.active {
                  border: 1px solid #000;
               }

               &.disabled {
                  opacity: 0.3;
                  cursor: not-allowed;

                  .color {
                     cursor: not-allowed;
                  }
               }
            }
         }

         .size-group {
            display: flex;

            // align-items: center;
            .size-list {
               display: flex;
               // align-items: center;
               // overflow-x: auto;
               flex-flow: row wrap;
               row-gap: 8px;
            }

            .size-item {
               width: max-content;
               min-width: 67px;
               height: 32px;
               border: 1px solid #252a2b;
               text-align: center;
               margin-right: 0.5rem;
               padding: 2px;
               border-radius: 10px;
               border: 1px solid #e2e4eb;
               background: #fff;
               display: flex;
               align-items: center;
               justify-content: center;
               white-space: nowrap;
               cursor: pointer;
               overflow: hidden;

               .size {
                  font-size: 1rem;
                  width: 100%;
                  height: 100%;
                  padding: 2px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
               }

               &.active {
                  border-width: 1px;
                  border-color: #000;
               }

               @include media-up(md) {
                  &:hover {
                     border-color: #000;
                  }
               }

               &.disabled {
                  border: 1px dashed #e2e4eb;
                  background: #fff;
                  cursor: not-allowed;

                  .size {
                     color: #e2e4eb;
                  }
               }
            }

            .variant-thumbnail-img {
               width: auto;
               height: 100%;
               object-fit: contain;
               margin-right: 4px;
               border-radius: 8px;
            }
         }

         .delivery {
            display: flex;
            margin-bottom: 24px;

            .delivery-info {
               .address {
                  display: flex;
                  align-items: flex-start;
                  white-space: nowrap;

                  .left {
                     min-height: 32px;
                     display: flex;
                     align-items: center;
                     white-space: nowrap;

                     .icon {
                        margin-right: 6px;
                     }

                     span {
                        font-size: 14px;
                        line-height: 16px;
                        /* 114.286% */
                        margin-right: 12px;
                        white-space: normal;
                     }
                  }
               }

               .right-text {
                  display: flex;
                  // align-items: center;
                  min-height: 32px;
               }
            }
         }

         .quantity {
            display: flex;
            align-items: center;

            .quantity-info {
               display: flex;
               align-items: center;

               .inputcount {
                  display: flex;
                  align-items: center;

                  .minus,
                  input,
                  .plus {
                     height: 40px;
                  }

                  .minus,
                  .plus {
                     color: #000;
                     font-size: 16px;
                     font-weight: 400;
                     line-height: 16px;
                     /* 100% */
                     width: 40px;
                     height: 40px;
                     border: 1px solid rgba(0, 0, 0, 0.05);
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     background: transparent;
                     cursor: pointer;
                  }

                  input {
                     color: #252a2b;
                     font-size: 16px;
                     font-weight: 400;
                     line-height: 16px;
                     /* 100% */
                     letter-spacing: -0.16px;
                     width: 88px;
                     border: unset;
                     border-top: 1px solid rgba(0, 0, 0, 0.05);
                     border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                     text-align: center;
                     outline: unset;

                     &:hover,
                     &:focus {
                        border: unset;
                        border-top: 1px solid rgba(0, 0, 0, 0.05);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                     }
                  }
               }
            }
         }
      }

      .content_delivery {
         .item_delivery {
            .img_icon {
               margin-right: 10px;
            }

            &:not(:last-child) {
               margin-bottom: 6px;
            }
         }
      }

      .buy-to-reward {
         display: flex;
         align-items: center;
         margin-top: 10px;

         span {
            color: #d98f00;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            letter-spacing: -0.14px;
         }
      }

      .add-to-cart {
         margin-bottom: 24px;

         a.add-to-cart-button,
         button.add-to-cart-button {
            width: 100%;
            height: 48px;
         }

         button.buy-now-button {
            border: none;
            outline: none;
            width: 100%;
            height: 48px;
            padding: 10px;
            border-radius: 40px;
            color: #ffffff;
            background-color: #ff6347;
            transition: box-shadow 0.4s ease;

            &:hover {
               box-shadow: 0 0 10px rgb(0 0 0 / 25%);
            }
         }

         button.add-bookmark {
            border: none;
            outline: none;
            width: max-content;
            min-width: 59px;
            height: 100%;
            border-radius: 4px;
            padding: 1.025rem;
            background: #fff;
            border: 1px solid #ff6347;
            transition: box-shadow 0.4s ease;

            img {
               filter: invert(50%) sepia(70%) saturate(4103%) hue-rotate(337deg)
                  brightness(118%) contrast(101%);
            }

            &:hover {
               box-shadow: 0 0 10px rgb(0 0 0 / 25%);
            }

            &:hover,
            &.active {
               img {
                  filter: invert(50%) sepia(70%) saturate(4103%)
                     hue-rotate(337deg) brightness(118%) contrast(101%);
               }
            }
         }
      }

      .brand-detail {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 12px 16px;
         background: rgba(245, 245, 250, 0.5);

         .logo {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 16px;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
               width: 100%;
               object-fit: contain;
               object-position: center;
            }
         }

         .brand-info {
            display: flex;
            align-items: center;

            .name {
               color: #252a2b;
               font-size: 18px;
               font-weight: 400;
               line-height: 16px;
               /* 88.889% */
               margin-bottom: 8px;
            }

            span {
               color: #9f9f9f;
               font-size: 14px;
               font-weight: 400;
               line-height: 16px;
               /* 114.286% */
               letter-spacing: -0.14px;
            }

            .space {
               display: block;
               width: 4px;
               height: 4px;
               margin: 8px;
               border-radius: 50%;
               background-color: #acacac;
            }
         }

         .btn-viewshop {
            color: #ff6347;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            letter-spacing: -0.14px;
            padding: 8px 12px;
            min-width: 108px;
            height: 32px;
            border-radius: 8px;
            border: 1px solid #ff6347;
            background: #fff9f8;
            display: flex;
            align-items: center;

            svg {
               margin-right: 4px;
            }
         }
      }

      .policy_return {
         color: #252a2b;
         font-size: 16px;
         font-weight: 400;
         line-height: 16px;
         /* 100% */

         padding: 4px 16px;
         display: flex;
         align-items: center;
         justify-content: space-between;
      }

      .content {
         text-align: justify;

         p {
            margin-bottom: 0 !important;
         }

         #gtx-trans {
            display: none;
         }

         div,
         table {
            width: 100% !important;
         }
      }
   }

   .detail_description_product {
      background: rgba(245, 245, 250, 0.5);
      margin-top: 40px;
      padding: 32px;

      @include media-down(md) {
         margin: 0;
         padding: 16px;
      }

      .title_detail {
         color: #252a2b;
         margin-bottom: 24px;
         font-weight: 500;
         line-height: 26px;
         font-size: 22px;
         text-transform: uppercase;

         @include media-down(md) {
            text-transform: math-auto;
            font-size: 14.22px;
            line-height: 16px;
         }
      }

      .title_description {
         color: #252a2b;
         font-size: 20px;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 24px;
      }

      .item {
         display: flex;
         margin-bottom: 16px;

         @include media-down(md) {
            margin-bottom: 10px;
         }

         .label {
            color: #9f9f9f;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            /* 100% */
            letter-spacing: -0.16px;
            min-width: 160px;

            @include media-down(md) {
               color: #252a2b;
               font-size: 12.64px;
               letter-spacing: -0.126px;
               min-width: 114px;
               padding-left: 12px;
               position: relative;

               &::after {
                  content: "";
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: black;
                  position: absolute;
                  left: 0px;
                  top: 6px;
               }
            }
         }

         .value {
            color: #252a2b;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            /* 100% */
            letter-spacing: -0.16px;

            @include media-down(md) {
               font-size: 12.64px;
            }
         }

         .vendor_name {
            color: $link;
            line-height: 16px;

            @include media-down(md) {
               font-size: 12.64px;
            }
         }
      }

      .breadcrumb_detail {
         line-height: 16px;

         .route_item {
            a {
               color: $link;

               @include media-down(md) {
                  font-size: 12.64px;
               }
            }

            // .route_name {
            //    color: $link;
            //    cursor: pointer;
            //    &:hover {
            //       color: #0055aa;
            //    }
            // }

            &:not(:first-child) {
               &::before {
                  content: ">";
                  cursor: default;
                  color: #252a2b;
                  margin-left: 6px;
                  margin-right: 6px;
               }
            }
         }

         @media (max-width: map.get($grid-breakpoints, "md")) {
            margin-top: 0;
         }
      }
   }

   .who-sale-action {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      row-gap: 16px;
      margin-bottom: 16px;
      padding: 16px;
      border-top: 1px dashed #e2e4eb;
      border-bottom: 1px dashed #e2e4eb;
      @include media-down(md) {
         border-top: unset;
         border-bottom: 8px solid rgb(245, 245, 250);
      }
      .label {
         font-size: 16px;
         font-weight: 400;
         color: #697f90;
         margin-right: 24px;
         @include media-down(md) {
            color: $text;
            font-weight: 500;
         }
      }

      a {
         button {
            color: #005993;
            font-size: 14px;
            font-weight: 400;
         }

         &:first-child button {
            padding-right: 16px;
            border-right: 1px solid #eef2f5;
         }
         &:last-child button {
            margin-left: 16px;
         }

         button svg {
            margin-right: 4px;
         }
      }
   }

   .describe {
      background: rgba(245, 245, 250, 0.5);
      margin-top: 40px;
      padding: 32px;
      padding-bottom: 24px;
      position: relative;

      @include media-down(md) {
         margin: 0;
         border-top: 1px solid #f3f3f3;
         padding: 8px 16px 16px;
      }

      .title_detail {
         color: #252a2b;
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         text-transform: uppercase;
         margin-bottom: 24px;

         @include media-down(md) {
            font-size: 14.22px;
            text-transform: math-auto;
            margin-bottom: 8px;
         }
      }

      .title_description {
         color: #252a2b;
         font-size: 20px;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 24px;
      }

      .btn_loadmore {
         color: #ff6347;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
         /* 114.286% */
         letter-spacing: -0.14px;

         background: transparent;
         cursor: pointer;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 14px;
         padding: 8px;
         margin: 24px auto 0;
         height: 40px;
         width: 111px;
         border-radius: 8px;
         border: 1px solid #ff6347;
         transition: 0.2s ease;
         box-shadow: 0px -12px 10px rgb(239 239 239 / 50%);

         @include media-down(md) {
            background: transparent;
            border: unset;
            box-shadow: unset;
            margin: 16px auto 0;
            height: 16px;
         }

         &:hover {
            color: #fff;
            background-color: $primary;

            @include media-down(md) {
               color: #ff6347;
               background: transparent;
            }
         }
      }

      img {
         max-width: 100%;
      }
   }

   .cart-statistic {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 1040; // 1030;
      background-color: #00000060;
      transition: 0.4s ease;
      animation: initAnimBackground 0.4s ease;

      &.hide {
         background-color: transparent;

         .right-panel {
            right: -355px;
         }
      }

      @keyframes initAnimBackground {
         from {
            background-color: transparent;
         }

         to {
            background-color: #00000060;
         }
      }

      .right-panel {
         position: absolute;
         background-color: #ffffff;
         width: 350px;
         height: 100%;
         top: 0;
         right: 0;
         overflow: hidden auto;
         transition: 0.4s ease;
         animation: initAnim 0.4s ease;

         @keyframes initAnim {
            from {
               right: -355px;
            }

            to {
               right: 0;
            }
         }

         .order-product {
            .img {
               width: 100%;
               height: 100%;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .size {
               p {
                  margin: 0;
                  font-weight: 500;
               }
            }

            .name {
               font-size: 1.125rem;
            }

            .quantity {
               font-size: 0.875rem;
               padding: 3px 8px;
               border-radius: 4px;
               background-color: #cccccc;
            }

            .price {
               font-weight: 500;
            }

            .real-price {
               font-size: 0.875rem;
               padding-left: 4px;
               text-decoration: line-through;
            }
         }

         .direct-button {
            text-align: center;
            display: block;
            text-transform: uppercase;
            padding: 8px 0;
            color: #ffffff;
            text-decoration: none;
            background-color: #252a2b;
            font-size: 0.875rem;
            width: 100%;
            border-radius: 4px;
         }
      }
   }

   .wrapper_review {
      margin-top: 34px;
      background-color: #fff;

      @include media-down(md) {
         padding: 16px;
         margin-top: 0;
      }

      .title-review {
         color: #252a2b;
         font-size: 20px;
         font-weight: 700;
         text-transform: uppercase;
         margin-bottom: 24px;

         @include media-down(md) {
            text-transform: capitalize;
            font-size: 16px;
            line-height: 16px;
         }
      }

      .with-review {
         background: rgba(245, 245, 250, 0.5);
         padding: 24px;
         margin-top: 16px;
         display: flex;
         align-items: center;

         @include media-down(md) {
            padding: 0;
            background: transparent;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
         }

         .info-left {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 64px;

            @include media-down(md) {
               width: 100%;
               margin-right: unset;
               flex-direction: row;
               justify-content: space-between;
               padding-bottom: 14px;
            }

            .rating-count {
               display: flex;
               align-items: flex-end;
               justify-content: center;
               margin-bottom: 8px;

               @include media-down(md) {
                  justify-content: flex-start;
               }

               .real-value {
                  color: #252a2b;
                  font-size: 34px;
                  font-weight: 500;
                  line-height: 20px;

                  /* 58.824% */
                  @include media-down(md) {
                     font-size: 24px;
                     line-height: 16px;
                     /* 66.667% */
                  }
               }

               .max-value {
                  color: #9f9f9f;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 16px;

                  /* 66.667% */
                  @include media-down(md) {
                  }
               }
            }

            .rating-star {
               margin-bottom: 8px;
            }
         }

         .info-right {
            display: flex;
            flex-direction: column;

            button {
               color: #252a2b;
               font-size: 16px;
               font-weight: 400;
               line-height: 16px;
               /* 100% */
               height: 40px;
               padding: 2px 16px;
               min-width: 120px;
               margin-right: 16px;
               margin-bottom: 16px;
               border-radius: 8px;
               border: 1px solid #e2e4eb;
               background: #fff;
               display: flex;
               align-items: center;
               justify-content: center;

               &:hover,
               &.active {
                  color: $primary;
                  border: 1px solid #ff6347;
                  background: #fff9f8;
               }
            }
         }
      }

      .product-review-empty {
         padding: 24px 0;
         min-height: 144px;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;

         img {
            width: 186px;
            height: auto;
         }

         .text {
            color: #252a2b;
            font-size: 12.64px;
            line-height: 16px;
            /* 126.582% */
            letter-spacing: -0.126px;
            margin-top: -32px;
         }
      }

      .no-review {
         background: rgba(245, 245, 250, 0.5);
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         padding: 42px 16px;

         .noreviewtext {
            color: #252a2b;
            font-size: 16px;
            line-height: 16px;
            /* 100% */
            letter-spacing: -0.16px;
            margin-bottom: 16px;
            text-align: center;
         }
      }

      .btn-product-review {
         color: #fff;
         font-size: 16px;
         font-weight: 500;
         line-height: normal;

         width: fit-content;
         min-width: 141px;
         height: 40px;
         border-radius: 32px;
         background: #ff6347;
         display: flex;
         align-items: center;
         justify-content: center;

         &:hover {
            color: $primary;
            border: 1px solid #ff6347;
            background: #fff9f8;
         }

         @include media-down(md) {
            font-size: 12.64px;

            &:hover,
            &:focus {
               color: #fff;
               border: unset;
               background: #ff6347;
            }
         }
      }

      .paginate-review {
         padding: 30px 0;
         margin-bottom: -24px;
      }

      .viewall {
         width: 100%;
         height: 32px;
         display: flex;
         align-items: center;
         justify-content: center;
         color: $primary;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.253px;
      }
   }

   .review-list {
      .customer-review-item {
         padding: 24px 0;

         @include media-down(md) {
            padding: 16px 0 10px;
         }

         &:not(:last-child) {
            border-bottom: 1px solid #dddddd;
         }

         .profileInfo {
            display: flex;

            @include media-down(md) {
               width: fit-content;
            }

            .avatar {
               width: 40px;
               height: 40px;
               min-width: 40px;
               border-radius: 50%;
               margin-right: 8px;
               overflow: hidden;

               img {
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                  object-position: center;
               }
            }

            .info {
               display: flex;
               flex-direction: column;
               margin-bottom: 0;

               @include media-down(md) {
                  margin-bottom: 8px;
               }

               p.name {
                  color: #252a2b;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 20px;
                  /* 88.889% */
                  margin-bottom: 8px;
                  max-width: 130px;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @include media-down(md) {
                     font-size: 12.64px;
                  }
               }

               .date {
                  color: #9f9f9f;
                  font-size: 14px;
                  line-height: 16px;
                  /* 114.286% */
                  letter-spacing: 0.14px;

                  @include media-down(md) {
                     font-size: 11.24px;
                     line-height: 12px;
                  }
               }
            }
         }

         .raiting-by-user {
            // margin-bottom: 16px;
            // @include media-down(md) {
            //    margin-bottom: 0;
            // }
            .MuiSvgIcon-root {
               width: 18px;
               height: 18px;
               fill: #ffd12e;

               @include media-down(md) {
                  width: 12px;
                  height: 12px;
               }
            }
         }

         .review-like {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            letter-spacing: -0.14px;
            display: flex;
            align-items: center;

            &.active {
               color: $primary;
            }
         }

         .content {
            .review-description {
               margin-top: 16px;

               @include media-down(md) {
                  margin-top: 8px;
               }
            }

            .title {
               font-weight: 500;
               font-size: 1.125rem;
               color: map.get($text-colors, "default");
               margin-bottom: 12px;
            }

            .des {
               font-size: 1rem;
               line-height: 20px;
               color: map.get($text-colors, "default");

               @include media-down(md) {
                  font-size: 12.64px;
                  letter-spacing: -0.126px;
                  line-height: 16px;
               }
            }

            .product-variant {
               color: #666;
               font-size: 16px;
               font-weight: 400;
               line-height: 20px;
               margin-top: 16px;

               @include media-down(md) {
                  font-size: 12.64px;
                  line-height: 16px;
                  margin-top: 8px;
               }
            }

            .review-image-list {
               display: flex;
               flex-flow: row nowrap;
               margin-top: 16px;
               overflow-x: auto;
               overflow-y: hidden;

               .image-item {
                  width: 80px;
                  height: 80px;
                  min-width: 80px;
                  aspect-ratio: 0.5;
                  margin-right: 8px;
                  border-radius: 8px;
                  overflow: hidden;
                  cursor: pointer;

                  @include media-down(md) {
                     margin-bottom: 8px;
                  }

                  // img {
                  // -webkit-user-drag:none;
                  // }
               }
            }

            .review-image {
               overflow: hidden;

               img {
                  width: 100%;
                  height: auto;
                  max-width: 504px;
                  max-height: 504px;
                  margin-top: 16px;
                  object-fit: cover;
               }
            }
         }

         .variant {
            p {
               font-size: 16px;
               line-height: 20px;
               color: #666666;
            }
         }

         .created-at {
            width: 100%;
            text-align: right;
            font-size: 1rem;
            line-height: 20px;
            color: map.get($text-colors, "default");
         }

         .like {
            display: flex;
            align-items: center;

            .btn-like {
               padding: 0;
               opacity: 0.4;
            }

            .like-count {
               font-size: 16px;
               line-height: 20px;
               color: #666666;
               padding-left: 1rem;
            }
         }
      }
   }

   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .bg-gray {
         padding: 20px;

         .none-review {
            .title-review {
               padding-bottom: 20px;
            }
         }

         .rating {
            padding: 0;
            padding-top: 12px;

            .rating-count {
               .rating-count-title-mb {
                  margin: 0;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  color: map.get($text-colors, "default");
                  margin-left: 12px;

                  span {
                     color: #666666;
                  }
               }
            }

            .detail-mb {
               padding-top: 12px;
            }
         }
      }
   }

   .product-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 64px 0;
      border-bottom: 1px solid $border;

      @include media-down(sm) {
         border-top: 8px solid $border;
         border-bottom: 8px solid $border;
      }

      svg {
         width: 152px;
         height: 138px;
         margin-bottom: 16px;

         @include media-down(sm) {
            margin-bottom: 12px;
         }
      }

      .text {
         margin-bottom: 16px;

         @include media-down(sm) {
            margin-bottom: 12px;
         }

         p {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;
            text-align: center;

            @include media-down(sm) {
               font-size: 12px;
               line-height: 16px;
            }
         }
      }
   }
}

.flash-sale-mb {
   height: 78px;
   padding: 0px 16px;
   margin-bottom: 12px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-size: contain;
   background-position: bottom;
   background-repeat: no-repeat;
   background-size: 100% 100%;

   .price {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;

      /* 133.333% */
      span {
         font-size: 14px;
         font-weight: 500;
         line-height: 8px;
         /* 57.143% */
         text-decoration: underline;
         vertical-align: super;
         margin-left: 6px;
      }
   }

   .real-price {
      display: flex;
      align-items: center;

      span {
         color: #fff;
         font-size: 12px;
         font-weight: 400;
         line-height: 18px;
         text-decoration: line-through;
         display: flex;
         align-items: center;
         margin-right: 8px;
      }

      .percen-discount {
         color: #fff;
         font-size: 11.24px;
         font-weight: 800;
         line-height: 12px;
         /* 106.762% */
         margin-left: 6px;
         width: 30px;
         height: 16px;
         background: rgba($color: #fff, $alpha: 0.3);
         display: flex;
         align-items: center;
         justify-content: center;
         position: relative;
         text-decoration: unset;

         &:after {
            content: "";
            position: absolute;
            left: -6px;
            top: 0;
            border-right: 6px solid rgba($color: #fff, $alpha: 0.3);
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
         }
      }
   }

   .flash-sale-title {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-align: end;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
         margin-right: 4px;
      }
   }

   .flash-sale-countdown {
      display: flex;
      align-items: center;
      flex-direction: column;

      .label {
         color: #fff;
         font-size: 11.24px;
         font-weight: 400;
         line-height: 16px;
         letter-spacing: -0.112px;
      }

      .countdown {
         display: flex;
         align-items: center;
         border-radius: 2px;

         & > div {
            color: $primary;
            font-size: 12.64px;
            font-weight: 600;
            line-height: 16px;
            background: #fff;
            width: 23px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
         }

         & > span {
            color: #000;
            margin: 0 2px;
            font-size: 12px;
         }
      }
   }
}

// Mobile
.product-detail-mb {
   overflow: hidden;

   .slider-image {
      height: 375px;
      position: relative;

      .current-slider {
         position: absolute;
         // bottom: 20px;
         // left: 50%;
         right: 14px;
         bottom: 11px;
         transform: translateX(-50%);

         color: #fff;
         font-size: 11.24px;
         font-weight: 600;
         line-height: 12px;
         /* 106.762% */
         border-radius: 40px;
         background: rgba(0, 0, 0, 0.3);
         width: 40px;
         height: 20px;
         display: flex;
         align-items: center;
         justify-content: center;
      }

      .slider-img {
         height: 375px;
         max-height: 375px;
      }
      .tag-campain {
         position: absolute;
         top: unset;
         right: unset;
         left: 0;
         bottom: 0;
         z-index: 1;

         height: auto;
         width: 100%;
         max-width: 285px;
         object-fit: contain;
      }
      .sale-label {
         position: absolute;
         top: 8px;
         left: 8px;
         z-index: 1;

         color: #fff;

         font-size: 12px;
         font-weight: 500;
         line-height: 22px;
         border-radius: 4px;
         width: fit-content;
         height: fit-content;
         padding: 2px 8px;
         background-color: $primary;
         border: 1px solid var(--Orange-orange-400, #ff826c);

         display: flex;
         align-items: center;

         svg {
            height: 16px;
            margin-right: 4px;
         }
      }
      .product-stock-out {
         position: absolute;
         top: 50%;
         left: 50%;
         right: unset;
         bottom: unset;
         transform: translate(-50%, -50%);
         z-index: 1;

         width: 96px;
         height: 96px;
         border-radius: 50%;

         color: #fff;
         font-size: 18px;
         font-weight: 500;
         line-height: 24px;
         background: rgba(37, 42, 43, 0.9);
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }

   .commit-real {
      color: #d98f00;
      font-size: 11.24px;
      font-weight: 600;
      line-height: 12px;
      /* 106.762% */
      padding: 8px 16px;
      background: #fff6db;
      display: flex;
      align-items: center;
   }

   .wrap-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;

      .price {
         font-size: 1.5rem;
         font-weight: 500;
         display: flex;
         align-items: center;
         flex-wrap: wrap;

         .discount-price {
            color: #ff6347;
            font-size: 18px;
            font-weight: 800;
            line-height: 24px;
            /* 100% */
            margin-right: 6px;

            span {
               text-decoration-line: underline;
               font-weight: 800;
            }
         }

         .real-price {
            color: #9f9f9f;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            /* 100% */
            text-decoration: line-through;
         }

         .percent-discount {
            color: #fff;
            font-size: 11.24px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.42px;
            text-align: center;
            background-color: #ff6347;
            width: max-content;
            height: 20px;
            min-width: 68px;
            border-radius: 8px;
            padding: 2px 4px;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }

      .rating-count {
         color: #252a2b;
         font-size: 11.24px;
         font-weight: 600;
         line-height: 12px;
         /* 106.762% */
         display: flex;
         align-items: center;
      }
   }

   .wrap-brand-text {
      padding: 0 16px;
      margin-bottom: 8px;

      svg {
         margin-right: 2px;
      }

      .name {
         color: #9f9f9f;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.126px;
      }
   }

   .product-title {
      display: flex;
      justify-content: space-between;
      padding: 0 16px 16px;

      h1 {
         color: #252a2b;
         font-size: 14.22px;
         line-height: 20px;
         /* 140.647% */
         letter-spacing: -0.142px;
      }

      span {
         color: #252a2b;
         font-size: 14.22px;
         line-height: 20px;
         /* 140.647% */
         letter-spacing: -0.142px;
      }
   }

   .attr {
      padding: 0 16px 16px;

      .attr-label {
         color: #252a2b;
         font-size: 12.64px;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.126px;
         display: flex;
         align-items: flex-end;
         margin-bottom: 12px;
         position: relative;
         width: fit-content;
         height: 20px;

         .super-label {
            color: #252a2b;
            font-size: 11.24px;
            line-height: 16px;
            /* 142.349% */
            letter-spacing: -0.112px;
            display: block;
            width: fit-content;
            height: 20px;
            padding: 2px 8px;
            border-radius: 16px;
            background: rgba(226, 228, 235, 0.4);
            margin-left: 8px;
         }
      }

      .color-group {
         display: flex;
         flex-direction: column;
         margin-bottom: 16px;

         .color-list {
            display: flex;
            flex-flow: row wrap;
            row-gap: 16px;
         }

         .color-item {
            border-radius: 50%;
            margin-right: 16px;
            border: 2px solid transparent;
            position: relative;
            cursor: pointer;

            .disabled-icon {
               width: 16px;
               height: 16px;
               position: absolute;
               top: -2px;
               right: -3px;
               z-index: 1;
               border: 1px solid #fff;
               border-radius: 50%;
               background: #fff;
            }

            .color {
               width: 26px;
               height: 26px;
               border-radius: 50%;
               border: 0.5px solid #dddddd;
            }

            &.active {
               border: 2px solid #000;
            }

            &.disabled {
               opacity: 0.3;
               cursor: not-allowed;

               .color {
                  cursor: not-allowed;
               }
            }
         }
      }

      .size-group {
         .size-list {
            display: flex;
            // align-items: center;
            // overflow-x: auto;
            flex-flow: row wrap;
            row-gap: 8px;
         }

         .size-item {
            width: max-content;
            min-width: 67px;
            height: 32px;
            border: 1px solid #252a2b;
            text-align: center;
            margin-right: 0.5rem;
            padding: 2px;
            border-radius: 32px;
            border: 1px solid #e2e4eb;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            cursor: pointer;
            overflow: hidden;

            .size {
               color: #252a2b;
               font-size: 14.22px;
               width: 100%;
               height: 100%;
               padding: 2px;
               display: flex;
               align-items: center;
               justify-content: center;
            }

            &.active {
               border-color: #000;
            }

            @include media-up(md) {
               &:hover {
                  border-color: #000;
               }
            }

            &.disabled {
               border: 1px dashed #e2e4eb;
               background: #fff;
               cursor: not-allowed;

               .size {
                  color: #e2e4eb;
               }
            }

            .variant-thumbnail-img {
               width: auto;
               height: 100%;
               margin-right: 4px;
            }
         }
      }
   }

   .delivery {
      padding: 16px;

      .label {
         color: #252a2b;
         font-size: 16px;
         font-weight: 500;
         line-height: 16px;
         /* 100% */
         margin-bottom: 16px;
      }

      .address {
         display: flex;
         align-items: center;
         justify-content: space-between;
         border-bottom: 1px solid rgba(0, 0, 0, 0.05);
         padding: 8px 0;

         &:last-child {
            border-bottom: unset;
         }

         .left {
            display: flex;
            align-items: center;

            svg {
               width: 24px;
               height: 24px;
               margin-right: 6px;
            }

            span {
               font-size: 12.64px;
            }

            .time {
               div:first-child {
                  font-size: 12.64px;
               }

               div:last-child {
                  font-size: 10px;
               }
            }
         }

         .right-text {
            font-size: 14.22px;

            .delivery-fee {
               font-size: 14.22px;
            }
         }
      }
   }

   .commit-return {
      padding: 0 16px 16px;

      & > div {
         color: #252a2b;
         font-size: 11.24px;
         font-weight: 400;
         line-height: 12px;
         /* 106.762% */
         letter-spacing: 0.112px;
         padding: 8px;
         border-radius: 4px;
         background: rgba(226, 228, 235, 0.4);

         @include media-down(md) {
            display: flex;
            justify-content: center;
            align-items: center;
         }
      }
   }

   .brand-detail {
      .brand-info {
         padding: 12px 16px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         border-bottom: 1px solid rgba(0, 0, 0, 0.05);

         .info-left {
            display: flex;
            align-items: center;

            .logo {
               width: 56px;
               height: 56px;
               border-radius: 50%;
               overflow: hidden;
               margin-right: 8px;
               padding: 4px;
               display: flex;
               align-items: center;
               justify-content: center;

               border: 1px solid rgba(0, 0, 0, 0.3);
               background: #fff;

               img {
                  width: 100%;
                  border-radius: 50%;
                  object-fit: contain;
                  object-position: center;
               }
            }
         }

         .name {
            color: #252a2b;
            font-size: 14.22px;
            font-weight: 600;
            line-height: 16px;
            /* 112.518% */
            margin-bottom: 4px;
         }

         span {
            color: #9f9f9f;
            font-size: 11.24px;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            letter-spacing: -0.14px;
         }

         .evaluatei-point {
            font-size: 12.64px;
         }

         .space {
            display: block;
            width: 4px;
            height: 4px;
            margin: 8px;
            border-radius: 50%;
            background-color: #acacac;
         }

         .btn-viewshop {
            color: $text;
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            letter-spacing: -0.14px;
            padding: 8px 12px;
            min-width: 71px;
            height: 24px;
            border-radius: 32px;
            border: 1px solid #e2e4eb;
            background: #fff;
            display: flex;
            align-items: center;

            svg {
               margin-right: 4px;
            }
         }
      }
   }

   .product-by-brand {
      padding: 16px;

      .label {
         color: #252a2b;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.126px;
      }

      .product-list {
         margin: 0 -4px;

         .product-item {
            padding: 0 2px;
         }
      }
   }

   .wrap-action {
      position: fixed;
      bottom: 0;
      z-index: 1200;

      display: flex;
      align-items: center;
      gap: 8px;

      width: 100%;
      padding: 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;

      button.bookmark {
         color: #252a2b;
         font-size: 9px;
         line-height: 12px;
         /* 133.333% */
         letter-spacing: -0.09px;
         white-space: nowrap;

         background: unset;
         border: unset;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;

         svg {
            margin-bottom: 5px;
         }
      }

      button.btn {
         width: 100%;
         text-align: center;
         font-size: 12.64px;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.253px;
         height: 40px;
         border-radius: 40px;
         border: 1px solid $primary;

         &.btn-add {
            color: $primary;
            font-weight: 400;
            background-color: #fff;
         }

         &.btn-buy {
            color: #fff;
            font-weight: 600;
            background-color: $primary;
         }
      }
   }
}
