.voucher {
   .voucher-input {
      width: 100%;
      height: 40px;
      padding: 4px 10px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      outline: none;
      display: inline-flex;
      align-items: center;

      .voucher-code {
         font-size: 14px;

         &:before {
            content: '';
            background-image: url('/assets/img/icon/voucher.svg');
            display: inline-block;
            background-size: 24px;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 6px;
         }

         span {
            font-weight: 700;
         }
      }
   }
}

.voucher.disabled {
   opacity: 0.8;
}