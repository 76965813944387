.page-banner,
.page-banner-2 {
   @include media-down(lg) {
      aspect-ratio: 2.64;
      height: auto;
   }

   @include media-down(lg) {
      --right-banner-width: 0px;
      --banner-height: 142px;
   }

   .slick-slider {
      .slick-arrow {
         z-index: 2;
         width: 48px;
         height: 48px;
         @include media-down(lg) {
            display: none !important;
         }
         &::before {
            display: none;
         }
         &.slick-disabled {
            opacity: 0.3;
            cursor: default;
         }
         .slick-arrow-custom {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.45);
            display: flex !important;
            align-items: center;
            justify-content: center;
         }
      }
      .slick-prev {
         left: 24px;
      }
      .slick-next {
         right: 24px;
      }
   }
   .slider {
      width: 100%;
      height: var(--banner-height);
      min-height: var(--banner-height);
      overflow: hidden;
      @include media-down(lg) {
         height: unset;
         min-height: unset;
      }
      .slider-item {
         overflow: hidden;
         position: relative;
         height: var(--banner-height);
         min-height: var(--banner-height);
         @include media-down(lg) {
            height: 157px;
            min-height: unset;
            border-radius: 0;
         }
         .link-banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
         }
         img {
            width: 100%;
            // object-fit: cover;
            object-position: top center;
            height: var(--banner-height);
            min-height: var(--banner-height);
            @include media-down(lg) {
               height: unset;
               min-height: unset;
            }
         }
      }
   }

   .right-banner {
      width: var(--right-banner-width);
      height: 100%;
      min-width: var(--right-banner-width);
      border-radius: 8px;
      overflow: hidden;
      margin-left: 8px;
      @include media-down(lg) {
         display: none;
      }
      img {
         width: 100%;
         height: 100%;
         object-fit: contain;
         object-position: center center;
      }
   }
}

.page-banner {
   --right-banner-width: 286px;
   --banner-height: 300px;
   margin-bottom: 24px;
   height: var(--banner-height);
   display: flex;

   @include media-down(lg) {
      height: 157px;
      width: -webkit-fill-available;
   }

   .slider {
      .slider-item {
         border-radius: 8px;

         @include media-down(lg) {
            border-radius: 0;
         }
      }
   }
}

.page-banner-2 {
   --right-banner-width: 286px;
   --banner-height: 250px;
   margin-bottom: 6px;
   height: var(--banner-height);
   display: flex;

   @include media-down(lg) {
      height: inherit;
      width: -webkit-fill-available;
   }

   .slider {
      .slider-item {
         border-radius: 8px 8px 0 0;
         height: inherit;
      }

      @include media-down(lg) {
         // img {
         //    height: 157px;
         //    min-width: 157px;
         // }
      }
   }
}
