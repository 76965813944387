@import "styles/variables.scss";

.sticky-right-button {
   position: fixed;
   right: calc(50% - 662px);
   bottom: 24px;
   display: flex;
   flex-direction: column;
   z-index: 9;
   @include media-down(md) {
      right: 12px;
      bottom: calc($NAVIGATOR-HEIGHT + 12px);
   }
}
.contact-icon {
   color: $text;
   width: 48px;
   height: 48px;
   margin-top: 12px;
   cursor: pointer;
   @include media-up(md) {
      scale: 1;
      transition: scale 0.2s ease;
      &:hover {
         color: $text;
         scale: 1.2;
      }
   }
}

// Ho tro 24/7
.info-general {
   position: absolute;
   top: -34px;
   right: 0px;
   width: fit-content;
   height: fit-content;

   animation-name: fadeIn;
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
   animation-delay: 0.2s;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
         scale: 1;
      }
   }
}

// Trợ lý mua sắm từ A-Z
.info-tro-ly-mua-sam {
   position: absolute;
   top: -43px;
   right: 0px;
   width: fit-content;
   height: fit-content;

   animation-name: fadeIn;
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
   animation-delay: 0.2s;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }

      to {
         opacity: 1;
         scale: 1;
      }
   }
}

// Tư vấn báo giá miễn phí, Không chi phí ẩn
.info-tu-van-bao-gia-free {
   position: absolute;
   top: -80px;
   right: 0;
   width: fit-content;
   height: fit-content;

   animation-name: fadeIn;
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
   animation-delay: 0.2s;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }

      to {
         opacity: 1;
         scale: 1;
      }
   }
}

// Liên hệ nhận ưu đãi, Tùy chỉnh nội thất
.info-lien-he-nhan-uu-dai {
   position: absolute;
   top: -62px;
   right: -0;
   width: fit-content;
   height: fit-content;

   animation-name: fadeIn;
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
   animation-delay: 0.2s;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }

      to {
         opacity: 1;
         scale: 1;
      }
   }
}

.noithatgiasi {
   @include media-down(md) {
      right: 12px !important;
      bottom: 29px !important;
   }
}
