@use "sass:map";
@import "styles/variables.scss";

.wrapper_content_html {
   margin: 16px auto;
   @include media-down(md) {
      margin: 16px auto 24px;
   }

   * {
      text-align: justify;
      @include media-down(md) {
         font-size: 14px;
      }
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      margin-bottom: 15px;
   }

   &.special-route {
      @include media-up(md) {
         h3 {
            font-size: 20px; // The size just for desktop
         }
      }
   }

   h2,
   h2 * {
      font-size: 22px;
      @include media-down(md) {
         font-size: 20px;
      }
   }

   h3,
   h3 * {
      font-size: 20px;
      @include media-down(md) {
         font-size: 18px;
      }
   }

   h4,
   h4 * {
      font-size: 18px;
      @include media-down(md) {
         font-size: 16px;
      }
   }

   p {
      font-size: 16px;
      @include media-down(md) {
         font-size: 14px;
      }
   }

   a[href] {
      line-break: anywhere;
      * {
         line-break: anywhere;
      }
   }

   p {
      a[href*="spacet.vn/thu-vien-anh"] {
         width: 100%;
         height: 100%;
         display: block;
         position: relative;
         &:after {
            right: 12px;
            bottom: 12px;
            z-index: 1;
            content: "";
            width: 32px;
            height: 32px;
            display: block;
            position: absolute;
            background-image: url(/assets/img/icon/go_to.png);
            pointer-events: none;
         }
      }
   }
   img:not(.product-img) {
      display: block;
      width: 100% !important;
      height: unset !important;
      object-fit: contain !important;
   }
   .table-custom {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      * {
         text-align: left !important;
      }
   }

   table {
      font-size: 16px !important;
      margin: 0 0 8px !important;
      max-width: 100% !important;
      @include media-down(md) {
         font-size: 14px !important;
      }
      ul,
      p {
         margin: 0 !important;
      }
      ul {
         @include media-down(md) {
            padding-left: 24px;
         }
      }
      tbody {
         tr {
            td {
               min-width: 60px !important;
               padding: 16px;
               @include media-down(md) {
                  padding: 8px !important;
               }
            }
         }
      }
   }
   p {
      span {
         a {
            img {
               display: block;
            }
         }
      }
   }

   // for tagging product
   .blog-tagging-list {
      position: relative;
      .relative-block {
         position: relative;
         margin-bottom: 86px;
      }
      .product-tag-list {
         display: flex;
         padding-top: 12px;
         overflow: auto hidden;
         position: absolute;
         left: 0;
         top: 100%;
         min-height: 72px;
         img.product-img {
            width: 60px;
            height: 60px;
            min-width: 60px;
            border-radius: 12px;
            border: 1px solid $border;
            margin-right: 12px;
         }
      }
   }
   .show-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      left: 16px;
      bottom: 16px;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;

      svg {
         min-width: 16px;
         min-height: 16px;
      }
   }
   .product-tag-category--append {
      margin: 0 -24px 16px;
      @include media-down(md) {
         margin: 0 -16px 16px;
      }
   }
}
