@import "styles/variables.scss";

.dialog-remind {
   .MuiPaper-root {
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      max-width: 455px;
      position: relative;
   }
   .top-bg {
      width: 100%;
      height: auto;
      padding: 28.5px;

      position: absolute;
      top: -32px;
      left: 0;
      right: 0;
      z-index: 1;
   }
   .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      z-index: 3;

      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      svg {
         width: 16px;
         height: 16px;
      }
   }
   .dialog-content {
      padding: 56px 32px;
      text-align: center;
      z-index: 2;

      .remind-title {
         color: #252a2b;
         font-size: 16px;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 32px;
      }

      .mail-icon {
         width: 100%;
         max-width: 186px;
         height: auto;
         margin-bottom: 16px;
      }

      .description {
         color: #252a2b;
         text-align: center;
         font-size: 14.22px;
         font-weight: 400;
         line-height: 20px; /* 140.647% */
         letter-spacing: -0.142px;
         margin-bottom: 32px;
      }

      .input-wrap {
         height: 52px;
         border-radius: 30px;
         border: 1px solid #e2e4eb;
         position: relative;
         input {
            font-size: 14px;
            line-height: 20px;
            width: 100%;
            height: 100%;
            border: unset;
            &::placeholder {
               color: #9f9f9f;
            }
         }
         .error-text {
            width: 100%;
            max-width: 340px;
            margin: 0 auto;
            position: absolute;
            top: 120%;
            left: 50%;
            transform: translateX(-50%);
         }
      }

      .btn-submit {
         margin-top: 56px;
      }

      a {
         color: var(--bs-link-hover-color);
      }
   }
}
