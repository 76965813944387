@use 'sass:map';
.abount-space-t-page {
    a {
        color: map.get($text-colors, 'default');
        font-weight: 500;
        text-decoration: none;
    }
    .banner {
        .banner-wrap {
            background-image: url(//theme.hstatic.net/1000380248/1000738680/14/faq_01_header_bg.jpg?v=68);
            background-size: cover;
            background-repeat: repeat;
            background-attachment: scroll;
            background-position: center center;
            background-color: #f4f6fd;
            height: 220px;
            margin-bottom: 50px;
            padding-top: 0px;
            padding-bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .text-title {
        .title_about {
            font-weight: 500;
            font-size: 2rem;
            line-height: 28px;
            text-align: center;
            margin-top: 50px;
        }
        .title-s {
            margin: 50px auto;
        }
        .sub-title {
            font-size: 1.5rem;
            line-height: 28px;
            text-align: center;
            margin-bottom: 50px;
        }
    }
    .abount {
        margin-bottom: 20px;
        .abount-item {
            .img {
                flex: 1;
                height: 100%;
                padding-bottom: 60%;
                position: relative;
                overflow: hidden;
                // z-index: -1;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .text {
                width: 100%;
                height: 100%;
                // border: 1px solid #000;
                display: flex;
                background: #fff;
                padding: 30px;
                flex-direction: column;
                // transform: scale(1.02);
                box-shadow: -2px 4px 13px -7px #858585;
                justify-content: center;

                .title_item {
                    font-size: 2rem;
                    line-height: 2.5rem;
                    text-align: left;
                    margin-bottom: 40px;
                }

                .des {
                    font-size: 1.5rem;
                    line-height: 2.5rem;
                    text-align: left;
                }
            }
        }
    }
    .slider {
        .slider-wrap {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .package-table {
        border-radius: 4px;
        tr {
            th {
                padding: 0.875rem 1.25rem;
                .title {
                    font-size: 20px;
                    color: #fff;
                    padding: 0 !important;
                }
            }
            .package-col-title {
                width: 20%;
            }
            .package-col-content {
                border: 1px solid #dddddd;
                width: 40%;
                background: #777;
            }
            .content-2 {
                background: map.get($text-colors, 'dafault');
            }
        }
        tbody {
            tr {
                vertical-align: middle !important;
                th {
                    border: 1px solid #dddddd;
                    text-align: center;
                    vertical-align: middle !important;
                    margin: 0;
                    font-size: 1rem;
                    text-transform: uppercase;
                    padding: 0.875rem 1.25rem;
                }
                td {
                    border: 1px solid #dddddd;
                    vertical-align: middle !important;
                    padding: 0.875rem 1.25rem;
                    text-align: justify;
                    font-size: 1.125rem;
                    line-height: 1.5;
                    span {
                        font-weight: bold;
                    }
                    &.col-highlight {
                        height: 100px;
                        background: #ff6347;
                        color: #fff;
                        text-align: center;
                        font-size: 1.5rem;
                    }
                }
            }
            .striped {
                background: #f6f6f6;
            }
        }
        tfoot {
            border: 1px solid #dddddd;
            td {
                border: 1px solid #dddddd;
                border-left: none;
                vertical-align: middle !important;
                padding: 0.875rem 1.25rem;
                text-align: justify;
                font-size: 1.125rem;
                line-height: 1.5;
                a {
                    font-size: 1.125rem;
                }
            }
        }
    }
    .faq {
        margin-bottom: 100px;
        .form-faq {
            padding: 15px 20px;
            border: 1px solid #e4e4e4;
            color: map.get($text-colors, 'default');

            .title-form {
                text-align: center;
            }
            input {
                height: 44px;
                padding: 0.875rem 1rem;
            }
            .btn {
                text-align: center;
                background: map.get($text-colors, 'default');
                width: 100%;
                padding: 15px 0;
                text-align: center;
                color: #fff;
            }
        }
    }
}
