@use "sass:map";
// @import 'src/assets/scss/core/variables.scss';
@import "styles/variables.scss";

main.home-pagev2 {
   .construction-design {
      .filter-cate {
         display: flex;
         flex-flow: row wrap;
         margin-top: 16px;
         @include media-down(md) {
            flex-flow: row nowrap;
            overflow: auto hidden;
            border-bottom: 1px solid $border;
            &::-webkit-scrollbar {
               display: none;
            }
         }
         .filter-item {
            line-height: 26px;
            padding: 2px 12px;
            min-width: 50px;
            height: 30px;
            border-radius: 100px;
            background: #f5f5f5;
            margin-right: 8px;
            margin-bottom: 12px;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            @include media-up(md) {
               &:hover {
                  color: #fff;
                  background-color: $primary;
               }
            }
            @include media-down(md) {
               font-size: 14px;
               background: unset;
               border-radius: 0;
               min-width: unset;
               margin: 0;
               margin-right: 16px;
               height: unset;
               padding: 6px 0 6px 0;
            }

            &.active {
               color: #fff;
               font-weight: 500;
               background-color: $primary;
               @include media-down(md) {
                  color: $primary;
                  background-color: #fff;
                  border-bottom: 1px solid $primary;
               }
            }
         }
      }
      .slick-slider {
         margin: 0 -10px;
         @include media-down(md) {
            margin: 0 -4px;
         }
      }
      .slider-item {
         padding: 10px 6px;
         @include media-down(md) {
            padding: 8px 4px 0px;
         }
         .img {
            aspect-ratio: 1.4;
            border-radius: 8px;
            overflow: hidden;
            img {
               width: 100%;
               object-fit: cover;
            }
         }
         .article-item-info {
            overflow: hidden;
         }
         .article-item-info-title {
            color: $text;
            font-size: 16px;
            line-height: 22px;
            margin-top: 8px;
            @include media-down(md) {
               font-size: 14px;
               line-height: 20px;
               margin-top: 4px;
            }
         }
      }
   }
   .procedure {
      .procedure-item {
         position: relative;
         overflow: hidden;
         height: 100%;

         &__image {
            margin-bottom: 32px;
            width: 240px;
            height: 144px;
            overflow: hidden;
            flex-shrink: 0;
         }

         &__content {
            width: 100%;
            height: 100%;
            border: 1px solid #cccccc;
            border-radius: 4px;
            padding: 48px 24px;
            display: flex;
            align-items: center;
            flex-direction: column;

            &--title {
               font-weight: 700;
               font-size: 18px;
               line-height: 21px;
               text-align: center;
               margin-bottom: 28px;
               display: flex;
               align-items: center;
               .step {
                  flex-shrink: 0;
                  color: #fff;
                  background-color: #252a2b;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  margin-right: 6px;
                  font-size: 13px;
               }
            }

            &--des {
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #4c3f37;
               margin-bottom: 0px;
            }
         }
      }
   }

   .review {
      .slider-wrap {
         margin: 0 -8px;
         margin-top: 8px;
         @include media-down(md) {
            margin: 0 -4px;
            margin-top: 12px;
         }

         .slick-arrow {
            color: $cl-white;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            width: 60px;
            height: 60px;
            z-index: 1;
            padding: 10px;
            // top: 20%;
         }

         .slick-next {
            right: 20px;
         }

         .slick-prev {
            left: 20px;
         }

         ul.slick-dots {
            width: fit-content;
            position: absolute;
            left: 50%;
            bottom: 3px;
            transform: translateX(-50%);
            height: 4px;
            border-radius: 16px;
            background-color: #ffefed;
            display: flex !important;
            li {
               margin: 0;
               border-radius: 16px;
               width: 12px;
               height: 4px;
               button {
                  width: 12px;
                  height: 4px;
                  padding: 0;
                  margin: 0;
                  &::before {
                     content: none;
                     display: none;
                  }
               }
               &.slick-active {
                  background: #ff6347;
               }
            }
         }

         .slick-slide {
            padding: 8px;
            @include media-down(md) {
               padding: 4px 4px 16px;
            }
         }
      }

      .slider-item {
         border-radius: 8px;
         box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
         overflow: hidden;
         .img {
            position: relative;
            order: 2;
            .row {
               height: 100%;
            }
            img.user-img {
               width: 100%;
               aspect-ratio: 1.6;
               object-fit: cover;
            }
         }
         .wrap-avatar img {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            max-width: 48px;
            max-height: 48px;
            border-radius: 100px;
            border: 3px solid #fff;
            overflow: hidden;
            object-fit: cover;
            object-position: center center;

            position: absolute;
            top: 12px;
            left: 12px;
            @include media-down(md) {
               width: 40px;
               height: 40px;
               min-width: 40px;
               min-height: 40px;
               max-width: 40px;
               max-height: 40px;

               position: absolute;
               top: 8px;
               left: 8px;
            }
         }
      }

      .review-detail {
         background: #fbfbfc;
         padding: 12px;
         order: 1;
         @include media-down(md) {
            padding: 8px;
         }
         &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            @include media-down(md) {
               font-size: 16px;
            }
         }

         &__sub-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-top: 8px;
            @include media-down(md) {
               font-size: 14px;
               margin-top: 4px;
            }
         }

         &__rating {
            width: 100%;
            margin-top: 8px;
            display: flex;
            align-items: center;
            @include media-down(md) {
               margin-top: 4px;
            }

            img {
               height: 32px;
               @include media-down(md) {
                  height: 24px;
               }
            }

            span {
               font-weight: 400;
               font-size: 20px;
               line-height: 28px;
               margin-left: 8px;
               @include media-down(md) {
                  font-size: 16px;
                  margin-left: 4px;
               }
            }
         }

         &__des {
            color: #252a2b;
            text-align: justify;
            padding: 12px;
            border-radius: 8px;
            background: #f5f5fa;
            aspect-ratio: 1.45;
            margin-top: 16px;
            @include media-down(md) {
               font-size: 14px;
               padding: 8px;
               margin-top: 8px;
               aspect-ratio: 1.48;
            }
            img {
               width: 10px;
               display: inline-flex;
               top: 0;
            }
         }

         &__date {
            color: #697f90;
            text-align: end;
            padding: 0;
            margin: 0;
            margin-top: 16px;
            @include media-down(md) {
               font-size: 14px;
               margin-top: 8px;
            }
         }
      }
   }

   .partner {
      &-list {
         overflow: hidden;
         margin: 0px -8px;

         .slider-item {
            padding-left: 8px;
            padding-right: 8px;
         }
      }

      &-item {
         background: #ffffff;
         border: 1px solid #e5e5e6;
         border-radius: 4px;
         color: #252a2b;
         overflow: hidden;

         .img {
            position: relative;
            --bs-aspect-ratio: 52%;
            .icon-verified {
               position: absolute;
               top: 8px;
               left: 8px;
               height: 16px;
               width: fit-content;
               aspect-ratio: unset;
            }
            img {
               aspect-ratio: 1.92;
            }
         }

         &__content {
            padding: 12px;
            gap: 4px;

            &--lg {
               gap: 8px;
               position: absolute;
               left: 8px;
               bottom: 8px;
               display: flex;
            }
         }

         &__logo {
            background: #e8eaed;
            border: 1px solid #e5e5e6;
            border-radius: 4px;
            width: 50px;
            height: 50px;
            filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
            overflow: hidden;
            flex-shrink: 0;

            img {
               width: 100%;
               height: 100%;
               aspect-ratio: 1 !important;
            }
         }

         &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #252a2b;

            &--name {
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               margin-bottom: 2px;
               color: #252a2b;
               text-shadow: none;
            }

            &--des {
               font-weight: 700;
               font-size: 13px;
               line-height: 15px;
               margin-bottom: 0px;
            }

            &--count {
               display: flex;
               align-items: center;
               gap: 4px;
               margin-top: 5px;
            }

            &--rating {
               padding: 2px 6px;
               gap: 2px;
               background: #f4f4f4;
               border-radius: 3px;
               display: inline-block;
               width: fit-content;
               display: flex;
               align-items: center;

               span {
                  color: #be4b27;
               }

               img {
                  width: 14px;
                  height: 14px;
               }
            }

            &--review {
            }

            &--project {
            }
         }
      }
      .partner-slider {
      }
   }
}
