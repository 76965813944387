@use "sass:map";
@import "styles/variables.scss";

.tagging-point {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   background: #ffffff;
   position: absolute;
   z-index: 1; // 99;
   background: #ff6347;
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   cursor: pointer;
   // transition: 0.4s ease;
   // opacity: 0;

   &::before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: #fff;
      z-index: 10;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   .popover {
      position: absolute;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      z-index: 100;

      .remove-tagging {
         width: 18px;
         height: 18px;
         cursor: pointer;
         line-height: 14px;
         margin-right: 3px;
         position: absolute;
         top: -6px;
      }
      &.left .remove-tagging {
         left: -6px;
      }
      &.right .remove-tagging {
         right: -6px;
      }

      &.left::before {
         content: "";
         position: absolute;
         top: 50%;
         right: -6px;
         transform: translateY(-50%);

         width: 7px;
         height: 1px;
         background: #fff;
      }

      &.right::before {
         content: "";
         position: absolute;
         top: 50%;
         left: -6px;
         transform: translateY(-50%);

         width: 7px;
         height: 1px;
         background: #fff;
      }

      .img-default {
         img {
            height: 30px !important;
            width: 30px !important;
         }
      }
   }

   .tag-similar,
   .tag-similar-popover {
      border: 1px solid #e0e0e0;
      border-radius: 20px;
      background-color: #ffffff;
      position: absolute;
      font-size: 10px;
      line-height: 12px;
      color: #8a8a8a;
      height: 12px;
      white-space: nowrap;
      opacity: 0.95;
      padding: 0px 4px;
      top: -6px;
      left: 14px;
      display: flex;
      align-items: center;
   }

   .tag-similar-popover {
      left: 1px;
      top: -7px;
   }

   .img-default {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cccccc;
   }

   .popover-tagging {
      padding: 3px 2px;
      width: 154px;
      height: 42px;
      border-radius: 8px;
      background: rgba(45, 45, 45, 0.85);
      cursor: pointer;
      border: 1px solid #fff;

      .thumbnail {
         width: 36px;
         height: 36px;
         border-radius: 6px;
         flex-shrink: 0;
         position: relative;
         background-size: 30px 30px;
         background-image: url("/assets/img/icon/image-default.svg");
         background-repeat: no-repeat;
         background-color: #fff;
         background-position: center;
         overflow: hidden;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            background-color: #fff;
            object-fit: contain;
            width: 100%;
         }
      }

      .content {
         width: 100%;
         overflow: hidden;
      }

      .name {
         color: #fff;
         opacity: 0.8;
         font-size: 10px;
         font-weight: 400;
         // text-overflow: ellipsis;
         // overflow: hidden;
         padding-right: 8px;
         line-height: 11px;
         min-height: 14px;
      }

      .price {
         color: #fff;
         font-size: 12px;
         line-height: normal;
         font-weight: 700;
         display: block;
      }

      .unverify {
         display: flex;
         align-items: center;
         padding: 0px 4px;
         margin-top: 1px;
         gap: 3px;
         border-radius: 4px;
         background: #e0e0e0;
         height: fit-content;
         width: fit-content;

         .icon-protection {
            width: 8px;
            height: 8px;
         }

         span {
            font-weight: 700;
            font-size: 8px;
            line-height: 12px;
            color: #666666;
         }
      }
   }
}
@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .tagging-point {
      .tagging-list {
         .tagging-item {
            width: 85px;
            height: 85px;
         }
      }

      .tag-similar {
         font-size: 12px;
         line-height: 14px;
         height: 22px;
         opacity: 0.85;
         padding: 4px;
         top: -11px;
         left: 24px;
      }
   }
}
