.connect-service-page {
    .procedure {
        height: calc(100vh - 116px);
        padding: 50px 60px;
        align-items: center;
        justify-content: center;
        background-color: #ffe9de;

        .procedure_dt {
            height: 100%;

            .procedure_wrapper_image {
                width: 55%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 90%;
                    width: 90%;
                    object-fit: contain;
                }
            }

            .procedure_wrapper_content {
                width: 45%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .procedure_title {
                    font-size: 45px;
                    font-weight: 700;
                    line-height: 54px;
                    margin-bottom: 40px;

                    strong {
                        color: #ff5d00;
                    }
                }

                .procedure_item {
                    display: flex;
                    height: 25%;

                    .stt {
                        position: relative;
                        padding-top: 14px;

                        span {
                            padding-top: 4px;
                            margin-right: 23px;
                            padding: 0px 6px;
                            color: #fff;
                            background-color: #000;
                            border: 1px solid #9b9b9b;
                            border-radius: 50%;
                        }
                    }

                    .stt_after {
                        &::after {
                            bottom: 0;
                            left: calc(50% - 12px);
                            width: 2px;
                            content: '';
                            border-radius: 8px;
                            position: absolute;
                            display: inline-block;
                            height: calc(100% - 50px);
                            transform: translate(-50%);
                            background: rgba(12, 11, 11, 0.3);
                        }
                    }

                    .procedure_right {
                        .title_process {
                            font-size: 28px;
                            font-weight: 600;
                            line-height: 40px;
                            margin-bottom: 8px;
                        }

                        .des_process {
                            font-size: 20px;
                            font-weight: 300;
                            line-height: 25px;
                            font-family: 'SourceSanProLight';
                        }
                    }
                }
            }

            @media (max-width: 1426px) {
                .procedure_wrapper_image {
                    img {
                        width: 90%;
                        object-fit: contain;
                    }
                }

                .procedure_wrapper_content {
                    .procedure_item {
                        height: 160px;
                    }
                }
            }
        }

        .procedure_mb {
            .procedure_title {
                font-size: 40px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 50px;

                strong {
                    color: #ff5d00;
                }
            }

            .procedure_item {
                margin-bottom: 4px;
                display: flex;

                .stt {
                    position: relative;
                    padding-top: 14px;

                    span {
                        padding-top: 4px;
                        margin-right: 6px;
                        padding: 0px 6px;
                        color: #fff;
                        background-color: #000;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                    }
                }

                .stt_after {
                    &::after {
                        bottom: 0;
                        left: 50%;
                        width: 2px;
                        content: '';
                        border-radius: 8px;
                        position: absolute;
                        display: inline-block;
                        height: calc(100% - 50px);
                        transform: translate(-50%);
                        background: rgba(12, 11, 11, 0.3);
                    }
                }

                .procedure_right {
                    .title_process {
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 50px;
                        margin-bottom: 9px;
                        letter-spacing: -0.03em;
                        color: #4e4e4e;
                    }

                    .des_process {
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 30px;
                        letter-spacing: -0.03em;
                        color: #4e4e4e;
                        font-family: 'SourceSanProLight';
                    }
                }
            }
        }
    }

    @media (max-width: 1020px) {
        .procedure {
            padding: 76px 60px;
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'lg')) {
        .procedure {
            .procedure_dt {
                .procedure_wrapper_image {
                }

                .procedure_wrapper_content {
                    .procedure_title {
                        font-size: 40px;
                        line-height: 40px;
                        margin-bottom: 30px;
                    }

                    .procedure_item {
                        height: 180px;
                    }

                    .procedure_right {
                        .title_process {
                            font-size: 30px;
                            font-weight: 600;
                            line-height: 30px;
                            margin-bottom: 8px;
                        }

                        .des_process {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 850px) {
        .procedure {
            padding: 60px 24px;
            .procedure_dt {
                .procedure_wrapper_content {
                    .procedure_item {
                        .procedure_right {
                            .title_process {
                                font-size: 26px;
                                line-height: 36px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'md')) {
        .procedure {
            padding: 76px 24px;
            height: unset;
        }
    }

    @media (max-width: 420px) {
        .procedure {
            .procedure_mb {
                .procedure_title {
                    margin-bottom: 20px;
                }

                .procedure_right {
                    .title_process {
                        font-size: 22px;
                        line-height: 50px;
                    }

                    .des_process {
                        color: #4e4e4e;
                    }
                }

                .stt_after {
                    height: 155px;
                }
            }
        }
    }

    @media (max-width: 378px) {
        .procedure {
            padding-top: 20px;

            .procedure_mb {
                .procedure_title {
                    margin-bottom: 20px;
                    font-size: 40px;
                    line-height: 45px;
                }

                .procedure_item {
                    .procedure_right {
                        .title_process {
                            line-height: 30px;
                        }
                    }

                    .stt_after {
                        height: 155px;
                    }

                    .stt {
                        padding-top: 6px;
                    }
                }
            }
        }
    }

    .procedure_visible {
        .pc1 {
            animation: bounce;
            animation-duration: 5s;
            animation-delay: 0s;
        }

        .pc2 {
            animation: bounce;
            animation-duration: 5s;
            animation-delay: 4s;
        }

        .pc3 {
            animation: bounce1;
            animation-duration: 900s;
            animation-delay: 9s;
        }
    }
}

@keyframes bounce {
    0% {
        color: #000;
    }

    25% {
        color: #ff5d00;
    }

    50% {
        color: #ff5d00;
    }

    100% {
        color: #ff5d00;
    }
}

@keyframes bounce1 {
    from {
        color: #ff5d00;
    }

    to {
        color: #ff5d00;
    }
}
