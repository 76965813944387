@use 'sass:map';

.landing-page-service-page {
   .our-drawing {
      padding: 100px 0;
      background: #fff;

      .header {}

      .items {
         .tabs {
            margin-bottom: 50px;
            justify-content: center;
            border-bottom: 1px solid #ccc;

            .tab {
               cursor: pointer;
               padding-bottom: 18px;
               text-align: center;
               width: 15%;
               font-size: 18px;
               font-weight: 700;
               color: #252A2B;
               line-height: 21px;
               border-bottom: 0px solid #ff6347;
            }
         }
      }

      .slick-dots {
         bottom: -10%;

         li {
            margin: 0 5px;
            width: 8px;
            height: 8px;

            button {
               width: 8px;
               height: 8px;
               border-radius: 50%;
               background-color: #ACACAC;
               padding: 4px;

               &::before {
                  display: none;
                  color: #ACACAC;
                  width: 8px;
                  height: 8px;
               }
            }

            &:hover,
            &.slick-active {
               button {
                  background: #252A2B;
               }
            }
         }
      }

      .slider-wrap {
         // background: #fff;
         position: relative;

         .slick-slider {
            .slick-arrow {
               width: 40px;
               height: 40px;
               z-index: 100;
               border-radius: 50%;
               background: transparent;

               &::before {
                  content: url(/assets/img/interior-design/prev.png);
                  font-size: unset;
                  opacity: unset;
                  line-height: unset;
               }
            }

            .slick-prev {
               top: 34%;
               left: 20px;
               transform: rotate(180deg);
               margin-top: -20px;
            }

            .slick-next {
               top: 34%;
               right: 36px;
            }
         }

         .arrow {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .btn {
               outline: none;
               border: none;
               width: 40px;
               height: 40px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .btn-prev {
               img {
                  transform: rotate(180deg);
               }
            }
         }

         .slider-item {
            width: calc(100% - 16px) !important;
            margin-right: 16px;
            display: block;

            img {
               object-fit: contain;
               margin-bottom: 10px;
               width: 100%;
            }

            .name {
               font-style: normal;
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               color: #252a2b;

               opacity: 0.9;
               margin-bottom: 4px;
            }

            .description {
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #666666;
            }
         }
      }

      .content {
         margin-top: 60px;
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;

         .target {
            margin-bottom: 50px;
         }

         .type {
            margin-bottom: 50px;
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .our-drawing {
         padding: 32px 12px;

         .header {
            .title {
               margin-bottom: 35px;
               padding: 0 12px;
            }
         }

         .container {
            max-width: unset;
         }

         .slick-dots {
            display: none !important;
         }

         .slider-wrap {

            .slick-slider {
               margin-right: -12px;

               .slick-arrow {
                  display: none !important;
               }
            }

            .slider-item {}
         }

         .items {
            .tabs {
               margin-bottom: 30px;
               justify-content: space-around;

               .tab {
                  width: max-content;
                  font-weight: 400;
               }
            }
         }

         .content {
            margin-top: 24px;

            .target {
               margin-bottom: 20px;
            }

            .type {
               margin-bottom: 20px;
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .our-drawing {
         .container {
            padding: 0;
         }
      }
   }
}