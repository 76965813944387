@import "./_mixins/breakpoints";

$text: #252a2b;
$span: #697f90; // #9f9f9f;
$link: #05a;
$border: #f0f0f0;
$primary: #ff6347;
$secondary: RED;

$success: #4caf50;
$warning: #ff9800;
$danger: #f44336;
$info: #2196f3;
$error: #f44336;

$bg: #f5f5f5;

$container-width: 1220px;
$container-padding: 16px;

$MENU-HEIGHT-MOBILE: 60px;
$MENU-HEIGHT-DESKTOP: 60px;
$NAVIGATOR-HEIGHT: 60px;

$cl-space-t-dark-black: #252a2b;
$cl-space-t-orange: #ff6347;
$cl-persian-green: #009999;
$cl-facebook: #1c77f3;
$cl-white: #ffffff;
$cl-line: #e8eaed;
$cl-bg-lightgrey: #fbfbfc;
$cl-line-light-2: #f5f5f5;
$cl-close: #7b8698;
$cl-highlight: #ffc323;
$pcl-opup-layer-blur: rgb(241, 241, 241);

$grid-breakpoints: (
   xs: 0,
   sm: 576px,
   md: 768px,
   lg: 992px,
   xl: 1200px,
   xxl: 1400px,
);

$grid-breakpoints-max-width: (
   xs: 0,
   sm: 575.98px,
   md: 767.98px,
   lg: 991.98px,
   xl: 1199.98px,
   xxl: 1399.98px,
);

$zindex: (
   dropdown: 1000,
   sticky: 1020,
   fixed: 1030,
   modal-backdrop: 1040,
   offcanvas: 1050,
   modal: 1060,
   popover: 1070,
   tooltip: 1080,
);

$shadows: (
   standard: rgb(0 0 0 / 5%) 0px 1px 5px 0px,
);

$fonts: (
   main: "Roboto",
);

$text-colors: (
   orange: #ff6347,
   default: #252a2b,
   blue: #0055aa,
);

$padding: (
   pc: 50px 0,
   mb: 20px 0,
);

$palettes: (
   grey: (
      1: #e0e0e0,
      2: #acacac,
      3: #8a8a8a,
      4: #666666,
      5: #515151,
   ),
   additional-colors: (
      0: #ceb5af,
      1: #a88074,
      2: #8a8a8a,
      3: #92584b,
      4: #6a433a,
      5: #efad6a,
      6: #803124,
      7: #3d1002,
      8: #765953,
      9: #ea8d58,
      10: #fdece7,
   ),
);

@mixin text-wrap-line($line) {
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: $line;
   line-clamp: $line;
   word-break: break-word;
   // white-space: break-spaces;
   -webkit-box-orient: vertical;
   display: -webkit-box;
}
