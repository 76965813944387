@import "./tagging/Tagging.scss";

.popover-tagging-product {
   .MuiPopover-paper {
      border-radius: 16px;
   }
   .MuiTabs-flexContainer {
      background-color: rgba(226, 228, 235, 0.5);
   }

   .popup-tagging {
      background: #ffffff;
      padding: 0;
      width: 320px;
      min-height: 366px;
      .tabs_tagging {
         display: flex;
      }
      .tab_tagging {
         flex: 1 1 50%;
         font-size: 14px;
         font-weight: 500;
         white-space: nowrap;
         text-transform: capitalize;
         &.Mui-selected {
            color: $primary;
            background: #fff;
         }
         svg {
            margin-right: 4px;
         }
      }
      .tab-content {
         height: 100%;
         position: relative;
      }
      .wrap-checkbox {
         padding: 8px 16px;
      }
      .wrap-input {
         width: 100%;
         padding: 8px 16px;
         border-bottom: 1px solid $border;
         input {
            font-size: 12px;
            width: 100%;
            height: 40px;
            padding: 12px;
            border-radius: 8px;
            background: transparent;
            border: 1px solid #e2e4eb;
         }
      }
      .product-list {
         padding: 0 16px;
         height: 300px;
         max-height: 300px;
         min-height: 300px;
         overflow-x: hidden;
         overflow-y: auto;
         display: flex;
         flex-direction: column;

         .item {
            display: flex;
            align-items: center;
            padding: 8px 0;
            cursor: pointer;
            border-bottom: 1px solid $border;
            img {
               width: 42px;
               height: 42px;
               min-width: 42px;
               object-fit: cover;
               object-position: center;
               border: 1px solid $border;
               border-radius: 10px;
               overflow: hidden;
               margin-right: 8px;
            }
            .product-info {
               overflow: hidden;
            }
            .text {
               color: #252a2b;
               font-size: 12px;
               line-height: 16px; /* 133.333% */
               letter-spacing: -0.12px;
               text-overflow: ellipsis;
               white-space: nowrap;
               opacity: 0.6;
            }
            .price {
               font-size: 14px;
               font-weight: 600;
               line-height: 16px;
               margin-top: 4px;
            }
         }
      }
   }
   .form-tagging {
      padding: 8px 16px;
      .form-item {
         margin-bottom: 6px;
         .label {
            cursor: default;
            margin-bottom: 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            .required {
               color: #ff0000;
            }
         }
         input,
         .wrap_input,
         textarea {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            background: #ffffff;
            border: 1px solid #dddddd;
            border-radius: 4px;
            &::placeholder {
               color: #b8b8b8;
            }
         }
         input {
            height: 40px;
         }
         .wrap_input {
            display: flex;
            align-items: center;
            position: relative;
            input {
               border: unset;
            }
            .suffix {
               color: $span;
               font-size: 12px;
               font-weight: 400;
               position: absolute;
               right: 10px;
               top: 50%;
               transform: translateY(-50%);
            }
         }
         .form_upload {
            display: flex;
            align-items: center;
            padding: 4px 12px 4px 4px;
            border-radius: 8px;
            border: 1px dashed #e2e4eb;
            position: relative;
            overflow: hidden;

            .upload__btn {
               overflow: hidden;
               color: $span;
               line-height: 14px;
               font-size: 14px;
               font-weight: 400;
               flex: 1;
               margin-left: 10px;
               display: flex;
               align-items: center;
               svg {
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
               }
            }
            .upload {
               width: 100%;
               height: 100%;
               position: absolute;
               left: 0;
               top: 0;
               opacity: 0;
               cursor: pointer;
            }
            .thumbnail_img {
               width: 56px;
               height: 56px;
               min-width: 56px;
               object-fit: cover;
               object-position: center;
               border-radius: 6px;
               border: 1px dotted #e6e7e8;
               display: flex;
               align-items: center;
               justify-content: center;
            }
            .icon_delete {
               margin-left: 12px;
               cursor: pointer;
               position: absolute;
               right: 3px;
               top: 2px;
               width: 16px;
               height: 16px;
               z-index: 2;
               svg {
                  width: 80%;
                  height: 80%;
               }
            }
            .info-tag {
               display: flex;
               flex-direction: column;
               margin-left: 16px;
               width: 100%;
               overflow: hidden;
               .name {
                  color: $text;
                  font-size: 14.22px;
                  font-weight: 400;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  opacity: 0.6;
                  width: 90%;
                  overflow: hidden;
               }
               .btn_change_file {
                  color: $text;
                  font-size: 14.22px;
                  font-weight: 500;
                  line-height: 16px;
                  margin-top: 4px;
                  text-decoration: underline;
               }
            }
         }
      }
      .btn-cancel {
         border: 1px solid #cccccc;
         color: #acacac;
      }
      .btn-submit {
         width: 100%;
         height: 40px;
         border-radius: 40px;
         background: #ff6347;
         border: 1px solid #ff6347;
         color: #ffffff;
         margin-top: 12px;
         &[disabled] {
            border-radius: 40px;
            background: var(--gray-gray-500, #9f9f9f);
            border: 1px solid #9f9f9f;
         }
      }
      button {
         font-weight: 500;
         font-size: 15px;
         line-height: 18px;
         padding: 8px 14px;
         border-radius: 4px;
         width: 100%;
      }
   }
   .product-empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      svg {
         width: 160px;
         height: auto;
      }
      p {
         font-size: 14px;
         font-weight: 500;
         margin-bottom: 4px;
      }
      span {
         font-size: 12px;
         font-weight: 400;
         line-height: 16px;
         color: #9f9f9f;
      }
   }
}
