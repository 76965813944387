@use "sass:map";
@import "./variables.scss";

.hidden-header {
   display: none !important;
}

.st-container,
.spacet-container {
   width: 100%;
   @include media-up(md) {
      max-width: $container-width;
      margin: 0 auto;
      padding-left: $container-padding;
      padding-right: $container-padding;
   }
}

.container {
   padding-top: 0px;
   padding-bottom: 0px;
   max-width: 1224px;
}

.page-content {
   background: #f4f4f4;
}

.content-page {
   overflow: clip;

   &.shop {
      margin-top: -56px;
      min-height: 100vh;
   }
}

.page-banner.slider {
   position: relative;
   width: 100%;
   height: 100%;
   max-height: 401px;

   img,
   picture {
      aspect-ratio: 2.78;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: inline-block;
      z-index: 1;
      border-radius: 12px;
      max-height: 401px;
   }

   .slick-dots {
      bottom: 0px;

      li {
         margin: 0;

         button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #c6c6c6;
            opacity: 0.8;
            padding: 3px;

            &::before {
               color: transparent;
               width: 10px;
               height: 10px;
            }
         }

         &:hover,
         &.slick-active {
            button {
               background: #ffffff;
               opacity: 1;
            }
         }
      }
   }

   .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .page-banner.slider {
      .slick-slider {
         margin-top: -20px;
         margin-left: -12px;
         margin-right: -12px;
      }

      img,
      picture {
         aspect-ratio: 1.4;
         width: 100%;
         height: 100%;
         border-radius: 0px;
      }
   }
}
