.block-user__avatar.avatar {
   width: 44px;
   height: 44px;
   display: flex;
   border-radius: 50%;
   // border: 1px solid #e0e0e0;
   background-size: cover;
   background-repeat: no-repeat;
   // background-image: url('/assets/img/cover/avatar.png');
   flex-shrink: 0;
   img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      border-radius: 50%;
   }
}
