@import "styles/variables.scss";

.photo-result {
   padding: 16px 16px 24px;
   max-width: 804px;
   margin: 16px auto 16px;
   border-radius: 10px;
   background: #FFF;
   box-shadow: 0px 1px 4px -5px rgba(0, 0, 0, 0.10);

   @include media-down(md) {
      padding: 0 12px 36px;
      margin: 16px auto 0;
   }

   .block-data {
      display: flex;
      flex-direction: column;
      gap: 8px;
   }

   .image-grid {
      grid-gap: 14px;
      display: grid;
      gap: 14px;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @include media-down(md) {
         gap: 8px;
         grid-template-columns: repeat(2, minmax(0, 1fr));
      }
   }

   .search-no-result {
      color: rgba(37, 42, 43, 0.5);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      padding-top: 24px;
   }
}