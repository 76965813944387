@use 'sass:map';

.landing-page {
   .reward {
      position: relative;
      padding-top: 60px;

      .container {
         .row {
            margin-top: 60px;
         }
      }

      .block {
         z-index: 1;
         height: auto;

         .subject-block {
            height: 100%;
            font-weight: 500;
            font-size: 18px;
            line-height: 38px;
            padding: 72px 16px 16px;
            background: #ffffff;
            border-width: 0.8px 0.8px 0px 0.8px;
            border-style: solid;
            border-color: map.get($text-colors, orange);
            border-radius: 26px 26px 0px 0px;
            position: relative;

            .btn {
               text-transform: uppercase;
               height: 56px;
               background: map-get($map: $text-colors, $key: orange);
               color: #ffffff;
               border-radius: 26px;
               position: absolute;
               top: -1px;
               left: -1px;
               font-weight: 700;
               font-size: 18px;
               line-height: 20px;
               padding: 0 34px;
            }

            li::marker {
               color: map.get($text-colors, orange);
               content: '- ';
            }
         }
      }

      .second-bg {
         background: #eee7e6;
         border-radius: 0px 150px 150px 0px;
         position: absolute;
         height: 62%;
         bottom: 0;
         width: 100%;
         z-index: 0;
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, 'lg')) {
      .reward {
         .container {
            .row {
               margin-top: 0px;
            }
         }

         .block {
            margin-top: 70px;

            .subject-block {
               border: 1px solid #ff543e;
               border-radius: 30px;

               .btn {
                  left: 0;
                  right: 0;
                  margin: auto;
                  top: -28px;
                  width: fit-content;
               }
            }
         }
      }
   }
}