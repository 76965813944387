@use "sass:map";
@import "./variables.scss";

nextjs-portal {
   display: none;
}

* {
   box-sizing: border-box;
}

html,
body {
   font-family: "Roboto", sans-serif;
   font-size: 16px;
   font-weight: 400;
   font-style: normal;
   line-height: 1.5;
   color: map.get($text-colors, "default");
   padding: 0;
   margin: 0;
   scroll-behavior: unset !important;
}

#__next {
   min-height: 100vh;
}
// Scroll scmooth only blog page with
// html.blog-detail {
//    scroll-behavior: smooth !important;
// }

img,
video {
   max-width: 100%;
   height: auto;
}

header {
   z-index: 1;
}

.c-pointer {
   cursor: pointer;
}

.group-chat {
   background: linear-gradient(135deg, #ff5858 0%, #f09819 100%);
   box-shadow: 0px 2px 16px rgba(255, 88, 88, 0.5);
}

.dzu-dropzone {
   overflow: auto !important;
   border: 2px dashed #d9d9d9 !important;
   &.error {
      border-color: $error !important;
   }
}

.chat-item {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 56px;
   height: 56px;
   border-radius: 50%;

   &-tidio {
      background: linear-gradient(135deg, #ff5858 0%, #f09819 100%);

      &__icon {
         width: 24px;
         height: 24px;
      }
   }

   &-zalo {
      &__icon {
         width: 100%;
         height: 100%;
      }
   }
}

@media screen and (orientation: landscape) {
   .min-screen-height-100 {
      min-height: 100vmin;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .chat-item {
      width: 40px;
      height: 40px;

      &-tidio {
         &__icon {
            width: 20px;
            height: 20px;
         }
      }
   }
}

@media screen and (orientation: portrait) {
   .min-screen-height-100 {
      min-height: 100vmax;
   }
}

.inner-scroll-example {
   padding: 8px;
   position: absolute;
   right: 42px;
   top: 84px;
   height: 240px;
   width: 220px;
   border-radius: 3px;
   background-color: #b7d7d8;
   overflow: auto;
   box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.inner-scroll-example > div {
   margin-bottom: 150px;
}

.inner-scroll-example > div:last-of-type {
   margin-bottom: 0;
}

:root {
   @each $colorname, $palette in $palettes {
      @each $key, $value in $palette {
         $keyname: "--palette-" + $colorname + "-" + $key;
         #{$keyname}: #{$value};
      }
   }
}

:root {
   color-scheme: only light;
}

@media (min-width: 576px) {
   .container-sm,
   .container {
      max-width: 540px;
   }
}

@media (min-width: 768px) {
   .container-md,
   .container-sm,
   .container {
      max-width: 720px;
   }
}

@media (min-width: 992px) {
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 960px;
   }
}

@media (min-width: 1400px) {
   .container-xxl,
   .container-xl,
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 1320px;
   }
}

@media (min-width: 1200px) {
   .container-xl,
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 1224px;
   }
}

.consultation-fixed-custom {
   position: fixed;
   bottom: 16px;
   left: 8px;
   z-index: 199;

   .btn-contact {
      border-radius: 100px;
      background: #ff6347;
      width: max-content;
      padding: 7px 12px;
   }

   a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff !important;
      text-decoration: none;
      padding: 10px;
   }
}

@media (prefers-color-scheme: dark) {
   html {
      color-scheme: dark;
   }

   body {
      // color: white;
      // background: black;
   }
}

@media screen and (orientation: landscape) {
   .min-screen-height-100 {
      min-height: 100vmin;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .chat-item {
      width: 40px;
      height: 40px;

      &-tidio {
         &__icon {
            width: 20px;
            height: 20px;
         }
      }
   }
}

@media screen and (orientation: portrait) {
   .min-screen-height-100 {
      min-height: 100vmax;
   }
}

@media (prefers-color-scheme: dark) {
   html,
   body {
      color-scheme: unset;
      background: unset;
   }
}

.MuiSkeleton-root {
   transform: unset !important;
}
