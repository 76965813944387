@use "sass:map";

.policy-page {
   padding: 50px 0;
   .title {
      font-size: 2.35rem;
      text-align: center;
      margin-bottom: 32px;
   }
   .group-menu {
      border: 1px solid #e4e4e4;
      padding: 20px;
      .title {
         font-size: 1.25rem;
         text-transform: uppercase;
         margin-bottom: 20px;
         padding-bottom: 10px;
         border-bottom: 2px solid map.get($text-colors, "default");
         text-align: center;
      }
      .menu {
         ul {
            list-style: none;
            padding-left: 0;
            li {
               a {
                  text-decoration: none;
                  color: map.get($text-colors, "default");
                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 28px;
                  letter-spacing: 1px;
               }
            }
         }
      }
   }
}

.main-policy {
   .post-wrap {
      table {
         max-width: 100%;
         min-width: 60%;
         tbody {
            tr,
            td {
               border-width: 1px !important;
               padding: 5px;
            }
         }
      }
   }
}

.policy-badge {
   display: flex;
   justify-content: center;
   .policy-badge-img {
      width: 100%;
      max-width: 840px;
      margin: 0 auto;
   }

   .main-img {
      width: 100%;
      max-width: 70%;
      @include media-down(md) {
         max-width: 100%;
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .policy-page {
      padding: 16px 0;
   }
   .main-policy {
   }
}
