@use 'sass:map';

.landing-page-service-page {
   .design-costs {
      padding: 100px 0;
      background: rgba(241, 241, 241, 0.9);

      .header {
         .description {
            max-width: 580px;
         }
      }

      .items {
         display: flex;
         justify-content: center;

         .tables {
            width: 80%;
            padding: 20px;
            border-radius: 4px;
            background-color: #fbfbfc;
            border: 1px solid #e8eaed;

            .wrapper_content {
               display: flex;
               gap: 10px;
               justify-content: center;

               .form {
                  padding: 20px;
                  border-radius: 4px;
                  background: #ffffff;
                  border: 1px solid #e0e0e0;

                  .title {
                     font-size: 18px;
                     font-weight: 700;
                     line-height: 21px;

                     color: #252a2b;
                     margin-bottom: 30px;
                  }

                  .item {
                     margin-bottom: 30px;

                     .label {
                        font-weight: 700;
                        font-size: 16px;
                        color: #765953;
                        line-height: 19px;
                     }
                  }

                  .button {
                     display: flex;
                     justify-content: center;

                     .btn-result {
                        gap: 10px;
                        color: #fff;
                        padding: 10px 20px;


                        border-radius: 4px;
                        background: #FF6347;
                     }
                  }

                  .price-mobile {
                     display: none;
                  }
               }

               .price {
                  padding: 20px;
                  border-radius: 4px;
                  background: #ffffff;
                  border: 1px solid #e0e0e0;

                  .title {
                     font-weight: 700;
                     font-size: 18px;
                     line-height: 21px;

                     color: #252a2b;
                     margin-bottom: 20px;
                  }

                  .item {
                     position: relative;

                     background: #ffffff;
                     border: 1px solid #ff6347;
                     height: 73px;
                     padding-top: 15px;
                     padding-left: 24px;
                     margin-bottom: 20px;

                     .icon {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: 36px solid #ff6347;
                        border-right: 36px solid transparent;
                        left: 0;
                        top: 0;

                        img {
                           left: 5px;
                           top: -31px;
                           position: absolute;
                        }
                     }

                     .label {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: #252a2b;
                     }
                  }

                  .total {
                     margin-top: 10px;
                     font-weight: 500;
                     font-size: 24px;
                     line-height: 28px;
                     text-align: center;

                     .label {
                        margin-bottom: 20px;
                     }
                  }
               }
            }

            .text_highlight {
               color: tomato;
               text-align: center;
               padding-top: 12px;
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'lg')) {
      .design-costs {
         .container {
            max-width: unset;
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .design-costs {
         padding: 32px 12px;

         .header {
            .title {
               margin-bottom: 20px;
            }

            .description {
               margin-bottom: 20px;
            }
         }

         .container {
            max-width: unset;
            padding: 0;
         }

         .items {
            .tables {
               .wrapper_content {

                  width: 100%;
               }
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'sm')) {
      .design-costs {
         .items {
            .tables {
               .wrapper_content {
                  width: 100%;

                  .form {
                     width: 100%;

                     .price-mobile {
                        display: block;
                        margin-top: 32px;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 28px;
                        text-align: center;

                        .label {
                           margin-bottom: 20px;
                        }
                     }
                  }

                  .price {
                     display: none;
                  }
               }
            }
         }
      }
   }
}