@use 'sass:map';

.landing-page {
   .rules {
      padding: 80px 0px;

      img {
         width: 100%;
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
      .rules {
         padding: 60px 0px;
         background-image: url('/assets/img/landing-page/image4-no-border.png');
         width: 100%;

         &__list {
            padding: 48px 24px;
            z-index: 1;
            background: #ffffff;
            border: 1px solid map.get($text-colors, orange);
            box-shadow: 10px 29.2128px 65.7289px rgba(51, 51, 51, 0.1);
            border-radius: 20px;
         }
      }
   }
}