@import "styles/variables.scss";

.download-fixed {
   .btn-download {
      border: unset;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 30px;
      background: #FFF;
      padding: 8px 16px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);

      position: fixed;
      bottom: 24px;
      right: 24px;

      height: 40px;
      min-width: 160px;

      @include media-down(md) {
         display: none;
      }

      svg {
         width: 24px;
         height: 24px;
      }

      .text {
         color: #252A2B;
         font-size: 16px;
         font-weight: 500;
         margin-left: 8px;
      }
   }

}

.download-dialog {
   display: block;

   @include media-down(md) {
      display: none;
   }

   .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      cursor: pointer;

      svg {
         width: 100%;
         height: 100%;
      }
   }

   .MuiPaper-root {
      width: 100%;
      max-width: 328px;
      border-radius: 10px;
      background: #FFF;
   }

   .MuiDialogTitle-root {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      color: #252A2B;
      font-size: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      height: 48px;
   }

   .dialog-content {
      padding: 16px 16px 24px;
   }

   .text-top {
      color: #252A2B;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.14px;
      text-align: center;
      margin-bottom: 12px;
      line-height: normal;
   }

   .text-bot {
      color: #252A2B;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.14px;
      text-align: center;
      margin-top: 32px;
      line-height: normal;
   }

   .qr-code {
      width: 168px;
      height: 168px;
      min-height: 168px;
      margin: 0 auto;
      padding: 4px;
      background: #FFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      object-fit: contain;

      img {
         width: 100%;
         height: 100%;
      }
   }

   .wrap-download {
      margin: 12px auto 0;
      max-width: 264px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;

      a {
         display: block;
         padding: 4px;
         border-radius: 8px;
         background: #FFF;
         box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

         img {
            width: 100%;
            height: 100%;
         }
      }
   }
}

.download-drawer-mobile {
   display: none;

   @include media-down(md) {
      display: block;
   }

   .MuiPaper-root {
      border-radius: 16px 16px 0px 0px;
      background: #FFF;
   }

   .drawer-header {
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);

      .drawer-title {
         color: #252A2B;
         text-align: center;
         font-size: 16px;
         font-weight: 500;
         line-height: normal;
         margin-bottom: 8px;
      }

      .drawer-description {
         color: #252A2B;
         text-align: center;
         font-size: 14px;
         font-weight: 400;
         line-height: normal;
         opacity: 0.5;
      }
   }

   .drawer-content {
      padding: 16px;

      .drawer-item {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 4px 0;
         margin-bottom: 16px;

         &:last-child {
            margin-bottom: 0;
         }

         .item-logo {
            width: 28px;
            height: 28px;
            margin-right: 8px;
         }

         .text {
            color: #252A2B;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
         }

         .btn-open-app {
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            width: 100%;
            max-width: 117px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            white-space: nowrap;
            border: unset;

            border-radius: 100px;
            background: #FF6347;
            cursor: pointer;
         }

         .btn-continue {
            color: #252A2B;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            width: 100%;
            max-width: 117px;
            padding: 8px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            white-space: nowrap;
            border: unset;

            border-radius: 100px;
            border: 1px solid rgba(0, 0, 0, 0.10);
            background: #FFF;
            cursor: pointer;

         }
      }
   }
}