@import "styles/variables.scss";

.feed-banner-slider {
   width: 100%;
   margin-bottom: -6px;

   @include media-down(md) {
      padding: 8px 12px 0;
      background-color: #fff;
   }

   .slick-arrow {
      display: block !important;

      @include media-down(md) {
         display: none !important;
      }

      &.slick-prev {
         display: block;
         left: 40px;
         z-index: 1;

         width: 24px;
         height: 24px;
      }

      &.slick-next {
         display: block;
         right: 40px;
         z-index: 1;

         width: 24px;
         height: 24px;
      }
   }

   .slick-dots {
      display: none !important;

      @include media-down(md) {
         display: block !important;
      }

      width: fit-content;
      position: absolute;
      bottom: 16px;
      right: 16px;

      li {
         margin: 0;
         width: 14px;
      }

      li button {
         width: 4px;
         height: 4px;
         padding: 0;
         border-radius: 100%;
         opacity: 0.3;
         background: #fff;
      }

      li button::before {
         display: none;
      }

      li.slick-active button {
         width: 6px;
         height: 6px;
         opacity: 1;
      }
   }

   .slick-list {
      margin: 0 -6px;
   }

   .slick-slide {
      padding: 0 6px;
   }

   .item {
      width: 100%;
      padding: 16px;
      height: 146px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      cursor: pointer;

      @include media-down(md) {
         padding: 8px 12px;
         height: 102px;
      }

      &.item-01 {
         background: url("/assets/img/feed/banner/banner-01.png");
      }

      &.item-02 {
         background: url("/assets/img/feed/banner/banner-02.png");
      }

      &.item-03 {
         background: url("/assets/img/feed/banner/banner-03.png");
      }

      .item-center {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         max-width: 370px;
         margin: 0 auto;

         @include media-down(md) {
            flex-direction: row-reverse;
         }
      }

      .title {
         color: #fff;
         font-size: 20px;
         font-weight: 700;
         line-height: normal;
         letter-spacing: -0.6px;
         margin-bottom: 4px;
         @include media-down(md) {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -0.6px;
            margin-bottom: 0;
         }
      }

      .des {
         color: #fff;
         font-size: 14px;
         font-weight: 400;
         line-height: normal;
         letter-spacing: -0.14px;
         max-width: 280px;
         @include media-down(md) {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.14px;
            padding-right: 12px;
         }
      }

      .wrap-icon {
         display: flex;

         @include media-down(md) {
            padding-left: 4px;
            padding-right: 10px;
         }

         .icon {
            width: 70px;
            height: auto;
            object-fit: contain;

            @include media-down(md) {
               width: 32px;
            }
         }
      }

      .banner-button {
         color: #ff6347;
         font-size: 16px;
         font-weight: 600;
         line-height: normal;

         min-width: 88px;
         height: 35px;
         border-radius: 50px;
         background: #fff;
         cursor: pointer;
         border: unset;
         margin-top: 16px;

         @include media-down(md) {
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            height: 24px;
            min-width: 66px;
            padding: 4px 12px;
            border-radius: 50px;
            background: #ff6347;
            margin-top: 8px;
            margin-left: 10px;
         }

         &:hover {
            outline: unset;
            border-radius: 50px;
            border: 1px solid #ff6347;
            background: #fff;
            box-shadow: 0px 6px 8px 0px rgba(255, 255, 255, 0.5);

            @include media-down(md) {
               background: #ff6347;
               box-shadow: unset;
            }
         }

         &:focus-within {
            outline: unset;
         }
      }

      .close-icon {
         width: 12px;
         height: 12px;
         position: absolute;
         top: 10px;
         right: 10px;
         cursor: pointer;
      }
   }
}
