@use "sass:map";

main.construction-page {
   .main-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
   }
   .feed-height-init {
      height: 440px;
      @include media-down(md) {
         height: 345px;
      }
   }
   .flash-sale-init {
      height: 334px;
      @include media-down(md) {
         height: 206px;
      }
   }
   .block {
      padding: 24px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-header {
         margin-bottom: 4px;

         &__title {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: $cl-space-t-orange;
            margin-bottom: 10px;
            cursor: pointer;
         }

         &__description {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
         }
      }
   }
   .content_end_page {
      padding: 24px 0;
      .more_content {
         margin-top: 24px;
         .content_item {
            &:not(:last-child) {
               margin-bottom: 24px;
            }
         }
      }

      .content_item {
         .content_title {
            margin-bottom: 12px;
            font-weight: 700;
            font-size: 16px;
            color: #252a2b;
            line-height: 19px;
         }
         .content_description {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #697f90;
            a {
               color: #0055aa;
               text-decoration: underline;
            }
            div {
               margin-top: 6px;
            }
            .btn_see_more {
               display: none;
            }
            h4 {
               // font-weight: 400;
               font-size: 12px;
               line-height: 14px;
            }
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   main.construction-page {
      .main-content {
         padding: 0px;
         gap: 12px;
      }

      .block {
         padding: 16px 12px;
         gap: 16px;
         &:not(:last-child) {
            // margin-bottom: 12px;
         }

         section {
            // margin-top: 20px;

            // &:not(:last-child) {
            //    padding-bottom: 20px;
            //    border-bottom: 2px solid #f0efef;
            // }
         }

         &-header {
            margin-bottom: 0px;

            &__title {
               font-weight: 700;
               font-size: 18px;
               line-height: 21px;
               margin-bottom: 2px;
            }

            &__description {
               font-weight: 400;
               font-size: 16px;
               line-height: 19px;
            }
         }
      }
      .content_end_page {
         .more_content {
            &.see_more {
               display: none;
            }
            &.see_less {
               display: block;
            }
         }
         .content_item {
            .content_description {
               .btn_see_more {
                  display: unset;
                  color: #ff6347;
                  cursor: pointer;
                  &.see_more {
                     &::before {
                        content: "...";
                        color: #000;
                     }
                  }
               }
               .btn_hide_see_more {
                  display: BLOCK;
                  padding: 12px 12px 0;
                  text-align: center;
                  color: #ff6347;
                  cursor: pointer;
                  &.see_more {
                     &::before {
                        content: "...";
                        color: #000;
                     }
                  }
               }
            }
         }
      }
   }
}
