.title {
   font-weight: 700;
   font-size: 18px;
   line-height: 21px;
   text-align: center;
   color: #252a2b;
   margin-bottom: 32px;
}

.dialog-action {
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 12px;
}

.dialog-btn {
   border-radius: 8px;
   outline: none;
   width: 100%;
   font-weight: 700;
   font-size: 15px;
   line-height: 18px;
   height: 42px;
}
.cancel-btn {
   border: 1px solid #cccccc;
   @extend .dialog-btn;
}
.submit-btn {
   color: #ffffff;
   background: #ff6347;
   border: 1px solid #ff6347;
   @extend .dialog-btn;
}
