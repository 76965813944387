@import "../../../styles/variables.scss";

.overlap-payment-loading {
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background: #fff;
   z-index: 100;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.completed-order-header {
   height: 56px;
   display: flex;
   align-items: center;
   background: #f5f5fa;
   button {
      background: unset;
      border: unset;
      width: 56px;
      height: 56px;
   }
}
.completed-order {
   background-color: #f5f5f5;
   padding: 16px 0;
   @include media-down(md) {
      padding: 0;
   }
   .order-success-style {
      min-height: 378px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include media-down(md) {
         padding: 16px;
         justify-content: flex-start;
         background: #f5f5fa;
      }
   }
   .wrap-order-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 12px;
      a {
         @include media-down(md) {
            flex: 1;
         }
      }
      button {
         min-width: 176px;
         @include media-down(md) {
            width: 100%;
            min-width: 100%;
         }
      }
   }
   .img-completed-order {
      width: 100%;
      height: 100%;
      margin-bottom: 22px;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .text {
      .title {
         text-align: center;
         font-size: 16px;
         font-weight: 500;
         line-height: normal;
         text-transform: uppercase;
         margin-bottom: 8px;
      }
      .des {
         p {
            color: #9f9f9f;
            text-align: center;
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px; /* 126.582% */
            letter-spacing: -0.126px;
            margin-bottom: 8px;
            max-width: 268px;
            margin-bottom: 32px;
         }
      }
   }
}

// List product suggest
.completed-order-suggest {
   background: #fff;
   .suggest-container {
      max-width: $container-width;
      padding-left: $container-padding;
      padding-right: $container-padding;
      margin: auto;
      @include media-down(md) {
         padding: 0 16px;
      }
   }
   .title {
      color: #252a2b;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px; /* 120% */
      padding: 24px 0;
      margin: 0;
      @include media-down(md) {
         font-size: 14.22px;
         line-height: 16px; /* 112.518% */
         padding: 16px 0;
         font-weight: 500;
      }
   }
   .see-more {
      color: #ff6347;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.14px;
      width: fit-content;
      height: 40px;
      min-width: 111px;
      border-radius: 8px;
      border: 1px solid #ff6347;
      background: #fff;
      margin: 24px auto 24px;
      transition: 0.2s;
      &:hover {
         color: #fff;
         background: $primary;
      }
   }
}
