@use "sass:map";

.navbar {
   position: sticky;
   top: 42px;
   background: #fff;
   z-index: 1019;

   .navbar-item {
      border-bottom: 1px solid #0000001a;
      padding: 0;
      overflow: auto;
      // -ms-overflow-style: none;
      // scrollbar-width: none;
   }

   // .navbar-item::-webkit-scrollbar {
   //     display: none;
   //     /* Safari and Chrome */
   // }

   &.sub-navbar {
      top: 116px !important;
      z-index: 1018;
   }

   ul.main-menu > li > a,
   ul.main-menu > li > p {
      font-size: 1rem;
      font-weight: 500;
      display: block;
      padding: 10px 15px;
      text-decoration: none;
      // text-transform: uppercase;
      color: map.get($text-colors, "default");
      transition: 0.25s ease-in-out;
      white-space: nowrap;
      margin: 0;
   }

   ul.main-menu > li > a.active {
      color: #ff6347;
      border-bottom: 1px solid #ff6347;
   }

   ul.main-menu > li > a:hover {
      color: #ff6347;
   }

   &.bottom {
      .level-2 {
         overflow: auto hidden;

         a {
            display: block;
            position: relative;
            font-size: 0.938rem;
            padding: 20px 15px;
            text-decoration: none;
            color: map.get($text-colors, "default");
         }

         a.active {
            font-weight: 500;
            color: #ff6347;

            &::after {
               content: "";
               position: absolute;
               width: 100%;
               height: 2px;
               left: 0;
               bottom: 0;
               background-color: #ff6347;
            }
         }
      }
   }
}

.user-profile-page {
   // margin: 50px 0;
   .profile {
      .sidebar-profile {
         border-right: 1px solid #efefef;

         .top-sidebar {
            padding: 20px 0;
            position: relative;

            &::before {
               content: "";
               position: absolute;
               width: 143px;
               height: 1px;
               border: 1px solid #efefef;
               left: 0px;
               top: 134px;
            }

            .top-sidebar-profile {
               display: flex;
               align-items: center;
               margin-left: 14px;
               margin-bottom: 17px;

               img {
                  width: 34px;
                  height: 34px;
                  margin-right: 6px;
                  border-radius: 50%;
                  object-fit: cover;
               }

               .name {
                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 1rem;
                  color: map.get($text-colors, "default");
                  margin: 0;
                  width: 100px;
                  white-space: pre-wrap;
                  word-break: break-word;
               }
            }

            .btn-profile {
               display: flex;
               justify-content: center;
               align-items: center;
               margin: 0 !important;
               color: #fff;
               width: 143px;
               padding: 10px;
               background: linear-gradient(86.06deg, #ff5d02 0%, #ff9d42 100%);

               &:focus {
                  box-shadow: none;
               }

               img {
                  margin-right: 6px;
               }
            }
         }

         .menu-sidebar {
            margin-top: 20px;

            .menu-list {
               padding-left: unset;
               list-style: none;

               .list-item {
                  // font-weight: bold;
                  // font-size: 1.25rem;
                  // line-height: 19px;
                  padding: 12px 0;

                  a {
                     &.active,
                     &:hover {
                        color: #ff6347;
                     }
                  }

                  span,
                  a {
                     color: map.get($text-colors, "default");
                     text-decoration: none;
                     font-weight: bold;
                     font-size: 1rem;
                     line-height: 19px;
                  }

                  .sub-list {
                     list-style: none;
                     padding-left: unset;

                     .sub-item {
                        padding: 12px 0;
                        padding-left: 10px;

                        a {
                           &.active,
                           &:hover {
                              color: #ff6347;
                           }
                        }

                        a {
                           font-size: 1rem;
                           line-height: 19px;
                           text-decoration: none;
                           color: map.get($text-colors, "default");
                           opacity: 0.6;
                           font-weight: normal;
                        }
                     }
                  }
               }
            }
         }
      }

      .error-text {
         color: #ff0000;
         font-size: 16px;
         line-height: 20px;
      }

      .custom-input {
         &:focus {
            outline: none;
            box-shadow: none;
            // border:initial ;
         }

         &.error {
            border: 1px solid #ff0000 !important;
         }
      }

      .custom-select {
         width: 100%;

         &:focus {
            outline: none;
            box-shadow: none;
            // border:initial ;
         }

         &.error {
            border: 1px solid #ff0000 !important;
         }
      }

      .head {
         padding: 21px 0;
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-bottom: 1px solid #e0e0e0;

         p {
            margin-bottom: 0;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #252a2b;
         }
      }

      //user
      .user-profile {
         .avatar-upload {
            position: relative;
            max-width: 150px;
            margin: 50px auto;
            margin-bottom: 0px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;

            .avatar-edit {
               width: 150px;
               height: 38px;
               display: flex;
               justify-content: center;
               align-items: center;
               bottom: 0;
               position: absolute;
               background: rgba(0, 0, 0, 0.5);

               input {
                  display: none;
                  background: #fbffff;

                  + label {
                     display: inline-block;
                     cursor: pointer;
                     transition: all 0.2s ease-in-out;
                  }
               }
            }

            .avatar-preview {
               width: 150px;
               height: 150px;

               img {
                  border-radius: 50%;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  z-index: 9;
               }
            }
         }

         .user-form-update {
            input {
               height: 38px;

               &::placeholder {
                  font-size: 0.875rem;
                  line-height: 20px;
                  color: rgba(129, 128, 133, 0.82);
               }
            }

            label {
               font-weight: 500;
               font-size: 1rem;
               line-height: 15px;
               color: #818085;
            }

            .input-readonly {
               position: relative;
               width: 100%;
               height: auto;

               .btn-edit {
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  width: max-content;
                  padding: 0 10px;
                  // background: linear-gradient(86.06deg, #ff5d02 0%, #ff9d42 100%);
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #000;
                  cursor: pointer;
                  text-decoration: none;
               }

               input:read-only {
                  background: #fff;
                  // border-color: #ff5d02;
               }
            }

            .gender-group {
               height: 38px;
               display: flex;
               justify-content: flex-start;
               align-items: center;

               .custom-check {
                  input {
                     margin-right: 8px;
                     width: 24px;
                     height: 24px;
                     border: 2px solid #dcf0f0;
                  }

                  label {
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 15px;
                     margin: 8px auto;
                     color: #818085;
                  }
               }
            }
         }

         .btn-update {
            padding: 0px 20px;
            width: 107px;
            height: 47px;
            background: #009999;
            margin: 0 auto;
            color: #fff;
         }
      }

      //user address
      .user-address {
         .btn-add-address {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: #fff;
            border: 1px solid #dadce0;
            // padding: 12px 20px;
            color: map.get($text-colors, "default");

            img {
               margin-right: 11px;
            }
         }

         .add-user-address {
            height: 84px;
            padding: 22px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-bottom: 1px solid #dadce0;

            .btn-add-address {
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 4px;
               background: #fff;
               border: 1px solid #dadce0;
               padding: 12px 20px;
               color: map.get($text-colors, "default");

               img {
                  margin-right: 11px;
               }
            }
         }

         .create-new-address {
            display: none;
            transition: 0.5s;

            &.enable {
               display: block;
               transition: 0.5s;
            }
         }

         .user-address-info {
            margin-bottom: 50px;

            .head-address-info {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               padding: 20px 0;

               h4 {
                  font-weight: 700;
                  font-size: 1rem;
                  line-height: 19px;
                  color: map.get($text-colors, "default");
                  margin-right: 20px;
               }

               .btn-update {
                  padding: 10px 15px;
                  background: #009999;
                  border-radius: 4px;
                  color: #fff;
               }

               .btn-df-address {
                  border: 1px solid #dadce0;
                  background: #fff;
                  color: map.get($text-colors, "default");
               }
            }

            @media (min-width: map.get($grid-breakpoints, "xl")) {
               .main-wrap {
                  width: 88%;
               }
            }

            .main-address-info {
               table {
                  width: 100%;
                  border-style: hidden;
                  /* hide standard table (collapsed) border */
                  box-shadow: 0 0 0 1px #dadce0;
                  border-radius: 8px;

                  tr {
                     border: 1px solid #dadce0;

                     td {
                        vertical-align: top;
                        padding: 20px 24px;
                        line-height: 22px;

                        input,
                        textarea {
                           border: none;
                           outline: none;
                           padding: 0;
                           width: 100%;
                        }
                     }
                  }
               }
            }

            .edit-address {
               display: flex;
               justify-content: flex-end;

               .btn-edit {
                  margin: 0 !important;
                  background: unset;
                  color: map.get($text-colors, "default");
                  border: 1px solid #dadce0;
                  width: 89px;
                  height: 40px;
                  border-radius: 8px;
                  padding: 10px 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                     margin-right: 10px;
                     opacity: 0.6;
                  }
               }
            }

            .btn-create {
               padding: 10px 30px;
               background: #009999;
               border-radius: 4px;
               color: #fff;
            }
         }
      }

      //user password
      .user-form-update {
         padding: 24px 0;
      }

      //bookmark
      .bookmark-profile {
         margin: 0;

         .MuiTabs-root.tab-menu-profile-bookmark {
            width: 100vw;
            margin: 0px -12px;
            padding: 0px 12px;
         }

         .head-bookmark-profile {
            .head-list {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               list-style: none;
               padding-left: 0;
               border-bottom: 1px solid #ededed;

               .list-item {
                  padding: 18px 0;
                  width: 166px;
                  height: 54px;
                  text-align: center;
                  cursor: pointer;

                  a {
                     // padding: 18px 40px;
                  }

                  &.active {
                     border-bottom: 1px solid #ff6347;

                     a {
                        color: #ff6347;
                     }
                  }

                  &:hover {
                     a {
                        color: #ff6347;
                     }
                  }

                  a {
                     text-decoration: none;
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 18px;
                     color: map.get($text-colors, "default");
                     text-decoration: none;
                  }
               }
            }
         }

         .edit {
            width: 100%;
            margin: 16px 0;
            text-align: right;

            span {
               font-weight: 500;
               font-size: 15px;
               line-height: 18px;
               color: map.get($text-colors, "default");
               cursor: pointer;
            }
         }

         .bookmark-profile-wrap {
            .img-product {
               flex: 1 1;
               height: 0;
               padding-bottom: 130%;
               position: relative;
               overflow: hidden;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1;
               }

               .percen-discount {
                  position: absolute;
                  top: 8px;
                  left: 8px;
                  font-size: 0.75rem;
                  font-weight: 500;
                  text-align: center;
                  color: #fff;
                  background-color: #ff6347;
                  width: max-content;
                  height: 20px;
                  min-width: 40px;
                  border-radius: 100px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 100;
               }

               .out-stock-tag {
                  position: absolute;
                  top: 8px;
                  right: 8px;
                  left: unset;
                  font-size: 0.75rem;
                  font-weight: 500;
                  text-align: center;
                  padding: 0 4px;
                  color: #000;
                  background-color: #fff;
                  width: max-content;
                  height: 20px;
                  border-radius: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 100;
               }
            }

            .price,
            .title {
               margin-bottom: 0.286rem;
            }

            .title_mark {
               position: absolute;
               z-index: 100;
               bottom: 16px;
               left: 0;
               padding: 0 16px;
               font-weight: 700;
               font-size: 1rem;
               line-height: 19px;
               color: #fff;
               height: 37px;
               text-overflow: ellipsis;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               display: -webkit-box;
               overflow: hidden;
               margin-bottom: 0.286rem;
            }

            .price {
               font-size: 1rem;
               font-weight: 500;
               color: #252a2b;
               margin-bottom: 0.286rem;
            }

            .price-wrap {
               color: #252a2b;

               .real-price {
                  font-size: 0.75rem;
                  font-weight: 400;
                  color: #252a2b;
                  text-decoration: line-through;
                  margin-bottom: 0.286rem;
               }

               .discount-price {
                  font-size: 1rem;
                  font-weight: 500;
                  color: #009999;
                  margin-bottom: 0.286rem;
                  margin-right: 6px;
               }
            }

            .title,
            .vendor {
               font-size: 1rem;
               font-weight: 400;
               color: rgba(37, 42, 43, 0.7);
            }

            .img {
               flex: 1 1;
               height: 0;
               padding-bottom: 75%;
               position: relative;
               overflow: hidden;

               .rule-tag {
                  position: absolute;
                  width: max-content;
                  height: 40px;
                  right: 16px;
                  top: 16px;
                  background: map.get($text-colors, "default");
                  opacity: 0.9;
                  padding: 8px 10px;
                  z-index: 1000;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .des {
                     font-size: 0.875rem;
                     line-height: 12px;
                     margin: 0;
                     /* identical to box height */
                     color: #fff;
                     text-align: center;
                     text-transform: capitalize;
                  }
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1;
               }

               .title {
                  position: absolute;
                  z-index: 100;
                  bottom: 16px;
                  left: 0;
                  padding: 0 16px;
                  font-weight: bold;
                  font-size: 1rem;
                  line-height: 19px;
                  color: #ffffff;
                  height: 37px;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  overflow: hidden;
               }

               .cover {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  z-index: 90;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57.19%, rgba(0, 0, 0, 0.67) 100%);
               }
            }
         }
      }

      //point
      .user-point {
         margin: 21px auto;
         padding: 40px 50px;
         padding-bottom: 14px !important;
         border: 1px solid #ededed;
         box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

         .main-wrap {
            margin-top: 10px;
            margin-bottom: 30px;

            .title {
               font-size: 1rem;
               margin-bottom: 23px;
            }
         }

         .head-point {
            border-bottom: 1px solid #ededed;
            padding-bottom: 10px;

            .main-des {
               .des {
                  font-weight: 500;
                  font-size: 1.15rem;
                  line-height: 19px;
                  color: map.get($text-colors, "default");
               }

               .point {
                  display: flex;
                  align-items: center;

                  span {
                     font-weight: 500;
                     font-size: 38px;
                     line-height: 45px;
                     text-transform: uppercase;
                     color: map.get($text-colors, "default");
                     padding-right: 8px;
                  }

                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 19px;
                  color: map.get($text-colors, "default");
               }
            }

            .sub-des {
               border: 1px solid #ddd;
               box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
               padding: 12px;

               .des {
                  font-size: 1rem;
                  line-height: 19px;
                  color: map.get($text-colors, "default");

                  span {
                     color: #ff6347;
                     text-decoration: none;
                  }
               }

               a {
                  font-size: 1rem;
                  line-height: 19px;
                  color: #ff6347;
                  text-decoration: none;
               }
            }
         }

         .main-point {
            padding: 15px 0;
            border-bottom: 1px solid #ededed;

            .product-img {
               width: 78px;
               height: 78px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }

               &.contain {
                  border: 0.5px solid #e4e4e4 !important;

                  img {
                     object-fit: contain !important;
                  }
               }
            }

            .h-des {
               height: 100px;
            }

            .text {
               color: map.get($text-colors, "default");
               padding-left: 12px;

               p {
                  margin: 0;
                  font-size: 16px;
                  line-height: 20px;
                  opacity: 0.6;
               }

               .btn-wrap {
                  margin-top: 12px !important;
               }

               .title {
                  font-weight: 500;
                  font-size: 1.15rem;
                  line-height: 19px;
                  margin-bottom: 0;

                  a {
                     text-decoration: none;
                     color: map.get($text-colors, "default");
                  }
               }

               .title-text {
                  font-weight: 500;
                  font-size: 1.15rem;
                  line-height: 19px;
                  margin-bottom: 0;

                  a {
                     text-decoration: none;
                     color: map.get($text-colors, "default");
                  }
               }

               .des {
                  margin: 12px 0 !important;
               }
            }

            .btn-point {
               background: #ff6347;
               padding: 10px 12px;
               color: #fff;
               font-weight: 500;
               font-size: 16px;
               line-height: 19px;
               cursor: auto;

               &.active {
                  background: #262b2ccc;
               }
            }
         }

         .point-system {
            .title {
               font-weight: 500;
               font-size: 1.5rem;
               line-height: 28px;
               color: map.get($text-colors, "default");
               margin-bottom: 12px;
            }

            .title_system {
               font-weight: 500;
               font-size: 1.5rem;
               line-height: 28px;
               color: map.get($text-colors, "default");
               margin-bottom: 12px;
            }

            .list {
               padding-left: 30px;

               .list-item {
                  font-size: 1rem;
                  line-height: 20px;
                  color: map.get($text-colors, "default");
                  padding-bottom: 8px;

                  .sub-list {
                     margin-top: 8px;
                     opacity: 0.6;

                     .sub-list-item {
                        font-size: 1rem;
                        line-height: 20px;
                        color: map.get($text-colors, "default");
                        padding-bottom: 8px;
                     }
                  }
               }
            }
         }
      }

      .review {
         margin-top: 0;

         .empty-wrap {
            .empty {
               display: flex;
               justify-content: center;
               align-items: center;

               .img-empty {
                  width: 178px;
                  height: 178px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               p {
                  font-size: 16px;
                  line-height: 20px;
                  color: #666666;
                  margin-bottom: 8px;
               }

               .btn-empty {
                  width: 147px;
                  height: 40px;
                  background: #252a2b;
                  border-radius: 4px;

                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  a {
                     color: #fff;
                     text-decoration: none;
                  }
               }
            }
         }

         .head-bookmark-profile {
            .head-list {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               list-style: none;
               padding-left: 0;
               border-bottom: 1px solid #ededed;

               .list-item {
                  padding: 18px;
                  width: max-content;
                  height: 54px;
                  text-align: center;
                  cursor: pointer;

                  &.active {
                     border-bottom: 1px solid #ff6347;

                     a {
                        color: #ff6347;
                     }
                  }

                  &:hover {
                     a {
                        color: #ff6347;
                     }
                  }

                  a {
                     text-decoration: none;
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 18px;
                     color: map.get($text-colors, "default");
                     text-decoration: none;
                     padding: 18px 20px;
                  }
               }
            }
         }

         .main-review {
            .item-review {
               box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.17);
               border: 1px solid #ededed;
               padding-top: 12px;
               padding-bottom: 12px;
               cursor: pointer;

               .item-img {
                  width: 67px;
                  height: 84px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               .text {
                  color: map.get($text-colors, "defautl");
                  width: 100%;
                  height: 100%;
                  justify-content: center;

                  .title {
                     font-weight: 500;
                     font-size: 1.15rem;
                     line-height: 19px;
                     color: #f07300;
                  }

                  p {
                     margin: 0;
                     font-size: 16px;
                     line-height: 20px;
                  }

                  .date {
                     opacity: 0.6;
                  }

                  .status {
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     color: #66ac5b;

                     &.pending {
                        color: #1c77f3;
                     }

                     &.disable {
                        color: #ff0000;
                     }
                  }
               }
            }
         }
      }

      //modal review
      .modal {
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background: rgba(241, 241, 241, 0.9);

         .modal-main {
            position: fixed;
            background: #ffffff;
            max-width: 560px;
            min-width: 350px;
            border-radius: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 34px;

            p {
               margin-bottom: 10px;
            }

            textarea {
               background: #ffffff;
               border-radius: 8px;
               resize: none;
               overflow: auto;
            }

            .MuiRating-root {
               font-size: 1.875rem;
            }

            .img {
               width: 100%;
               height: 100%;
               max-width: 143px;
               max-height: 179px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .btn {
               background: #252a2b;
               border-radius: 4px;
               color: #fff;
               padding: 10px 30px;
            }
         }
      }

      .display-block {
         display: block;
      }

      .display-none {
         display: none;
      }

      //Q&A
      .user-qa {
         .empty-wrap {
            .empty {
               display: flex;
               justify-content: center;
               align-items: center;

               .img-empty {
                  width: 178px;
                  height: 178px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               p {
                  font-size: 16px;
                  line-height: 20px;
                  color: #666666;
                  margin-bottom: 8px;
               }

               .btn-empty {
                  width: 147px;
                  height: 40px;
                  background: #252a2b;
                  border-radius: 4px;

                  padding: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  a {
                     color: #fff;
                     text-decoration: none;
                  }
               }
            }
         }

         .menu-qa {
            border-bottom: 1px solid #ededed;
            margin-top: 12px;
            margin-bottom: 20px;

            .menu-item {
               width: 200px;
               height: 51px;
               padding: 18px 0;
               text-align: center;
               cursor: pointer;

               p,
               a {
                  color: map.get($text-colors, "default");
                  text-decoration: none;
                  font-size: 1rem;
                  font-weight: 500;
                  line-height: 19px;
                  text-align: center;
                  margin: 0;
                  padding: 18px 30px;
               }

               &.active {
                  border-bottom: 1px solid #ff6347;

                  p,
                  a {
                     color: #ff6347;
                  }
               }
            }

            .btn {
               background: #252a2b;
               opacity: 0.7;
               border: 1px solid #252a2b;
               box-sizing: border-box;
               border-radius: 8px;
               color: #fff;
               padding: 9.5px 33.5px;
               line-height: 19px;
               font-size: 1rem;
            }
         }

         .main-qa {
            border-bottom: 1px solid #ededed;
            border-top: 1px solid #ededed;
            padding: 7px 0;
            position: relative;

            .cover {
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               z-index: 100;
            }

            p {
               margin: 0;
            }

            .img {
               width: 56px;
               height: 56px;
               margin-right: 13px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .status {
               display: flex;
               align-items: center;
               gap: 4px;
               color: #66ac5b;

               &.pending {
                  color: #1c77f3;
               }

               &.disable {
                  color: #ff0000;
               }
            }

            .text-block {
               .text {
                  font-weight: 500;
                  font-size: 1.15rem;
                  line-height: 19px;
                  color: #252a2b;

                  span {
                     color: #ff6347;
                  }
               }
            }

            .info {
               display: flex;
               justify-content: space-between;
               align-items: center;

               p {
                  font-size: 1rem;
                  line-height: 20px;
                  color: #252a2b;

                  &.date {
                     padding-left: 6px;
                     position: relative;

                     &::before {
                        content: "";
                        width: 1px;
                        height: 10px;
                        position: absolute;
                        background-color: #252a2b;
                        top: 25%;
                        left: 0;
                     }
                  }

                  &.name {
                     padding-right: 6px;
                  }
               }
            }
         }
      }

      //order
      .user-orders {
         padding: 0;
         height: 100%;

         .empty-order-body {
            height: 100%;

            .title {
               font-size: 1rem;
               font-weight: 400;
               text-align: center;
            }

            .go-to-shop-btn {
               display: block;
               text-align: center;
               text-decoration: none;
               width: 100%;
               color: #fff;
               border: none;
               background-color: #252a2b;
            }
         }

         table {
            tr {
               border-bottom: 1px solid #252a2b49;

               th {
                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 19px;
                  color: map.get($text-colors, "default");
                  padding: 20px 10px;
               }

               td {
                  .order-id {
                     margin-bottom: 0;
                     white-space: nowrap;
                     width: 100px;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     text-transform: uppercase;
                  }

                  .products-order {
                     .img {
                        width: 46px;
                        height: 58.18px;
                        border: 0.5px solid #e4e4e4;

                        img {
                           width: 100%;
                           height: 100%;
                           object-fit: cover;
                        }
                     }
                  }
               }
            }
         }

         .order-wrap {
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            padding: 12px;
            margin-bottom: 20px;
            border-radius: 4px;

            .head {
               padding-bottom: 12px;
               border-bottom: 1px solid #e4e4e4;
            }

            .main {
               padding-top: 12px;

               .img {
                  width: 60px;
                  height: 60px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }
            }
         }
      }

      //order-detail
      .user-orders-detail {
         padding: 50px 0;

         .title_detail {
            margin-bottom: 50px;
            font-weight: 500;
            font-size: 1.875rem;
            line-height: 28px;
            color: map.get($text-colors, "default");
         }

         .date-order {
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
            color: map.get($text-colors, "default");
            margin-bottom: 0;

            span {
               font-weight: 400;
            }

            &.status {
               margin-top: 28px;
            }
         }

         .tab {
            margin: 28px 0;

            .tab-head {
               background: rgba(0, 153, 153, 0.05);
               border-radius: 4px;
               padding: 10px;
               color: #009999;
               font-weight: normal;
               font-size: 1rem;
               line-height: 20px;
            }

            .tab-body {
               justify-content: space-between;
               padding-top: 12px;
               font-size: 1rem;
               line-height: 20px;

               .left {
                  white-space: normal;
                  width: 36%;
               }

               .right {
                  text-align: right;
               }
            }
         }

         table {
            tr {
               th {
                  background: rgba(0, 153, 153, 0.05);
                  border-radius: 4px;
                  padding: 10px;
                  color: #009999;
                  font-weight: normal;
                  font-size: 1rem;
                  line-height: 20px;
               }

               td {
                  font-size: 1rem;
                  line-height: 20px;

                  // .products-order {
                  //     .img {
                  //         width: 46px;
                  //         height: 58px;
                  //         img {
                  //             width: 100%;
                  //             height: 100%;
                  //             object-fit: cover;
                  //         }
                  //     }
                  // }
               }
            }

            tbody {
               td {
                  font-size: 1rem;
                  line-height: 20px;

                  .products-order {
                     display: flex;
                     align-items: center;
                     padding-left: 0;

                     .img {
                        width: 46px;
                        height: 58px;

                        img {
                           width: 100%;
                           height: 100%;
                           object-fit: cover;
                        }
                     }

                     .name {
                        padding-left: 12px;
                     }
                  }
               }
            }
         }

         .order-wrap {
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            padding: 12px;
            margin-bottom: 20px;
            border-radius: 4px;

            .head {
               padding-bottom: 12px;
               border-bottom: 1px solid #e4e4e4;

               .text-end {
                  color: #009999;
               }
            }

            .main {
               padding-top: 12px;

               .img {
                  width: 60px;
                  height: 75px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }
            }
         }

         .tab-amount {
            .tab-head {
               background: #fff;
               padding: 0;
               padding-bottom: 28px;
               border-bottom: 1px solid #252a2b1f;
            }

            .tab-body {
               .total-text {
                  font-weight: 500;
                  font-size: 1.15rem;
                  line-height: 19px;
                  color: map.get($text-colors, "default");
               }

               .total-text {
                  font-weight: 500;
                  font-size: 1.15rem;
                  line-height: 19px;
                  color: #009999;
               }
            }
         }

         .btn-wrapper {
            .disabled {
               cursor: not-allowed;
            }

            .btn {
               height: 40px;
               text-align: center;
               display: flex;
               align-items: center;
               justify-content: center;

               &:disabled {
                  background-color: #ccc;
                  opacity: 1;
               }
            }

            .btn-cancel {
               background: #009999;
               border-radius: 4px;
               padding: 10px 20px;
               color: #fff;
            }

            .btn-back {
               padding: 10px 20px;
               color: #009999;
               border: 1px solid #009999;
               border-radius: 4px;
               white-space: nowrap;

               a {
                  color: #009999;
                  text-decoration: none;
               }
            }
         }
      }

      //vouchers
      .user-vouchers {
         .img {
            flex: 1;
            height: 0;
            padding-bottom: 50%;
            margin: 0 10px;
            position: relative;
            overflow: hidden;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;

               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
            }
         }
      }

      //noti
      .user-noti {
         margin-top: 28px;
         margin-bottom: 28px;
         border: 1px solid #e8eaed;

         &.empty {
            border: unset;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .img-empty {
               width: 178px;
               height: 178px;
               display: flex;
               flex-direction: column;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }

               span {
                  padding-top: 20px;
                  text-align: center;
               }
            }
         }

         .head {
            display: block;
            padding: 10px 18px 10px 0;
            width: 100%;
            text-align: right;
            cursor: pointer;
         }

         .main {
            align-items: center;
            padding: 0 12px;

            .item {
               padding: 12px;
               background: #fff;
               cursor: pointer;

               a {
                  text-decoration: none;
               }

               .text {
                  margin-left: 12px;

                  p.title {
                     margin-bottom: 10px;
                     font-size: 1rem;
                     line-height: 17px;
                     font-weight: bold;
                     color: #515151;

                     .highlight {
                        color: #ff6347;
                        text-transform: uppercase;
                     }
                  }

                  span {
                     font-size: 1rem;
                     line-height: 16px;
                     color: #8a8a8a;
                  }
               }

               .img {
                  width: 78px;
                  height: 78px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               &.unseen {
                  background: rgba(255, 99, 71, 0.05);
                  // background: #ff634714;
               }
            }
         }

         .bottom {
            border-top: 1px solid #e8eaed;
            padding-top: 20px;
         }
      }
   }

   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .profile {
         // margin: 20px 0;

         .user-profile {
            .avatar-upload {
               margin: 20px auto;
            }
         }

         //user password
         .user-form-update {
            padding: 20px 0;
         }

         .user-orders {
            padding: 0 !important;

            .order-wrap {
               .main {
                  .img {
                     width: 60px;
                     height: 75px;
                  }
               }
            }
         }

         .user-orders-detail {
            padding: 0;

            .title {
               margin-bottom: 20px;
               font-weight: bold;
               font-size: 16px;
               line-height: 19px;
            }

            .date-order {
               margin-bottom: 12px;
            }

            .tab {
               margin: 0;
               margin-bottom: 16px;

               .tab-head {
                  background: rgba(0, 153, 153, 0.05);
                  border-radius: 4px;
                  padding: 10px;
                  color: #009999;
                  font-weight: normal;
                  font-size: 1rem;
                  line-height: 20px;
               }

               .tab-body {
                  justify-content: space-between;
                  padding-top: 12px;
                  font-size: 1rem;
                  line-height: 20px;

                  .left {
                     white-space: normal;
                     width: 36%;
                  }

                  .right {
                     text-align: right;
                  }
               }
            }

            .btn {
               width: 100%;
            }

            .btn-cancel {
               padding: 10px 0;
            }

            .btn-back {
               padding: 10px 0;
            }
         }

         .user-noti {
            margin-top: 0;
            margin-bottom: 0;
         }
      }
   }

   // Small devices (landscape phones, 576px and up)
   @media (max-width: map.get($grid-breakpoints, "sm")) {
      .profile {
         .sidebar-profile {
            display: none;
         }

         //user address
         .user-address {
            .btn-edit {
               display: none;
            }
         }

         //bookmark
         .bookmark-profile {
            margin: 0;

            .head-bookmark-profile {
               margin: 14px 0;

               .head-list {
                  width: 100%;
                  height: 40px;
                  padding: 4px;
                  border: 1px solid #c5c5c5;
                  box-sizing: border-box;
                  border-radius: 100px;
                  justify-content: center;

                  .list-item {
                     width: 100%;
                     height: auto;
                     padding: 5px;
                     border-radius: 32px;
                     height: 100%;
                     display: flex;
                     justify-content: center;
                     align-items: center;

                     a {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        margin: 0;
                     }

                     &.active {
                        border: none;
                        background: map.get($text-colors, "default");

                        a {
                           color: #fff;
                        }
                     }
                  }
               }
            }

            .main-bookmark-profile {
               .edit {
                  display: none;
               }
            }
         }

         //my qa
         .my-qa {
            .head-my-qa {
               border: none;

               .head-list {
                  margin-right: 32px;
               }

               .btn-goto-qa {
                  position: relative;
                  width: 69px;
                  height: 36px;
                  font-size: 11px;
                  line-height: 13px;

                  span {
                     display: none;
                  }

                  &::after {
                     content: "";
                     position: absolute;
                     width: 1px;
                     height: 24px;
                     background: map.get($text-colors, "default");
                     left: -20px;
                     bottom: 5px;
                  }
               }
            }

            .main-my-qa {
               .detail-right {
                  display: none;
               }

               .detail {
                  display: block !important;
               }
            }
         }

         .review {
            margin-top: 0px;
            margin-left: 0;

            .head-bookmark-profile {
               .head-list {
                  width: 100%;
                  height: 40px;
                  padding: 4px;
                  border: 1px solid #c5c5c5;
                  box-sizing: border-box;
                  border-radius: 100px;
                  justify-content: center;

                  .list-item {
                     width: 100%;
                     height: auto;
                     padding: 5px;
                     border-radius: 32px;
                     height: 100%;
                     display: flex;
                     justify-content: center;
                     align-items: center;

                     a {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        margin: 0;
                     }

                     &.active {
                        border: none;
                        background: map.get($text-colors, "default");

                        a {
                           color: #fff;
                        }
                     }
                  }
               }
            }

            .menu-review {
               border: 1px solid #ededed;
               border-radius: 108px;
               margin-top: 20px;
               margin-bottom: 20px;

               .menu-item {
                  width: 200px;
                  height: 55px;
                  padding: 18px 31px;
                  font-weight: 500;

                  a {
                     color: map.get($text-colors, "default");
                     text-decoration: none;
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 19px;
                     text-align: center;
                  }

                  &.active {
                     border-bottom: 1px solid #ff6347;

                     a {
                        color: #ff6347;
                     }
                  }
               }
            }
         }

         //point
         .user-point {
            margin: 0 auto;
            padding: 12px;
         }

         .user-orders-detail {
            .btn-back {
               padding: 10px 0;
            }

            .date-order {
               &.status {
                  margin-top: 0;
               }
            }
         }
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      .profile {
         .user-qa {
            .menu-qa {
               margin-top: 0;
               border-bottom: none;

               span {
                  width: 1px;
                  height: 24px;
                  background-color: #252a2b;
                  opacity: 0.6;
                  margin-top: 10px;
               }

               .menu-wrap {
                  border: 1px solid #252a2b;
                  justify-content: center;
                  align-items: center;
                  border-radius: 100px;
                  padding: 4px;
                  position: relative;

                  .menu-item {
                     width: 100%;
                     height: 100%;
                     padding: 0;
                     background-color: #fff;
                     border-radius: 100px;
                     text-align: center;
                     padding: 6px 0;

                     a {
                        padding: 0;
                     }

                     &.active {
                        background-color: #252a2b;
                        border: none;

                        p,
                        a {
                           color: #fff;
                        }
                     }
                  }
               }

               .btn {
                  padding: 8px 0;
               }
            }

            .main-qa {
               .img {
                  width: 68px;
                  height: 68px;
               }
            }
         }
      }
   }
}

.drawer-profile {
   .MuiPaper-root {
      max-width: 325px;
      width: 75%;
   }
}
// Medium devices (tablets, 768px and up)
@media (max-width: map.get($grid-breakpoints, "md")) {
   .navbar {
      // top: 50px;
      top: 0px;

      &.sub-navbar {
         // top: 96px !important;
      }
   }

   .drawer-profile {
      padding: 16px !important;

      .header-sidebar {
         border-bottom: 1px solid #efefef;
         height: 66px;
         display: flex;
         align-items: center;
         padding: 16px;

         span {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            margin-left: 12px;
         }
      }

      .list-menu {
         padding: 16px;
      }

      .list-item {
         margin-bottom: 4px;
         height: 48px;
         padding: 12px;
         border-radius: 4px;

         .menu-icon {
            margin-right: 8px;
            width: 24px;
            height: 24px;
         }

         &.active {
            background: rgba(255, 99, 71, 0.04);
         }

         .MuiListItemText-primary {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #515151;
         }
      }

      .list-item-sub {
         padding: 12px 12px 12px 40px;

         .MuiListItemText-primary {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #8a8a8a;
         }
      }
   }
}
