@use "sass:map";

.container {
   padding-left: 12px;
   padding-right: 12px;
   // padding-right: var(--bs-gutter-x, .75rem);
   // padding-left: var(--bs-gutter-x, .75rem);
}

div.grecaptcha-badge {
   width: 0 !important;
   overflow: hidden;
}

.button-add-item {
   content: url("/assets/img/icon/add.svg");
   display: block;
   position: fixed;
   bottom: 30px;
   right: 30px;
   width: 54px;
   height: 54px;
   border-radius: 50%;
   border-radius: 50%;
   cursor: pointer;
   // box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.dot {
   width: 2px;
   height: 2px;
   border-radius: 50%;
   background: #666666;
}

.action-icon {
   align-items: center;

   &:before {
      display: inline-block;
      width: 26px;
      height: 26px;
      background-size: 26px;
      background-repeat: no-repeat;
      background-position: center;
      content: "";
      vertical-align: middle;
      padding-left: 34px;
      background-size: contain;
   }

   &__bookmark {
      &--black {
         &:before {
            background-image: url("/assets/img/icon/bookmark.svg");
         }
      }

      &--grey {
         &:before {
            background-image: url("/assets/img/icon/gray/bookmark.svg");
         }
      }

      &--orange {
         &:before {
            background-image: url("/assets/img/icon/active-bookmark.svg");
            // filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
         }
      }
   }

   &__like {
      &--black {
         &:before {
            background-image: url("/assets/img/icon/like_black.svg");
         }
      }

      &--grey {
         &:before {
            background-image: url("/assets/img/icon/gray/like.svg");
         }
      }

      &--orange {
         &:before {
            background-image: url("/assets/img/icon/orange/like.svg");
         }
      }
   }

   &__comment {
      &--black {
         &:before {
            background-image: url("/assets/img/icon/comment.svg");
         }
      }

      &--grey {
         &:before {
            background-image: url("/assets/img/icon/gray/comment.svg");
         }
      }

      &--orange {
         &:before {
            background-image: url("/assets/img/icon/comment.svg");
            filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
         }
      }
   }

   &__share {
      &--black {
         &:before {
            background-image: url("/assets/img/icon/share.svg");
         }
      }

      &--grey {
         &:before {
            background-image: url("/assets/img/icon/gray/share.svg");
         }
      }

      &--orange {
         &:before {
            background-image: url("/assets/img/icon/share.svg");
            filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
         }
      }
   }

   &__view {
      &--black {
         &:before {
            background-image: url("/assets/img/icon/eye.svg");
         }
      }

      &--grey {
         &:before {
            background-image: url("/assets/img/icon/eye.svg");
         }
      }

      &--orange {
         &:before {
            background-image: url("/assets/img/icon/eye.svg");
            filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
         }
      }
   }
}

.wempty {
   padding: 32px 0;
   .empty {
      display: flex;
      justify-content: center;
      align-items: center;

      .img-empty {
         width: 178px;
         height: 178px;
         margin-bottom: 16px;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      p {
         font-size: 16px;
         line-height: 20px;
         color: #666666;
         margin-bottom: 8px;
      }

      .btn-empty {
         width: 147px;
         height: 40px;
         background: #252a2b;
         border-radius: 4px;

         padding: 10px;
         display: flex;
         justify-content: center;
         align-items: center;

         a {
            color: #fff;
            text-decoration: none;
         }
      }
   }
}

.word-break {
   white-space: break-spaces;
}

.button-text {
   cursor: pointer;
   background: none;
   border: none;

   &:hover {
      opacity: 0.7;
   }
}

.img-cover {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: -1;
}

.consultation-fixed {
   background-color: #fbfbfc;
   padding: 16px 0px;
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 99;

   .btn-contact {
      background: #ff6347;
      width: 100%;
   }

   a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff !important;
      text-decoration: none;
      padding: 10px;
   }
}

.consultation-fixed-custom {
   position: fixed;
   bottom: 16px;
   left: 8px;
   z-index: 199;

   .btn-contact {
      border-radius: 100px;
      background: #ff6347;
      width: max-content;
      padding: 7px 12px;
   }

   a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff !important;
      text-decoration: none;
      padding: 10px;
   }
}

.banner {
   position: relative;

   .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
   }

   img {
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: inline-block;
   }
}

.text-helper {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   margin-bottom: 0px;
   margin-top: 8px;
}

.custom-tab {
   position: relative;
   background: #fff;
   height: auto;
   // z-index: map-get($map: $zindex, $key: "sticky");
   min-height: 36px !important;

   .MuiTabs-indicator {
      display: flex;
      justify-content: center;
      background-color: #ff6347;
   }

   .Mui-selected,
   .active .MuiTab-root {
      color: #ff6347;
   }

   .MuiTab-root {
      opacity: 1;
      font-family: "Roboto";
      text-transform: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #252a2b;
      min-height: 36px;
      text-align: center;
      padding: 14px 16px;
      width: unset;
      height: unset;

      &.Mui-selected {
         color: #ff6347;
         font-weight: 500;
         font-size: 16px;
         line-height: 19px;
      }
   }
}

.custom-tab-in-block.MuiTabs-root {
   .MuiTab-root {
      padding: 0px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      &.Mui-selected {
         font-weight: 500;
         font-size: 14px;
         line-height: 16px;
      }
   }
}

.custom-dialog {
   .MuiPaper-root {
      border-radius: 24px;
      padding: 0px;
      padding-bottom: 16px;
   }

   .MuiDialogTitle-root {
      border-bottom: 1px solid #e8eaed;
      height: 70px;
   }

   .MuiDialogContent-root {
      padding: 0px;
   }
}

.dialog-medium {
   .MuiPaper-root {
      width: 500px !important;
      max-height: 560px;
      height: 70vh;
   }
}

.dialog-small {
   .MuiPaper-root {
      width: 300px !important;
      max-height: 560px;
      height: 70vh;
   }
}

.dialog-large {
   .MuiPaper-root {
      width: 900px !important;
      max-height: 850px;
      height: 90vh;
   }
}

.auto-height {
   .MuiPaper-root {
      height: auto;
   }
}

.custom-form {
   .block:not(:last-child) {
      margin-bottom: 16px;
   }

   .form {
      .control-item {
         &:not(:last-child) {
            margin-bottom: 24px;
         }

         label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 8px;
            width: 100%;
         }

         .form-control {
            height: 44px;
            width: 100%;
            border: 1px solid #e8eaed;
            border-radius: 0px;
            border-radius: 6px;

            &::placeholder,
            .placeholder {
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #acacac;
            }

            &:focus {
               border: 1px solid #e8eaed;
               outline: none;
               box-shadow: none;
            }
         }

         input,
         select {
            // background: #ffffff;
            background: inherit;
            padding: 8px 12px;
         }

         select {
            padding-right: 24px;
            // &:after {
            //    content: '';
            //    display: inline-block;
            //    height: 20px;
            //    width: 20px;
            //    border-left: 1px solid #8a8a8a;
            //    border-bottom: 1px solid #8a8a8a;
            // }
         }

         .textarea {
            height: 144px;

            textarea {
               height: 100%;
               resize: none;
               width: 100%;
               background: #ffffff;
               padding: 12px;
               outline: none;
            }
         }
      }
   }

   .error {
      .form-control {
         border: 1px solid #ff0000 !important;
      }
   }
}

.text-error {
   color: #f00;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   margin-top: 6px;
   margin-bottom: 0;
}

button.btn {
   outline: none;
   border: none;
}

.btn-primary {
   --bs-btn-color: #fff;
   --bs-btn-bg: #ff6347;
   --bs-btn-border-color: #ff6347;
   --bs-btn-hover-color: #fff;
   --bs-btn-hover-bg: #ff6347;
   --bs-btn-hover-border-color: #ff6347;
   --bs-btn-focus-shadow-rgb: unset;
   --bs-btn-active-color: #fff;
   --bs-btn-active-bg: #ff6347;
   --bs-btn-active-border-color: #ff6347;
   --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
   --bs-btn-disabled-color: #fff;
   --bs-btn-disabled-bg: #ff6347;
   --bs-btn-disabled-border-color: #ff6347;
}

.btn-link {
   border: none;
   box-shadow: none;
   outline: none;
   background: none;
   color: #ff6347;
   text-decoration: none;

   &:hover {
      color: #ff6347;
   }
}

.suggestion-article {
   .suggestion-title {
      font-size: 22px;
      font-weight: 900;
      line-height: 23px;
      text-align: left;
      margin-bottom: 32px;
      @include media-down(md) {
         font-size: 14.22px;
         font-weight: 600;
         margin-bottom: 16px;
      }
   }

   .suggestion-item {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      border-radius: 10px;

      .item-img {
         position: relative;
         background: #cccccc;
         aspect-ratio: 1;
         width: 100%;
         border-radius: 8px;
         overflow: hidden;

         .view_count {
            position: absolute;
            z-index: 1;
            bottom: 8px;
            right: 8px;

            padding: 2px 6px;
            height: 20px;
            border-radius: 30px;
            opacity: 0.7;
            background: rgba(0, 0, 0, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;

            color: #fff;
            font-size: 11.24px;
            font-weight: 400;
            letter-spacing: -0.112px;
            svg {
               height: 10px;
               margin-right: 4px;
            }
         }

         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }

         .rule-tag {
            position: absolute;
            width: max-content;
            height: 40px;
            right: 0px;
            top: 0px;
            background: map.get($text-colors, "default");
            opacity: 0.9;
            padding: 12px;
            z-index: 1000;

            .des {
               font-size: 0.875rem;
               line-height: 12px;
               margin: 0;
               /* identical to box height */
               color: #fff;
               text-align: center;
               text-transform: capitalize;
            }

            &.active {
               background: #ff6347;
            }
         }
      }

      .text-block {
         text-align: left;
         color: map.get($text-colors, "default");
         padding: 10px 0 0;
         width: 100%;

         .title-item {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin: 0;
            margin-bottom: 10px;
            height: 38px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;

            a {
               font-size: 11.24px;
               font-weight: 400;
               line-height: 12px; /* 106.762% */
               letter-spacing: 0.112px;
               text-decoration: none;
               color: map.get($text-colors, "default");
            }
         }

         .text-block-view {
            display: flex;
            align-items: center;

            span {
               width: 2px;
               height: 2px;
               top: 6.5px;
               background: #252a2b;
               opacity: 0.7;
            }

            .des-item {
               font-size: 12px;
               font-weight: 400;
               margin: 0 6px;
               display: flex;
               align-items: center;
               gap: 4px;
               color: #666666;

               p {
                  font-size: 14px;
                  line-height: 16px;
                  color: #666666;
               }

               img,
               svg {
                  width: 12px;
                  height: 12px;
                  object-fit: contain;
               }
            }

            .item-ml-0 {
               margin-left: 0 !important;
            }
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints, "lg")) {
   .text-helper {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
   }
}

.img {
   position: relative;

   .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .custom-tab.MuiTabs-root {
      .MuiTabs-indicator {
         display: flex;
         justify-content: center;
         background-color: #ff6347;
      }

      .Mui-selected,
      .active .MuiTab-root {
         color: #ff6347;
      }

      .MuiTab-root {
         font-weight: 400;
         font-size: 14px;
         line-height: 16px;
         padding: 10px;

         &.Mui-selected {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
         }
      }
   }

   .custom-tab-in-block.MuiTabs-root {
      .MuiTab-root {
         padding: 0px 16px;
         font-weight: 400;
         font-size: 14px;
         line-height: 16px;

         &.Mui-selected {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
         }
      }
   }

   .suggestion-article {
      .suggestion-item {
         display: flex;
         flex-direction: column;
         gap: 12px;
         background: var(--gray-white, #fff);
         box-shadow: 0px 4px 50px -5px rgba(0, 0, 0, 0.1);
         aspect-ratio: 0.66;
         .item-img {
            width: 100%;
            flex-shrink: 0;

            .rule-tag {
               padding: 6px;
               height: unset;

               .des {
                  font-size: 14px;
                  line-height: 12px;
                  margin: 0;
               }

               &.active {
                  background: #ff6347;
               }
            }
         }

         .text-block {
            padding: 0px 8px;
         }
      }
   }

   .btn-cta {
      width: auto;
      height: 44px;
      border: 1px solid #ff6347;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      position: fixed;
      bottom: 12px;
      left: 12px;
      right: 12px;
      z-index: map-get($map: $zindex, $key: "fixed");
   }

   .btn-consulting {
      color: #ffffff;
      background: #ff6347;

      &:hover,
      &:focus,
      &:focus-visible {
         color: #fff;
      }
   }
}

.btn-close {
   position: absolute;
   top: 27px;
   right: 20px;
   width: 16px;
   height: 16px;
   background-size: 16px;
   padding: 0;
   z-index: map.get($zindex, "fixed");
   cursor: pointer;
}

// Content editor input
[contenteditable="true"] {
   a {
      color: #0055aa;
   }
}
[contenteditable="true"]:empty::before {
   content: attr(placeholder);
   color: #555;
}

[contenteditable="true"]:empty:focus::before {
   content: "";
}
