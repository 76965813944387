.connect-service-page {
    .send_request {
        // border-bottom: 12px solid #F4F4F4;
        background-color: #ffe9de;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 121px;
        // height: calc(100vh - 116px);

        .send_content {
            background-color: #fff;
            border-radius: 0px 60px 60px 60px;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            padding: 121px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            strong {
                color: #ff6d00;
                text-align: center;
            }

            .wrapper_button_send {
                //     display: flex;
                //     max-width: 60%;
                //     justify-content: center;
                margin-top: 53px;

                // .btn_connect {
                //     color: #fff;
                //     background-color: #FF6D00;
                //     padding: 27px 80px;
                //     border-radius: 14px;
                //     border: none;
                //     font-weight: 700;
                //     font-size: 26px;
                //     line-height: 30px;
                // }
            }
        }
    }

    @media (max-width: 1200px) {
        .send_request {
            padding: 80px;

            .send_content {
                padding: 70px;
            }
        }
    }
}
