@use "sass:map";
@import "styles/variables.scss";

.actual-page {
   margin: 50px 0;

   .actual-works {
      .btn-actual-nav {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         width: 76px;
         height: 42px;
         background: #ffffff;
         border: 1px solid rgba(37, 42, 43, 0.5);
         gap: 10px;
         display: none;

         span {
            color: map.get($text-colors, "default");
         }
      }

      .actual-bar {
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         padding: 1rem 1.875rem;
         padding: 1.15rem;
         flex-direction: column;

         .item-area {
            height: auto !important;

            .area-option {
               .option {
                  cursor: pointer;
                  margin-bottom: 4px;
                  width: max-content;
                  border-radius: 4px;
                  background: #f5f5f5;
                  padding: 8px;
                  text-align: center;

                  span {
                     white-space: nowrap;
                     font-size: 13px;
                     line-height: 20px;
                  }

                  &:hover {
                     background: #252a2b52;

                     span {
                        color: #fff;
                     }
                  }

                  &.active {
                     background: #ff6347;

                     span {
                        color: #fff;
                     }
                  }
               }
            }

            .detail {
               margin-top: 0px;
            }
         }

         .item-range {
            height: 136px;

            .custom-range {
               width: 93%;
               margin: 0 10px;

               .MuiSlider-rail {
                  border: 1px solid #ff6347;
                  background: #fff;
                  opacity: 1;
                  height: 8px;
               }

               .MuiSlider-track {
                  background: #ff6347;
                  border: 1px solid #ff6347;
                  opacity: 1;
                  height: 9px;
               }

               .MuiSlider-thumb {
                  background: #ff6347;
                  opacity: 1;
                  width: 26px;
                  height: 26px;
                  box-shadow: 0px 4px 4px rgba(255, 152, 134, 0.31);
               }
            }
         }

         .actual-bar-item {
            width: 100%;
            height: 105px;
            border-bottom: 1px solid rgba(37, 42, 43, 0.1);
            color: #252a2ba5;

            label {
               font-weight: 500;
               font-size: 1rem;
               line-height: 16px;
               padding: 16px 0;
               color: #252a2ba5;

               sup {
                  font-size: 0.5rem;
                  color: #252a2ba5;
               }
            }

            select,
            input {
               color: #252a2ba5;

               option {
                  color: #252a2ba5;
               }
            }

            .range-input {
               display: flex;
               align-items: center;

               span {
                  width: 20%;
                  height: 1px;
                  background-color: map.get($text-colors, "default");
                  opacity: 0.1;
                  margin: 0 12px;
               }
            }
         }
      }

      .actual-work {
         .actual-work-item {
            position: relative;
            margin-bottom: 12px;

            .item-img {
               flex: 1;
               height: 0;
               padding-bottom: 65%;
               position: relative;
               overflow: hidden;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;

                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
               }
            }

            .text-block {
               text-align: left;
               bottom: 0;
               // background-color: rgba(255, 255, 255, 0.9);
               color: map.get($text-colors, "default");
               padding: 10px 0;
               width: 100%;

               .title-item {
                  font-size: 1.25rem;
                  font-weight: bold;
                  margin: 0;

                  a {
                     text-decoration: none;
                     color: map.get($text-colors, "default");
                  }
               }

               .text-block-des {
                  display: flex;
                  align-items: center;

                  span {
                     width: 2px;
                     height: 2px;
                     left: 53px;
                     top: 6.5px;
                     background: #252a2b;
                     opacity: 0.7;
                  }

                  // .des-item-design {
                  //     font-size: 0.938rem;
                  //     margin: 0 30px !important;
                  //     sup {
                  //         font-size: 0.5rem;
                  //     }
                  // }
                  .item-ml-0 {
                     margin-left: 0 !important;
                  }
               }
            }

            .rule-tag {
               position: absolute;
               width: 88px;
               height: 40px;
               right: 0px;
               top: 0px;
               background: map.get($text-colors, "default");
               opacity: 0.8;
               padding: 12px;

               .des {
                  font-size: 0.875rem;
                  line-height: 12px;
                  margin: 0;
                  color: #fff;
                  text-align: center;
                  text-transform: capitalize;
               }

               &.active {
                  background: #ff6347;
               }
            }
         }
      }

      .actual-nav {
         display: none;
         position: fixed;
         width: 300px;
         top: 0;
         left: 0;
         overflow: hidden;
         background-color: #ffffff;
         transition: transform 0.4s ease;
         box-shadow: 0 0 10px #00000070;
         z-index: 1100;
         height: 100vh;
         padding: 10px !important;
         transform: translateX(-100%);
         transition: 0.5s;

         &.active {
            display: block;
            transition: transform 0.4s ease;
            transform: translateX(0);
            transition: 0.5s;
         }
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: 20px 0;

      .actual-works {
         .actual-bar {
            display: none;
         }

         .btn-actual-nav {
            display: flex;
            margin-bottom: 20px;
         }

         .actual-work {
            .actual-work-item {
               margin-bottom: 12px;

               .item-img {
                  width: 100%;
                  height: 310px;
               }
            }
         }
      }
   }

   // Large devices (desktops, 992px and up)
   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .actual-works {
         .actual-bar {
            padding: 0;
         }
      }
   }
}

.title_of_page {
   font-weight: 600;
   line-height: 21px;
   font-size: 20px;
   margin-bottom: 0px;
   color: #333333;
   margin-top: 20px;
}
