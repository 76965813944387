@use "sass:map";
@import "../../styles/variables.scss";

.bookmark-icon-style {
   width: fit-content;
   height: fit-content;
   // width: 40px !important;
   // height: 40px !important;
   position: absolute;
   top: 8px;
   right: 8px;
   z-index: 1;
   cursor: pointer;
   &__secondary {
      object-fit: contain !important;
      width: 18px !important;
      height: auto !important;
      top: 12px;
      right: 12px;
      .b-icon {
         width: 32px;
         height: 32px;
      }
   }
   &__primary {
      .b-icon {
         width: 32px;
         height: 32px;
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .bookmark-icon-style {
      position: absolute;
      z-index: 1;
   }
}
