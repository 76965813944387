.custom-dialogv2 {
   .MuiPaper-root {
      padding: 0px;
      margin: 24px;
      border-radius: 24px;
   }

   .MuiDialogTitle-root {
      padding: 24px 12px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
   }

   .MuiDialogContent-root {
      padding: 0px;
   }
}

.dialogv2-medium {
   .MuiPaper-root {
      width: 100%;
      max-width: 500px;
      max-height: 560px;
   }
}

.dialogv2-small {
   .MuiPaper-root {
      width: 100%;
      max-width: 300px;
      max-height: 560px;
   }
}

.dialogv2-large {
   .MuiPaper-root {
      width: 100%;
      max-width: 900px;
      max-height: 850px;
   }
}