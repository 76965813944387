@use "sass:map";


.event-page {
    margin: 50px 0;

    .img {
        flex: 1;
        height: 0;
        margin: 0 10px;
        overflow: hidden;
        position: relative;
        padding-bottom: 50%;

        .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.event-detail-page {
    padding: 50px 0;

    .title_event {
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 28px;
        color: map.get($text-colors, "default");
    }

    .date {
        font-weight: 500;
        font-size: 1.15rem;
        line-height: 19px;
        color: #252A2B;
        margin-bottom: 50px;
        margin-top: 16px;
    }

    .img {
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;
        width: 100%;
        height: 100%;
        padding: 80px 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid #DDDDDD;

            //   position: absolute;
            //   top:50%;
            //   left:50%;
            //   transform:translate(-50%,-50%);
            //   padding: 80px 0;
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (max-width: map.get($grid-breakpoints, "sm")) {}

    // Medium devices (tablets, 768px and up)
    @media (max-width: map.get($grid-breakpoints, "md")) {
        padding: 20px 0;

        .date {
            margin-bottom: 20px;
            margin-top: 8px;
        }

        .img {
            padding: 0;
        }
    }

}