@import 'styles/variables.scss';

$warning: red;

.input-code-container {
  width: 100%;
  & > div {
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    .input-code-item {
      width: 100%;
      height: 46px;
      max-width: 46px;
      margin-right: 6px;
      margin-left: 6px;
      @media (min-width: 768px) {
        height: 50px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      input {
        color: #000;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;

        width: 100%;
        height: 100%;
        flex: 1 1 auto;

        text-align: center;
        border-radius: 8px;
        border: 1px solid #EAEAEA;
        background: #FFF;

        &:valid {
          outline: unset;
        }
        &:focus,
        &:hover {
          border: 1px solid #c1c1c3;
          outline: unset;
        }

        &:not(:first-child) {
          margin-left: 8px;
          @include media-up(sm) {
            margin-left: 20px;
          }
        }

        &.error {
          border: 1px solid $warning;
        }
      }
    }
  }
}
