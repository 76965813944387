@use 'sass:map';
// @import '../../../assets/scss/core/variables.scss';

.landing-page-service-page {
   .value {
      height: 438px;
      padding: 100px 0;
      // background-image: url('/assets/img/design_construction/thi-cong-noi-that-cover.jpeg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .header {
         text-align: center;
         .title {
            font-size: 32px;
         }
         .description {
            width: 50%;
            color: #fff;
            font-size: 20px;
            line-height: 27px;
         }
      }

      .items {
         display: flex;
         margin: 0 -12px;

         .item {
            margin: 0 12px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: calc(100% / 3 - 16px);
            background: #ffffff;
            border: 1px solid #e0e0e0;

            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
            border-radius: 4px;

            .name {
               font-weight: 500;
               font-size: 24px;
               line-height: 28px;
               text-align: center;
               color: #252a2b;
               margin-bottom: 40px;
            }

            .text {
               text-align: center;
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #252a2b;
               margin-bottom: 40px;
            }

            .icon {
               width: 120px;
               height: 120px;
            }

            .icon_mobile {
               display: none;
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .value {
         padding: 32px 12px;
         display: flex;
         align-items: center;
         .header {
            .title {
               margin-bottom: 30px;
               font-size: 30px;
               line-height: 40px;
            }

            .description {

               margin-bottom: 30px;
               width: 80%;
            }
         }

         .items {
            flex-direction: column;

            .item {
               &:not(:last-child) {
                  margin-bottom: 20px;
               }

               width: 100%;
               border: none;
               display: flex;
               padding: 20px;
               align-items: center;
               flex-direction: column;

               box-shadow: none;
               border-radius: 0;

               .name {
                  margin-bottom: 20px;
               }

               .text {
                  margin-bottom: 20px;
               }

               .icon {
                  display: none;
               }

               .icon_mobile {
                  display: block;
                  margin-bottom: 20px;
                  width: 50px;
                  height: 50px;
               }
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'sm')) {
      .value {
         padding: 32px 12px;

         .header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
               margin-bottom: 20px;
            }

            .description {
               width: 100%;
               line-height: 24px;
               margin-bottom: 20px;
            }
         }

         .items {
            flex-direction: column;

            .item {
               padding: 10px;
               width: 100%;

               .name {
                  margin-bottom: 20px;
               }

               .text {
                  margin-bottom: 18px;
                  text-align: center;
               }

               .icon {
                  display: none;
               }
            }
         }
      }
   }


}