@use 'sass:map';
@import 'styles/variables.scss';

.noti-button {
    position: relative;
    cursor: pointer;
    img {
        width: 26px;
        height: 26px;
    }
    .noti-counter {
        position: absolute;
        width: max-content;
        min-width: 16px;
        // height: 16px;
        top: -2px;
        right: -2px;
        background-color: #ff4747;
        padding: 2px 4px;
        font-size: 9px;
        text-align: center;
        border-radius: 100px;
        color: #ffffff;
        font-weight: 500;
    }
}

.notifications {
    .header {
        padding: 24px 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e8eaed;
        position: sticky;
        top: 0;
        background-color: #fff;
        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;

            color: #252a2b;
        }

        .read_all {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;
            color: #ff6347;
        }
    }

    .list_notification {
        max-height: 470px;
        overflow-x: hidden;
        overflow-y: scroll;
        .notification {
        }
        .noti-item {
            display: flex;
            gap: 12px;
            padding: 12px;
            cursor: pointer;
            &:hover {
                color: #252a2b;
            }
            .img {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                overflow: hidden;
                flex-shrink: 0;
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                .title {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #252a2b;
                    margin-bottom: 0px;
                    word-break: normal;
                }
                .time {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #acacac;
                }
                .highlight {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 18px;
                    // display: flex;
                    // align-items: center;
                    color: #252a2b;
                }
            }
        }
        .unread {
            background: #fbfbfc;
        }
    }

    .empty-center {
        min-height: calc(100vh - 52px - 52px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.dialog {
    width: 480px;
    border-radius: 24px;
}

.notification-modal,
.dialog {
    .title {
        font-size: 18px;
        font-weight: 700;
        color: #252a2b;
    }
    .header {
        border-bottom: 1px solid #e8eaed;
        padding: 16px 20px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        svg {
            position: absolute;
            cursor: pointer;
            right: 20px;
        }
        .icon-back {
            position: absolute;
            cursor: pointer;
            left: 20px;
            transform: rotate(180deg);
        }
    }

    .content {
        padding: 16px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .appeal {
        textarea {
            width: 100%;
            resize: none;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            padding: 5px 12px;
            &:focus,
            &:focus-visible {
                border: 1px solid #d9d9d9;
                outline: none;
            }
            &::placeholder {
                color: #909090;
                font-size: 14px;
                line-height: 22px;
            }
        }
        .action {
            .btn-submit {
                width: fit-content;
            }
        }
    }

    .action {
        display: flex;
        justify-content: flex-end;
        padding: 14px 16px;
        width: 100%;
        gap: 10px;
        button {
            border-radius: 100px;
            padding: 10px 20px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 15px;
            font-weight: 700;
        }
        .btn-cancel {
            color: #666;
            background: inherit;
            border: 1px solid #ccc;
            width: 100%;
        }
        .btn-submit {
            border: 1px solid #ff6347;
            background: #ff6347;
            color: #fff;
            width: 100%;
        }
    }
}

.notification-agree {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    span,
    p {
        text-align: center;
    }
}

@media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
    .notifications {
        .list_notification {
            max-height: unset;
        }
        .read_all {
            position: fixed;
            z-index: map-get($map: $zindex, $key: 'fixed');
            top: 10px;
            right: 12px;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #ff6347;
        }
    }

    .notification-modal,
    .dialog {
        .header {
            padding: 7px 12px;
            .icon-back {
                left: 12px;
            }
            .btn-next {
                svg {
                    position: static;
                }
                position: absolute;
                right: 12px;
                color: #ff6347;
                font-size: 18px;
                font-weight: 700;
                gap: 4px;
            }
        }
    }
}
