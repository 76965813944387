@use "sass:map";
@import "styles/variables.scss";

.drawer-custom {
   --header-height: 56px;

   // with anchor bottom
   .MuiDrawer-paperAnchorBottom {
      max-height: 90vh;
      max-height: 90dvh;
      border-radius: 16px 16px 0 0;
   }

   // with anchor right
   .MuiDrawer-paperAnchorRight {
      width: 100%;
      max-width: 80%;
      overflow: hidden;
      height: 100vh;
      height: 100dvh;
   }

   &__header {
      height: var(--header-height);
      min-height: var(--header-height);
      padding: 0 16px;
      display: flex;
      align-items: center;
      // justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      position: relative;

      &--title {
         color: #252a2b;
         font-size: 18px;
         font-weight: 500;
         line-height: 24px;

         /* 133.333% */
         @include media-down(md) {
            font-size: 16px;
         }
      }

      button.back-icon {
         padding: 0;
         margin: 0;
         border: unset;
         background-color: unset;
         cursor: pointer;
         position: absolute;
         left: 16px;
         top: 50%;
         transform: translateY(-50%);

         svg {
            width: 24px;
            height: 24px;
         }
      }

      button.back-label {
         font-size: 18px;
         font-weight: 700;
         line-height: 24px;
      }

      .img-close {
         position: absolute;
         top: 50%;
         right: 16px;
         transform: translateY(-50%);

         width: 16px;
         height: 16px;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            width: 100%;
            height: 100%;
         }
      }
   }

   &__body {
      height: 100%;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;

      &.showHeader {
         height: calc(100% - var(--header-height));
      }
   }
}

.drawer-custom.full-height {
   .MuiPaper-root {
      height: 100%;
      max-height: 100vh;
      max-height: 100dvh;
      border-radius: 0;
   }
}

.drawer-custom.fullWidth {
   .MuiPaper-root {
      width: 100%;
      height: 100%;
      border-radius: 0;
   }
}
