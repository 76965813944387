h1,
h2,
h3,
h4,
h5,
h6 {
   padding: 0;
   margin: 0;
}

a {
   color: inherit;
   text-decoration: none;
   cursor: pointer;
}

p {
   a {
      color: #0055aa;
   }
}

textarea {
   resize: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
}
