@use 'sass:map';

.floorplan-detail {
   padding: 50px 0;

   .detail {
      &__header {
         &-title {
            font-size: 3rem;
            font-weight: 700;
            color: map.get($text-colors, 'default');
            padding-bottom: 10px;
         }

         &-des {
            padding: 20px 0;
            text-align: justify;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            p {
               margin-bottom: 0;
               font-size: 1.25rem;
               line-height: 28px;
               color: map.get($text-colors, 'default');
               opacity: 0.8;
            }
         }
      }

      &__content {
         padding: 40px 0;

         div {
            img {
               margin-bottom: 10px;
            }

            p {
               padding: 10px;
            }
         }
      }
   }
}