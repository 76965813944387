.back-to-home-page {
    .inner {
        min-height: calc(100vh - 71px);

        .notice-text {
            font-size: 1.143rem;
        }

        .btn {
            color: #ffffff;
            background-color: #252a2b;
            padding: 10px;
        }
    }
}