@import "../../styles/variables.scss";

.dialog-ads {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1201;
   display: none;

   .dialog-backdrop {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      background: rgba(0, 0, 0, 0.2);
   }

   &.open .dialog-backdrop {
      display: block;
      animation-name: opacity;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
   }
   &.open > div {
      animation-name: opacityScale;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
   }

   .dialog-popup {
      position: relative;

      scale: 0.9;
      z-index: 1;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .close-icon {
         position: absolute;
         top: -45px;
         right: 0;
         width: 32px;
         height: 32px;
         cursor: pointer;
      }
      .wimg {
         margin: auto;
         width: 460px;
         height: 570px;
         max-height: 76vh;
         border-radius: 16px;
         // background: #cac8d4;
         overflow: hidden;
         cursor: pointer;
         @include media-down(md) {
            width: 320px;
            height: 400px;
         }

         .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
         }
      }
      .default-img {
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         @include media-down(md) {
            svg {
               width: 100px;
               height: 100px;
            }
         }
      }
   }
}
.floating-ads {
   display: none;
   width: 200px;
   height: 200px;
   border-radius: 8px;
   // background: #cac8d4;
   z-index: 10;
   scale: 0.9;

   position: fixed;
   left: 24px;
   bottom: 24px;

   @include media-down(md) {
      width: 92px;
      height: 92px;

      left: 12px;
      bottom: 75px;
   }
   &.open {
      animation-name: opacityScale;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
   }
   .close-icon {
      position: absolute;
      top: -13px;
      right: -12px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 2;
      @include media-down(md) {
         width: 24px;
         height: 24px;
      }
   }
   .default-img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      overflow: hidden;
      svg {
         width: 60px;
         height: auto;
      }
   }
   .wimg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 8px;
      cursor: pointer;
      .img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: center center;
      }
   }
}
.text-ads {
   width: 100%;
   display: none;
   position: fixed;
   bottom: 0;
   left: 0;
   padding: 4px;
   opacity: 0;
   scale: 0.5;
   z-index: 10;

   &.open {
      display: flex;
      animation-name: fadeOpacity;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
   }
   &.close {
      display: none;
      animation-name: fadeOpacityClose;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
   }
   @keyframes fadeOpacity {
      to {
         bottom: 62px;
         scale: 1;
         opacity: 1;
      }
   }
   @keyframes fadeOpacityClose {
      from {
         opacity: 1;
         display: flex;
         bottom: 62px;
         scale: 1;
      }
      to {
         opacity: 0;
         display: none;
      }
   }
   & > div {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 47px;
      padding: 8px 12px;
      border-radius: 100px;
      border: 1px solid #ff9684;
      background: #e85a41;
   }
   .w-avatar {
      width: 28px;
      height: 28px;
      min-width: 28px;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
      cursor: pointer;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .link-to {
      font-weight: 600;
      text-decoration: underline;
      margin-left: 4px;
      &:hover {
         color: #fff;
      }
   }
}
