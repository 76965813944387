@import "styles/variables.scss";

.title {
   font-weight: 700;
   font-size: 18px;
   line-height: 21px;
   text-align: center;
   color: #252a2b;
   margin-bottom: 12px;
   @include media-down(md) {
      font-size: 16px;
   }
}

.message {
   font-size: 14.22px;
   font-weight: 400;
   line-height: 16px; /* 112.518% */
   letter-spacing: -0.142px;
   text-align: center;
   margin-bottom: 0;
}

.dialog-action {
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 12px;
   margin-top: 32px;
}

.dialog-btn {
   border-radius: 8px;
   outline: none;
   width: 100%;
   font-weight: 700;
   font-size: 15px;
   line-height: 18px;
   height: 42px;
}
.cancel-btn {
   // @extend .dialog-btn;
}
.submit-btn {
   // @extend .dialog-btn;
}
