@use "sass:map";

.gallery {
   background: rgba(0, 0, 0, 0.8);
   position: fixed;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   .close {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 20px;
      cursor: pointer;
      color: #fff;
      z-index: 99999;
   }

   .slick-slider {
      .img {
         img {
            object-fit: contain;
            width: 100vw;
            height: 100vh;
            &:focus-visible {
               outline: none;
               border: none;
            }
         }
         &:focus-visible {
            outline: none;
            border: none;
         }
      }

      .slick-arrow {
         width: 30px;
         height: 30px;
         padding: 0;
         z-index: 999999;
      }

      .slick-prev {
         left: 20px;
      }

      .slick-next {
         right: 20px;
      }
      .slick-disabled {
         display: none !important;
      }
   }

   @media (max-width: map.get($grid-breakpoints, "md")) {
      .close {
         top: 16px;
         left: 16px;
         // font-size: 16px;
      }
   }
}
