@use 'sass:map';

.construction-page {
    .interior-construction-cost {
        padding: 100px 0;
        background: #fff;

        .content {
            .tabs {
                margin-bottom: 50px;
                justify-content: center;
                border-bottom: 1px solid #e8eaed;

                .tab {
                    cursor: pointer;
                    padding: 12px 40px;
                }
            }

            .wrapper-table {
                display: flex;
                flex-direction: column;
                align-items: center;

                .title-table {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;

                    color: #252a2b;
                    margin-bottom: 50px;
                    max-width: 600px;
                }

                table {
                    thead {
                        tr {
                            background-color: #e0e0e0;

                            th {
                                h5 {
                                    margin-bottom: 0;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #252a2b;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;

                            color: #252a2b;

                            border: 1px solid #e0e0e0;
                            border-top: none;
                            padding: '11px 0';

                            td {
                                padding-top: 12px;
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }

        // for component table costs
        .table-costs {
            .header {
                .title-table {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;

                    color: #252a2b;
                    margin-bottom: 50px;
                    max-width: 600px;
                }
            }

            .content {
                table {
                    thead {
                        tr {
                            background-color: #e0e0e0;

                            th {
                                h5 {
                                    margin-bottom: 0;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #252a2b;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #252a2b;

                            border: 1px solid #e0e0e0;
                            border-top: none;
                            padding: '11px 0';

                            td {
                                padding-top: 12px;
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
        .interior-construction-cost {
            padding: 32px 0;

            .header {
                .title {
                    margin-bottom: 35px;
                }
            }

            .container {
                padding: 0;
                padding-left: 12px;
            }

            .content {
                .tabs {
                    margin-bottom: 35px;

                    .tab {
                        padding: 10px;
                    }
                }

                .wrapper-table {
                    .title-table {
                        margin-bottom: 35px;
                    }

                    table {
                        display: block;
                        overflow-x: auto;
                        white-space: nowrap;

                        thead {
                            tr {
                                background-color: #e0e0e0;

                                th {
                                    padding-right: 30px;
                                }
                            }
                        }

                        tbody {
                            tr {
                                padding-right: 30px;

                                .title-combo {
                                    text-align: left !important;
                                    padding-left: 75px;
                                }

                                .image {
                                    max-width: 150px;
                                    max-height: 100px;
                                }
                            }
                        }
                    }
                }
            }

            .table-costs {
                .header {
                    .title-table {
                        margin-bottom: 35px;
                    }
                }

                .content {
                    display: block;
                    overflow-x: auto;
                    white-space: nowrap;

                    table {
                        thead {
                            tr {
                                th {
                                    padding-right: 30px;
                                }
                            }
                        }

                        tbody {
                            tr {
                                padding: '11px 0';

                                td {
                                    padding-right: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}