@import "../../../styles/variables.scss";
.flash-sale-page {
   padding-top: 16px;
   background: $bg;
   border-top: unset;
   @include media-down(md) {
      min-height: calc(100dvh - 90px);
      background: #f5f5fa;
   }
   .wrap-sticky {
      @include media-down(md) {
         position: sticky;
         top: 96px;
         z-index: 3;
      }
   }

   .banner {
      width: 100%;
      height: auto;
      max-width: $container-width;
      padding: 0 $container-padding 0;
      margin: 0 auto;
      cursor: pointer;
      @include media-down(md) {
         padding-top: 8px;
         height: 103px;
         padding: 0;
         overflow: hidden;
         background-color: #fff;
      }
      img {
         width: 100%;
         object-fit: cover;
         object-position: center;
         // border-radius: 13px;
         overflow: hidden;
         @include media-down(md) {
            border-radius: 10px;
         }
      }
   }
   .spacet-shop-catalog {
      background-color: $bg;
      padding-top: 32px;
      padding-bottom: 24px;
      @include media-down(md) {
         padding-top: 8px;
         padding-bottom: 0;
      }
   }
}
.flash-sale-countdown {
   margin: 0 auto;
   max-width: $container-width;
   padding: 0 $container-padding;
   @include media-down(md) {
      padding: 0;
   }
   .bg {
      height: 56px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: #ff6347;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      @include media-down(md) {
         height: 36px;
         padding: 8px 16px;
         border-bottom: unset;
      }
   }
   h1 {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1px 1px rgba(31, 31, 31, 0.5);
      font-size: 26px;
      font-weight: 800;
      line-height: 32px; /* 123.077% */
      margin: 0;
      margin-right: 48px;
   }
   .countdown-style {
      display: flex;
      align-items: center;
      .label {
         color: #fff;
         font-size: 14px;
         font-weight: 500;
         line-height: 16px; /* 114.286% */
         text-transform: uppercase;
         margin-right: 8px;
         display: flex;
         align-items: center;
         @include media-down(md) {
            color: #fff;
            font-size: 11.24px;
            font-weight: 400;
            line-height: 16px; /* 142.349% */
            letter-spacing: -0.112px;
         }
      }
      .countdown {
         display: flex;
         align-items: center;
         border-radius: 2px;

         & > div {
            color: $primary;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px; /* 100% */
            background: #fff;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include media-down(md) {
               font-size: 12.64px;
               font-weight: 600;
               line-height: 16px;
               width: 23px;
               height: 20px;
            }
         }
         & > span {
            color: #fff;
            margin: 0 4px;
            font-size: 12px;
         }
      }
   }
}
// flash sale cart
.flash-sale {
   padding: 16px;
   background: #fff;
   overflow: hidden;
   margin-bottom: 40px;
   // padding: 0 4px 40px 4px;
   @include media-down(md) {
      margin-bottom: 0;
      margin-left: -4px;
      padding: 16px 0 16px;
      border-bottom: 8px solid #f5f5fa;
   }
   &__head {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-down(md) {
         padding: 0 16px;
      }
      &--left {
         display: flex;
         align-items: center;

         img {
            height: 24px;
            margin-right: 16px;
            @include media-down(md) {
               height: 16px;
               margin-right: 8px;
            }
         }

         svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            @include media-down(md) {
               width: 16px;
               height: 16px;
               margin-right: 4px;
            }
         }

         .title {
            margin-right: 24px;
            margin-bottom: 0;
            @include media-down(md) {
               margin-right: 16px;
            }
         }
      }
   }
   &__countdown {
      display: flex;
      align-items: center;
      margin: 0 -6px;
      @include media-down(md) {
         font-size: 8px;
      }
      div {
         width: 30px;
         height: 30px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 4px;
         background: #062a46;
         margin: 0 3px;

         color: #fff;
         font-size: 16px;
         font-weight: 500;
         line-height: 22px; /* 80% */
         letter-spacing: 0.4px;
         @include media-down(md) {
            font-size: 14px;
            width: 24px;
            height: 24px;
         }
      }
   }
   &__content {
      @include media-down(md) {
         padding-left: 16px;
         // padding-right: 16px;
      }
   }

   // mobile
   .w-item {
      padding: 0 2px;
   }
}
