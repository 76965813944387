@use 'sass:map';

.connect-service-page {
    .you {
        position: relative;
        // height: calc(100vh - 116px);
        .content_you {
            padding: 78px 102px;
            padding-left: 0;
            z-index: 2;
            position: absolute;
            font-style: normal;

            .title_you {
                color: #dddddd;
                font-weight: 300;
                font-size: 36px;
                line-height: 32px;
                margin-bottom: 35px;
                font-family: 'SourceSanProLight';
            }

            .des {
                color: #fff;
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                letter-spacing: -0.04em;
            }

            .des4 {
                font-weight: 300;
                font-size: 36px;
                line-height: 32px;
                margin-top: 45px;
                font-family: 'SourceSanProLight';
            }
        }

        .bg_cover {
            z-index: 1;
            width: 100%;
            // height: calc(100vh - 116px);
        }
    }

    .you_visible {
        .des1 {
            animation: bounce;
            animation-duration: 2s;
            animation-delay: 0s;
        }

        .des2 {
            animation: bounce;
            animation-duration: 2s;
            animation-delay: 2s;
        }

        .des3 {
            animation: bounce;
            animation-duration: 2s;
            animation-delay: 4s;
        }

        .des4 {
            animation: bounce1;
            animation-duration: 900s;
            animation-delay: 6s;
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'lg')) {
        .you {
            .content_you {
                padding: 48px 0;
                .title_you {
                    font-size: 30px;
                    line-height: 25px;
                }

                .des {
                    font-size: 35px;
                    line-height: 45px;
                }

                .des4 {
                    font-size: 30px;
                    line-height: 25px;
                    margin-top: 35px;
                }
            }
        }
    }
    @media (max-width: 850px) {
        .you {
            .content_you {
                .title_you {
                    font-size: 25px;
                    line-height: 25px;
                }

                .des {
                    font-size: 30px;
                    line-height: 45px;
                }

                .des4 {
                    font-size: 25px;
                    line-height: 25px;
                    margin-top: 35px;
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'md')) {
        .you {
            padding: 24px 0;
            background-color: #fff2d3;
            height: unset;
            .content_you {
                padding: 0 12px;
                margin-top: 30px;
                .title_you {
                    color: #000;
                    font-weight: 700;
                    font-size: 25px;
                    margin-bottom: 22px;
                    line-height: 45px;
                }

                .des {
                    color: #000;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 31px;

                    &:not(:last-child) {
                        margin-bottom: 32px;
                    }
                }

                .des4 {
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 32px;
                    // text-align: center;
                    margin-top: 40px;
                    padding-right: 30px;
                }
            }

            .bg_cover {
                z-index: 1;
                width: 100%;
            }
        }
    }

    @media (max-width: 415px) {
        .you {
            .content_you {
                .title_you {
                    font-weight: 700;
                    font-size: 25px;
                    margin-bottom: 28px;
                    line-height: 45px;
                }

                .des {
                    color: #000;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 32px;
                    margin-bottom: 18px;

                    &:not(:last-child) {
                        margin-bottom: 18px;
                    }
                }

                .des4 {
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 32px;
                    // text-align: center;
                    margin-top: 0px;
                    padding-right: 30px;
                }
            }
        }
    }

    @media (max-width: 378px) {
        .you {
            .content_you {
                .title_you {
                    margin-bottom: 22px;
                    line-height: 30px;
                }

                .des {
                    margin-bottom: 22px;
                }
            }
        }
    }
}

@keyframes bounce {
    0% {
        color: #ff5d00 !important;
    }

    100% {
        color: #ff5d00 !important;
    }
}

@keyframes bounce1 {
    from {
        color: #ff5d00;
    }

    to {
        color: #ff5d00;
    }
}
