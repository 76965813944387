@use "sass:map";

.connect-service-page {
   .our-drawing {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // height: calc(100vh - 116px);

      .items {
         .tabs {
            margin-bottom: 20px;
            justify-content: center;
            border-bottom: 1px solid #ccc;

            .tab {
               cursor: pointer;
               padding-bottom: 12px 18px;
               text-align: center;
               width: 15%;
               font-size: 18px;
               font-weight: 700;
               color: #252a2b;
               line-height: 21px;
               border-bottom: 0px solid #ff6347;
               white-space: nowrap;
            }
         }
      }

      .slick-dots {
         bottom: -6%;

         li {
            margin: 0 5px;
            width: 8px;
            height: 8px;

            button {
               width: 8px;
               height: 8px;
               border-radius: 50%;
               background-color: #acacac;
               padding: 4px;

               &::before {
                  display: none;
                  color: #acacac;
                  width: 8px;
                  height: 8px;
               }
            }

            &:hover,
            &.slick-active {
               button {
                  background: #252a2b;
               }
            }
         }
      }

      .slider-wrap {
         // background: #fff;
         position: relative;

         .slick-slider {
            .slick-arrow {
               width: 40px;
               height: 40px;
               z-index: 100;
               border-radius: 50%;
               background: transparent;

               &::before {
                  content: url(/assets/img/interior-design/prev.png);
                  font-size: unset;
                  opacity: unset;
                  line-height: unset;
               }
            }

            .slick-prev {
               top: 34%;
               left: 20px;
               transform: rotate(180deg);
               margin-top: -20px;
            }

            .slick-next {
               top: 34%;
               right: 36px;
            }
         }

         .arrow {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .btn {
               outline: none;
               border: none;
               width: 40px;
               height: 40px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .btn-prev {
               img {
                  transform: rotate(180deg);
               }
            }
         }

         .slider-item {
            width: calc(100% - 16px) !important;
            margin-right: 16px;
            display: block;

            img {
               object-fit: contain;
               margin-bottom: 10px;
               width: 100%;
            }

            .name {
               font-style: normal;
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               color: #252a2b;

               opacity: 0.9;
               margin-bottom: 4px;
            }

            .description {
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #666666;
            }
         }
      }

      .content {
         padding: 0 12px;
         font-weight: 700;
         font-size: 45px;
         line-height: 54px;
      }

      .content_dt {
         flex-direction: column;
         align-items: center;
         margin-bottom: 44px;
      }

      .actions {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 20px;
         margin-top: 30px;
         .action-item {
            border-radius: 4px;
            border: 1px solid #ff6347;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            padding: 14px 18px;
            display: flex;
            align-items: center;
            justify-content: center;
         }
         .action-design-interior {
            background: #fff;
            color: #ff6347;
         }
         .action-construction {
            background: #ff6347;
            color: #fff;
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, "md")) {
      .our-drawing {
         // padding: 32px 12px;
         padding-bottom: 0;
         display: flex;
         flex-direction: column;

         .header {
            .title {
               padding: 0 12px;
               margin-bottom: 35px;
            }
         }

         .container {
            max-width: unset;
         }

         .slick-dots {
            display: none !important;
         }

         .slider-wrap {
            .slick-slider {
               // margin-right: -12px;

               .slick-arrow {
                  display: none !important;
               }
            }

            .slider-item {
            }
         }

         .items {
            .tabs {
               margin-bottom: 20px;
               justify-content: space-around;

               .tab {
                  width: max-content;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  padding: 10px;
               }
            }
         }

         .content {
            margin-top: 24px;
            padding: 0 12px;
            padding-bottom: 48px;
         }

         .content {
            margin-top: 24px;
            font-weight: 900;
            font-size: 40px;
            line-height: 45px;
            letter-spacing: -0.03em;
         }

         .button-bottom {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            // background-color: #FF5D00;
            margin: 0 -24px;

            .button {
               background-color: #ff5d00;
               flex: 1;
               color: #fff;
               font-weight: 900;
               padding: 20px 0;
               line-height: 30px;
               font-size: 20px;
            }
         }
         .actions {
            display: grid;
            gap: 12px;
            margin-top: 20px;
            width: 100%;
            grid-template-columns: 1fr 1fr;
         }
      }
   }
}
