@use "sass:map";

.landing-page-service-page {
   .faq {
      padding: 100px 0;
      background: rgba(241, 241, 241, 0.9);

      .header {
      }

      .faq_item {
         .accordion-button {
            justify-content: space-between;

            &::after {
               margin-left: 12px;
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, "md")) {
      .faq {
         padding: 32px 12px;

         .header {
            .title {
               margin-bottom: 20px;
            }
         }

         .container {
            padding: 0;
         }
      }
   }
}
