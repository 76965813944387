@import "./breakpoints";

// Trun cate by line
$limit-line: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

@each $line in $limit-line {
   .truncate-line-#{$line} {
      @include text-wrap-line($line);

      div.text {
         & > * {
            // display: inline;
            &::after {
               content: "\a";
               white-space: pre;
            }
         }
      }
   }
}

// Space
$spaces: 6, 8, 12, 16, 20, 24, 36, 42, 48, 50, 60, 80, 100, 120;

@each $space in $spaces {
   .m-t-#{$space} {
      margin-top: $space + px;
   }

   .m-b-#{$space} {
      margin-bottom: $space + px;
   }

   .m-l-#{$space} {
      margin-left: $space + px;
   }

   .m-r-#{$space} {
      margin-right: $space + px;
   }

   .m-x-#{$space} {
      margin: auto $space + px;
   }

   .m-y-#{$space} {
      margin: $space + px auto;
   }

   .p-t-#{$space} {
      padding-top: $space + px;
   }

   .p-b-#{$space} {
      padding-bottom: $space + px;
   }

   .p-l-#{$space} {
      padding-left: $space + px;
   }

   .p-r-#{$space} {
      padding-right: $space + px;
   }
}

// font size and font weight
$fontweights: 200, 300, 400, 500, 600, 700, 800;

@each $breakKey, $breakVal in $breakpoints {
   // font size
   @if $breakKey ==xm {
      @for $i from 8 through 32 {
         .fs-#{$i} {
            font-size: $i + px !important;
         }
      }
   } @else {
      @media (min-width: map-get($map: $breakpoints, $key: $breakKey)) {
         @for $i from 8 through 32 {
            .fs-#{$breakKey}-#{$i} {
               font-size: $i + px !important;
            }
         }
      }
   }

   // font weight
   @if $breakKey ==xm {
      @each $num in $fontweights {
         .fw-#{$num} {
            font-weight: $num !important;
         }
      }
   } @else {
      @media (min-width: map-get($map: $breakpoints, $key: $breakKey)) {
         @each $num in $fontweights {
            .fw-#{$breakKey}-#{$num} {
               font-weight: $num !important;
            }
         }
      }
   }

   // line-height
   @if $breakKey ==xm {
      @for $i from 10 through 32 {
         .lh-#{$i} {
            line-height: $i + px !important;
         }
      }
   } @else {
      @media (min-width: map-get($map: $breakpoints, $key: $breakKey)) {
         @for $i from 10 through 32 {
            .lh-#{$breakKey}-#{$i} {
               line-height: $i + px !important;
            }
         }
      }
   }
}
