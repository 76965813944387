.card-project {
   aspect-ratio: 1.5;
   position: relative;

   .card-project-body {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid #E8EAED;
      overflow: hidden;
   }

   .bookmark-position {
      cursor: pointer;
      height: 50px;
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      z-index: 99;
   }

   .title {
      color: #252A2B;
      font-size: 12px;
      font-weight: 400;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-line-break: 2;
      overflow: hidden;
      margin-top: 8px;
   }
}