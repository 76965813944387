@use 'sass:map';

.construction-page {
   .procedure-custom {
      background: rgba(241, 241, 241, 0.9) !important;

      .item {
         background-color: #FBFBFC !important;
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .procedure-custom {
         background: rgba(241, 241, 241, 0.9) !important;

         .item {
            background: rgba(241, 241, 241, 0.9) !important;
         }
      }
   }
}