@import "styles/variables.scss";

.badge-detail {
   background: #ffffff;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
   padding: 18px 24px;
   gap: 20px;

   @include media-down(md) {
      padding: 0;
   }
   .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #252a2b;
      border-bottom: 1px solid #252a2b;
      padding-bottom: 20px;
      margin-bottom: 20px;
      @include media-down(md) {
         padding: 28px 12px 12px;
         color: #808089;
         font-size: 16px;
         font-weight: 500;
         line-height: 20px; /* 125% */
         margin-bottom: 0;
         border-bottom: 1px solid #808089;
      }
   }
   .badge-info {
      display: flex;
      justify-content: space-between;
      @include media-down(md) {
         padding: 16px 12px;
      }
      .badge-left {
         padding-right: 16px;
      }

      .badge-select-title {
         color: #252a2b;
         font-size: 14px;
         font-weight: 500;
         line-height: normal;
         margin-bottom: 8px;
      }

      .badge-select-des {
         color: #515151;
         font-size: 13px;
         line-height: normal;
      }

      .badge-switch {
         width: 42px;
         height: 26px;
         padding: 0px;

         & .MuiSwitch-switchBase {
            padding: 0px;
            margin: 2px;
            transition-duration: 300ms;

            &.Mui-checked {
               transform: translateX(16px);
               color: #fff;

               & + .MuiSwitch-track {
                  background-color: #65c466;
                  opacity: 1px;
                  border: 0px;
               }

               &.Mui-disabled + .MuiSwitch-track {
                  opacity: 0.5;
               }
            }

            &.Mui-focusVisible .MuiSwitch-thumb {
               color: #33cf4d;
               border: 6px solid #fff;
            }

            &.Mui-disabled .MuiSwitch-thumb {
               color: grey;
            }

            &.Mui-disabled + .MuiSwitch-track {
               opacity: 0.7;
            }
         }

         & .MuiSwitch-thumb {
            box-sizing: border-box;
            width: 22px;
            height: 22px;
         }

         & .MuiSwitch-track {
            border-radius: 13px;
            background-color: #d7d7d7;
            opacity: 1;

            transition: 500;
         }
      }
   }
}
