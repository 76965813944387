@import "styles/variables.scss";

.modal-receive {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1300;

   .MuiPaper-root {
      width: fit-content;
      max-width: 100%;
      background: unset;
      border-radius: 0;
      background-color: #fafafa;
      border-radius: 10px;
      width: 100%;
      max-width: 1112px;
      overflow-x: hidden;
      overflow-y: auto;

      @include media-down(md) {
         margin: 0;
         border-radius: 16px;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
      }
   }

   .MuiDialog-container {
      @include media-down(sm) {
         align-items: end;
      }
      .img-close {
         width: 100%;
         height: 100%;
         position: absolute;
         right: 15px;
         top: 15px;
         cursor: pointer;
   
         width: 32px;
         height: 32px;
         border-radius: 50%;
         background-color: #f4f4f4;
         padding: 10px;
   
         display: flex;
         align-items: center;
         justify-content: center;
         z-index: 101;
   
         &:hover {
            background-color: #e1dfdf;
         }
   
         img {
            width: 12px;
            height: 12px;
            object-fit: cover;
         }
      }
   
   }

   &.full-height {
      .MuiPaper-root {
         @include media-down(sm) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
         }
      }
   }

   .display-block {
      display: block;
   }

   .display-none {
      display: none;
   }

   .col-md-5_7 {
      @include media-up(md) {
         width: 46.7%;
      }
   }

   .col-md-6_3 {
      @include media-up(md) {
         width: 53.3%;
      }
   }

   .drawer-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      .img-close {
         position: absolute;
         top: 50%;
         right: 0;
         transform: translateY(-50%);
         cursor: pointer;
         padding: 16px;

         display: flex;
         align-items: center;
         justify-content: center;
         z-index: 101;

         img {
            width: 16px;
            height: 16px;
            object-fit: cover;
         }
      }
      span {
         color: #252a2b;
         font-size: 16px;
         font-weight: 500;
         line-height: 16px; /* 100% */
      }
   }

   // Slider
   .slick-slider {
      height: 100%;
      position: relative;

      .slick-list {
         height: 100%;
      }

      .slick-track {
         height: 100%;
      }

      .slick-slide {
         height: 100%;

         & > div {
            height: 100%;
         }
      }

      .slider-item {
         height: 100%;
      }

      .slick-dots {
         position: absolute;
         bottom: 26px;
         left: 50%;
         transform: translateX(-50%);

         & > li {
            button {
               width: 12px;
               height: 12px;
               border-radius: 50%;
               border: 1px solid rgba(0, 0, 0, 0.3);

               &::before {
                  display: none;
               }
            }

            &.slick-active button {
               background: #ff6347;
               border: 1px solid #ff6347;
            }
         }
      }
   }
   .modal-left {
      height: 100%;
      @media (min-width: 1200px) {
         min-height: 638px;
      }
   }

   .modal-right {
      width: 100%;
      height: 100%;
      padding: 72px 64px;
      background: #fff;
      @include media-down(md) {
         padding: 16px;
      }
      .current-coin {
         display: flex;
         align-items: center;
         justify-content: space-between;
         background: rgba(238, 238, 238, 0.5);
         margin-bottom: 16px;
         border: 2px solid #ff6347;
         @include media-down(md) {
            margin-bottom: 0px;
            background-color: unset;
            border: unset;
            flex-direction: column;
         }
         .name {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 88.889% */
            height: 64px;
            padding: 2px 16px;
            background-color: #ff6347;
            display: flex;
            align-items: center;
            justify-content: center;
            @include media-down(md) {
               color: #252a2b;
               text-align: center;
               font-size: 12.64px;
               font-weight: 400;
               letter-spacing: -0.126px;
               height: unset;
               padding: 0;
               margin-bottom: 8px;
               background-color: transparent;
            }
         }
         .coin-wrap {
            display: flex;
            align-items: center;
            height: 64px;
            padding: 2px 16px;
            @include media-down(md) {
               height: unset;
            }
            .value {
               color: #ffc323;
               font-size: 24px;
               font-weight: 500;
               line-height: 24px; /* 100% */
               margin-right: 2px;
               @include media-down(md) {
                  font-size: 20px;
                  font-weight: 700;
               }
            }
            .coin-icon {
               width: 28px;
               height: 28px;
               @include media-down(md) {
                  width: 24px;
                  height: 24px;
               }
            }
         }
      }
      .br-link {
         margin-bottom: 62px;
         @include media-down(md) {
            margin-bottom: 16px;
         }
         img {
            width: 100%;
         }
      }
      .list-mission {
         display: flex;
         flex-direction: column;
         row-gap: 24px;
         @include media-down(md) {
            row-gap: 16px;
         }
         .mission-item {
            border-radius: 10px;
            border-radius: 10px;
            border: 1px solid #e2e4eb;
            background: #fff;
            padding: 16px;
            box-shadow: 0px 1px 3px -5px rgba(0, 0, 0, 0.1);
            @include media-down(md) {
               padding-left: 8px;
            }
            &__content {
               display: flex;
               justify-content: space-between;
               align-items: center;
            }
            &__left {
               display: flex;
               align-items: center;
               .item-title {
                  color: #000;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 6px;
                  @include media-down(md) {
                     font-size: 14px;
                     margin-bottom: 15px;
                  }
               }
               .b-icon {
                  width: 56px;
                  height: 56px;
                  border-radius: 32px;
                  background: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                     width: 100%;
                     height: auto;
                     object-fit: contain;
                  }
               }
               .space-icon {
                  width: 2px;
                  height: 24px;
                  margin: 0 16px;
                  @include media-down(md) {
                     margin-left: 6px;
                     margin-right: 8px;
                  }
               }

               .plus {
                  color: #9f9f9f;
                  text-align: center;
                  font-size: 16px;
                  margin-right: 6px;
               }

               .coin-icon {
                  width: 16px;
                  height: 16px;
                  margin-right: 2px;
               }

               .coin-value {
                  color: #ffc323;
                  text-align: center;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: -0.18px;
                  @include media-down(md) {
                     font-size: 16px;
                  }
               }
            }
            &__right {
               width: 32px;
               height: 32px;
               border-radius: 6px;
               background: #ff6347;
               display: flex;
               align-items: center;
               justify-content: center;
               cursor: pointer;
               svg {
                  width: 10px;
                  height: 18px;
               }
               &.disabled {
                  background: #e2e4eb;
                  cursor: not-allowed;
               }
            }
            &__noproduct {
               color: #bfa01d;
               font-size: 14px;
               line-height: 16px; /* 114.286% */
               letter-spacing: -0.14px;
               padding-top: 16px;
               margin-top: 8px;
               border-top: 1px solid rgba(0, 0, 0, 0.1);
               display: flex;
               align-items: center;
               svg {
                  margin-right: 4px;
               }
            }
         }
      }
   }
}
