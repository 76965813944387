@use "sass:map";
@import "styles/variables.scss";

.actual-detail-page {
   .design-library-similar-container {
      @include media-down(md) {
         padding-top: 16px;
         border-top: 8px solid $border;
      }
   }
   .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: unset;
      .modal-main {
         position: fixed;
         background: rgba(37, 42, 43, 0.95);
         max-width: 560px;
         min-width: 350px;
         border-radius: 8px;
         top: 70%;
         left: 50%;
         transform: translate(-50%, -50%);
         padding: 12px 15px;

         p.success {
            margin: 0;
            color: #fff;
            span {
               color: #ff6347;
            }
         }
         .edit {
            color: #ff6347;
            white-space: nowrap;
            margin: 0;
            text-decoration: none;
         }
         .img {
            width: 100%;
            height: 100%;
            img {
               width: 100%;
               height: 100%;

               object-fit: cover;
            }
         }
      }
   }
   .display-block {
      display: block;
   }

   .display-none {
      display: none;
   }
   .post-wrap {
      img {
         width: 100% !important;
         height: 100% !important;
         object-fit: cover;
      }
   }
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
   // margin: 50px 0;
   .actual-detail {
      margin: 44px 0;
      .hidden-lg {
         display: none;
      }
      .actual-detail-post {
         .top-post {
            .top-img-post {
               position: relative;
               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
               .rule-tag {
                  position: absolute;
                  width: 108px;
                  height: 40px;
                  right: 12px;
                  top: 2.16%;
                  background: #252a2b;
                  border-radius: 4px;
                  opacity: 1;
                  margin: 12px;
                  padding: 8px;
                  .des {
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 21px;
                     color: #fff;
                     /* identical to box height */

                     text-align: center;
                     text-transform: capitalize;
                  }
               }
            }
            .title {
               text-align: left;
               font-size: 1.5rem;
               margin: 20px 0;
            }
            .top-meta-post {
               display: flex;
               justify-content: flex-start;
               align-items: center;

               .meta-item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: max-content;
                  height: 48px;
                  background: #f0f0f0;
                  border-radius: 46px;
                  padding: 14px;
                  img {
                     padding-right: 13px;
                     width: initial;
                  }
                  .des {
                     font-size: 16px;
                     line-height: 16px;
                     margin: 0;
                     color: map.get($text-colors, "default");
                  }
               }
               .meta-link {
                  a {
                     font-size: 16px;
                     line-height: 16px;
                     color: map.get($text-colors, "default");
                     opacity: 0.7;
                     text-decoration: none;
                  }
               }
            }
            .top-variant-post {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               margin-top: 20px;
               img {
                  width: initial;
               }
               p {
                  margin: 0;
               }
               .meta-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  img {
                     margin-right: 13px;
                     width: 18px;
                     height: 18px;
                  }
                  .des {
                     font-size: 16px;
                     line-height: 16px;
                     color: map.get($text-colors, "default");
                     span {
                        opacity: 0.8;
                     }
                  }
               }
            }
         }
         .content-post {
            .des {
               font-weight: 500;
               font-size: 13px;
               line-height: 23px;
               margin: 32px 0;
               strong {
                  font-weight: bold;
               }
               h4 {
                  font-weight: bold;
               }
            }
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .actual-detail-page {
      .actual-detail {
         margin: 30px 0;
      }
   }
}
