@use 'sass:map';

.landing-page-service-page {
   .trending-style {
      padding: 100px 0;
      background: #fff;

      .header {
         text-align: center;


         .description {
            max-width: 580px;
         }
      }

      .slick-dots {
         bottom: -10%;

         li {
            margin: 0 5px;
            width: 8px;
            height: 8px;

            button {
               width: 8px;
               height: 8px;
               border-radius: 50%;
               background-color: #ACACAC;
               padding: 4px;

               &::before {
                  display: none;
                  color: #ACACAC;
                  width: 8px;
                  height: 8px;
               }
            }

            &:hover,
            &.slick-active {
               button {
                  background: #252A2B;
               }
            }
         }
      }

      .slider-wrap-mobile {
         display: none;
      }

      .slider-wrap {
         background: #fff;
         position: relative;

         .slick-slider {
            .slick-arrow {
               width: 40px;
               height: 40px;
               z-index: 100;
               border-radius: 50%;
               background: transparent;

               &::before {
                  content: url(/assets/img/interior-design/prev.png);
                  font-size: unset;
                  opacity: unset;
                  line-height: unset;
               }
            }

            .slick-prev {
               top: 34%;
               left: 20px;
               transform: rotate(180deg);
               margin-top: -20px;
            }

            .slick-next {
               top: 34%;
               right: 36px;
            }
         }

         .arrow {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .btn {
               outline: none;
               border: none;
               width: 40px;
               height: 40px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .btn-prev {
               img {
                  transform: rotate(180deg);
               }
            }
         }

         .slider-item {
            width: calc(100% - 16px) !important;
            margin-right: 16px;
            display: block;

            img {
               object-fit: contain;
               margin-bottom: 10px;
               width: 100%;
            }

            .name {
               font-style: normal;
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               color: #252a2b;

               opacity: 0.9;
               margin-bottom: 4px;
            }

            .description {
               font-style: normal;
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #666666;
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .trending-style {
         padding: 32px 12px;

         .header {
            .title {
               margin-bottom: 20px;
            }

            .description {
               margin-bottom: 20px;
            }
         }

         .slick-dots {
            display: none !important;
         }

         .container {
            padding: 0;
         }

         .slider-wrap {
            margin-right: -12px;

            .slick-slider {
               .slick-arrow {
                  display: none !important;
               }
            }
         }
      }
   }
}