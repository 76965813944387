.card-user {
   padding: 12px;
   border-radius: 8px;
   border: 1px solid #E8EAED;
   aspect-ratio: 0.72;

   display: flex;
   justify-content: space-between;
   flex-direction: column;
   align-items: center;

   .head {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img {
         width: 72px;
         height: 72px;
         border-radius: 50%;
         overflow: hidden;
         // background-image: url('/assets/img/icon/default-user.png');

         svg {
            width: 100%;
            height: 100%;
         }
      }

      .username {
         color: #252A2B;
         text-align: center;
         font-size: 16px;
         font-weight: 500;
         margin-top: 8px;
      }
   }

   .btn-follow {
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      width: 100%;
      height: 28px;
      padding: 8px 16px;
      min-height: 32px;
      border-radius: 4px;

      @include media-down(md) {
         font-weight: 400;
         padding: 8px 12px;
      }

      svg {
         width: 13px;
         height: 15px;
         margin-right: 6px;
         vertical-align: text-top;
      }

      &.follow {
         color: #FF6347;
         background-color: #fff;
         border: 1px solid #FF6347;

         @include media-down(md) {
            color: #FFF;
            border: 1px solid #FF6347;
            background: #FF6347;
         }
      }

      &.following {
         color: #252A2B;
         border: 1px solid #E8EAED;
         background: #fff;


      }
   }
}