@import "../../styles/variables.scss";

.input {
   --padding-input: 8px;
   --input-height: 40px;

   width: 100%;
   border-radius: 8px;
   border: 1px solid #e2e4eb;
   overflow: hidden;
   padding: 0 var(--padding-input);

   position: relative;
   display: flex;
   align-items: center;

   input {
      width: 100%;
      height: 45px;
      background-color: unset;

      border: unset;
      outline: unset;
      &:hover,
      &:focus,
      &:valid {
         border: unset;
         outline: unset;
      }
   }

   &[data-error="true"] {
      border: 1px solid #ff0000;
   }
}
