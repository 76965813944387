@use "sass:map";
@import "styles/variables.scss";

@mixin inputInvisible() {
   input[type="file"] {
      opacity: 0;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
   }
}
.creation-post {
   @include inputInvisible;
}
.wrap-other-action {
   position: absolute;
   left: 12px;
   top: 12px;
   z-index: 9;
   display: flex;
   align-items: center;
   .btn {
      color: #252a2b;
      font-size: 16px;
      font-weight: 500;
      padding: 7px 10px;
      background: #fff;
      border-radius: 8px;
      border: unset;
      outline: unset;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
   }
   .btn-status-tagging {
      margin-right: 12px;
   }
   .btn-add-image {
      position: relative;
      input[type="file"] {
         opacity: 0;
         position: absolute;
         top: 0px;
         bottom: 0px;
         left: 0px;
         right: 0px;
         cursor: pointer;
      }
   }
}

.title-dialog {
   width: 100%;
   border-bottom: 1px solid #e8eaed;
   padding: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   span {
      font-size: 18px;
      font-weight: 500;
   }
   .icon-back {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
   }

   .btn-done {
      cursor: pointer;
      border-radius: 100px;
      background: #ff6347;
      width: 108px;
      height: 40px;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto 0;
   }
}

.content-dialog {
   padding: 0;
}

.action-dialog {
   border-radius: 0px 0px 24px 24px;
   border-top: 1px solid #e8eaed;
   padding: 16px;
   display: flex;
   gap: 8px;
   .add-media {
      height: 56px;
      cursor: pointer;
      position: relative;
      color: #68ba6b;
      font-size: 16px;
      font-weight: 700;
      border-radius: 100px;
      background: #f3f2ef;
      padding: 10px 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      input[type="file"] {
         opacity: 0;
         position: absolute;
         top: 0px;
         bottom: 0px;
         left: 0px;
         right: 0px;
      }
   }
}
.textarea_custom {
   width: 100%;
   border: none;
   background: #fff;
   padding: 0px 6px;
   &:hover,
   &:focus-visible {
      outline: none;
      border: none;
   }
}
.upload-image-error {
   // @include media-down(md) {
   //    text-align: center;
   //    max-width: 240px;
   //    margin: 0 auto;
   //    line-height: 20px;
   // }
}
.action {
   border-radius: 0px 0px 24px 24px;
   border-top: 1px solid #e8eaed;
   background: #fff;
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 12px 16px;
   z-index: 11;
   height: 81px;

   .btn-add-link {
      padding: 10px 20px;
      height: 56px;
      background: #f3f2ef;
      border-radius: 100px;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #565656;
      border: none;
      outline: none;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      &:disabled {
         background: #e9e9e9;
         color: #a5a5a5;
      }
      &.disable {
         background: #e9e9e9;
         color: #a5a5a5;
      }
   }
   .add-media {
      height: 56px;
      margin-right: 12px;
      cursor: pointer;
      position: relative;
      color: #68ba6b;
      font-size: 16px;
      font-weight: 700;
      border-radius: 100px;
      background: #f3f2ef;
      padding: 10px 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      input[type="file"] {
         opacity: 0;
         position: absolute;
         top: 0px;
         bottom: 0px;
         left: 0px;
         right: 0px;
      }
   }
   .bt-schedule-post {
      color: $primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      width: 100%;
      height: 40px;
      margin-right: 8px;
      padding: 10px 20px;
      border-radius: 100px;
      border: 1px solid var(--Orange-orange-500, $primary);
      background: var(--gray-white, #fff);
      &:disabled {
         background: #e9e9e9;
         color: #a5a5a5;
         border-color: #e9e9e9;
      }
      &.disable {
         background: #e9e9e9;
         color: #a5a5a5;
         border-color: #e9e9e9;
      }
   }
   .submit {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;

      width: 100%;
      height: 40px;
      padding: 10px 20px;
      margin-left: 8px;
      background: $primary;
      border-radius: 100px;

      border: none;
      outline: none;

      display: flex;
      align-items: center;
      justify-content: center;
      &:disabled {
         background: #e9e9e9;
         color: #a5a5a5;
      }
      &.disable {
         background: #e9e9e9;
         color: #a5a5a5;
      }
   }
}

.dialog-tagging-paper {
   width: 100%;
   min-width: 50vh;
   max-width: 1117px !important;
   max-height: 96vh !important;
}

.drawer-tagging-paper {
   width: 100vw;
   position: relative;
   .drawer-title {
      position: relative;
      position: sticky;
      top: 0;
      z-index: 9;
      height: 56px;
      min-height: 56px;
      border-bottom: 1px solid #e8eaed;
      background: #fff;
      padding: 7px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      .icon-back {
         position: absolute;
         left: 12px;
         top: 0px;
         bottom: 0px;
         margin: auto;
      }
      button {
         position: absolute;
         right: 12px;
         top: 0px;
         bottom: 0px;
         margin: auto;
         font-weight: 700;
         height: 40px;
         border-radius: 24px;
         background-color: $primary;
         color: #fff;
         min-width: 82px;
         text-align: center;
      }
   }
   .upload-image-mb {
      border-top: 1px solid #e8eaed;
   }
   .list-thumbnail-media {
      // position: absolute;
      // bottom: 0;
      // right: 0;
      // left: 0;
      background-color: #fff;
      border-top: 1px solid $border;
      // box-shadow: 0 -5px 14px rgba(0, 0, 0, 0.1);
   }
}

.selected-active {
   background-color: rgba(0, 0, 0, 0.04);
}

.upload-image-mb {
   @include inputInvisible;
   height: fit-content;
   display: flex;
   align-items: flex-end;
   justify-content: center;
   width: 100%;
   margin: auto;
   padding: 0px 0px 24px 0px;
   gap: 46px;
   background: #fff;
   padding: 12px;
   z-index: 1;
   .option-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .input {
         border-radius: 28px;
         background: #f3f2ef;
         width: 56px;
         height: 56px;
         display: flex;
         align-items: center;
         justify-content: center;
         position: relative;
      }
      .text {
         color: rgba(0, 0, 0, 0.6);
         font-size: 16px;
         font-weight: 500;
      }
   }
}

.tagging {
   display: flex;
   flex-direction: column;
   overflow: hidden;
   @include media-down(md) {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: calc(100vh - 56px - 105px);
   }

   textarea {
      resize: none;
   }

   @include inputInvisible;

   .image-upload {
      border-radius: 6px;
      border: 2px dashed #dadada;
      padding: 58px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      img {
         cursor: pointer;
      }

      .title {
         font-size: 14px;
         font-weight: 600;
         margin-top: 16px;
         cursor: pointer;
      }

      .desc {
         color: #ababab;
         font-size: 12px;
         text-align: center;
      }
   }
   textarea,
   input,
   select {
      width: 100%;
      // border: 1px solid #e8eaed;
      border: none;
      background: #fff;
      padding: 0px 6px;
      &:hover,
      &:focus-visible {
         outline: none;
         // border: 1px solid #e8eaed;
         border: none;
      }
   }

   .grid-medias {
      display: flex;
      flex-direction: column;
      gap: 36px;
      width: 100%;
      min-height: 656px;
   }

   .media-item {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      scroll-margin: 80px;

      .form-control {
         width: 50%;
      }

      .media__image {
         height: fit-content;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0px;
         position: relative;
         height: 520px;
         min-height: 520px;
         max-height: 520px;
         overflow: hidden;
         @include media-down(md) {
            height: auto;
            min-height: auto;
         }

         .bg-blur {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: blur(40px);
            @include media-down(md) {
               display: none;
            }
         }

         img {
            border-radius: 0px;
         }

         .delete-image {
            position: absolute;
            left: 12px;
            bottom: 12px;
         }

         .sort-image {
            position: absolute;
            right: 12px;
            bottom: 12px;
            color: #fff;
            z-index: 2;
         }
      }

      .media__description {
         display: flex;
         flex-direction: column;
         gap: 16px;
         padding: 16px;
         background-color: #fff;
         textarea {
            padding: 16px;
            min-height: 104px;
            border: 1px solid #e2e4eb;
            border-radius: 8px;
            @include media-down(md) {
               border: unset;
               padding: 0;
               border-radius: 0;
            }
         }
      }
   }
   .add-media-mb {
      display: none;
   }
}

.list-thumbnail-media {
   padding: 16px;
   flex-shrink: 0;
   width: 100%;
   display: flex;
   flex-direction: row;
   gap: 12px;
   border-top: 1px solid #e2e4eb;
   overflow-x: auto;
   overflow-y: hidden;
   .thumbnail-media-item {
      position: relative;
      cursor: pointer;
      border-radius: 8px;
      overflow: hidden;
      width: 72px;
      height: 72px;
      min-width: 72px;
      &:hover {
         .thumbnail-overlap {
            display: flex;
         }
      }

      img.thumbnail-image {
         width: 100%;
         height: 100%;
         // border-radius: 8px;
         background: rgba(255, 255, 255, 0);
         box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset, 0px 1px 9px 0px rgba(0, 0, 0, 0.05) inset;
         object-fit: cover;
      }

      &.thumbnail-image--selected {
         border: 3px solid #ff6347;
      }

      .thumbnail-overlap {
         z-index: 1;
         background: rgba(0, 0, 0, 0.6);
         display: none;
         position: absolute;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         align-items: center;
         justify-content: center;
         color: #fff;

         img {
         }
      }
   }

   .add-media {
      border-radius: 4px;
      border: 2px dashed #e9e9e9;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      cursor: pointer;
      position: relative;
      input {
         z-index: 1;
      }
      svg {
         opacity: 0.5;
         pointer-events: none;
      }
   }

   input[type="file"] {
      opacity: 0;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .creation-post {
      padding-bottom: 57px;
   }
   .action {
      width: 100%;
      height: 57px;
      padding: 8px 16px;

      display: flex;
      border-top: 1px solid var(--gray-gray-400, #e2e4eb);
      background: var(--Background-colorBgBase, #fff);
      border-radius: 0;

      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 1300;

      button {
         width: 100%;
         height: 32px;
         padding: 8px 18px;
         border-radius: 24px;
         font-weight: 500;
         font-size: 14px;
      }
   }
   .tagging {
      .grid-medias {
         gap: 16px;
      }
      .media-item {
         grid-template-columns: 1fr;
         .sort-image {
            display: none;
         }
      }
   }
}

.popover-emoij-paper {
   background: unset;
   box-shadow: unset;
}
.feed-emoij-icon {
   position: absolute;
   right: 2px;
   bottom: 4px;
   width: 24px;
   height: 24px;
   border-radius: 24px;
   cursor: pointer;
   svg {
      width: 100%;
      height: 100%;
   }
}
