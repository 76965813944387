.product-cardv4 {
   .process-selling {
      position: relative;
      width: 100%;
      max-width: 100px;
      height: 14px;
      border-radius: 100px;
      background: #697f90;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      .process-filler {
         content: "";
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         border-radius: 100px;
         background: $primary;
         z-index: 1;
         width: 60%;
      }
      .process-text {
         z-index: 2;
         display: flex;
         align-items: center;
         justify-content: center;
         svg {
            width: 7px;
            margin-right: 2px;
         }
      }
   }
}

.product-cardv4.primary {
   width: 100%;
   transition-duration: 0.2s;
   transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
   position: relative;

   .product-cardv4-info {
      width: 100%;
      padding: 4px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid;
      background: #fff;
      // margin: -4px;
      @include media-down(md) {
         margin: 0;
         padding: 0;
         border: unset;
         background: #fff;
         box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.1);
         border-radius: 13px;
      }
   }
   .product-cardv4-info.showBorder {
      border-color: #e2e4eb;
   }
   .product-cardv4-info:not(.showBorder) {
      border-color: transparent;
   }
   .product-card-image {
      position: relative;
      width: 100%;
      overflow: hidden;

      .wrap-img {
         --sp-aspect-ratio: 104%;
         position: relative;
         border-radius: 10px;
         border: 1px solid #e2e4eb;
         background: #eae9e9;
         overflow: hidden;
         &::before {
            content: "";
            display: block;
            padding-top: var(--sp-aspect-ratio);
         }

         & > .image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
         }

         .image-wrapper img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
         .tag-campain {
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 20px;
            max-width: 128px;
            object-fit: contain;
            z-index: 1;
         }

         .product-stock-out {
            position: absolute;
            top: 50%;
            left: 50%;
            right: unset;
            bottom: unset;
            transform: translate(-50%, -50%);
            z-index: 2;

            width: 64px;
            height: 64px;
            border-radius: 50%;

            color: #fff;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            background: rgba(37, 42, 43, 0.9);
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }

   &:hover {
      @include media-up(md) {
         --tw-skew-x: 0;
         --tw-skew-y: 0;
         --tw-scale-x: 1;
         --tw-scale-y: 1;
         --tw-rotate: 0;
         --tw-translate-x: 0;
         --tw-translate-y: -1px;
         transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
            scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
         .product-cardv4-info {
            border-color: #ff6347;
            box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
            background: #fff;
         }
      }
      .wrap-img {
         // border: unset;
      }
   }

   .product-item-detail {
      width: 100%;
      padding: 8px 4px;
      .name {
         color: #252a2b;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.126px;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-line-break: 2;
         line-break: 2;
         overflow: hidden;
         text-overflow: hidden;
         margin-bottom: 4px;
      }

      .w-price-wrap {
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
      .price-wrap {
         display: flex;
         flex-direction: column;
      }

      .price,
      .discount-price {
         color: #ff6347;
         font-size: 16px;
         font-weight: 500;
         line-height: 20px; /* 100% */
         margin-right: 3px;
         white-space: nowrap;
         margin-bottom: 6px;
         @include media-down(md) {
            font-size: 14.22px;
            margin-bottom: 3px;
         }
         span {
            line-height: 1;
         }
      }

      .wrap-real-price {
         display: flex;
         align-items: center;
      }
      .real-price {
         color: #9f9f9f;
         font-size: 12px;
         font-weight: 400;
         line-height: 12px; /* 100% */
         white-space: nowrap;
         text-decoration-line: strikethrough;
         text-decoration: line-through;
         margin-right: 8px;
         @include media-down(md) {
            font-size: 11px;
         }
      }
      .percent-price {
         color: #ff383b;
         font-size: 10px;
         font-weight: 600;
         width: fit-content;
         min-width: 35px;
         height: 16px;
         display: block;
         white-space: nowrap;
         border-radius: 4px;
         background: #ffe6e8;
         display: flex;
         align-items: center;
         justify-content: center;

         position: absolute;
         top: 4px;
         left: 4px;
         z-index: 5;

         border-radius: 4px;
         background: var(--red-red-100, #ffe6e8);
      }
   }

   // dynamic
   &.fixedHeight {
      height: 100%;
      .product-cardv4-info {
         height: 100%;
      }
      .product-item-detail {
         min-height: 96px;
         @include media-down(md) {
            min-height: 92px;
         }
         .name {
            min-height: 34px;
         }
         .w-price-wrap {
            min-height: 47px;
         }
      }
   }
}
