@use 'sass:map';
@import 'service';
@import 'referral';
@import 'rule';
@import 'reward';
@import 'step';
@import 'contact';

.landing-page {
   scroll-behavior: smooth;

   .btn-custom {
      background-color: map-get($map: $text-colors, $key: orange) !important;
      color: #ffffff;
      border-radius: 10px;
      height: 64px;
      width: 235px;
      padding: 20px 30px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &::before {
         content: url('/assets/img/icon/arrow-right.svg');
         width: 0px;
         height: 24px;
         display: inline-block;
         margin-right: 10px;
         opacity: 0;
         transition: all 0.2s linear;
      }

      &:hover {
         background-color: #ffffff !important;
         border: 1px solid map-get($map: $text-colors, $key: orange);
         color: map-get($map: $text-colors, $key: orange);

         &::before {
            width: 30px;
            opacity: 1;
         }
      }

      &:focus {
         border: none;
      }
   }

   .secondary-bg {
      background-color: #f4efef;
   }

   .img-rhombus::before {
      background-image: url('/assets/img/icon/rhombus.png');
      height: 30px;
      width: 30px;
      display: inline-block;
      flex-shrink: 0;
      vertical-align: middle;
      margin-right: 12px;
      content: '';
      background-size: 30px;
      background-repeat: no-repeat;
   }

   .text-italic {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 38px;
      color: #333333;
   }

   section {
      h1 {
         text-transform: uppercase;
      }

      h2 {
         font-weight: 400;
      }

      .strong {
         font-weight: 700;
      }

      .highlight {
         color: map.get($text-colors, orange);
      }

      .section-title {
         font-weight: 900;
         font-size: 40px;
         line-height: 47px;
         text-transform: uppercase;
      }

      .section-description {
         font-weight: 400;
         font-size: 20px;
         line-height: 23px;
         text-align: center;
         color: #666666;
      }
   }

   // XX-Large devices (larger desktops, 1400px and up)
   @media (min-width: map.get($grid-breakpoints, 'xxl')) {
      .container {
         max-width: 1224px;
      }
   }

   @media (max-width: map.get($grid-breakpoints, 'md')) {
      .container {
         padding-left: 24px;
         padding-right: 24px;
      }

      section {
         .section-title {
            text-transform: none;
         }
      }

      .secondary-bg {
         background: #f1ccc7;
      }
   }
}