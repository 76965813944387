@import "styles/variables.scss";

.user-result {
   padding: 12px 12px 24px;
   max-width: calc(590px + 24px);
   margin: 0 auto;

   @include media-down(md) {
      padding-bottom: 36px;
   }

   .block-user {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include media-down(md) {
         gap: 12px;
      }
   }

   .group-grid {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 14px;

      @include media-down(md) {
         gap: 8px;
      }
   }

   .list-grid {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 14px;

      @include media-down(sm) {
         gap: 4px;
      }
   }

   .user-item {
      padding: 12px 16px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 1px 10px -8px rgba(0, 0, 0, 0.6);

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-down(md) {
         padding: 10px 8px;
         background-color: unset;
         border-radius: unset;
         box-shadow: unset;
      }

      .left {
         display: flex;
         align-items: center;

         .img {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-right: 8px;
            border-radius: 50%;
            background-color: #E0E0E0;
            overflow: hidden;

            img {
               object-fit: cover;
               width: 100%;
               height: 100%;
            }
         }

         .name {
            color: #252A2B;
            font-size: 14px;
            font-weight: 600;
         }

         .follow {
            color: #252A2B;
            font-size: 12px;
            font-weight: 400;
         }
      }

      .btn-follow {
         text-align: center;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
         padding: 8px 16px;
         min-height: 32px;
         border-radius: 4px;

         @include media-down(md) {
            padding: 8px 12px;
         }

         svg {
            width: 13px;
            height: 15px;
            margin-right: 6px;
            vertical-align: text-top;
         }

         &.follow {
            color: #FF6347;
            background-color: #fff;
            border: 1px solid #FF6347;

            @include media-down(md) {
               color: #FFF;
               border: 1px solid #FF6347;
               background: #FF6347;
            }
         }

         &.following {
            color: #252A2B;
            border: 1px solid #E8EAED;
            background: #fff;


         }
      }
   }

   .search-no-result {
      color: rgba(37, 42, 43, 0.5);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      padding-top: 24px;
   }
}