@use "sass:map";
@import "styles/variables.scss";

.modal-share {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(241, 241, 241, 0.9);
   z-index: 1301;

   .modal-main {
      position: fixed;
      background: #ffffff;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 31px;

      .img-close {
         width: 100%;
         height: 100%;
         position: relative;

         img {
            position: absolute;
            width: 18px;
            height: 18px;
            object-fit: cover;
            opacity: 0.6;
            top: 0;
            right: 0;
         }
      }

      .title-share {
         padding-top: 18px;
         font-weight: 500;
         font-size: 1.714rem;
         line-height: 28px;
         color: #1b1c34;
         text-align: center;
      }

      .group-link {
         padding-top: 45px;
         display: flex;
         justify-content: center;
         align-items: center;

         .link-item {
            cursor: pointer;
            text-decoration: none;
            padding: 0 16px;
            position: relative;

            .popup-copy {
               position: absolute;
               visibility: hidden;
               width: 160px;
               background-color: #555;
               color: #fff;
               text-align: center;
               border-radius: 6px;
               padding: 8px 0;
               position: absolute;
               z-index: 1;
               bottom: 100%;
               left: 50%;
               margin-left: -80px;

               &.show-popup {
                  visibility: visible !important;
                  -webkit-animation: fadeIn 1s;
                  animation: fadeIn 1s;
               }

               &::after {
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: #555 transparent transparent transparent;
               }
            }

            @-webkit-keyframes fadeIn {
               from {
                  opacity: 0;
               }

               to {
                  opacity: 1;
               }
            }

            @keyframes fadeIn {
               from {
                  opacity: 0;
               }

               to {
                  opacity: 1;
               }
            }

            p {
               font-weight: 500;
               font-size: 1.15rem;
               line-height: 19px;
               text-align: center;
               color: #1b1c34;
               margin-bottom: 14px;
               white-space: nowrap;
            }

            .img-icon {
               margin-bottom: 10px;
               background: #fff;
               border: 1px solid #dee4ed;
               border-radius: 50%;
               width: 70px;
               height: 70px;
               padding: 20px;

               img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
               }
            }
         }
      }
   }

   .display-block {
      display: block;
   }

   .display-none {
      display: none;
   }
}

.notification {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(241, 241, 241, 0.9);
   z-index: 1350;
   display: none;

   &.open {
      display: block;
   }

   .main {
      position: fixed;
      background: #ffffff;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      max-width: 348px;
      padding: 20px;

      .panel {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         .content {
            color: map.get($text-colors, "default");
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 12px;
            white-space: break-spaces;
         }

         .btn {
            // background: #252525;
            color: #fff;
            min-width: 100px;
            padding: 8px;
         }
      }
   }
}

.modal-bookmark {
   z-index: 9999;
   position: fixed;
   bottom: 8%;
   left: 50%;
   transform: translate(-50%, -50%);
   .modal-main {
      height: 40px;
      max-height: 40px;
      background: rgba(37, 42, 43, 0.95);
      max-width: 560px;
      min-width: 350px;
      border-radius: 8px;
      padding: 4px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      & > * {
         width: 100%;
      }

      p.success {
         margin: 0;
         color: #fff;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.126px;
         display: flex;
         align-items: center;
         svg {
            margin-right: 6px;
         }
         span {
         }
         a {
            cursor: pointer;
            color: #ff6347;
         }
         .hightlight {
            color: #ff6347;
            margin-right: 4px;
         }
      }

      .edit {
         color: #ff6347;
         font-size: 12.64px;
         font-weight: 600;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.253px;
         white-space: nowrap;
         margin: 0;
         text-decoration: none;
         display: flex;
         align-items: center;
         svg {
            margin-left: 4px;
         }
      }

      .img {
         width: 100%;
         height: 100%;
         position: relative;

         .cover {
            position: absolute;
            content: "";
            z-index: 10;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
         }

         img {
            width: 24px;
            height: 100%;
            object-fit: cover;
         }
      }
      .icon-close {
         opacity: 0.5;
         width: 24px;
         height: 24px;
      }
   }
}

.modal-image-by-space {
   .dialog-detail-media-by-space {
      width: 85vw;
      max-width: 1540px !important;

      &__content {
         .slick-slider {
            margin-bottom: 3rem;
         }

         .image-fake {
            overflow: hidden;
         }

         .slider-item {
            img:not(.bookmark-icon) {
               max-width: 100%;
               height: 450px;
               object-fit: cover;
            }

            .bookmark-icon {
               position: absolute;
               top: 24px;
               right: 24px;
               z-index: 99;
            }
         }

         .slick {
            &-arrow {
               color: #e0e0e0;
               z-index: 1;
            }

            &-next {
               right: 20px;
            }

            &-prev {
               left: 20px;
            }
         }

         .target-media {
            border: 1px solid #dddddd;

            img {
               width: 80px;
               height: 80px;
               object-fit: cover;
            }

            &-content {
               .action {
                  color: #666666;
               }

               .title {
                  font-weight: 700;
                  @include text-wrap-line(1);
               }

               .title_ibs {
                  font-weight: 700;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1;
                  word-break: break-all;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
               }
            }
         }
      }
   }

   // Small devices (landscape phones, 576px and up)
   @media (max-width: map.get($grid-breakpoints, "sm")) {
      .dialog-detail-media-by-space {
         width: 100%;
         height: 100%;
         margin: 0;
         max-height: unset;

         &__content {
            padding: 0px 0px 98px 0px !important;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: fit-content;

            .target-media {
               position: absolute;
               bottom: 16px;
               right: 0px;
               left: 0px;
            }

            .slick-track {
               display: flex;
               align-items: center;
            }

            .slider-item {
               img:not(.bookmark-icon) {
                  height: auto !important;
                  max-height: 420px !important;
                  max-width: 100%;
               }
            }

            .slick {
               &-arrow {
                  display: none !important;
               }

               &-dots {
                  bottom: -50px;
               }
            }
         }
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      .dialog-detail-media-by-space {
         width: 90%;

         .slider-item {
            img:not(.bookmark-icon) {
               height: 300px;
            }
         }
      }
   }
}

.dialog-coin-policy-content {
   padding: 16px 16px 0;
   ul {
      padding: 0 16px;
      li {
         font-size: 16px;
         line-height: 24px; /* 150% */
         @include media-down(md) {
            font-size: 14.22px;
         }
      }
   }
   .wrap-action {
      padding: 16px 24px;
      border-top: 1px dotted $border;
      display: flex;
      justify-content: center;
      @include media-down(md) {
         padding: 16px 0;
         border: unset;
      }
      button {
         // max-width: 327px;
         // @include media-down(md) {
         //    max-width: 100%;
         // }
      }
   }
}

.dialog-contact {
   &__content {
      padding: 32px 16px;
      img {
         width: 48px;
         height: 48px;
      }
   }

   .wrap-action {
      padding: 0 16px 32px;
      button {
         height: 40px;
         border-radius: 40px;
      }
   }
}
