@use 'sass:map';
@import "styles/variables.scss";

.model-product-review {
   .MuiPaper-root {
   }
   .MuiDialog-paper {
      max-width: 544px;
   }
   .MuiDrawer-paper {
   }

   .drawer-header {
      height: 56px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .close-icon {
         position: absolute;
         left: 16px;
         top: 50%;
         transform: translateY(-50%);

         width: 20px;
         height: 20px;
      }
      .title {
         color: #252a2b;
         font-size: 18px;
         font-weight: 700;
         line-height: 21px; /* 116.667% */
      }
      .btn-submit {
         color: #fff;
         font-size: 14px;
         font-weight: 600;
         height: 32px;
         width: 65px;
         border-radius: 24px;
         background: #ff6347;
         cursor: pointer;

         position: absolute;
         right: 16px;
         top: 50%;
         transform: translateY(-50%);
      }
   }

   .auth-review {
      height: 40px;
      padding: 8px;
      margin: 8px 0 12px;
      border: 1px solid #5acb4c;
      background: rgba(240, 255, 234, 0.2);
      @include media-down(md) {
         height: unset;
         padding: 16px;
         margin: 0;
         background: unset;
         border: unset;
         border-bottom: 8px solid #f5f5fa;
      }
      p {
         color: rgba(37, 42, 43, 0.7);
         font-size: 12px;
         line-height: 16px; /* 133.333% */
      }
      .coin-policy-link {
         color: #ff6347;
         font-size: 12px;
         line-height: 16px; /* 133.333% */
         svg {
            margin-left: 4px;
         }
      }
   }

   .modal-content {
      padding: 0 24px;
      overflow-y: auto;
      overflow-x: hidden;
      @include media-down(md) {
         padding: 0;
      }
   }
   .product-item {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 12px 0 16px;
      @include media-down(md) {
         padding: 12px 16px 16px;
      }
   }
   .product-image {
      width: 64px;
      height: 64px;
      overflow: hidden;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-down(md) {
         width: 40px;
         height: 40px;
      }
      img {
         object-fit: contain;
         width: auto;
         height: 100%;
      }
   }
   .product-info {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
   }
   .product-name {
      color: #252a2b;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.4px;

      @include media-down(md) {
         font-size: 12.64px;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.126px;
      }
   }
   .rating-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      @include media-down(md) {
         width: 100%;
         max-width: 279px;
         margin: 0 auto 24px;
      }
      .product-rating {
         @include media-down(md) {
            width: 100%;
            display: flex;
            justify-content: space-between;
         }
         svg {
            width: 40px;
            height: 40px;
            margin: 0 7px;
            @include media-down(md) {
               width: 24px;
               height: 24px;
            }
         }
      }

      .rating-text {
         color: #252a2b;
         font-size: 18px;
         font-weight: 500;
         opacity: 0.8;
         margin-top: 10px;
         @include media-down(md) {
            margin-top: 16px;
         }
      }
   }

   .form {
      @include media-down(md) {
         padding-bottom: 42px;
         border-top: 8px solid $border;
      }
      .label {
         color: #252a2b;
         font-size: 16px;
         font-weight: 500;
         line-height: normal;
         opacity: 0.8;
         margin-bottom: 8px;
         @include media-down(md) {
            font-size: 12.64px;
            font-weight: 600;
            padding: 16px;
            margin-bottom: 0;
         }
      }
      .form-item {
         @include media-down(md) {
            padding: 0 16px;
         }
         .input {
            --padding-input: 8px;
            --input-height: 43px;

            textarea {
               width: 100%;
               font-size: 16px;
               font-weight: 400;
               line-height: normal;
               padding: var(--padding-input) !important;
               border: unset;
               outline: unset;
               &:hover,
               &:focus,
               &:valid {
                  border: unset;
                  outline: unset;
               }
            }
            input.input-image {
               position: absolute;
               z-index: 2;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
            }

            &.file-input {
               height: 80px;
               display: flex;
               justify-content: center;
               border-style: dotted;
               input {
                  opacity: 0;
                  cursor: pointer;
               }
               svg {
                  margin-right: 12px;
               }
               .fake-input {
                  color: #252a2b;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
               }
               .max-image {
                  color: #9f9f9f;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px; /* 142.857% */
               }
            }
         }
         &.item-textarea {
            @include media-down(md) {
               padding: 16px 16px 0;
               border-top: 8px solid $border;
            }
         }
      }
   }

   .image-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;
      @include media-down(md) {
         padding: 0 16px;
         row-gap: 8px;
      }
      .img-item {
         width: 80px;
         height: 80px;
         aspect-ratio: 1;
         position: relative;
         border-radius: 8px;
         background: #e2e4eb;
         @include media-down(md) {
            width: 18%;
            height: auto;
         }
         .delete-icon {
            width: 20px;
            height: 20px;
            position: absolute;
            top: -6px;
            right: -6px;
            z-index: 1;
            cursor: pointer;
         }
         &.default-img {
            border-radius: 8px;
            border: 1px dashed #e2e4eb;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
      img {
         border-radius: 3px;
         object-fit: cover;
      }
   }

   .action {
      width: 100%;
      text-align: center;
      padding-top: 0;
      padding-bottom: 16px;
      @include media-down(md) {
         padding: 10px 12px;
         border-top: 1px solid #ededed;
      }
      button {
         color: #fff;
         font-size: 16px;
         font-weight: 500;
         line-height: normal;

         width: 100%;
         height: 47px;
         max-width: 107px;
         border-radius: 100px;
         background: #ff6347;
         @include media-down(md) {
            width: 100%;
            max-width: 100%;
         }
      }
   }
}

.model-product-review-success {
   .MuiPaper-root {
      max-width: 407px;
      border-radius: 10px;
      background: #fff;
   }
   .modal-content {
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include media-down(md) {
         padding: 24px 16px;
      }
   }

   svg {
      width: 46px;
      height: 46px;
   }

   .modal-success-title {
      color: #252a2b;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 4px;
      line-height: 20px;
   }
   .description {
      color: #65676b;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
   }
   .review-success-img {
      width: 169px;
      height: auto;
      margin: auto;
      margin-bottom: 32px;
   }

   button {
      width: 100%;
      height: 52px;
      border-radius: 100px;
   }
}
