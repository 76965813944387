.empty {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 14px;

   .img {
      width: 56px;
      height: 46px;
   }

   .des-empty {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000;
      margin-bottom: 0px;
      text-align: center;
   }
}