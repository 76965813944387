.article-item {
   position: relative;
   border-radius: 8px 8px 0px 0px;
   overflow: hidden;

   &__image {
      position: relative;
      aspect-ratio: 1.54;
      overflow: hidden;
      border-radius: 3px;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      height: 40px;
      margin-top: 12px;
      color: #252a2b;
   }

   &__meta {
      margin-top: 8px;
      display: flex;
      gap: 6px;
      align-items: center;

      .des-item {
         display: flex;
         align-items: center;
         gap: 4px;
      }

      span {
         font-weight: 400;
         font-size: 13px;
         line-height: 15px;
         display: flex;
         align-items: center;
         color: #666666;
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, 'md')) {

   .article-item {
      &__image {
         position: relative;
         aspect-ratio: 1.54;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &__title {
         font-size: 13px;
         line-height: 15px;
         height: 30px;
      }

      &__meta {
         .des-item {
            font-size: 13px;
            line-height: 15px;
         }
      }
   }

}