@use 'sass:map';

.account-page {
    margin: 63px auto;
    margin-top: 24px;
    .title_info {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 28px;
        color: #6a433a;
        margin-bottom: 64px;
    }

    .title_sign_up {
        color: #131313;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 24px;
        text-align: center;
    }
    .type_sign_up {
        display: flex;
        margin-bottom: 16px;
        padding: 10px 18px;
        border-radius: 32px;
        border: 1px solid #e8eaed;
        background: #fff;
        .img {
            width: 18px;
            height: 18px;
            img {
                object-fit: contain;
            }
        }
        .text {
            flex: 1;
            display: flex;
            cursor: pointer;
            justify-content: center;
        }
    }
    .title-gg-fb {
        color: #131313;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 43px 0;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;

        .text {
            background-color: #fafafa;
            height: 1rem;
            z-index: 100;
            padding: 0 5px;
        }

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #acacac;
            display: block;
            top: 10px;
            left: 0;
            z-index: 1;
        }
    }

    .des {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* grey/5 */

        color: #515151;

        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            /* identical to box height */

            /* Add/7 */

            color: #3d1002;
        }
    }
    .register {
        margin-top: 30px;
        color: #131313;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;

        a {
            text-decoration: none;
            color: #0055aa;
        }
    }
    form {
        .error-text {
            font-size: 0.875rem;
            line-height: 20px;
            color: #ff0000;
            margin-top: 5px;
        }
        .password_input {
            position: relative;
            .icon_eye {
                position: absolute;
                right: 15px;
                top: 34px;
                cursor: pointer;
            }
        }

        .item-form {
            display: flex;
            flex-direction: column;
            margin-bottom: 9px;

            .label {
                font-size: 0.875rem;
                line-height: 20px;
                color: #131313;
                margin-bottom: 5px;
            }

            .wrap {
                position: relative;

                .code {
                    position: absolute;
                    width: max-content;
                    height: 100%;
                    padding: 9px 14px;
                    top: 0;
                    right: 0;
                    border-radius: 4px;
                    background-color: transparent;

                    .btn-code {
                        cursor: pointer;
                        background-color: transparent;
                        color: #ff6347;
                        font-size: 16px;

                        &.btn-code-reset {
                            color: #acacac;

                            &.active {
                                color: #ff6347;
                            }
                        }
                    }
                }
            }

            .input {
                padding: 9px 14px;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                outline: none;
                width: 100%;
                font-size: 0.875rem;
                height: 38px;

                &.error {
                    border: 1px solid #ff0000;
                }

                &::placeholder {
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 20px;
                    color: #acacac;
                }
            }

            .forgot-pass {
                color: #131313;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin: 6px 0;
                cursor: pointer;
            }
        }

        .btn {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .gg-fb {
            font-size: 0.75rem;
            line-height: 20px;
            width: 100%;
            color: #acacac;

            .title-gg-fb {
                margin: 43px 0;
                margin-bottom: 34px;
                width: 100%;
                text-align: center;
                position: relative;
                display: flex;
                justify-content: center;

                .text {
                    background-color: #fff;
                    height: 1rem;
                    z-index: 100;
                    padding: 0 5px;
                }

                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: #acacac;
                    display: block;
                    top: 10px;
                    left: 0;
                    z-index: 1;
                }
            }

            .btn-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;

                .btn-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 64px;
                    height: 64px;
                    border: 1px solid #e8eaed;
                    border-radius: 50%;
                    padding: 18px;
                    cursor: pointer;

                    .img {
                        width: 28px;
                        height: 28px;

                        img {
                            width: 28px;
                            height: 28px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .gender-group {
            .custom-check {
                margin-top: 8px;

                input {
                    height: 16px;
                    width: 16px;
                    padding: 0;
                }
            }
        }

        input,
        select {
            height: 44px;
            padding: 10px 20px;
        }

        .btn {
            background: map.get($text-colors, 'default');
            color: #fff;
            width: 100%;
            padding: 10px;
            font-size: 1rem;
        }

        .group-link {
            font-size: 1rem;

            .forgot {
                padding-left: 10px;
            }

            a {
                padding-right: 10px;
                text-decoration: none;
            }
        }
    }
    .noti {
        font-size: 14px;
        text-align: center;
        color: #131313;
        font-weight: 400;
        span {
        }
        a {
            color: #05a;
        }
    }
}

#reset-password {
    .code {
        margin-top: 12px;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;

            &.color-brow {
                color: #131313;
            }
        }
    }

    form,
    .form {
        .error-text {
            font-size: 0.875rem;
            line-height: 20px;
            color: #ff0000;
            margin-top: 5px;
        }

        .label,
        label {
            font-size: 0.875rem;
            line-height: 20px;
            color: #131313;
            margin-bottom: 5px;
        }

        .input-radio {
            display: flex;
            align-items: center;

            span {
                font-size: 22px;
            }

            label {
                font-size: 1rem;
                color: #252525;
                margin-bottom: 0;
                margin-left: 10px;
            }

            input[type='radio'] {
                background-color: #fff;
                border-color: #252525;
                width: 18px;
                height: 18px;
                padding: 0;
                box-shadow: none;

                &:checked {
                    background-color: #252525;
                }
            }
        }

        .input {
            padding: 9px 14px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            outline: none;
            width: 100%;
            font-size: 0.875rem;
            height: 38px;

            &.error {
                border: 1px solid #ff0000;
            }

            &::placeholder {
                font-weight: normal;
                font-size: 0.875rem;
                line-height: 20px;
                color: #acacac;
            }
        }

        .btn {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .register {
            margin-top: 38px;
            font-weight: normal;
            font-size: 1rem;
            line-height: 20px;
            color: #6a433a;

            a {
                text-decoration: none;
                color: #ff6347;
            }
        }

        input,
        select {
            height: 44px;
            padding: 10px 20px;
        }
    }

    .btn {
        background: map.get($text-colors, 'default');
        color: #fff;
        width: 100%;
        padding: 10px;
        font-size: 1rem;
    }
}
@media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
    .account-page {
        margin-top: 24px;
        .title_sign_up {
            color: #131313;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
        .type_sign_up {
            margin-bottom: 16px;
        }
        .title-gg-fb {
            margin: 38px 0;
            margin-bottom: 10px;
        }
        form {
            .item-form {
                margin-bottom: 12px;
            }
        }
        .register {
            text-align: center;
        }
    }
}
// #reset-password {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//     background: #e5e5e57a;
//     z-index: 9999;
//     .main {
//         justify-content: center;
//         align-items: center;
//         height: 100%;
//         .wrap {
//             position: relative;
//             background: #fff;
//             padding: 30px 15px;
//             padding-top: 30px;
//             padding-bottom: 30px;
//             border-radius: 6px;
//             .close {
//                 position: absolute;
//                 top: 10px;
//                 right: 10px;
//                 border: 1px solid;
//                 background-color: #252525;
//                 width: 20px;
//                 height: 20px;
//                 border-radius: 50%;
//                 color: #fff;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 cursor: pointer;
//             }
//             form,
//             .form {
//                 .error-text {
//                     font-size: 0.875rem;
//                     line-height: 20px;
//                     color: #ff0000;
//                     margin-top: 5px;
//                 }
//                 .label,
//                 label {
//                     font-size: 0.875rem;
//                     line-height: 20px;
//                     color: #131313;
//                     margin-bottom: 5px;
//                 }

//                 .input-radio {
//                     display: flex;
//                     align-items: center;
//                     span {
//                         font-size: 1rem;
//                     }
//                     label {
//                         color: #252525;
//                         margin-bottom: 0;
//                         margin-left: 10px;
//                     }
//                     input[type="radio"] {
//                         background-color: #252525;
//                         border-color: #252525;
//                     }
//                 }
//                 .input {
//                     padding: 9px 14px;
//                     border: 1px solid #e0e0e0;
//                     border-radius: 4px;
//                     outline: none;
//                     width: 100%;
//                     font-size: 0.875rem;
//                     height: 38px;

//                     &.error {
//                         border: 1px solid #ff0000;
//                     }

//                     &::placeholder {
//                         font-weight: normal;
//                         font-size: 0.875rem;
//                         line-height: 20px;
//                         color: #acacac;
//                     }
//                 }
//                 .btn {
//                     height: 40px;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                 }
//                 .register {
//                     margin-top: 38px;
//                     font-weight: normal;
//                     font-size: 1rem;
//                     line-height: 20px;
//                     color: #6a433a;

//                     a {
//                         text-decoration: none;
//                         color: #ff6347;
//                     }
//                 }
//                 input,
//                 select {
//                     height: 44px;
//                     padding: 10px 20px;
//                 }
//             }
//             .btn {
//                 background: map.get($text-colors, "default");
//                 color: #fff;
//                 width: 100%;
//                 padding: 10px;
//                 font-size: 1rem;
//             }
//         }
//     }
// }
