@import "styles/variables.scss";

.rewardcoin {
   display: block;
   z-index: 1301;

   .MuiPaper-root {
      width: 100%;
      max-width: 407px;
      border-radius: 10px;
      background: #fff;
      margin: 16px;
      @include media-down(md) {
         border-radius: 28px;
      }
   }

   &__content {
      padding: 40px;
      @include media-down(md) {
         padding: 24px;
      }
   }

   &__head {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &__title {
         color: #252a2b;
         font-size: 20px;
         font-weight: 600;
         line-height: normal;
         letter-spacing: 0.2px;
         margin-bottom: 8px;
         @include media-down(md) {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
         }
      }
      .subtitle {
         color: #252a2b;
         text-align: center;
         font-size: 14px;
         line-height: 20px; /* 142.857% */
         @include media-down(md) {
            font-size: 14px;
         }
      }

      &__icon {
         width: 180px;
         height: 180px;
         object-fit: contain;
      }
      .coin-number {
         color: #ffc323;
         font-size: 18px;
         font-weight: 700;
         line-height: 16px; /* 88.889% */

         position: absolute;
         bottom: 28px;
         left: 50%;
         transform: translateX(-50%);
      }
   }

   &__action {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
         width: 100%;
         height: 52px;
         border: unset;
         text-align: center;
         font-size: 16px;
         font-weight: 600;
         line-height: 20px;
         border-radius: 100px;
         cursor: pointer;
         @include media-down(md) {
            font-size: 14.22px;
            height: 40px;
            border-radius: 40px;
         }
         &.other {
            color: #ff6347;
            border: 1px solid #ff6347;
            margin-right: 6px;
            background: #fff;
         }

         &.create-post {
            color: #fff;
            background: #ff6347;
            margin-left: 6px;
         }
      }
   }
}
