@use "sass:map";
@import "../../styles/variables.scss";

.spacet-main-footer {
   background-color: #ffffff;
   border-top: 2px solid #f4f4f4;

   &.custom-border-footer {
      border-top: 1px solid var(--gray-gray-400, #e2e4eb);
   }

   .top {
      padding: 24px 0px;

      .footer-grid {
         display: grid;
         grid-template-columns: 5.7fr 3.8fr 5fr 3fr 5.3fr;
         grid-gap: 12px;

         @include media-down(lg) {
            grid-template-columns: auto;
            grid-gap: 0;
         }
      }

      .app {
         li {
            a {
               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }
      }

      .info-icon {
         width: 24px;
         height: 24px;
         min-width: 24px;
         border-radius: 50%;
         background: #ff6347;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            width: 10px;
            height: auto;
         }
      }

      a {
         display: block;
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
         color: #252a2b;
         text-decoration: none;
         cursor: pointer;

         &:hover {
            text-decoration: underline;
         }
      }

      a.highlight {
         color: #e85a41;
         text-decoration: underline;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
      }

      a.no-highlight {
         color: map.get($text-colors, "default");
         max-width: 200px;
      }

      & > div {
         &:nth-child(1) {
            order: 1;
         }
         &:nth-child(2) {
            order: 2;
         }
         &:nth-child(3) {
            order: 3;
         }
         &:nth-child(4) {
            order: 4;
         }
         &:nth-child(5) {
            order: 5;
         }
         &:nth-child(6) {
            order: 6;
         }
      }

      .block {
         ul {
            li {
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
            }

            li:not(:last-child) {
               margin-bottom: 8px;
            }
         }
      }
      .block.spacet-social,
      .block.spacet-info {
         ul {
            li:not(:last-child) {
               margin-bottom: 12px;
            }
         }
      }

      .title_footer {
         font-weight: 700;
         font-size: 14px;
         line-height: 20px;
         margin-bottom: 8px;
         text-transform: uppercase;
      }

      .block-continue-images {
         ul:not(:last-child) {
            margin-bottom: 24px;
         }

         .socials {
            .socials-label {
               color: #252a2b;
               font-size: 14px;
               margin-left: 8px;
               line-height: 19px;
            }

            img {
               width: 24px;
               height: 24px;
            }

            .title {
               color: #252a2b;
               font-size: 14px;
               margin-left: 8px;
            }
         }
      }

      .block-download {
         max-width: 380px;
         @include media-down(lg) {
            border-bottom: 1px solid var(--gray-gray-400, #e2e4eb);
            padding-bottom: 16px;
         }

         .qr-code {
            height: 100%;
            padding: 4px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            border: 1px solid var(--gray-gray-400, #e2e4eb);
            background: #fff;

            img {
               width: 100%;
               height: 100%;
            }
         }

         .wrap-icon-download {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 8px;

            & > a {
               padding: 4px;
               border-radius: 4px;
               background: #fff;
               box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
               cursor: pointer;

               img {
                  width: 100%;
                  height: fit-content;
               }
            }
         }
      }
   }

   .spacet-social {
      @include media-down(lg) {
         border-bottom: 1px solid var(--gray-gray-400, #e2e4eb);
         padding-top: 14px;
         padding-bottom: 16px;
      }
      ul {
         @include media-down(lg) {
            display: flex;
            align-items: center;
            margin-bottom: 0;
         }
         li {
            @include media-down(lg) {
               margin-bottom: 0 !important;
               margin-right: 16px;
            }
         }
         .socials-label {
            @include media-down(lg) {
               display: none;
            }
         }
      }
   }

   .subfooter-grid {
      display: grid;
      grid-template-columns: 5.7fr 17.8fr;
      grid-gap: 12px;
      margin-top: 24px;
      @include media-down(lg) {
         display: flex;
         flex-direction: column;
         margin-top: 16px;
      }
      .subfooter-block {
         display: flex;
         flex-direction: column;
         @include media-down(lg) {
            border-bottom: 1px solid var(--gray-gray-400, #e2e4eb);
            padding-bottom: 16px;
            margin-bottom: 16px;
         }
         .title {
            color: var(--gray-gray-900, #252a2b);
            font-size: 14px;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            text-transform: uppercase;
            margin-bottom: 8px;
            @include media-down(lg) {
               margin-bottom: 14px;
            }
         }
         ul {
            list-style: none;
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            img {
               width: auto;
               height: 36px;
               margin-right: 8px;
            }
         }
      }
   }

   .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 24px 0px;
      border-top: 1px solid #e7e7e7;

      .title_company {
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
         margin-bottom: 8px;
         text-transform: uppercase;
      }

      p.description {
         color: #697f90;
         font-size: 12px;
         font-weight: 400;
         line-height: 18px;
         margin-bottom: 2px;
      }
   }

   .collapse-content {
      padding-top: 16px;
   }

   // Small devices (landscape phones, 576px and up)
   @media (max-width: map.get($grid-breakpoints-max-width, "md")) {
      .top {
         padding: 0 0;

         .block:not(:last-child) {
            margin-bottom: 24px;
         }

         .title_footer {
            margin-bottom: 14px;
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "lg")) {
   footer.spacet-main-footer {
      margin-bottom: 70px;

      .top {
         .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 12px;
         }

         .title-collapse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--gray-gray-900, #252a2b);
            font-size: 14px;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            text-transform: uppercase;
         }

         .block-continue-images {
            ul:not(:last-child) {
               margin-bottom: 12px;
            }
         }

         .block:not(:last-child) {
            margin-bottom: 16px;
         }

         .block-collapse {
            border-bottom: 1px solid #dddddd;
            padding: 14px 0px;
            margin-bottom: 0px !important;
         }

         .block-collapse:last-child {
            border-bottom: 1px solid #dddddd;
         }

         .row {
            margin-left: 0px;
            margin-right: 0px;
         }

         .block {
            margin-left: 0px;
            margin-right: 0px;

            ul {
               li:not(:last-child) {
                  margin-bottom: 12px;
               }
            }
         }
      }

      .bottom {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         align-items: flex-start;
         gap: 12px;
         border-top: none;
         padding: 16px 0;

         .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 12px;
         }
      }
   }
}
