@import "../../../styles/variables.scss";

// flash sale cart
.home-flash-sale {
   @include media-down(md) {
      margin-bottom: 0;
      margin-left: 0;
      padding: 0 12px;
   }
   &__head {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-down(md) {
         padding: 0;
      }
      &--left {
         display: flex;
         align-items: center;

         img {
            height: 24px;
            margin-right: 16px;
            @include media-down(md) {
               height: 16px;
               margin-right: 8px;
            }
         }

         svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            @include media-down(md) {
               width: 16px;
               height: 16px;
               margin-right: 4px;
            }
         }

         .title {
            margin-right: 24px;
            margin-bottom: 0;
            @include media-down(md) {
               margin-right: 16px;
            }
         }
      }
   }
   &__countdown {
      display: flex;
      align-items: center;
      margin: 0 -6px;
      @include media-down(md) {
         font-size: 8px;
      }
      div {
         width: 30px;
         height: 30px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 4px;
         background: #062a46;
         margin: 0 3px;

         color: #fff;
         font-size: 16px;
         font-weight: 500;
         line-height: 22px; /* 80% */
         letter-spacing: 0.4px;
         @include media-down(md) {
            font-size: 14px;
            width: 24px;
            height: 24px;
         }
      }
   }
   &__content {
      @include media-down(md) {
      }
   }
   .slick-list {
      @include media-down(md) {
         overflow: unset;
      }
   }
   // mobile
   .w-item {
      padding: 0 2px;
      @include media-down(md) {
         padding: 0 2px 16px;
      }
   }
}
