@use "sass:map";
@import "../../../styles/variables.scss";

main.home-shop-page {
   display: flex;
   flex-direction: column;
   background: #f5f5f5;

   .main-content {
      display: flex;
      flex-direction: column;

      @include media-down(md) {
         padding: 0;
      }
   }

   .user-card {
      padding: 0 16px;
      margin-top: -20px;
      z-index: 1;
      position: relative;

      .bg {
         width: 100%;
         height: 64px;
         object-fit: fill;
      }

      .user-info {
         .info-left {
            display: flex;
            align-items: center;
            position: absolute;
            top: 45%;
            left: 6%;
            bottom: 0;
            transform: translateY(-50%);

            .avatar {
               width: 34px;
               height: 34px;
               margin-right: 5px;
               border-radius: 50%;
               overflow: hidden;
               border-radius: 38px;
               border: 2px solid rgba(0, 0, 0, 0.05);

               img {
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                  object-position: center;
               }
            }

            .fullname {
               color: $text;
               text-align: center;
               font-size: 16px;
               font-weight: 500;
               line-height: 16px;
               /* 100% */
               margin-bottom: 4px;
               width: 100%;
               max-width: 120px;
               overflow: hidden;
               text-overflow: ellipsis;
            }

            .coin {
               color: #ff6347;
               font-size: 12px;
               font-weight: 600;
               line-height: normal;

               span {
                  color: $text;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: normal;
               }
            }
         }

         .coin-earn {
            position: absolute;
            top: 45%;
            left: 59%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
               width: 24px;
               height: 24px;
               margin-right: 5px;
            }

            .coin-label {
               color: rgba(0, 0, 0, 0.5);
               font-size: 11.24px;
               font-weight: 400;
               line-height: 12px;
               /* 106.762% */
               letter-spacing: -0.112px;
               margin-bottom: 8px;
            }

            button {
               color: #fff;
               text-align: center;
               font-size: 10px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               width: fit-content;
               height: 16px;
               border-radius: 0px 4px 4px 4px;
               background: #ff6347;
               border: unset;
               outline: unset;

               svg {
                  width: 5px;
                  height: 8px;
                  margin-left: 4px;
               }
            }
         }
      }

      .register-reward {
         .info-left {
            display: flex;
            align-items: center;
            position: absolute;
            top: 45%;
            left: 6%;
            bottom: 0;
            transform: translateY(-50%);

            .avatar {
               width: 34px;
               height: 34px;
               margin-right: 5px;
               border-radius: 50%;
               overflow: hidden;
            }

            .fullname {
               color: #252a2b;
               font-size: 12px;
               line-height: 16px;
               /* 133.333% */
               margin-bottom: 2px;
            }

            .coin {
               color: #ffc323;
               font-size: 14px;
               font-weight: 700;
               line-height: 16px;
               /* 114.286% */
            }
         }

         .coin-earn {
            position: absolute;
            top: 45%;
            left: 64%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: row;
            align-items: center;

            .btn-reward {
               color: #fff;
               text-align: center;
               font-size: 10px;
               font-weight: 500;
               line-height: normal;
               width: fit-content;
               min-width: 72px;
               height: 28px;
               border-radius: 4px;
               background: #ff6347;
               border: unset;
               outline: unset;

               svg {
                  margin-left: 4px;
               }
            }
         }
      }
   }

   .btn-link {
      font-size: 1.15rem;
      font-weight: 500;
      text-decoration: none;

      a {
         color: #000;
         text-decoration: none;
      }
   }

   .block_banner {
      padding: 18px 0 24px;
      position: relative;
      background: #ffffff;
      @include media-down(md) {
         padding: 0 0 34px;
      }
      .container_custom {
         padding: 0;
         padding-top: 16px;
      }
      .page-banner {
         @include media-down(md) {
            aspect-ratio: unset;
         }
      }
   }

   .viewall {
      color: #1a73ab;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      align-items: center;

      @include media-down(md) {
         color: #697f90;
         font-size: 12.64px;
      }

      svg {
         width: 16px;
         height: 16px;

         @include media-down(md) {
            width: 12px;
            height: 12px;
            margin-left: 4px;
         }
      }
   }

   .flash-sale-init {
      height: 334px;

      @include media-down(md) {
         height: 206px;
      }
   }

   .catalog {
      padding: 48px 0 24px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      @include media-down(md) {
         overflow-x: auto;
         overflow-y: hidden;
         margin-bottom: 24px;
         padding: 0 0 0 16px;
         border-bottom: unset;
      }

      .catalog-wrap {
         .slick-slider {
            .slick-arrow {
               width: 48px;
               height: 48px;
               background: rgba(0, 0, 0, 0.49);
               border-radius: 50%;
               z-index: 11;

               &::before {
                  content: none;
               }

               &.slick-prev {
                  left: -18px;
                  background-image: url(/assets/img/icon/prev.svg);
                  background-repeat: no-repeat;
                  background-size: auto;
                  background-position: center;
                  //    transform: rotate(180deg);
                  //    top: 25%;
               }

               &.slick-next {
                  right: -18px;
                  background-image: url(/assets/img/icon/next.svg);
                  background-repeat: no-repeat;
                  background-size: auto;
                  background-position: center;
               }
            }
         }

         .item {
            color: map.get($text-colors, "default");
            text-decoration: none;
            padding: 0 16px;
            z-index: 10;
            padding: 8px 4px;

            img {
               width: 65px;
               height: 65px;
               object-fit: cover;
            }

            .des {
               font-size: 16px;
               white-space: nowrap;
               text-align: center;
               margin: 0;
               margin-top: 12px;
            }
         }
      }
   }

   .collection {
      padding-top: 50px;

      .collection-wrap {
         display: grid;
         grid-template-columns: repeat(4, 1fr);
         grid-template-rows: repeat(2, 1fr);
         gap: 10px;

         .collection-item {
            overflow: hidden;
            cursor: pointer;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }

         .item1 {
            grid-area: 1/1/2/3;
         }

         .item2 {
            grid-area: 1/3/3/5;
         }
      }
   }

   .title {
      color: $text;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      /* 120% */
      margin-bottom: 0;

      @include media-down(md) {
         font-size: 14.22px;
      }
   }

   .cart-item {
      position: relative;

      .cover {
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
      }

      .item-img-hover {
         position: relative;
         width: 100%;
         --bs-aspect-ratio: 125.1%;

         .img_best_seller {
            // z-index: 100;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      .rule-tag {
         position: absolute;
         top: 0px;
         right: 0px;
         z-index: 1000;
         width: 35px;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      .item-detail {
         p {
            font-size: 1rem;
            font-weight: 700;
            color: #009999;
            padding-top: 10px;
            margin: 0;

            .real-price {
               font-size: 0.875rem;
               font-weight: 500;
               color: #000;
               padding-left: 10px;
               text-decoration: line-through;
            }
         }

         .price {
            font-size: 1rem;
            font-weight: 700;
            color: #009999;
            padding-top: 10px;
            margin: 0;

            .real-price {
               font-size: 0.875rem;
               font-weight: 500;
               color: #000;
               padding-left: 10px;
               text-decoration: line-through;
            }
         }

         .title-name {
            // a {
            font-size: 1rem;
            line-height: 24px;
            text-decoration: none;
            color: map.get($text-colors, "default");
            padding: 4px 0;
            cursor: pointer;
            // }
         }
      }
   }

   .best-seller {
      padding: 16px;
      margin-bottom: 40px;
      background-color: #fff;

      @include media-down(md) {
         border-bottom: 8px solid #f5f5fa;
         margin-bottom: 0;
         padding-bottom: 32px;
      }

      &__head {
         margin-bottom: 24px;
         display: flex;
         align-items: center;
         justify-content: space-between;

         @include media-down(md) {
            margin-bottom: 16px;
            // padding: 16px 16px 0 16px;
         }

         &--left {
            display: flex;
            align-items: center;

            svg {
               width: 24px;
               height: 24px;
               margin-right: 8px;
            }

            .title {
               margin-right: 24px;
               margin-bottom: 0;
            }
         }
      }

      &__content {
         // @include media-down(md) {
         //    padding-left: 16px;
         //    padding-right: 16px;
         // }
      }

      @include media-down(md) {
         .slick-dots {
            width: 100%;
            max-width: 50px;
            height: 4px;
            background-color: #f0f0f0;
            border-radius: 8px;
            display: flex !important;
            position: absolute;
            left: 50%;
            bottom: -14px;
            transform: translateX(-50%);

            li {
               width: 100%;
               height: fit-content;
               margin: 0;
               padding: 0;
               opacity: 0;

               button {
                  width: 100%;
                  height: 4px;
                  background: #004e83;
                  padding: 0;
                  margin: 0;
                  border-radius: 8px;

                  &::before {
                     display: none;
                  }
               }

               &.slick-active {
                  opacity: 1;
               }
            }
         }

         .w-item {
            padding-right: 12px;
         }

         .card-item {
            display: flex;

            .wrap-img {
               width: 72px;
               aspect-ratio: 1;
               margin-right: 8px;
               border: 1px solid #e2e4eb;
            }

            .product-item-detail {
               h3 {
                  color: $text;
                  font-size: 11.24px;
                  font-weight: 400;
                  line-height: 16px;
                  letter-spacing: -0.112px;
                  margin-bottom: 6px;
               }

               .price-wrap {
                  display: flex;
                  flex-direction: column;
               }

               .price,
               .discount-price {
                  color: #ff6347;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 16px;
                  /* 100% */
                  margin-right: 3px;
                  white-space: nowrap;
                  margin-bottom: 6px;
                  font-size: 14.22px;
                  margin-bottom: 3px;
               }

               .wrap-real-price {
                  display: flex;
                  align-items: center;
               }

               .real-price {
                  color: #9f9f9f;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 12px;
                  /* 100% */
                  white-space: nowrap;
                  text-decoration-line: strikethrough;
                  text-decoration: line-through;
                  margin-right: 8px;
                  font-size: 11px;
                  margin-right: 8px;
               }

               .percent-price {
                  color: #ff383b;
                  font-size: 12px;
                  font-weight: 600;
                  width: 35px;
                  height: 16px;
                  display: block;
                  white-space: nowrap;
                  border-radius: 4px;
                  background: #ffe6e8;

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 8px;
                  width: 24px;
                  border-radius: 0 4px 4px 0;
                  position: relative;

                  &::after {
                     content: "";
                     position: absolute;
                     top: 0;
                     left: -5px;
                     border-top: 8px solid transparent;
                     border-bottom: 8px solid transparent;
                     border-right: 6px solid #ffe6e8;
                  }
               }
            }
         }
      }
   }

   .idea {
      .idea-img {
         padding: 10px 0;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
   }

   .brand {
      padding: 16px;
      margin-bottom: 40px;
      background-color: #fff;

      @include media-down(md) {
         border-bottom: 8px solid #f5f5fa;
         margin-bottom: 0;
         overflow: hidden;
      }

      &__head {
         margin-bottom: 24px;
         display: flex;
         align-items: center;
         justify-content: space-between;

         @include media-down(md) {
            margin-bottom: 16px;
            // padding-left: 16px;
            // padding-right: 16px;
            // padding-top: 16px;
         }
      }

      &__content {
         @include media-down(md) {
            // padding: 0 16px 16px;
            margin: 0 -6px;
         }
      }

      .slick-slider {
         .slick-arrow {
            width: 48px;
            height: 48px;
            top: 37%;
            // background: rgba(0, 0, 0, 0.49);
            border-radius: 50%;
            z-index: 11;

            @include media-down(md) {
               display: none !important;
            }
         }

         &::before {
            content: none;
         }

         &.slick-prev {
            top: 40%;
            left: -23px;
            background-image: url(/assets/img/icon/prev.svg);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center;
            // transform: rotate(180deg);
            // top: 33%;
         }

         &.slick-next {
            top: 40%;
            right: -23px;
            background-image: url(/assets/img/icon/next.svg);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center;
         }

         .slick-list {
            margin-right: -3px;
         }
      }

      .wrap-item {
         .brand-item {
            padding-bottom: 16px;

            @include media-down(md) {
               padding: 8px;
               margin: 0 6px;
               border-radius: 4px;
               border: 1px solid rgba(0, 0, 0, 0.05);
               background: #fff;
            }

            .brand-img {
               display: block;
               flex: 1;
               height: 0;
               padding-bottom: 66%;
               position: relative;
               overflow: hidden;
               border-radius: 0;
               background: #fff;
               position: relative;

               @include media-down(md) {
                  border: unset;
               }

               &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  z-index: 1;

                  width: 100%;
                  height: 100%;
                  border: 1px solid rgba(0, 0, 0, 0.08);
                  display: block;

                  @include media-down(md) {
                     display: none;
                  }
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  padding: 20px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
               }
            }

            .cover {
               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
            }
         }
      }

      .item-detail {
         text-align: center;
         padding-top: 16px;

         @include media-down(md) {
            margin-top: 8px;
            padding-top: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
         }

         a {
            color: $text;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            letter-spacing: -0.14px;
            text-decoration: none;
            color: map.get($text-colors, "default");
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
         }
      }
   }

   // Suggest secction
   .suggest {
      padding: 16px;
      background: #fff;
      @include media-down(md) {
         padding: 16px 0;
      }
      .suggest-head {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 24px;

         @include media-down(md) {
            // padding-top: 16px;
            // padding-left: 16px;
            // padding-right: 16px;
            padding: 0 16px 0;
            margin-bottom: 0;
         }
      }

      ul.suggest-cate {
         position: sticky;
         top: $MENU-HEIGHT_MOBILE;
         z-index: 2;
         display: flex;
         align-items: center;
         padding: 0;
         margin: 0;
         background: #fff;
         overflow-x: auto;
         overflow-y: hidden;
         @include media-down(md) {
            padding: 0 16px 4px;
         }
         &::-webkit-scrollbar {
            display: none;
         }

         // @include media-down(md) {
         //    padding-left: 16px;
         //    padding-right: 16px;
         // }

         li {
            list-style: none;
            margin-right: 14px;

            a {
               color: #9f9f9f;
               font-size: 14.22px;
               font-weight: 400;
               line-height: 16px;
               /* 112.518% */
               letter-spacing: -0.142px;
               white-space: nowrap;

               height: 42px;
               position: relative;

               display: flex;
               align-items: center;
            }
         }

         li.active {
            a {
               color: $primary;
               font-weight: 600;

               &::after {
                  content: "";
                  position: absolute;
                  bottom: 3px;
                  left: 50%;
                  transform: translateX(-50%);
                  height: 3px;
                  width: 16px;
                  border-radius: 10px;
                  background-color: $primary;
               }
            }
         }
      }

      .suggest-list {
         // padding-bottom: 24px;

         @include media-down(md) {
            // padding-left: 16px;
            // padding-right: 16px;
            // padding-bottom: 20px;
            padding: 8px 16px 0;
            background: $bg;
         }
      }
   }

   .see-more {
      color: #ff6347;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      /* 114.286% */
      letter-spacing: -0.14px;
      width: fit-content;
      height: 40px;
      min-width: 111px;
      border-radius: 8px;
      border: 1px solid #ff6347;
      background: #fff;
      margin: 24px auto 24px;
      transition: 0.2s;

      &:hover {
         color: #fff;
         background: $primary;
      }
   }

   .wrap-loading-icon {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints-max-width, "md")) {
      .catalog {
         .catalog-list {
            margin-top: 12px;
            background: #cccccc;
            padding: 0px;
            // grid-template-columns: repeat(4, 1fr);

            .catalog-item {
               background: #fff;
               padding: 8px 4px;

               &__name {
                  font-weight: 400;
                  font-size: 11.24px;
                  line-height: 16px;
                  white-space: unset;
               }
            }
         }

         .catalog-wrap {
            margin: 0;

            .catalog-item {
               .item {
                  padding: 0 5px;
               }
            }
         }
      }
   }
}

.catalog-list {
   &.list {
      padding: 1px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include media-down(md) {
         justify-content: flex-start;
      }
   }

   &.slider {
      .slick-dots {
         display: flex;
         align-items: center;
         justify-content: center;
         bottom: -32px;

         ul {
            display: flex;
            align-items: center;
            height: 32px;
            padding: unset;

            li {
               width: 12px;
               margin: 0;

               button {
                  width: 12px;

                  &::before {
                     width: 12px;
                  }
               }
            }
         }

         .arrow {
            width: 32px;
            height: 32px;
            cursor: pointer;
         }
      }
   }

   .catalog-item {
      min-width: calc(120px + 17px);
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-user-drag: none;

      @include media-down(md) {
         min-width: 64px;
      }

      .img {
         width: 80px;
         height: 80px;
         border-radius: 50%;
         background-color: #f0f3f9;
         margin-bottom: 8px;
         display: flex;
         align-items: center;
         justify-content: center;

         @include media-down(md) {
            width: 32px;
            height: 32px;
         }

         img {
            width: 80%;
            height: auto;
         }
      }

      &__name {
         color: $text;
         font-size: 16px;
         font-weight: 400;
         line-height: 20px;
         /* 125% */
         letter-spacing: 0.064px;
         text-align: center;
         margin: 0;
         // max-width: 76%;
      }
   }
}

.catalog-list-auto-wrap {
   .catalog-item {
      // padding: 16px;
      min-width: 120px;
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-down(md) {
         min-width: 64px;
      }

      .img {
         width: 80px;
         height: 80px;
         border-radius: 50%;
         background-color: #f0f3f9;
         margin-bottom: 8px;
         display: flex;
         align-items: center;
         justify-content: center;

         @include media-down(md) {
            width: 48px;
            height: 48px;
         }

         img {
            width: 80%;
            height: auto;
            font-size: 12px;
         }
      }

      &__name {
         color: $text;
         font-size: 16px;
         font-weight: 400;
         line-height: 20px;
         /* 125% */
         letter-spacing: 0.064px;
         // white-space: nowrap;
         text-align: center;
         max-width: 76%;

         @include media-down(md) {
            font-size: 11.24px;
            font-weight: 400;
            line-height: 12px;
            /* 106.762% */
            letter-spacing: 0.112px;
         }
      }
   }
}

.content_end_page_shop {
   padding-top: 16px;
   background: $bg;
   // border-top: 1px solid rgba(0, 0, 0, 0.05);

   .content_text {
      padding-bottom: 28px;
      border-bottom: 1px solid #e0e0e0;

      .content_item {
         &.content_item_custom {
            margin-bottom: 12px;
         }

         &:not(:last-child) {
            margin-bottom: 24px;
         }

         .content_title {
            color: $text;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            /* 118.75% */
            text-transform: uppercase;
            padding: 16px 0;
            // margin-bottom: 12px;

            &.title_shop {
               font-size: 16px;
               line-height: 19px;
            }
         }

         .content_subtitle {
            color: $text;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
         }

         ul.content_cate_list {
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-bottom: 4px;

            li {
               list-style: none;
               padding: 6px 0;
               margin: 0 8px;

               &:first-child {
                  margin-left: 0;
               }

               a {
                  color: $text;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  /* 133.333% */
               }
            }
         }

         .content_description {
            color: rgba(37, 42, 43, 0.7);
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            /* 116.667% */

            a {
               color: #0055aa;
               text-decoration: underline;
            }

            div {
               margin-top: 6px;
            }

            .btn_see_more {
               display: none;
            }
         }
      }

      .child_item {
         font-size: 12px;
         line-height: 14px;
         margin-bottom: 12px;
         color: #565656;

         .title_child {
            font-weight: 700;
            margin-bottom: 5px;

            a {
               &:hover {
                  color: #0055aa;
                  text-decoration: underline;
               }
            }
         }

         .des_child {
            font-weight: 400;
         }

         &.child_item_custom {
            margin-bottom: 24px !important;
         }
      }
   }

   .category_list {
      padding-top: 24px;
      padding-bottom: 12px;

      .category_title {
         font-size: 16px;
         line-height: 19px;
         font-weight: 700;
         color: $text;
         margin-bottom: 16px;
      }

      .cate_lv2 {
         width: 20%;
         color: #565656;

         .title_cate_lv2 {
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
         }

         .wrapper_lv3 {
            display: flex;
            flex-wrap: wrap;

            .title_cate_lv3 {
               font-size: 12px;
               line-height: 14px;
               font-weight: 400;
            }
         }
      }

      .btn_see_less {
         display: none;
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, "md")) {
      margin-top: 24px;

      .content_text {
         &.see_more {
            padding-bottom: 0;
            border-bottom: none;
         }

         &.see_less {
            padding-bottom: 16px;
            border-bottom: 1px solid #e0e0e0;
         }

         .more_content {
            &.see_more {
               display: none;
            }

            &.see_less {
               display: block;
            }
         }

         .content_item {
            &.content_item_custom {
               margin-bottom: 0px;
            }

            .content_description {
               margin-bottom: 0px;

               .btn_see_more {
                  display: unset;
                  color: #ff6347;
                  cursor: pointer;

                  &.see_more {
                     &::before {
                        content: "...";
                        color: #000;
                     }
                  }
               }

               .btn_hide_see_more {
                  display: BLOCK;
                  padding: 12px 12px 0;
                  text-align: center;
                  color: #ff6347;
                  cursor: pointer;

                  &.see_more {
                     &::before {
                        content: "...";
                        color: #000;
                     }
                  }
               }
            }
         }
      }

      .category_list {
         &.see_more {
            display: none;
         }

         &.see_less {
            display: block;
         }

         .cate_lv2 {
            width: 50%;
         }

         .btn_see_less {
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            display: unset;
            color: #ff6347;
            cursor: pointer;
         }
      }
   }
}
