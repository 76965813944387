@use "sass:map";
@import "styles/variables.scss";

.magazine-page2 {
   padding: 20px 0px;
   background-color: #f5f5f5;
   @include media-down(md) {
      padding-top: 0;
      padding-bottom: $NAVIGATOR_HEIGHT;
   }

   .row-custom {
      display: flex;
      flex-wrap: nowrap;
      // overflow: hidden;
      @include media-down(md) {
         // padding: 16px;
         margin: 0 -16px;
         border-top: 8px solid $border;
         background-color: #fff;
      }
      // [data-type="1"] {
      //    position: sticky;
      //    top: 80px;
      // }
      .block-left {
         width: 100%;
         max-width: calc(100% - 380px);
         @include media-down(md) {
            max-width: 100%;
         }
      }
      .block-right {
         width: 380px;
         min-width: 380px;
         margin-left: 24px;
      }
   }
   .contact-custom {
      .container_custom {
         padding-left: 0px;
         padding-right: 0px;
         display: flex;
         justify-content: center;

         .contact-wrap {
            // width: 65%;
            justify-content: unset;
            width: 100%;
            .contact-img {
               display: none;
            }

            .contact-content {
               width: 100%;
               justify-content: space-around;

               .title_custom {
                  padding-right: 20px;
                  margin-left: 0;
                  font-size: 20px !important;
               }

               .btn-contact {
                  a {
                     font-size: 20px;
                  }
               }
            }
         }
      }

      @media (max-width: map.get($grid-breakpoints, "xl")) {
         .container_custom {
            .contact-wrap {
               .contact-img {
               }

               .contact-content {
                  .title_custom {
                     font-size: 17px !important;
                     padding-right: 0;
                  }

                  .btn-contact {
                     a {
                        font-size: 17px;
                     }
                  }
               }
            }
         }
      }

      @media (max-width: 768px) {
         // margin: 0 12px;
         .container_custom {
            // display: unset;
            .contact-wrap {
               // width: unset;
               .contact-content {
                  margin: 0 12px;
               }
            }
         }
      }
   }

   .interior-title {
      .title {
         font-weight: 500;
         font-size: 2rem;
         line-height: 28px;
         text-align: center;
         margin: 50px auto;
      }
   }

   .slider {
      .slider-wrap {
         position: relative;

         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
         }

         img {
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
   }

   .interior {
      .interior-wrap {
         border-bottom: 1px solid #ededed;
         padding-bottom: 40px;

         .text-block-view {
            &.mr-0 {
               margin: 0;
            }

            display: flex;
            align-items: center;
            margin: 18px 0;

            span {
               width: 2px;
               height: 2px;
               top: 6.5px;
               background: #252a2b;
               opacity: 0.7;
            }

            .des-item {
               margin: 0 12px;
               display: flex;
               align-items: center;
               gap: 4px;
               color: #666666;

               p {
                  font-size: 16px;
                  line-height: 20px;
                  color: #666666;
               }

               img {
                  width: 12px;
                  height: 12px;
                  object-fit: contain;
               }
            }

            .item-ml-0 {
               margin-left: 0 !important;
            }
         }

         .interior-top {
            display: grid;
            grid-template-columns: 60% 40%;
            grid-template-rows: auto;

            .interior-top-img {
               position: relative;

               .cover {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 10;
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .interior-top-text {
               border: 1px solid #000;
               display: flex;
               background: #fff;
               padding: 30px;
               flex-direction: column;
               transform: scale(1.02);
               box-shadow: -2px 4px 13px -7px #858585;

               .btn-post {
                  width: 10rem;
                  background: map.get($text-colors, "default");
                  border-radius: 104px;
                  margin-bottom: 40px;

                  a {
                     text-decoration: none;
                     color: #fff;
                     font-size: 1.25rem;
                  }
               }

               .title {
                  font-size: 30px;
                  line-height: 30px;
                  text-align: left;
                  margin-bottom: 0;
               }

               .btn-sm {
                  font-size: 18px;
                  text-align: left;
                  line-height: 1.5;

                  a {
                     color: map.get($text-colors, "default");
                  }
               }
            }
         }

         .interior-top-reverse {
            grid-template-columns: 40% 60%;

            .interior-top-img {
               grid-area: 1/2/2/3;
            }
         }

         .interior-bottom {
            margin-top: 35px;
            position: relative;
            overflow: hidden;

            .cover {
               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
               z-index: 10;
            }

            .interior-bottom-item {
               margin-bottom: 0px;

               .item-img {
                  flex: 1;
                  height: 0;
                  padding-bottom: 55%;
                  position: relative;
                  overflow: hidden;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     transform: translate(-50%, -50%);
                  }
               }

               .item-tag {
                  display: inline-block;
                  max-width: 100%;
                  margin: 0 auto;
                  padding: 0.375rem 0.75rem;
                  font-size: 0.875;
                  position: absolute;
                  margin-top: -25px;
                  background: map.get($text-colors, "default");
                  text-align: center;
                  cursor: pointer;
                  transform: translate(0.3s);

                  a {
                     text-decoration: none;
                     white-space: nowrap;
                     color: white;
                  }

                  &:hover {
                     opacity: 0.5;
                     transform: translate(0.3s);
                  }
               }

               .item-title {
                  margin-bottom: 12px;
                  margin-top: 15px;
                  font-weight: bold;
                  text-transform: none;
                  letter-spacing: 0;
                  // height: 65px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;

                  a {
                     text-align: left;
                     font-size: 1.5rem;
                     color: map.get($text-colors, "default");
                     text-decoration: none;
                  }
               }

               .item-meta {
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  gap: 10px;

                  span {
                     font-size: 0.875rem;
                  }
               }

               p.des {
                  height: 75px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
               }
            }
         }
      }
   }

   .btn-link {
      font-size: 1.15rem;
      font-weight: 500;
      text-decoration: none;

      a {
         color: #000;
         text-decoration: none;
      }
   }

   .ebook {
      .ebook-item {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         margin-bottom: 30px;

         a {
            text-decoration: none;
            color: map.get($text-colors, "default");
         }

         .link-img {
            position: relative;

            .cover {
               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
               z-index: 10;
            }
         }

         img {
            width: 100%;
            height: 100%;
            padding: 0 25%;
            object-fit: cover;
         }

         .title {
            font-size: 1.5rem;
            text-align: center;
            font-weight: bold;
            margin: 20px 0;
         }

         .btn-ebook {
            background: map.get($text-colors, "default");

            a {
               color: #fff;
               text-decoration: none;
            }
         }
      }
   }
}

.magazine-detail-page2 {
   .detail {
      .container_blog {
         width: 65%;
      }

      .brow {
         color: #765953;
      }

      .detail-img {
         flex: 1;
         height: 0;
         padding-bottom: 55%;
         position: relative;
         overflow: hidden;
         border-radius: 16px 16px 0 0;

         @include media-down(md) {
            border-radius: 0;
         }

         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            // z-index: 10;
         }

         .img {
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }

      .detail-info {
         position: relative;
         padding: 0 24px;
         padding-right: 66px;
         @include media-down(md) {
            padding: 0 16px;
         }
         .title {
            padding: 0;
            margin-bottom: 24px;
            @include media-down(md) {
               margin-bottom: 16px;
            }
            h1 {
               font-size: 46px;
               line-height: normal;
               text-align: left;
               margin: 0;
               @include media-down(md) {
                  font-size: 22px;
                  font-weight: 700;
                  line-height: 24px;
               }
            }
         }

         .info {
            padding: 20px 0;
            display: flex;
            align-items: center;
            position: relative;
            @include media-down(md) {
               padding: 16px 0;
            }
            .gray {
               font-size: 12.64px;
               font-weight: 400;
               color: #9f9f9f;
            }
            .viewcount {
               font-size: 12.64px;
               padding-left: 16px;
               margin-left: 16px;
               border-left: 1px solid #9f9f9f;
               display: flex;
               align-items: center;
               svg {
                  margin-right: 3px;
               }
            }
            .share_icon {
               position: absolute;
               right: 0;
               top: 50%;
               transform: translateY(-50%);
               width: 24px;
               height: 24px;
            }
         }

         .btn-wrap {
            display: flex;
            align-items: center;
            @include media-down(md) {
               margin: 0 -4px;
            }
            .btn {
               height: 40px;
               padding: 3px 12px;
               border-radius: 4px;
               border: 1px solid var(--gray-gray-400, #e2e4eb);
               font-size: 14px;
               font-style: normal;
               font-weight: 400;
               margin-right: 16px;
               display: flex;
               flex-wrap: nowrap;
               align-items: center;
               justify-content: center;
               @include media-down(md) {
                  width: 100%;
                  margin: 0 4px;
               }
               svg,
               img {
                  width: 16px;
                  height: auto;
                  margin-right: 4px;
                  line-height: 1;
               }
               span {
                  line-height: 1;
               }
               .count {
                  padding-left: 12px;
                  margin-left: 12px;
                  border-left: 1px solid var(--gray-gray-500, #9f9f9f);
                  @include media-down(md) {
                     border-left: unset;
                     padding-left: unset;
                     margin-left: 6px;
                  }
               }
            }
         }
      }

      .right_action_absolute {
         position: absolute;
         right: 24px;
         top: 24px;
         display: flex;
         flex-direction: column;
         row-gap: 8px;
         & > div {
            width: 32px;
            height: 32px;
            cursor: pointer;
         }
      }
      .w_action_icon.facebook {
         width: 32px;
         height: 32px;
         border-radius: 50%;
         display: flex;
         align-items: center;
         justify-content: center;
         text-align: center;
         position: relative;

         img,
         svg {
            width: 100%;
            height: 100%;
         }
         .popup-copy {
            position: absolute;
            visibility: hidden;
            width: 160px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 8px 0;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 50%;
            margin-left: -80px;

            &.show-popup {
               visibility: visible !important;
               -webkit-animation: fadeIn 1s;
               animation: fadeIn 1s;
            }

            &::after {
               content: "";
               position: absolute;
               bottom: 100%;
               left: 50%;
               margin-left: -5px;
               border-width: 5px;
               border-style: solid;
               border-color: transparent transparent #555 transparent;
            }
         }

         @-webkit-keyframes fadeIn {
            from {
               opacity: 0;
            }

            to {
               opacity: 1;
            }
         }

         @keyframes fadeIn {
            from {
               opacity: 0;
            }

            to {
               opacity: 1;
            }
         }
      }
      .w_action_icon.copy {
         width: 32px;
         height: 32px;
         border-radius: 50%;
         display: flex;
         align-items: center;
         justify-content: center;
         text-align: center;
         position: relative;
         border: 1px solid rgba(0, 0, 0, 0.3);

         img,
         svg {
            width: 55%;
            height: 55%;
         }
         .popup-copy {
            position: absolute;
            visibility: hidden;
            width: 160px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 8px 0;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 50%;
            margin-left: -80px;

            &.show-popup {
               visibility: visible !important;
               -webkit-animation: fadeIn 1s;
               animation: fadeIn 1s;
            }

            &::after {
               content: "";
               position: absolute;
               bottom: 100%;
               left: 50%;
               margin-left: -5px;
               border-width: 5px;
               border-style: solid;
               border-color: transparent transparent #555 transparent;
            }
         }

         @-webkit-keyframes fadeIn {
            from {
               opacity: 0;
            }

            to {
               opacity: 1;
            }
         }

         @keyframes fadeIn {
            from {
               opacity: 0;
            }

            to {
               opacity: 1;
            }
         }
      }

      .detail-info-mb {
         margin-top: 16px;

         .title {
            padding: 0;
            margin-bottom: 20px;
            @include media-down(md) {
            }
            h1 {
               font-size: 1.875rem;
               text-align: left;
               margin: 0;
            }
         }

         .info {
            display: flex;
            align-items: center;
            .gray {
               font-size: 12.64px;
               font-weight: 400;
               color: #9f9f9f;
            }
            .viewcount {
               font-size: 12.64px;
               padding-left: 16px;
               margin-left: 16px;
               border-left: 1px solid #9f9f9f;
               svg {
                  margin-right: 3px;
               }
            }
         }

         .title h2 {
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            margin-top: 16px;
         }

         .btn-wrap {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .btn-fb,
            .btn-copy,
            .btn-bm,
            .btn-like {
               cursor: pointer;
               width: 25px;
               height: 26px;
               margin-right: 30px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .btn-copy {
               position: relative;

               .popup-copy {
                  position: absolute;
                  visibility: hidden;
                  width: 160px;
                  background-color: #555;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 8px 0;
                  position: absolute;
                  z-index: 1;
                  top: 130%;
                  left: 50%;
                  margin-left: -80px;

                  &.show-popup {
                     visibility: visible !important;
                     -webkit-animation: fadeIn 1s;
                     animation: fadeIn 1s;
                  }

                  &::after {
                     content: "";
                     position: absolute;
                     top: -24%;
                     left: 50%;
                     margin-left: -5px;
                     border-width: 5px;
                     border-style: solid;
                     border-color: transparent transparent #555 transparent;
                  }
               }

               @-webkit-keyframes fadeIn {
                  from {
                     opacity: 0;
                  }

                  to {
                     opacity: 1;
                  }
               }

               @keyframes fadeIn {
                  from {
                     opacity: 0;
                  }

                  to {
                     opacity: 1;
                  }
               }
            }
         }
      }

      .title {
         text-align: center;
         padding: 20px 0;
      }

      .detail-category {
         p {
            margin: 0;
         }
      }
   }

   .post-wrap {
      margin: 50px auto;

      img {
         display: block;
         width: 100% !important;
         object-fit: contain !important;
      }

      table {
         margin: 0 !important;

         tbody {
            tr {
               td {
                  padding: 20px;
                  width: 50% !important;
               }
            }
         }
      }

      p {
         span {
            a {
               img {
                  display: none;
               }
            }
         }
      }
   }

   .related-post {
      .title {
         position: relative;

         &::before {
            position: absolute;
            content: "";
            width: 80px;
            height: 3px;
            border-radius: 100px;
            background: #252a2b;
            top: 35px;
         }
      }
   }

   .tag-post {
      .tag {
         text-align: center;
         background-color: #e4e4e4;
         padding: 10px 10px;
         margin: 30px 5px;
         white-space: nowrap;
      }
   }

   .ebook-detail {
      padding: 0px 0px 20px;

      h2 {
         font-size: 3rem;
         font-weight: bold;
      }

      p {
         font-size: 1.25rem;
         line-height: 2rem;
         padding-top: 20px;
      }

      .detail-img {
         flex: 1;
         height: 100%;
         width: 100%;
         // padding-bottom: 156%;
         position: relative;

         // overflow:hidden;
         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 60px;
            // position: absolute;
            // top:50%;
            // left:50%;
            // transform:translate(-50%,-50%);
         }
      }

      .form {
         padding-top: 20px;

         .title {
            font-size: 1.5rem;
         }

         form {
            padding: 20px;
            background: #ddd;

            label {
               font-weight: 500;
            }

            input,
            select {
               height: 44px;
               border-radius: unset;
            }

            .btn {
               background: #252a2b;
               border-radius: unset;
               border: 1px solid #000;
               color: #fff;
               padding: 10px 20px;
               font-size: 1.25rem;
            }
         }
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      .post-wrap {
         margin: 30px auto !important;
      }
   }

   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .detail {
         .container_blog {
            width: 100%;
         }
      }
   }
}

// Medium devices (tablets, 768px and up)
@media (max-width: map.get($grid-breakpoints, "md")) {
   .magazine-page2 {
      .interior-title {
         .title {
            margin-top: 30px;
            margin-bottom: 20px;
         }
      }

      .ebook {
         .ebook-item {
            margin-bottom: 20px;
         }
      }
      .row {
         // max-width: 100%;
         table {
            max-width: 100%;
         }
      }
   }
}

// Large devices (desktops, 992px and up)
@media (max-width: map.get($grid-breakpoints, "lg")) {
   .magazine-page2 {
      .interior {
         .interior-wrap {
            .interior-top {
               display: grid;
               grid-template-columns: auto;
               grid-template-rows: auto auto;

               .interior-top-img {
                  grid-area: unset;
               }
            }

            // .interior-top-reverse {
            //     grid-template-columns: 40% 60%;
            // }
         }
      }
   }
}
