@import "../../../styles/variables.scss";

.spacet-shop-catalog {
   overflow: hidden;
   background-color: #fff;
   // border-top: 1px solid rgba(0, 0, 0, 0.05);
   @include media-down(md) {
      background: #f5f5fa;
   }
   .cate-title {
      font-size: 18px;
      padding: 8px 16px 12px;
   }
   .product-shop-container {
      max-width: calc(1200px + 32px);
      padding: 0 16px;
      margin: 0 auto;
      @include media-down(md) {
         padding: 0 0;
      }
   }
   .wrap-breadcrumbs {
      padding: 20px 0;
   }
   .cate-list {
      padding-bottom: 32px;
      display: flex;
      justify-content: center;
      .catalog-list {
         a {
            width: 144px;
            min-width: 144px;
         }
      }
   }
   .cate-list-mb {
      @include media-down(md) {
         // padding: 16px 16px;
         margin: 0 -12px;
         display: flex;
         flex-direction: column;
         background-color: #fff;
         .cate-title {
            color: #252a2b;
            font-size: 14.22px;
            font-weight: 600;
            line-height: 16px; /* 112.518% */
            padding: 16px 0;
            margin-bottom: 0;
         }
      }
   }

   .wrap-select {
      .product-sort {
         min-width: 209px;
      }
   }
   .product-list {
      padding: 16px;
      background: #fff;
      @include media-down(md) {
         padding: 0;
         border-top: unset;
         background: unset;
      }
      &__head {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom: 16px;
         height: 34px;
         &--title {
            color: #9f9f9f;
            font-size: 16px;
            line-height: 20px; /* 125% */
            letter-spacing: 0.064px;
            span {
               color: #252a2b;
               font-size: 18px;
               font-weight: 600;
               line-height: 20px;
               letter-spacing: 0.072px;
            }
         }
      }
   }
   .wrap-loading-icon {
      margin: 0;
      display: flex;
      padding: 16px;
      justify-content: center;
      position: absolute;
      bottom: 0; // $NAVIGATOR-HEIGHT;
      left: 50%;
      transform: translateX(-50%);
   }
   .content_of_category {
      display: flex;
      align-items: center;
      flex-direction: column;
      opacity: 0.8;
      padding: 24px 0 24px;
      font-size: 12px;
      border-top: 12px solid #f4f4f4;
      @include media-down(md) {
         padding: 16px 0 8px;
         border-top: unset;
      }
      * {
         text-align: justify;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
         margin-bottom: 15px;
      }
      h2 {
         font-size: 30px;
      }
      h3 {
         font-size: 24px;
      }
      h4 {
         font-size: 20px;
      }
      ul li,
      p {
         font-size: 16px;
      }
      img {
         display: block;
         margin: auto;
         max-width: 100% !important;
         height: auto !important;
         border-radius: 3px !important;
         overflow: hidden;
         @include media-down(md) {
            width: 100% !important;
         }
      }
   }
   .products {
      h1 {
         margin-bottom: 16px;
      }
      .product-card {
         text-decoration: none;

         .avatar-combo {
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position-y: bottom;
            --bs-aspect-ratio: 65% !important;

            .avatar-hover {
               visibility: hidden;
               position: absolute;
               background-size: cover;
               background-repeat: no-repeat;
               background-position-y: bottom;
               opacity: 0;
               transition: opacity 0.4s ease;
            }

            &:hover {
               .avatar-hover {
                  visibility: visible;
                  transition: opacity 0.4s ease;
                  opacity: 1;
               }
            }
         }

         .avatar {
            position: relative;

            .percen-discount {
               position: absolute;
               top: 8px;
               left: 8px;
               font-size: 0.75rem;
               font-weight: 500;
               text-align: center;
               color: #fff;
               background-color: #ff6347;
               width: max-content;
               height: 20px;
               min-width: 40px;
               border-radius: 100px;
               display: flex;
               justify-content: center;
               align-items: center;
               z-index: 100;
            }

            .out-stock-tag {
               position: absolute;
               top: 8px;
               right: 8px;
               left: unset;
               font-size: 0.75rem;
               font-weight: 500;
               text-align: center;
               padding: 0 4px;
               color: #000;
               background-color: #fff;
               width: max-content;
               height: 20px;
               border-radius: 8px;
               display: flex;
               justify-content: center;
               align-items: center;
               z-index: 100;
            }

            .cover {
               position: absolute;
               content: "";
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               z-index: 10;
            }

            img.avatar-01,
            img.avatar-02 {
               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
               object-fit: cover;
            }

            img.avatar-01 {
               z-index: 5;
            }

            img.avatar-02 {
               z-index: 10;
               opacity: 0;
               transition: opacity 0.4s ease;
            }

            &:hover {
               .avatar-02 {
                  opacity: 1;
               }
            }

            &.ratio {
               &.ratio-1x1dot27 {
                  --bs-aspect-ratio: 127%;
               }
            }
         }

         .price,
         .title {
            margin-bottom: 0.286rem;
         }

         .price {
            font-size: 1rem;
            font-weight: 500;
            color: #252a2b;
            display: block;
            width: 100%;
            margin-bottom: 0.286rem;
            padding: 0 6px;
         }

         .price-wrap {
            color: #252a2b;
            padding: 0 6px;
            width: 100%;

            display: flex;
            flex-flow: row wrap;
            margin-bottom: 0.286rem;

            .real-price {
               font-size: 0.75rem;
               font-weight: 400;
               color: #252a2b;
               text-decoration: line-through;
               margin-bottom: 0.286rem;
            }

            .discount-price {
               font-size: 1rem;
               font-weight: 500;
               color: #009999;
               margin-right: 6px;
            }
         }

         .title,
         .vendor {
            font-size: 1rem;
            font-weight: 400;
            color: rgba(37, 42, 43, 0.7);
            width: 100%;
            padding: 0 6px;
         }
      }
   }

   .empty {
      display: flex;
      justify-content: center;
      min-height: 300px;
      @include media-down(md) {
         min-height: 300px;
      }
   }
}
.shop-category {
   .spacet-shop-catalog {
      background-color: $bg;

      @include media-down(md) {
         background-color: #fff;
      }
   }
}
