@import "styles/variables.scss";

.drawerFilter {
   .filter-header {
      display: flex;
      align-items: center;
      padding: 16px;
      .title {
         color: #252a2b;
         font-size: 16px;
         font-weight: 500;
         line-height: 16px;
         margin-right: 6px;
      }
      .count {
         color: #252a2b;
         font-size: 10px;
         font-weight: 600;
         line-height: normal;
         width: 16px;
         height: 16px;
         border-radius: 50%;
         border: 1px solid #252a2b;

         display: flex;
         align-items: center;
         justify-content: center;
      }
      .space-price {
         color: rgba(37, 42, 43, 0.5);
         font-size: 12px;
         font-weight: 400;
         line-height: normal;
      }
   }
   .filter-list {
      padding: 0 16px;
      display: flex;
      flex-flow: row wrap;
      row-gap: 12px;
      .item {
         color: #252a2b;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.253px;

         border-radius: 4px;
         background: rgba(226, 228, 235, 0.3);
         width: fit-content;
         height: 24px;
         padding: 2px 16px;
         display: flex;
         align-items: center;
         justify-content: center;
         border: 0.5px solid rgba(226, 228, 235, 0.3);
         margin-right: 12px;
         cursor: pointer;
         // &:hover,
         &.active {
            color: #ff6347;
            border: 0.5px solid #ff6347;
            background: #ffefed;
         }
      }
   }
   .slider-wrap {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      .label {
         color: #252a2b;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.253px;
      }
      .MuiSlider-root {
         position: relative;
         .MuiSlider-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #505050;
         }
         .MuiSlider-track {
            background-color: #505050;
            border-color: #505050;
         }
         .MuiSlider-rail {
            height: 2px;
            background-color: #d9d9d9;
         }
      }
   }
   &__content {
   }
   &__color {
      input.input-color {
         width: 24px;
         height: 24px;
         border-radius: 50%;
         &:checked {
            border-color: $primary;
         }
         &:hover,
         &:focus {
            box-shadow: unset;
         }
      }
      .active {
         position: relative;
         .activeIcon {
            position: absolute;
            top: -1px;
            right: -10px;
            z-index: 1;
         }
         input.input-color {
            background-image: unset;
         }
      }
   }
   &__material {
   }
   &__price {
   }
   &__brand {
   }
   &__actions {
      background-color: #fff;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      button {
         height: 40px;
         color: #fff;
         font-size: 12.64px;
         font-weight: 600;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.253px;
         border-radius: 40px;

         display: flex;
         justify-content: center;
         align-items: center;
      }
      .btn-refresh {
         width: 100%;
         color: $text;
         background: #fff;
         border: 1px solid #e2e4eb;
         margin-right: 8px;
      }
      .btn-submit {
         width: 100%;
         min-width: 60%;
         color: #fff;
         border: 1px solid $primary;
         background-color: $primary;
      }
   }
}
