@use "sass:map";

.group-filter {
   padding-bottom: 16px;
   border-bottom: 1px solid #252a2b1a;
   .form-check {
      position: relative;
      .circle {
         background-color: #fff;
         position: absolute;
         z-index: 100;
         top: 0;
         left: 0;
         width: 25px;
         height: 25px;
         display: flex;
         align-items: center;
         display: none;
         &.active {
            display: flex;
         }
      }
   }
   .label-filter {
      .title {
         font-weight: 500;
         font-size: 1.15rem;
         line-height: 19px;
         color: #252a2b;
      }
   }
   .form-check-label {
      font-size: 1rem;
      line-height: 20px;
      color: #252a2b;
      margin-left: 8px;
      padding: 5px 0;
   }
   .form-check-input {
      width: 20px;
      height: 20px;
      &:focus {
         box-shadow: unset;
      }
      &.input-color {
         width: 32px;
         height: 32px;
         margin: 0;
      }
      &:checked[type="checkbox"] + .fake {
         opacity: 1;
      }
   }
   .fake {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
   }
}
.btn-catalog-nav {
   border: 1px solid #252a2b;
   border-radius: 8px;
   img {
      padding-right: 12px;
   }
}
.blur {
   width: 100vw;
   height: 100vh;
   position: fixed;
   top: 0;
   left: 0;
   background: #00000070;
   z-index: 1030;
   &.active {
      display: block;
   }
}
.catalog-nav {
   position: fixed;
   width: 300px;
   top: 0;
   left: 0;
   overflow: auto;
   background-color: #ffffff;
   transition: transform 0.4s ease;
   box-shadow: 0 0 10px #00000070;
   z-index: 1100;
   height: 100vh;
   padding: 15px 20px !important;
   transform: translateX(-100%);
   transition: 0.5s;
   &.active {
      transition: transform 0.4s ease;
      transform: translateX(0);
      transition: 0.5s;
   }
}
.btn-see-more {
   border: none;
   outline: none;
   background-color: transparent;
   padding: 0;
}

// input[type='checkbox'] {
//    cursor: pointer;
// }
// label {
//    cursor: pointer;
// }
