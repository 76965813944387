@import "../../styles/variables.scss";

.switch {
   display: flex;

   &.small {
      --checkbox-width: 16px;
      --checkbox-height: 16px;
   }
   &.medium {
      --checkbox-width: 22px;
      --checkbox-height: 22px;
   }
   &.large {
      --checkbox-width: 28px;
      --checkbox-height: 28px;
   }

   .toggle_switch {
      position: relative;
      display: inline-block;
      width: var(--checkbox-width);
      height: var(--checkbox-height);
      max-height: var(--checkbox-height);
   }

   .toggle_switch input[type="checkbox"] {
      display: none;
   }

   .toggle_switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: transparent;
      border-radius: 21px;
      border: 2px solid #e2e4eb;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
   }

   .toggle_switch .switch::before {
      content: "";
      height: 38%;
      width: 64%;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      border-radius: 2px;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
   }

   // .toggle_switch input[type="checkbox"]:checked + .switch::before {
   //    transform: translateX(14px);
   //    background-color: #fff;
   // }
   .toggle_switch input[type="checkbox"]:checked + .switch {
      background-color: $primary;
      border: 2px solid $primary;
   }

   // &.checked {
   //    background: #ff6347;
   //    border-color: #ff6347;
   // }
}
