.connect-service-page {
    .banner {
        position: relative;
        // height: calc(100vh - 116px);

        .banner_dt {
            .content_banner {
                height: 100%;
                z-index: 2;
                position: absolute;
                padding: 170px 0;
                // padding-left: 104px;
                flex-direction: column;
                justify-content: center;

                .banner-title {
                    font-weight: 700;
                    font-size: 80px;
                    line-height: 85px;
                    color: #070707;
                    margin-bottom: 32px;

                    p {
                        margin-bottom: 0px;
                    }
                }

                .banner-description {
                    font-weight: 300;
                    line-height: 35px;
                    font-size: 32px;
                }

                .wrapper_icon {
                    display: flex;
                    justify-content: center;

                    .down_icon {
                        width: 40px;
                        height: 50px;
                        position: relative;
                        animation: upDownIcon 2s infinite;
                        animation-duration: 4s;
                    }
                }
            }

            .banner_cover {
                z-index: 1;
                // height: calc(100vh - 116px);
                object-fit: initial;
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'lg')) {
        .banner_dt {
            .content_banner {
                width: 60%;

                .banner-title {
                    font-size: 60px !important;
                    line-height: 60px !important;
                    color: #070707;

                    strong {
                        color: #ff5d00;
                        p {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 850px) {
        .banner_dt {
            .content_banner {
                .banner-title {
                    font-size: 48px !important;
                    line-height: 48px !important;
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'md')) {
        .banner_mb {
            height: 100vh;

            .content_banner {
                z-index: 2;
                position: absolute;
                padding: 0 30px;
                margin-top: 30px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .banner-title {
                    font-weight: 700;
                    font-size: 56px;
                    line-height: 64px;
                    color: #070707;
                    margin-bottom: 30px;
                    p {
                        margin-bottom: 0px;
                    }
                }

                .banner-description {
                    font-family: 'SourceSanProLight';

                    strong {
                        font-family: 'SourceSanProRegular';
                    }
                }

                .wrapper_icon {
                    display: flex;
                    justify-content: center;

                    .down_icon {
                        width: 40px;
                        height: 50px;
                        position: relative;
                        animation: upDownIcon 2s infinite;
                        animation-duration: 4s;
                    }
                }
            }

            .banner_cover {
                z-index: 1;
                // height: 100vh;
            }
        }
    }

    @media (max-width: 420px) {
        .banner_mb {
            .content_banner {
                .banner-title {
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 52px;
                    color: #070707;
                    letter-spacing: -3px;
                    p {
                        margin-bottom: 0px;
                    }
                }

                .banner-description {
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 30px;
                    letter-spacing: -0.04em;

                    strong {
                        font-weight: 600;
                    }
                }
            }

            .banner_cover {
                z-index: 1;
                height: 100vh;
            }
        }
    }

    @media (max-width: 378px) {
        .banner_mb {
            .content_banner {
                .banner-title {
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 50px;
                    color: #070707;
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@keyframes upDownIcon {
    0% {
        left: 0px;
        top: 0px;
    }

    50% {
        left: 0px;
        top: 20px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}
