@use "sass:map";
@import "ourDrawing";
@import "procedure";
@import "outstandingProject";
@import "interiorConstructionCost";
@import "experience";
@import "banner";

.construction-page {
   .main-category {
      background: #fff;
      padding: 0px;

      @include media-down(md) {
         .carowsel-mb .slick-list {
            overflow: unset;
         }
         ul.slick-dots {
            width: fit-content;
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
            height: 4px;
            border-radius: 16px;
            background-color: #ffefed;
            display: flex !important;
            li {
               margin: 0;
               border-radius: 16px;
               width: 16px;
               height: 4px;
               button {
                  width: 16px;
                  height: 4px;
                  padding: 0;
                  margin: 0;
                  &::before {
                     content: none;
                     display: none;
                  }
               }
               &.slick-active {
                  background: #ff6347;
               }
            }
         }
      }
   }

   scroll-behavior: smooth;

   .faq-custom {
      // background: #fff !important;

      button {
         background-color: #fff !important;
      }
   }

   .highlight-custom {
      background: #fff !important;
   }

   .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
         text-align: center;
         color: #ff6347;
         font-weight: 500;
         font-size: 30px;
         line-height: 35px;
         margin-bottom: 50px;
      }

      .description {
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;
         text-align: center;
         color: #252a2b;
         margin-bottom: 50px;
      }
   }

   section {
      h1 {
         text-transform: uppercase;
      }

      h2 {
         font-weight: 400;
      }

      .strong {
         font-weight: 700;
      }

      .highlight {
         color: map.get($text-colors, orange);
      }

      .section-title {
         font-weight: 900;
         font-size: 40px;
         line-height: 47px;
         text-transform: uppercase;
      }

      .section-description {
         font-weight: 400;
         font-size: 20px;
         line-height: 23px;
         text-align: center;
         color: #666666;
      }
   }

   .contact {
      padding-top: 50px;
      margin-bottom: -40px;
   }

   // XX-Large devices (larger desktops, 1400px and up)
   @media (min-width: map.get($grid-breakpoints, "xxl")) {
      .container {
         max-width: 1224px;
      }
   }

   @media (max-width: map.get($grid-breakpoints, "md")) {
      .container {
         padding-left: 24px;
         padding-right: 24px;
      }

      .highlight-custom {
         .item {
            padding: 20px;
            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);

            &:not(:last-child) {
               margin-bottom: 20px !important;
            }
         }
      }

      section {
      }

      .contact {
         padding-top: 20px !important;
         margin-bottom: -50px !important;
      }
   }

   @media (max-width: map.get($grid-breakpoints, "sm")) {
      .contact {
         display: none;
      }
   }
}
