@import "styles/variables.scss";

.drawer-hashtag {
   .MuiPaper-root {
      max-height: 70%;
      border-radius: 10px 10px 0 0;
   }

   .drawer-header {
      height: 56px;
      padding: 16px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
   }

   .icon-close {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 80px;
      background: #F4F4F4;

      position: absolute;
      right: 16px;
      top: 16px;

      img {
         width: 11px;
         height: 11px;
      }
   }

   .drawer-title {
      color: #252A2B;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
   }

   .drawer-content {
      overflow-y: auto;
      overflow-x: hidden;

      display: flex;
      flex-direction: column;

      padding-bottom: 24px;

      .item {
         padding: 0 12px;
         margin-top: 16px;
         display: flex;
         align-items: center;

         .icon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.10);

            svg {
               width: 11px;
               height: 11px;
            }
         }

         .info {
            margin-left: 8px;

            .hashtag-name {
               color: #252A2B;
               font-size: 14px;
               font-weight: 500;
               line-height: normal;
               margin-bottom: 4px;
            }

            .count {
               color: rgba(37, 42, 43, 0.50);
               font-size: 10px;
               font-weight: 400;
               line-height: normal;
            }
         }
      }
   }
}