@use "sass:map";

$cols: 1, 2, 3, 4;

.customized-radios {
   .radio-item {
      margin-right: 0px !important;
   }

   .control-item {
      overflow: hidden;
      // & > .MuiFormControlLabel-label {
      //    @include text-wrap-line(1);
      // }
   }
}

@each $col in $cols {
   .control-grid-#{$col} {
      grid-template-columns: repeat($col, 1fr);
   }
}
.header-select {
   position: sticky;
   top: 0px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #fff;
   border-bottom: 1px solid #d8d8d8;
   z-index: 9;
   padding: 0px 16px;
   span {
      font-weight: 500;
   }
   a {
      float: right;
      cursor: pointer;
      position: absolute;
      right: 16px;
   }
}

.custom-select {
   width: fit-content;
   .control-label {
      margin-right: 0px !important;
   }

   .icon-select {
      right: 12px !important;
   }

   .selected {
      color: map-get($map: $text-colors, $key: orange) !important;
      border-color: map-get($map: $text-colors, $key: orange) !important;
   }
}

.select-item {
   padding: 12px 16px !important;
}

// Checkbox v2
.wcheckbox {
   display: flex;
   align-items: center;
   .checkbox-input {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 4px;
      border: 1px solid var(--gray-gray-400, #e2e4eb);
      background: #fff;
      position: relative;
      input.similer-checkbox {
         position: absolute;
         left: 0;
         opacity: 0;
         cursor: pointer;
         height: inherit;
         width: inherit;
         z-index: 2;
      }
      .checkmark {
         position: absolute;
         top: -1px;
         left: -1px;
         width: inherit;
         height: inherit;
      }
      .checkmark:after {
         content: "";
         position: absolute;
         display: none;
      }
      input:checked ~ .checkmark {
         border-radius: 4px;
         background: #ff6347;
      }
      input:checked ~ .checkmark:after {
         display: block;
      }
      .checkmark:after {
         left: 8px;
         top: 5px;
         width: 5px;
         height: 10px;
         border: solid white;
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         border-color: #fff;
      }
   }
   span.label {
      color: #252a2b;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
   }
}
