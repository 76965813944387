.switch-custom {
   display: flex;
   &__label {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 16px;
   }
   &__label input[type="checkbox"] {
      display: none;
   }
   &__label .switch {
      position: absolute;
      cursor: pointer;
      background-color: #78788029;
      border-radius: 21px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
   }
   &__label .switch::before {
      position: absolute;
      content: "";
      left: 1px;
      top: 1px;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease;
   }
   &__label input[type="checkbox"]:checked + .switch::before {
      transform: translateX(14px);
      background-color: #fff;
   }
   &__label input[type="checkbox"]:checked + .switch {
      background-color: $primary;
   }
}
