// @import 'styles/variables.scss';
@use 'sass:map';
@import './communityPost.scss';
@import './socialFeed.scss';

.user-public-profile {
   .sidebar-profile {
      padding-right: 12px;

      &__description {
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;
         color: $cl-space-t-dark-black;

         a {
            text-decoration: none;
            cursor: pointer;
            color: map-get($map: $text-colors, $key: 'default');
         }

         .strong {
            font-weight: 500;
         }
      }

      .top-sidebar {
         &:before {
            display: none;
         }

         &-profile {
            .name {
               font-weight: 500 !important;
               font-size: 24px !important;
               line-height: 28px !important;
               margin-left: 12px !important;
               width: unset !important;
               @include text-wrap-line(1);
            }
         }

         .btn-follow {
            width: 100%;
            // background-color: $cl-space-t-orange;
            color: $cl-white;
            margin-top: 20px;
         }

         .btn-followed {
            background-color: var(--palette-grey-4);
         }

         .btn-unfollow {
            background-color: $cl-space-t-orange;
         }
      }

      .block-follow {
         font-size: 14px;
         line-height: 16px;
         color: var(--palette-grey-4);

         .strong {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: var(--palette-grey-5);
         }
      }
   }
}

@media (max-width: map-get($grid-breakpoints-max-width, 'lg')) {
   .user-public-profile {
      .sidebar-profile {
         border-right: none !important;
      }
   }
}

@media (max-width: map-get($grid-breakpoints-max-width, 'sm')) {
   .user-public-profile {
      .sidebar-profile {
         display: block !important;
      }

      .top-sidebar-profile {
         justify-content: center;
      }
   }
}