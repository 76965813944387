@use 'sass:map';

.landing-page {
   .service {
      padding: 60px 0;

      .title_ldp {
         font-size: 34px;
         line-height: 50px;
      }

      .text-highlight-secondary {
         font-weight: 400;
         font-size: 24px;
         line-height: 20px;
         color: rgba(236, 65, 61, 0.6);
      }

      .time {
         font-weight: 500;
         font-size: 28px;
         color: #999999;

         .strong {
            color: #000;
         }
      }

      .block-image {
         background-repeat: no-repeat;
         background-position: center;
         background-size: cover;
         background-image: url('/assets/img/landing-page/image1.jpg');
         animation: changeImage 4s infinite;
      }

      img {
         width: 100%;
      }

      @-webkit-keyframes changeImage {
         0% {
            background-image: url('/assets/img/landing-page/image1.jpg');
         }

         33% {
            background-image: url('/assets/img/landing-page/image2.jpg');
         }

         66% {
            background-image: url('/assets/img/landing-page/image3.jpg');
         }

         100% {
            background-image: url('/assets/img/landing-page/image1.jpg');
         }
      }

      @keyframes changeImage {
         0% {
            background-image: url('/assets/img/landing-page/image1.jpg');
         }

         33% {
            background-image: url('/assets/img/landing-page/image2.jpg');
         }

         66% {
            background-image: url('/assets/img/landing-page/image3.jpg');
         }

         100% {
            background-image: url('/assets/img/landing-page/image1.jpg');
         }
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
      .service {
         .block-content {
            margin-bottom: -290px;
         }

         .block-image {
            background-image: url('/assets/img/landing-page/image-service-mobile-1.png');
         }

         .image-shape {
            position: absolute;
            bottom: 0;
            z-index: -1;
         }

         .time {
            font-size: 24px;
         }
      }

      @-webkit-keyframes changeImage {
         0% {
            background-image: url('/assets/img/landing-page/image-service-mobile-1.png');
         }

         33% {
            background-image: url('/assets/img/landing-page/image-service-mobile-2.png');
         }

         66% {
            background-image: url('/assets/img/landing-page/image-service-mobile-3.png');
         }

         100% {
            background-image: url('/assets/img/landing-page/image-service-mobile-1.png');
         }
      }

      @keyframes changeImage {
         0% {
            background-image: url('/assets/img/landing-page/image-service-mobile-1.png');
         }

         33% {
            background-image: url('/assets/img/landing-page/image-service-mobile-2.png');
         }

         66% {
            background-image: url('/assets/img/landing-page/image-service-mobile-3.png');
         }

         100% {
            background-image: url('/assets/img/landing-page/image-service-mobile-1.png');
         }
      }
   }

   @media (max-width: 390px) {
      .service {
         .block-content {
            margin-bottom: -200px;

            .title_ldp,
            .time {
               font-size: 24px;
               line-height: 40px;
            }
         }
      }
   }

   @media (max-width: 280px) {
      .service {
         .block-content {
            margin-bottom: -150px;

            .title,
            .time {
               font-size: 18px;
               line-height: 30px;
            }
         }
      }
   }
}

@-webkit-keyframes fade {
   0% {
      opacity: 1;
   }

   22% {
      opacity: 1;
   }

   33% {
      opacity: 0;
   }

   89% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes fade {
   0% {
      opacity: 1;
   }

   22% {
      opacity: 1;
   }

   33% {
      opacity: 0;
   }

   89% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}