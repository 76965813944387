.product-card {
   border-radius: 4px;
   border: 1px solid #e5e5e6;
   background: #fff;

   display: flex;
   justify-content: space-between;
   flex-direction: column;
   align-items: center;

   .product-card-image {
      position: relative;
      width: 100%;
      overflow: hidden;
   }

   .wrap-img {
      aspect-ratio: 0.8;
      position: relative;

      &::before {
         content: "";
         display: block;
         padding-top: var(--bs-aspect-ratio);
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   .product-item-detail {
      width: 100%;
      padding: 8px;

      .name {
         color: #252a2b;
         font-size: 16px;
         font-weight: 400;
         min-height: 38px;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-line-break: 2;
         line-break: 2;
         overflow: hidden;
         text-overflow: hidden;
      }

      .price-wrap {
         display: flex;
         flex-flow: row wrap;
      }

      .price,
      .discount-price {
         color: #ff6347;
         font-size: 16px;
         font-weight: 700;
         line-height: normal;
         margin-right: 3px;
      }

      .real-price {
         color: #252a2b;
         text-decoration: line-through;
         font-size: 14px;
      }
   }
}
