@use 'sass:map';

.spacet-consulting-request:not(.spacet-consulting-request-v2) {
    padding: 1.571rem 0;

    .intro-image {
        img {
            height: 90vmax;
        }
    }
    p.counter {
        font-size: 0.75rem;
        font-weight: 400;
        margin-bottom: 2.857rem;
    }
    p.message {
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 2.1875rem;
        margin-bottom: 0;
    }

    .question {
        font-size: 1rem;
        font-weight: 700;
    }

    .question-hint {
        color: var(--palette-grey-4);
    }

    button[type='submit'] {
        background-color: map.get($text-colors, orange);
        border: none;
        padding: 14px 36px;
        color: #ffffff;
        width: 100%;
        height: 100%;
    }

    .list-type {
        .item {
            padding: 20px 0;
            border-bottom: 1px solid #dddddd;
            cursor: pointer;

            .select-box {
                position: relative;
                padding: 0.1rem;
                width: 24px;
                height: 24px;
                background-color: #ffffff;
                border: 1px solid map.get($text-colors, orange);

                &::before {
                    position: absolute;
                    content: '';
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    left: 1px;
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                }
            }

            .content {
                margin-bottom: 0;
                text-align: right;
            }
        }

        .item.selected {
            .select-box {
                &::before {
                    background-color: map.get($text-colors, orange);
                }
            }
        }
    }

    .images-type {
        .item {
            border: 1px solid #dddddd;
            padding: 0.571rem;
            position: relative;
            border-radius: 4px;
            height: 100%;
            div.image-block {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid #dddddd;
                border-radius: 4px;
            }

            p.content {
                font-size: 1rem;
                text-align: center;
            }
            .selected-cover {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(226, 226, 226, 0.4);
                z-index: 100;
                display: none;
                justify-content: center;
                align-items: center;
                .img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    padding: 4px;
                    background-color: #ff6347;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            &.selected {
                border: 1px solid #ff6347;
                box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.07);
                .selected-cover {
                    display: flex;
                }
            }
        }

        .refer-block {
            border: 1px solid #dddddd;

            .image {
                min-width: 5.714rem;
            }

            .refer-content {
                padding: 10px;
            }

            &.selected {
                border: 1px solid map.get($text-colors, 'default');
                box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.07);
            }
        }
    }

    .form-type {
        .block {
            input {
                padding: 0.714rem 1.071rem;
                border: 1px solid #dddddd;
                outline: none;
                box-shadow: 0 0 0 inset #dddddd;
                transition: 0.25s ease;

                &:focus {
                    border: 1px solid map.get($text-colors, 'default');
                    box-shadow: 0 0 10px inset #dddddd;
                }
            }

            button {
                color: #ffffff;
                padding: 0.714rem 1.071rem;
                border: 1px solid map.get($text-colors, 'default');
                background-color: map.get($text-colors, 'default');
                transition: all 0.4s ease;

                &:hover {
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.336);
                }
            }
        }
    }
    .direct-buttons {
        position: sticky;
        bottom: 0px;
        background: #ffffff;
        padding-top: 12px;
        padding-bottom: 12px;
        z-index: map.get($zindex, 'dropdown');
    }
    .arrow {
        // position: absolute;
        // top: -100%;
        // right: 0;
    }
    .direct-button {
        display: block;
        padding: 0.857rem 1rem;
        text-decoration: none;
        color: map.get($text-colors, 'default');
        background-color: rgba(241, 241, 241, 0.9);
        box-shadow: 0px 0px 0 transparent;
        transition: all 0.4s ease;
        border-radius: 4px;
    }
    .direct-button.next {
        background-color: map.get($text-colors, orange);
        color: #ffffff;
    }

    .step-circle {
        display: block;
        width: 1rem;
        height: 1rem;
        border: 1px solid map.get($text-colors, 'default');
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.4s ease;

        &.active {
            background-color: map.get($text-colors, 'default');
        }
    }
    .submission__header-title {
        color: #6a433a;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
    }

    .hint {
        // font-style: italic;
        font-size: 1rem;
        color: var(--palette-grey-4);
        margin-bottom: 0px;
    }

    form {
        .label {
            font-size: 1rem;
            line-height: 1.25rem;
            // margin-top: 12px;
            font-weight: 700;
            span {
                font-weight: 400;
            }
        }

        .form-control::placeholder,
        .form-control.placeholder {
            color: #d6d6d6;
            background-color: unset;
            opacity: 1;
        }
    }
    @media (min-width: map.get($grid-breakpoints, 'lg')) {
        a.direct-button {
            &:hover {
                border: none;
                opacity: 0.6;
                // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.336);
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints-max-width, 'lg')) {
        p.message {
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 1.75rem;
        }
        .question {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1875rem;
            color: $cl-space-t-dark-black;
        }
    }
    @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
        padding-top: 32px;
        .intro-image {
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    @media screen and (orientation: landscape) {
        .intro-image {
            img {
                height: 90vmin;
            }
        }
    }
}
