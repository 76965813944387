@use 'sass:map';

.spacet-main-header {
   background-color: #ffffff;
   box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.09);

   a {
      text-decoration: none;
   }

   .overflow-hidden-auto {
      overflow: hidden auto;
   }

   .top,
   .bottom {
      border-bottom: 1px solid #0000001a;
   }

   .top {
      .container_custom {
         padding-top: 7px;
         padding-bottom: 7px;
      }

      .icon-back {
         position: absolute;
         left: 16px;
         bottom: 0px;
         top: 0px;
         margin: auto;
         width: fit-content;
      }

      .online_consultation_button {
         font-size: 1rem;
         font-weight: 500;
         line-height: 26px;
         border: 1px solid #252a2b;
         border-radius: 24px;
         outline: none;
         background-color: #ff6347;
         box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
         color: #ffffff;
         padding: 10px 20px;
         text-decoration: none;
         border: none;
      }

      ul.main-menu > li > a {
         font-size: 1rem;
         font-weight: 500;
         display: block;
         padding: 10px 15px;
         text-decoration: none;
         text-transform: uppercase;
         color: #252a2b;
         transition: 0.25s ease-in-out;
         cursor: pointer;
      }

      ul.main-menu > li > a.active,
      ul.main-menu > li > a:hover {
         color: #ff6347;
      }

      .search-box {
         padding: 10px 12px;
         border: 1px solid #e6e6e6;
         border-radius: 24px;
         position: relative;

         .input {
            border: none;
            outline: none;
            font-size: 1rem;

            &::placeholder {
               color: #252a2b99;
            }
         }

         .button {
            border: none;
            outline: none;
            background-color: transparent;
         }

         .search-result {
            height: auto;
            overflow: auto;
            background-color: #fff;
            border: 1px solid #e4e4e4;
            border-radius: 8px;
            padding: 10px 20px;
            display: none;
            position: absolute;
            top: 50px;
            left: 0;
            width: 125%;
            z-index: 1300;

            &.active {
               display: block;
            }

            a {
               font-size: 1rem;
               text-decoration: none;
               color: #252a2b;
            }

            .text-see-more {
               font-size: 10px;
               bottom: 0;
               margin-top: 10px;
               margin-bottom: -5px;
               text-align: left;
               cursor: pointer;
            }

            .img {
               width: 50px;
               height: 50px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }
      }

      .search-button,
      .cart-button,
      .user-button,
      .hamburger-button {
         padding: 0 !important;
         border: none;
         outline: none;
         background-color: transparent;

         img {
            width: 24px;
         }
      }

      .cart-button,
      .user-button {
         .noti-counter {
            background-color: #efad6a !important;
         }

         .cart-counter,
         .noti-counter {
            position: absolute;
            width: max-content;
            min-width: 16px;
            height: 16px;
            top: -2px;
            right: -2px;
            background-color: #ff4747;
            padding: 2px;
            font-size: 9px;
            text-align: center;
            border-radius: 100px;
            color: #ffffff;
            font-weight: 500;
         }
      }
   }

   .bottom {
      position: relative;

      .menu-item {
         display: block;
         position: relative;
         font-size: 16px;
         padding: 20px 10px;
         text-decoration: none;
         color: #252a2b;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 8px;
         cursor: pointer;

         img.icon-menu {
            width: 20px;
            height: 20px;
         }
      }

      .menu-item.active {
         font-weight: 500;
         color: #ff6347;

         img.icon-menu {
            filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
         }

         &::after {
            content: "";
            position: absolute;
            width: 70%;
            height: 2px;
            left: 0;
            bottom: 0;
            background-color: #ff6347;
            margin-left: 15%;
         }
      }

      .level-1 {
         .menu-item {
            padding: 8px 12px;
            font-weight: 700;
         }
      }

      .level-2 {
         overflow: auto hidden;

         .menu-item {
            padding: 8px 8px;
         }
      }

      a.active {
         font-weight: 500;
         color: #ff6347;

         img.icon-menu {
            filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
         }

         &::after {
            content: "";
            position: absolute;
            width: 70%;
            height: 2px;
            left: 0;
            bottom: 0;
            background-color: #ff6347;
            margin-left: 15%;
         }
      }
   }

   .level-3 {
      position: absolute;
      width: 100%;
      top: calc(100% + 1px);
      left: 0;
      color: #252a2b;
      background: #fff;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 9%);

      a {
         color: #252a2b;
         // font-weight: bold;
         text-decoration: none;
         padding: 0 !important;

         &.active {
            color: #ff6347;
         }
      }
   }

   .top-menu,
   .right-menu,
   .bottom-menu,
   .left-menu,
   .left-menu-custom {
      .menu-item {
         display: block;
         padding: 10px;
         font-size: 1rem;
         font-weight: 700;
         text-decoration: none;
         color: #252a2b;

         &.active {
            color: #ff6347;
         }
      }

      .online-consultation {
         background: #ff6347;
         color: #ffffff;
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
         height: 50px;
      }

      .header {
         padding: 22px 0;
         background-color: #ffffff;
         border: 1px solid #e1e1e1;
         box-shadow: 0px 2px 8px 0px #0000001a;

         button.close {
            background-color: transparent;
            border: none;
            outline: none;
         }
      }

      .body {
         background: #ffffff;
         color: #252a2b;

         .account {
            text-decoration: none;

            .img-account {
               width: 40px;
               height: 40px;
               // border: 1px solid #E4E4E4;
               border-radius: 50%;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
               }
            }

            .name {
               color: #252a2b;
            }
         }

         .btn-wrap {
            padding-left: 20px;
            padding-right: 20px;

            .button-group {
               .btn {
                  width: 100%;
               }

               .button-signup {
                  // padding-left: 20px;
                  padding-right: calc(var(--bs-gutter-x) * 0.25);

                  .btn-signup {
                     background: #252a2b;
                     border-radius: 4px;
                     color: #fff;
                     text-decoration: none;
                  }
               }

               .button-signin {
                  padding-left: calc(var(--bs-gutter-x) * 0.25);

                  // padding-right: 20px;
                  .btn-signin {
                     border: 1px solid #252a2b;
                     border-radius: 4px;
                     color: #000;
                     text-decoration: none;
                  }
               }
            }
         }

         ul.main-mobile-menu {
            background: #ffffff;

            > li a:not(.level-1) {
               border-bottom: 1px solid var(--palette-grey-1);
            }

            > li {
               position: relative;

               a {
                  padding: 10px 24px 10px 12px;
                  @extend .menu-item;
               }

               button.expand-button {
                  background-image: url(/assets/img/icon/+.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  background-color: transparent;
                  border: none;
                  outline: none;
                  position: absolute;
                  top: 0;
                  right: 12px;
                  width: 40px;
                  height: 40px;
                  z-index: 1;
                  margin: 0;

                  &.expanded {
                     background-image: url(/assets/img/icon/-.svg);
                  }
               }

               ul.sub-mobile-menu {
                  background: #ffffff;
                  // box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
                  overflow: hidden;

                  li {
                     a {
                        padding-left: 24px;
                     }

                     ul {
                        li {
                           a {
                              padding-left: 36px;
                              font-weight: 400;
                           }

                           ul {
                              box-shadow: unset;

                              li {
                                 a {
                                    padding-left: 44px;
                                 }
                              }
                           }
                        }
                     }
                  }
               }

               button.expand-button + ul.sub-mobile-menu {
                  max-height: 0;
               }

               button.expand-button.expanded + ul.sub-mobile-menu {
                  max-height: unset;
               }
            }
         }
      }

      .cart-detail {
         padding: 10px 20px;

         .cart-product {
            .img {
               width: 100%;
               height: 100%;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .text {
               .variant {
                  .product-variant {
                     padding-right: 10px;
                  }
               }

               position: relative;

               .name {
                  font-size: 1rem;
                  line-height: 19px;
                  color: #000000;
                  font-weight: 500;
               }

               .num {
                  background: #e6e6e6;
                  padding: 8px 15px;
                  color: #000;
                  width: 35px;
               }

               .remove {
                  position: absolute;
                  right: 0;
                  bottom: 0;
               }
            }
         }

         .cart-bottom {
            margin-top: 20px;
            border-top: 1px solid #ddd;

            .btn {
               background: #000;
               color: #fff;
               border-radius: 4px;
               width: 100%;

               &:hover {
                  background: #ff6347;
               }
            }
         }
      }

      .search-mb {
         .search-form {
            margin-right: 13.5px;

            .search-box {
               width: 100%;
               position: relative;

               .input {
                  width: 100%;
                  height: 49px;
                  border: 1px solid #e6e6e6;
                  border-radius: 105px;
                  padding: 15px 20px;
                  margin-right: 13.5px;
               }

               .button {
                  position: absolute;
                  border: none;
                  outline: none;
                  background: unset;
                  top: 10px;
                  right: 10px;
               }
            }
         }

         .img-close {
            width: 22px;
            height: 22px;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }

         .main {
            height: 100%;
            overflow: auto;

            .result {
               background: #f5f5f5;
               border-radius: 8px;
               padding: 14px;

               .img {
                  width: 40px;
                  height: 40px;
                  margin-right: 16px;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               .name {
                  p {
                     font-size: 1rem;
                     font-weight: 500;
                     line-height: 19px;
                     margin: 0;
                     height: auto;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     -webkit-line-clamp: 2;
                     -webkit-box-orient: vertical;
                     display: -webkit-box;
                  }
               }
            }
         }
      }
   }

   .left-menu-custom {
      overscroll-behavior: contain;
   }

   .off-canvas-menu {
      position: fixed;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      &::before,
      .top-menu,
      .right-menu,
      .bottom-menu,
      .left-menu {
         background-color: #ffffff;
         transition: transform 0.4s ease;
         box-shadow: 0 0 10px #00000070;
         overflow: scroll;
      }

      .right-menu {
         position: absolute;
         width: 300px;
         top: 0;
         right: -305px;
      }

      &::before {
         content: "";
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         opacity: 0;
         background-color: #00000070;
         transition: opacity 0.4s ease;
      }

      .right-menu.enable {
         transform: translateX(-305px);
      }

      .right-menu.enable-search {
         transform: translateX(-305px);
      }

      .left-menu {
         position: absolute;
         width: 100%;
         top: 0;
         left: -100%;
         overscroll-behavior: contain;
      }

      .left-menu.enable {
         transform: translateX(100%);
      }
   }

   .off-canvas-menu.enable {
      &::before {
         opacity: 1;
      }
   }

   @media (max-width: 375px) {
      .top {
         .pd-left {
            padding-left: 14px !important;
         }
      }
   }

   @media (max-width: 576px) {
      .level-2 {
         a {
            padding: 16px 10px;
         }
      }
   }

   .overflow-auto::-webkit-scrollbar {
      display: none;
   }

   @media (max-width: 987px) {
      .overflow-auto::-webkit-scrollbar {
         display: none;
      }
   }

   @media (max-width: 1201) {
      .overflow-auto::-webkit-scrollbar {
         display: none;
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (min-width: 576px) {
   }

   // Large devices (desktops, 992px and up)
   @media (min-width: 992px) {
      .level-1 {
         .menu-item {
            padding: 20px 10px !important;
         }
      }

      .level-2 {
         .menu-item {
            padding: 20px 10px !important;
         }
      }
   }

   // X-Large devices (large desktops, 1200px and up)
   @media (min-width: 1130px) {
   }

   // XX-Large devices (larger desktops, 1400px and up)

   @media screen and (orientation: landscape) {
      .screen-height-100 {
         height: 100vmin;
      }
   }

   @media screen and (orientation: portrait) {
      .screen-height-100 {
         height: 100vmax;
      }
   }
}

.spacet-landing-page-header {
   a {
      text-decoration: none;
   }

   .menu {
      &-item {
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
         text-transform: uppercase;
         color: #252a2b;
         padding: 0 20px;
      }

      &-item-active {
         color: #ff6347;
      }
   }

   .online-consultation-button {
      background-color: #ff6347 !important;
      border: none !important;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      font-weight: 500;
      line-height: 26px;
      border-radius: 24px;
      outline: none;
      color: #ffffff;
      padding: 10px 20px;
      text-decoration: none;
   }

   .header {
      padding: 16px auto;
   }
}

.consulting-channel {
   .MuiPaper-root {
      background: #ffffff;
      border-radius: 13px;
      overflow: hidden;

      .consulting-channel-item:not(:last-child) {
         border-bottom: 1px solid #e5e5e6;
      }

      .consulting-channel-item {
         background: #ffffff;
         padding: 10px 20px;
         display: block;
         text-decoration: none;
         width: 100%;
         text-align: start;

         &:focus,
         &:focus-visible {
            border: none;
            outline: none;
            box-shadow: none;
         }

         span {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            margin-left: 16px;
            color: #252a2b;
            text-decoration: none;
         }

         img {
            width: 36px;
            height: 36px;
            object-fit: cover;
         }
      }
   }
}

.notification-paper {
   .MuiPaper-root {
      max-height: unset;
      width: 360px;
      // max-height: calc(100% - 100px);
      border-radius: 24px;
      background-color: #fff;
      box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
      overflow: hidden;
   }
}

.drawer-bottom {
   .MuiPaper-root {
      background: #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 13px 13px 0px 0px;
      overflow: hidden;
   }
}

.drawer-consulting-channel {
   z-index: 1300 !important;
   .MuiPaper-root {
      background: #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 13px 13px 0px 0px;
      overflow: hidden;

      .title {
         padding: 16px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         height: 65px;
         border-bottom: 1px solid rgba(204, 204, 204, 0.5);

         span {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
         }

         .icon-close {
            background: #f2f5fa;
            backdrop-filter: blur(27.1828px);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            & > * {
               color: #cccccc;
            }
         }
      }

      .drawer-consulting-channel-list {
         margin: 16px;
         background: #ffffff;
         border-radius: 13px;
         border: 1px solid #e5e5e6;
         overflow: hidden;
      }

      .drawer-consulting-channel-item:not(:last-child) {
         border-bottom: 1px solid #e5e5e6;
      }

      .drawer-consulting-channel-item {
         background: #ffffff;
         padding: 24px 16px;
         display: block;
         text-decoration: none;
         width: 100%;
         text-align: start;

         &:focus,
         &:focus-visible {
            border: none;
            outline: none;
            box-shadow: none;
         }

         span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-left: 16px;
            color: #252a2b;
         }

         img {
            width: 38px;
            height: 38px;
            object-fit: cover;
            margin-right: 16px;
         }
      }
      .reward-coin {
         color: #f5c404;
         font-size: 12px;
         font-weight: 700;
         border-radius: 3px;
         background: rgba(255, 195, 35, 0.1);
         padding: 3px 8px;
      }
      .header {
         margin-bottom: 4px;
         .text {
            color: #000;
            font-size: 16px;
            font-weight: 700;
         }
      }
      .description {
         color: rgba(0, 0, 0, 0.5);
         font-size: 14px;
      }
      .see-coin-policy {
         color: #1c77f3;
         text-align: center;
         font-size: 16px;
         font-weight: 500;
         padding-bottom: 16px;
         cursor: pointer;
         display: block;
      }
   }
}

.introducing-popover {
   .MuiPopover-paper {
      border-radius: 8px;
      overflow: unset;
      // top: 74px !important;
      // left: unset !important;
      // right: 170px !important;

      max-width: 280px;
      @include media-down(sm) {
         // top: 50px !important;
         // right: 4px !important;
      }
   }
   // .backdrop {
   // }
   &__content {
      position: relative;
      padding: 16px;
      .arrow-top {
         position: absolute;
         top: -10px;
         right: 18px;

         border-left: 10px solid transparent;
         border-right: 10px solid transparent;
         border-bottom: 10px solid #fff;
      }
      h3 {
         color: #000;
         font-size: 16px;
         font-weight: 700;
      }
      p {
         color: #000;
         font-size: 14px;
         font-weight: 400;
      }
      .btn-introducing {
         width: 100%;
         color: #fff;
         font-size: 16px;
         font-weight: 700;
         border-radius: 4px;
         background: #ff6347;
         padding: 8px;
      }
   }
}

.header-back-link {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 10;
   .header-content {
      height: $MENU-HEIGHT-MOBILE;
      min-height: $MENU-HEIGHT-MOBILE;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      position: relative;
      .title {
         color: #252a2b;
         font-size: 16px;
         font-weight: 500;
         line-height: 24px; /* 133.333% */
      }
      button.back-icon {
         width: $MENU-HEIGHT-MOBILE;
         height: 100%;
         padding: 0;
         margin: 0;
         border: unset;
         background-color: unset;
         cursor: pointer;
         position: absolute;
         left: 0;
         top: 50%;
         transform: translateY(-50%);
         z-index: 1;
         svg {
            width: 24px;
            height: 24px;
         }
      }
   }
}
.header-back-link ~ .header-space {
   height: $MENU-HEIGHT-MOBILE;
   min-height: $MENU-HEIGHT-MOBILE;
}
