.connect-service-page {
    .steps {
        height: calc(100vh - 116px);
        display: flex;
        flex-direction: row-reverse;
        padding-top: 56px;
        padding-bottom: 22px;
        background-color: #FFFAF7;
        align-items: center;
        justify-content: center;

        .wrapper_bg {
            height: 100%;
            width: 44%;
            align-items: center;

            .bg_step {
                width: 100%;
                height: 80%;
                object-fit: contain;
            }
        }

        .content {
            padding: 12px 24px;

            .title_step {
                font-weight: 700;
                font-size: 45px;
                line-height: 54px;
                color: #000;
                margin-bottom: 32px;

                strong {
                    color: #FF5D00;
                }
            }

            .step_item {
                margin-bottom: 20px;
                display: flex;
                align-items: center;

                span {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 32px;
                    font-family: 'SourceSanProLight';
                }

                .icon_verify {
                    width: 22px;
                    height: 22px;
                    margin-right: 8px;
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'lg')) {
        .steps {
            padding: 80px 0;
            height: unset;
        }
    }

    @media (max-width: 850px) {
        .steps {
            .content {
                .title_step {
                    font-size: 40px;
                    line-height: 48px;
                }

                .step_item {
                    span {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'md')) {
        .steps {
            height: 100%;
            display: block;
            padding-bottom: 22px;
            background-color: #FFFAF7;

            .wrapper_bg {
                height: 100%;
                width: unset;

                .bg_step {
                    width: 100%;
                    height: 80%;
                    object-fit: contain;
                }
            }

            .bg_step {
                width: 100%;
                height: 60%;
            }

            .content {
                padding: 12px 24px;

                .title_step {
                    font-weight: 700;
                    line-height: 50px;
                    font-size: 40px;
                    color: #000;
                    margin-bottom: 24px;
                    letter-spacing: -3px;

                    strong {
                        color: #FF5D00;
                    }
                }

                .step_item {
                    margin-bottom: 24px;

                    .icon_verify {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                    }

                    span {
                        font-size: 22px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    @media (max-width: 378px) {
        .steps {

            .content {
                .title_step {
                    font-weight: 700;
                    line-height: 45px;
                    font-size: 35px;
                    color: #000;
                    margin-bottom: 24px;
                    letter-spacing: -3px;

                    strong {
                        color: #FF5D00;
                    }
                }

                .step_item {
                    margin-bottom: 12px;

                    .icon_verify {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                    }

                    span {
                        font-size: 22px;
                        line-height: 25px;
                    }
                }
            }

        }
    }
}