// @import 'styles/variables.scss';
@use 'sass:map';

.user-social-feed-item {
   font-size: 16px;
   line-height: 20px;
   color: $cl-space-t-dark-black;

   .item-img {
      height: 250px;
      background-color: var(--palette-grey-1);
      position: relative;

      img {
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }

   .item-image {
      height: 0;
      background-color: var(--palette-grey-1);

      img {
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }

   .info-block {
      &__action {
         display: flex;
         justify-content: space-between;
         opacity: 0.9;
         text-decoration: none;
         color: inherit;

         &-item {
            display: flex;
         }
      }

      &__description {
         opacity: 0.9;
         white-space: break-spaces;
      }
   }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
   .user-social-feed-item {
      .item-image {
         height: auto;
         width: 100%;
      }
   }
}