@use "sass:map";
@import "styles/variables.scss";

.progress-bar {
   position: fixed !important;
   top: 0px;
   left: 0px;
   right: 0px;
   z-index: 1200 !important;
   background-color: #ffc5bb !important;

   span {
      background: #ff6347;
   }
}

// .header-container.feed,
// .header-container.thiet-ke-thi-cong {
//    max-width: unset !important;
//    display: flex;
//    justify-content: center;
// }
.header-container {
   min-width: calc(1224px - 32px);
   @include media-down(xl) {
      width: 100%;
      max-width: 1224px;
      min-width: unset;
   }
}

.title_regis {
   font-size: 16px;
   font-weight: 500;
   white-space: nowrap;
   padding: 6px 9px;
   width: 100%;
   min-width: 109px;
   height: 35px;
   border-radius: 100px;
   border: 1px solid rgba(255, 99, 71, 0.6);
   display: flex;
   align-items: center;
   justify-content: center;

   &.btn-login {
      color: #ff6347;
      background: #fff;
   }

   &.btn-register {
      color: #fff;
      background-color: #ff6347;
   }
}

.menu-top {
   display: flex;
   padding: 4px;
   justify-content: space-between;
   border-bottom: 1px solid #e1e1e1;
   z-index: 1040;

   .menu-top-item {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &:hover {
         color: $primary;
      }

      &.active {
         color: $primary;
      }
   }

   .menu-top-sub-item {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-left: 12px;
      &:hover {
         color: $primary;
      }
      img {
         width: 14px;
         margin-right: 4px;
      }
   }

   .grid-menu-top {
      display: flex;
      align-items: center;
      gap: 32px;
   }

   .group-action {
      display: flex;
      align-items: center;
      gap: 9px;
   }

   .menu-download {
      position: relative;

      &:hover {
         .pannel-download {
            display: block;
            z-index: 10000;
         }
      }

      .pannel-download {
         display: none;
         box-shadow: 0px 0px 8px 0px rgba(20, 19, 19, 0.13);
         background: #fff;
         padding: 12px;
         position: absolute;
         top: 18px;
         z-index: 1500;
         left: -80px;

         .list-app {
            margin-top: 12px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 12px;

            img {
               width: 100%;
               height: auto;
            }
         }
      }
   }
}

.banner-top {
   display: flex;
   align-items: center;
   gap: 12px;
   padding: 8px 12px;
   border-bottom: 1px solid #e1e1e1;

   .logo {
      width: 39px;
      height: 39px;
      padding: 0px 3px;
      filter: drop-shadow(0px 0px 5px rgba(45, 43, 43, 0.29));
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
   }

   .text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
         color: #151414;
         font-size: 11px;
         font-weight: 700;
      }

      .description {
         color: #8d8888;
         font-size: 11px;
         font-weight: 400;
      }
   }

   .btn-download {
      border-radius: 10px;
      background: #ff6347;
      display: flex;
      padding: 10px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      border: none;
   }

   .close-icon {
      color: #666;
      position: absolute;
      right: 12px;
   }
}

.bottom_navbar {
   position: fixed;
   bottom: 0;
   width: 100%;
   display: flex;
   align-items: center;
   z-index: 1200;
   padding: 0 6px;
   border-top: 1px solid var(--gray-gray-400, #e2e4eb);
   background: #fff;
   left: 0;
   right: 0;
   background: #fff;
   border-top: 1px solid #e1e1e1;
   transition: transform 0.3s ease-in-out;

   &--hidden {
      transform: translateY(100%);
   }
}

.bottom_text {
   position: fixed;
   bottom: 16px;
   left: 50%;
   transform: translateX(-50%) translateY(100%);
   background: #fff;
   color: #252a2b;
   border-radius: 12px;
   border: 1px solid #ff6347;
   font-size: 14px;
   opacity: 0;
   transition: all 0.3s ease-in-out;
   padding: 8px 16px;
   width: 95%;
   max-width: 600px;

   &--visible {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
   }

   .calculator-form {
      display: flex;
      align-items: center;
      gap: 12px;

      .calculator-icon {
         flex-shrink: 0;
         width: 40px;
         height: 40px;
         background: #fff1ef;
         border-radius: 8px;
         display: flex;
         align-items: center;
         justify-content: center;
      }

      .calculator-button {
         flex-shrink: 0;
         background: #ff6347;
         color: white;
         border: none;
         padding: 8px 24px;
         border-radius: 8px;
         cursor: pointer;
         font-weight: 500;
         &:hover {
            background: darken(#ff6347, 5%);
         }
      }
   }
}

.cart-button,
.user-button,
.search-button {
   flex: 1;
   padding: 0;
   border: none;
   outline: none;
   background-color: transparent;
   cursor: pointer;

   .cart-counter {
      color: #fff;
      font-size: 8px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;

      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 6px;
      border-radius: 20px;
      background: #ff6347;

      position: absolute;
      top: -3px;
      right: -4px;
   }
}

.navigator-item {
   position: relative;
   height: $NAVIGATOR-HEIGHT;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;

   flex: 1;
   padding: 0;
   border: none;
   outline: none;
   background-color: transparent;
   cursor: pointer;

   img {
      width: 24px;
      height: 24px;
   }
   svg {
      height: 24px;
   }

   .label {
      margin-top: 2px;
      text-align: center;
   }

   .tag-new {
      color: #fff;
      font-size: 8px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 6px;
      border-radius: 20px;
      background: #ff6347;

      position: absolute;
      top: 12px;
      right: -5px;
   }
   .tag-hot {
      color: #fff;
      font-size: 8px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 6px;
      border-radius: 20px;
      background: #ff6347;

      position: absolute;
      top: 12px;
      right: -2px;
   }
   .cart-counter,
   .noti-counter {
      color: #fff;
      font-size: 8px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;

      min-width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 6px;
      border-radius: 20px;
      background: #ff6347;

      position: absolute;
      top: 12px;
      right: -0px;
   }
}

.cart-button,
.user-button {
   position: relative;
}

header.header {
   width: 100%;
   position: sticky;
   top: 0px;
   transition: top 0.3s ease 0s;
   background-color: #ffffff;
   z-index: 99;

   &.shop {
      width: 100%;
      background-color: transparent;
   }

   &.border-bottom {
      @include media-down(md) {
         background: transparent;
      }
   }

   a {
      text-decoration: none;
   }

   .overflow-hidden-auto {
      overflow: hidden auto;
   }

   .title_header {
      color: #252a2b;
      font-size: 22px;
      font-weight: 700;
      text-transform: capitalize;
   }

   .consult_icon {
      background-color: #ff6347;
      color: #fff;
      padding: 6px 10px;
      font-size: 15px;
      line-height: 16px;
      font-weight: 700;
      border-radius: 10px;
   }

   .banner-bottom {
      position: fixed;
      bottom: 52px;
      background-color: #fff;
      width: 100%;
      display: flex;
      padding: 10px;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e6;
      border-top: 1px solid #e5e5e6;

      .title {
         color: #05a;
         font-size: 13px;
         font-weight: 500;
      }
   }

   .level-3 {
      position: absolute;
      width: 100%;
      top: calc(100% + 1px);
      left: 0;
      color: #252a2b;
      background: #fff;
      box-shadow: 0px 8px 16px rgb(0 0 0 / 9%);

      .menu-item {
         padding: 8px 8px;

         &:hover {
            color: #ff6347;
         }
      }

      a {
         color: #252a2b;
         text-decoration: none;

         &.active {
            color: #ff6347;
         }
      }
   }

   .cta-btn {
      font-weight: 500;
      font-size: 16px;
      border-radius: 32px;
      outline: none;
      color: #000;
      padding: 4px 16px;
      text-decoration: none;
      margin-left: 8px;
      height: 35px;
      display: flex;
      align-items: center;
      border-radius: 100px;
      border: 1px solid rgba(204, 204, 204, 0.4);
      background: #fff;

      span {
         font-size: 16px;
         margin-right: 8px;
      }

      &.isLogin {
         color: #fff;
         background-color: #ff6347;
         box-shadow: 0px 4px 18px 0px #00000040;
      }
   }

   .icon-back {
      position: absolute;
      left: 16px;
      bottom: 0px;
      top: 0px;
      margin: auto;
      width: fit-content;
   }

   .wrap-search {
      width: 100%;
      max-width: 531px;
      height: auto;
      overflow: auto;
      // position: absolute;
      // top: 50px;
      // left: 0;
      width: 125%;
      z-index: 1300;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.14);
   }
}

.top,
.bottom {
   background-color: #ffffff;
   // transition: top 0.5s ease 0s;
   border-bottom: 1px solid #e1e1e1;

   &.shop {
      border-bottom: unset;
   }
}

.top {
   display: flex;
   align-items: center;
   justify-content: center;

   padding: 12px 0;
   height: $MENU-HEIGHT-MOBILE;
   position: relative;
   z-index: 1200;

   @include media-up(md) {
      // height: 70px;
      display: flex;
      align-items: center;
      padding: 12px;
   }

   ul {
      margin-bottom: 0px;
      // padding: 10px 0px;
   }

   ul.main-menu .top-menu-dropdown {
      top: 32px;
      left: -54px;
   }

   ul.main-menu > li .menu-item {
      position: relative;
      display: flex;
      flex-wrap: nowrap;

      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      padding: 8px 6px;
      text-decoration: none;
      color: map.get($text-colors, "default");
      white-space: nowrap;

      @include media-down(xl) {
         white-space: unset;
         padding: 8px 12px;
      }

      .tag-new {
         color: #fff;
         font-size: 12px;
         font-weight: 600;
         line-height: 18px;
         text-transform: capitalize;

         display: flex;
         justify-content: center;
         align-items: center;
         padding: 2px 8px;
         border-radius: 20px;
         background: #ff6347;

         position: absolute;
         top: -4px;
         right: -18px;
      }
      .tag-hot {
         color: #fff;
         font-size: 12px;
         font-weight: 600;
         line-height: 1;
         text-transform: capitalize;

         display: flex;
         justify-content: center;
         align-items: center;
         min-width: 36px;
         height: 22px;
         padding: 3px 6px;
         margin-left: 4px;
         margin-bottom: 12px;
         border-radius: 20px;
         background: #ff6347;

         // position: absolute;
         // top: 12px;
         // right: -5px;
      }
   }

   ul.main-menu > li .menu-item:hover {
      border-radius: 8px;
      background: rgba(6, 42, 70, 0.05);
   }

   ul.main-menu > li .menu-item.active {
      color: #ff6347;
   }

   .popper-search {
      top: 46px !important;
      transform: unset !important;
   }
   .search-box {
      position: relative;

      .button {
         position: absolute;
         left: 10px;
         top: 50%;
         transform: translateY(-50%);
         z-index: 10;
      }

      .input {
         border: none;
         outline: none;
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;
         background-color: transparent;

         padding: 8px 8px 8px 38px;
         position: relative;
         height: 38px;
         border-radius: 100px;
         border: 1px solid #e8eaed;
         background: #f0f2f5;

         &::placeholder {
            color: #8a8a8a;
         }
      }

      .button {
         border: none;
         outline: none;
         background-color: transparent;
         padding: 0;

         img {
            width: 26px;
         }
      }
   }
}

.bottom {
   top: 69px;
   left: 0;
   right: 0;

   .menu-item {
      position: relative;
      font-size: 16px;
      padding: 20px 10px;
      text-decoration: none;
      color: map.get($text-colors, "default");
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      height: 40px;

      span {
         margin-top: 0px;
      }

      img.icon-menu {
         width: 20px;
         height: 20px;
      }
   }

   .menu-item.active {
      font-weight: 500;
      color: #ff6347;

      img.icon-menu {
         filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
      }

      &::after {
         content: "";
         position: absolute;
         width: 50%;
         height: 2px;
         left: 0;
         bottom: 0;
         background-color: #ff6347;
         margin-left: 25%;
      }
   }

   .level-1 {
      .menu-item {
         padding: 8px 12px;
         font-weight: 700;
      }
   }

   .level-2 {
      overflow: auto hidden;
      display: flex;
      align-items: center;

      .menu-item {
         padding: 8px 8px;

         &:hover {
            color: #ff6347;
         }
      }

      &:first-child .menu-item {
         padding-left: 0;
      }

      &:last-child .menu-item {
         padding-right: 0;
      }
   }

   .level-3 {
      a {
         &:hover {
            color: #ff6347;
         }
      }
   }

   a.active {
      font-weight: 500;
      color: #ff6347;

      img.icon-menu {
         filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
      }

      &::after {
         content: "";
         position: absolute;
         width: 100%;
         height: 2px;
         left: 0;
         bottom: 0;
         background-color: #ff6347;
      }
   }
}

.cart-detail {
   // padding: 10px 20px;

   .cart-product {
      .img {
         width: 100%;
         height: 100%;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      .text {
         .variant {
            .product-variant {
               padding-right: 10px;
            }
         }

         position: relative;

         .name {
            font-size: 1rem;
            line-height: 19px;
            color: #000000;
            font-weight: 500;
         }

         .num {
            background: #e6e6e6;
            padding: 8px 15px;
            color: #000;
            width: 35px;
         }

         .remove {
            position: absolute;
            right: 0;
            bottom: 0;
         }
      }
   }

   .cart-bottom {
      margin-top: 20px;
      border-top: 1px solid #ddd;

      .btn {
         background: #000;
         color: #fff;
         border-radius: 4px;
         width: 100%;

         &:hover {
            background: #ff6347;
         }
      }
   }
}

.search-mb {
   .img-close {
      width: 32px;
      cursor: pointer;

      svg {
         width: 14px;
         height: 26px;
         object-fit: cover;
      }
   }

   .search-input {
      padding: 8px 12px;
      display: flex;
      align-items: center;
   }

   .search-form {
      .search-box {
         width: 100%;
         position: relative;

         .input {
            width: 100%;
            height: 49px;
            border: 1px solid #e6e6e6;
            border-radius: 105px;
            padding: 15px 46px 15px 15px;
         }

         .button {
            position: absolute;
            border: none;
            outline: none;
            background: unset;
            top: 10px;
            right: 10px;
         }
      }
   }

   .main {
      height: 100%;
      overflow: auto;

      .result {
         background: #f5f5f5;
         border-radius: 8px;
         padding: 14px;

         .img {
            width: 40px;
            height: 40px;
            margin-right: 16px;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }

         .name {
            p {
               font-size: 1rem;
               font-weight: 500;
               line-height: 19px;
               margin: 0;
               height: auto;
               overflow: hidden;
               text-overflow: ellipsis;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               display: -webkit-box;
            }
         }
      }
   }
}

.consulting-channel {
   .MuiPaper-root {
      background: #ffffff;
      border-radius: 13px;
      overflow: hidden;

      .consulting-channel-item:not(:last-child) {
         border-bottom: 1px solid #e5e5e6;
      }

      .consulting-channel-item {
         background: #ffffff;
         padding: 10px 20px;
         display: block;
         text-decoration: none;
         width: 100%;
         text-align: start;

         &:focus,
         &:focus-visible {
            border: none;
            outline: none;
            box-shadow: none;
         }

         span {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            margin-left: 16px;
            color: #252a2b;
            text-decoration: none;
         }

         img {
            width: 36px;
            height: 36px;
            object-fit: cover;
         }
      }
   }
}

.notification-paper {
   .MuiPaper-root {
      max-height: unset;
      width: 360px;
      // max-height: calc(100% - 100px);
      border-radius: 24px;
      background-color: #fff;
      box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
      overflow: hidden;
   }
}

.drawer-bottom {
   .MuiPaper-root {
      background: #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 13px 13px 0px 0px;
      overflow: hidden;
   }
}

.drawer-consulting-channel {
   z-index: 1300 !important;

   .MuiPaper-root {
      background: #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 13px 13px 0px 0px;
      overflow: hidden;

      .title {
         padding: 16px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         height: 65px;
         border-bottom: 1px solid rgba(204, 204, 204, 0.5);

         span {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
         }

         .icon-close {
            background: #f2f5fa;
            backdrop-filter: blur(27.1828px);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            & > * {
               color: #cccccc;
            }
         }
      }

      .drawer-consulting-channel-list {
         margin: 16px 16px 32px 16px;
         background: #ffffff;
         border-radius: 13px;
         border: 1px solid #e5e5e6;
         overflow: hidden;
      }

      .drawer-consulting-channel-item:not(:last-child) {
         border-bottom: 1px solid #e5e5e6;
      }

      .drawer-consulting-channel-item {
         background: #ffffff;
         padding: 14px 16px;
         display: block;
         text-decoration: none;
         width: 100%;
         text-align: start;

         &:focus,
         &:focus-visible {
            border: none;
            outline: none;
            box-shadow: none;
         }

         span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-left: 16px;
            color: #252a2b;
         }

         img {
            width: 28px;
            height: 28px;
            object-fit: cover;
         }
      }

      .drawer-post-item {
         background: #ffffff;
         padding: 14px 16px;
         display: block;
         text-decoration: none;
         width: 100%;
         text-align: start;

         &:focus,
         &:focus-visible {
            border: none;
            outline: none;
            box-shadow: none;
         }

         span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-left: 16px;
            color: #252a2b;
         }

         img {
            width: 28px;
            height: 28px;
            object-fit: cover;
         }
      }

      .reward-coin {
         color: #f5c404;
         font-size: 12px;
         font-weight: 700;
         border-radius: 3px;
         background: rgba(255, 195, 35, 0.1);
         padding: 3px 8px;
      }
   }
}

.btn-close {
   position: absolute;
   top: 27px;
   right: 20px;
   width: 16px;
   height: 16px;
   background-size: 16px;
   padding: 0;
   z-index: map.get($zindex, "fixed");
   cursor: pointer;
}

.secondary-header-mobile {
   box-shadow: none !important;
}

.progress {
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0px;
}

.popover {
   border-radius: 13px !important;
   background: #fff;
   display: flex;
   flex-direction: column;
   overflow: unset !important;
   margin-top: 18px;

   .content {
      position: relative;
      padding: 14px;
   }

   .arrow-top {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);

      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
   }

   .item {
      width: 100%;
      padding: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #252a2b;
      border-radius: 5px;

      &:hover {
         color: #252a2b;
         background: rgba(0, 0, 0, 0.03);
      }

      img {
         width: 38px;
         height: 38px;
         margin-right: 12px;
      }

      .header {
         color: #1e1c1b;
         font-family: Roboto;
         font-size: 16px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 8px;

         .reward-coin {
            color: #f5c404;
            font-size: 12px;
            font-weight: 700;
            border-radius: 3px;
            background: rgba(255, 195, 35, 0.1);
            padding: 3px 8px;
         }
      }

      .description {
         color: #686463;
         font-family: Roboto;
         font-size: 12px;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
      }
   }

   .see-coin-policy {
      color: #1c77f3;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 12px 0px 0;
      cursor: pointer;
      display: block;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .menu-top {
      display: none;
   }

   header.header {
      position: sticky;

      .top {
         .logo {
            position: absolute;
            left: 80px;
            right: 80px;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "lg")) {
   .search-button,
   .cart-button,
   .user-button,
   .hamburger-button {
      margin-left: 0px;
   }

   .top {
      ul.main-menu {
         white-space: nowrap;
      }

      ul.main-menu > li .menu-item {
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
         text-transform: capitalize;
         padding: 0px 4px;
      }
   }

   .bottom {
      .menu-item {
         height: 40px;
      }

      .level-2 {
         .menu-item {
            padding: 6px 8px;

            span {
               font-size: 14px;
            }
         }
      }
   }

   .btn-scroll-to-top {
   }
}
