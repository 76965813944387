@use "sass:map";

.slick-track {
   margin-left: unset !important;
   margin-right: unset !important;
}

.st-base-slider,
.base-slider {
   width: 100%;
   position: relative;
   margin-bottom: -8px;

   img {
      width: 100%;
      object-fit: contain;
      z-index: 1;
   }

   .slick-dots {
      bottom: 8px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 16px 0;

      li {
         margin: 0;
         display: flex;
         justify-content: center;
         align-items: center;

         button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #c6c6c6;
            opacity: 0.8;
            padding: 3px;

            &::before {
               display: none;
            }
         }

         &:hover,
         &.slick-active {
            button {
               background: #ffffff;
               opacity: 1;
            }
         }
      }
   }

   .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .base-slider {
      .slick-dots {
         padding: 8px 0;
      }

      img {
         width: 100%;
         height: 100%;
         border-radius: 0px;
      }
   }
}

.customization-slider {
   .slick {
      &-list {
         height: 100% !important;
      }

      &-track {
         display: flex;
         height: 100%;
      }

      &-slide {
         height: unset;

         & > div:first-child {
            height: 100%;
         }
      }

      &-arrow {
         padding: 0px;
         width: 30px;
         height: 30px;
         z-index: 1;
      }

      &-next {
         right: 20px;
      }

      &-prev {
         left: 20px;
      }

      &-disabled {
         opacity: 0.5;
         display: none !important;
         cursor: not-allowed;
      }

      &-dots {
         position: absolute;
         bottom: 28px;
         display: flex !important;
         width: 100%;
         justify-content: center;
         gap: 9px;

         li {
            margin: 0px;
            width: 10px;
            height: 10px;

            button {
               width: 10px;
               height: 10px;
               border-radius: 50%;
               opacity: 0.8;
               background: #c6c6c6;
               padding: 0;

               &::before {
                  display: none;
               }
            }
         }

         .slick-active {
            button {
               background: #fff;
               opacity: 1;
            }
         }
      }
   }
}

.slider-social-feed {
   // margin-top: 14px;
   .slick {
      &-list {
         height: 100% !important;
      }

      &-track {
         display: flex;
         height: 100%;
      }

      &-slide {
         height: unset;

         & > div:first-child {
            height: 100%;
         }
      }

      &-arrow {
         // visibility: hidden;
         cursor: pointer;
         color: $cl-white;
         z-index: 1;
         display: flex !important;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         width: unset;
         height: unset;
         padding: 0;
         background: rgba(255, 255, 255, 0.2);
         box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.1);

         &::before {
            display: none;
         }

         img {
            min-width: 60px;
         }
         svg {
            width: 42px;
            height: 42px;
            min-width: 42px;
            min-height: 42px;
         }
         &:hover {
            svg {
               color: #fff;
            }
         }
      }

      &-next {
         right: 16px;
      }

      &-prev {
         left: 16px;
      }

      &-disabled {
         opacity: 0.5;
         display: none !important;
         cursor: not-allowed;
      }

      &-dots {
         bottom: 30px;

         li {
            margin: 0px;
            width: 12px;
            height: 12px;

            button {
               width: 12px;
               height: 12px;

               &::before {
                  line-height: 12px;
                  width: 12px;
                  height: 12px;
                  color: rgba(198, 198, 198, 0.8);
                  opacity: 0.5;
               }
            }
         }
      }

      &-active {
         margin: 0px;

         button {
            &::before {
               color: #ffffff !important;
               opacity: 1 !important;
            }
         }
      }
   }
}

.popup-ads-slider {
   .slick {
      &-list {
         height: 100% !important;
      }

      &-track {
         display: flex;
         height: 100%;
      }

      &-slide {
         height: unset;

         & > div:first-child {
            height: 100%;
         }
      }

      &-arrow {
         cursor: pointer;
         color: $cl-white;
         z-index: 1;
         display: flex !important;
         align-items: center;
         justify-content: center;
         padding: 10px;
         border-radius: 50%;

         &::before {
            font-size: 30px;
            display: none;
         }
      }

      &-next {
         right: 20px;
      }

      &-prev {
         left: 20px;
      }

      &-disabled {
         opacity: 0.5;
         display: none !important;
         cursor: not-allowed;
      }

      &-dots {
         bottom: 16px;

         li {
            margin: 0px;
            width: 12px;
            height: 12px;

            button {
               width: 12px;
               height: 12px;

               &::before {
                  line-height: 12px;
                  width: 12px;
                  height: 12px;
                  color: rgba(198, 198, 198, 0.8);
                  opacity: 0.5;
               }
            }
         }
      }

      &-active {
         margin: 0px;

         button {
            &::before {
               color: #ff6347 !important;
               opacity: 1 !important;
            }
         }
      }
   }
}

.tagging-list {
   .slick {
      &-list {
      }

      &-track {
         margin-left: unset;
         margin-right: unset;
      }

      &-arrow {
         height: 30px;
         width: 30px;
         z-index: 9;
      }

      &-next {
         right: -15px;
         width: fit-content;
      }

      &-prev {
         left: -15px;
         width: fit-content;
      }

      &-disabled {
         opacity: 0.5;
         display: none !important;
         cursor: not-allowed;
      }

      &-active {
         margin: 0px;

         button {
            &::before {
               color: #ffffff !important;
               opacity: 1 !important;
            }
         }
      }
   }
}

.tagging-list-in-dialog {
   .slick {
      &-list {
      }

      &-track {
         margin-left: unset;
         margin-right: unset;
      }

      &-arrow {
         z-index: 9;
         width: 44px;
         height: 44px;

         &:hover {
            color: #fff;
         }
      }

      &-next {
         right: 16px;
      }

      &-prev {
         left: 16px;
      }

      &-disabled {
         opacity: 0.5;
         display: none !important;
         cursor: not-allowed;
      }

      &-active {
         margin: 0px;

         button {
            &::before {
               color: #ffffff !important;
               opacity: 1 !important;
            }
         }
      }
   }
}

.magic-dots.slick-dots ul {
   padding: 0;
   display: flex;
   transition: all 0.2s;
   position: relative;
   margin: 0px;
}

.magic-dots.slick-dots li.slick-active button::before {
   color: #ffffff;
}

.magic-dots.slick-dots li button::before {
   transition: font-size 0.35s;
   font-size: 6px;
   content: "\2022";
}

.magic-dots.slick-dots li.small button::before {
   font-size: 4px;
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .customization-slider {
      .slick {
         &-dots {
            position: static !important;
            margin-top: 16px;
            gap: 4px;

            li {
               width: 5px;
               height: 5px;

               button {
                  width: 5px;
                  height: 5px;
               }
            }

            .slick-active {
               button {
                  background: #ff6347;
               }
            }
         }
      }
   }

   .slider-social-feed {
      margin-bottom: 16px;

      .slick {
         &-dots {
            bottom: -10px;

            li {
               width: 10px;
               height: 10px;
               margin: 0px;

               button {
                  width: 10px;
                  height: 10px;
                  padding: 0px;

                  &::before {
                     line-height: 12px;
                     width: 10px;
                     height: 10px;
                  }
               }
            }
         }

         &-active {
            margin: 0px;

            button {
               &::before {
                  color: #ff6347 !important;
                  opacity: 1;
               }
            }
         }
      }
   }

   .magic-dots.slick-dots li.slick-active button::before {
      color: #ff6347;
   }

   .magic-dots.slick-dots li button::before {
      font-size: 6px;
   }

   .magic-dots.slick-dots li.small button::before {
      font-size: 4px;
   }

   .tagging-list {
      .slick {
         &-arrow {
            display: none !important;
         }
      }
   }

   .popup-ads-slider {
      .slick {
         &-dots {
            bottom: 10px;
         }
      }
   }
}
