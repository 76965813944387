@import "styles/variables.scss";

.blog-result {
   padding: 16px 16px 24px;
   max-width: 804px;
   margin: 16px auto 16px;
   border-radius: 10px;
   background: #FFF;
   box-shadow: 0px 1px 4px -5px rgba(0, 0, 0, 0.10);

   @include media-down(md) {
      padding: 0 12px 36px;
      margin: 16px auto 0;
   }

   .block-body {
      border-top: 1px solid #e8eaed;
      padding-top: 16px;
      margin-bottom: 16px;
   }

   .total {
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
   }

   .group-grid {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 14px;

      @include media-down(sm) {
         gap: 8px;
      }
   }

   .list-grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 14px;

      @include media-down(sm) {
         gap: 8px;
         grid-template-columns: repeat(2, minmax(0, 1fr));
      }
   }

   .search-no-result {
      color: rgba(37, 42, 43, 0.5);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      padding-top: 24px;
   }
}