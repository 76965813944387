@use 'sass:map';

.submission {
   padding: 71.5px 0;

   &__header {
      width: 100%;
      margin-bottom: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;

      &-title {
         font-weight: 500;
         font-size: 2.375rem;
         line-height: 45px;
         text-align: center;
         text-transform: uppercase;
         color: map.get($text-colors, 'default');
         margin: 0;
      }

      &-des {
         font-weight: 700;
         font-size: 1rem;
         line-height: 19px;
         text-align: center;
         color: #252a2be1;
         margin: 0;
      }
   }

   &__tutorial {
      border: 1px solid #e8eaed;
      border-radius: 4px;
      overflow: hidden;

      min-height: 60px;
      margin-bottom: 40px;

      &.expan {
         .submission__tutorial-option {
            height: 100%;
            // min-height: 100%;
            // max-height: 100%;
            // transition: height 0.5s;
         }

         .submission__tutorial-select {
            img {
               transition: all 0.5s ease;
               transform: rotate(-90deg);
            }
         }
      }

      &-select {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 20px;
         cursor: pointer;

         span {
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            color: map.get($text-colors, 'default');
         }

         img {
            transform: rotate(90deg);
            transition: all 0.5s ease;
         }
      }

      &-option {
         padding: 0 20px;
         height: 0;

         // transition: height 0.5s;
         p {
            margin-bottom: 28px;
         }
      }
   }

   &__form {

      // display: flex;
      // flex-direction: column;
      &-item {
         margin-bottom: 20px;
         display: flex;
         flex-direction: column;

         // gap: 12px;
         &.error {

            // .label {
            //    color: #ff0000;
            // }
            .input,
            .textarea {
               border: 1px solid #ff0000;
            }
         }

         .label {
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            gap: 7px;
            color: map.get($text-colors, 'default');
            position: relative;

            &__attention {
               cursor: pointer;

               &-text {
                  position: absolute;
                  visibility: hidden;
                  width: 100%;
                  max-width: max-content;
                  background-color: #fff;
                  font-weight: 400;
                  border-radius: 4px;
                  padding: 20px;
                  z-index: 1;
                  bottom: 100%;
                  left: 0%;
                  // margin-left: -80px;
                  border: 1px solid #e8eaed;
                  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

                  p,
                  ul {
                     margin-bottom: 0;
                  }
               }

               &:hover {
                  .label__attention-text {
                     visibility: visible !important;
                     -webkit-animation: fadeIn 1s;
                     animation: fadeIn 1s;
                  }
               }

               @-webkit-keyframes fadeIn {
                  from {
                     opacity: 0;
                  }

                  to {
                     opacity: 1;
                  }
               }

               @keyframes fadeIn {
                  from {
                     opacity: 0;
                  }

                  to {
                     opacity: 1;
                  }
               }
            }
         }

         .input,
         .textarea {
            height: 60px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #e8eaed;
            padding: 20px;
            outline: none;
         }

         .textarea {
            height: 233px;
            resize: none;
         }
      }

      .text-error {
         font-size: 14px;
         line-height: 16px;
         color: #ff0000;
         margin-bottom: 0px;
         font-style: italic;
         font-weight: 400;
         margin-top: 8px;
         // text-align: right;
      }

      .btn {
         border: none;
         outline: none;
         background-color: #252a2b;
         color: #fff;
         width: max-content;
         padding: 13.5px 35px;
      }

      // .upload-img {
      //    border: 1px solid #ebebeb;
      //    border-radius: 4px;
      //    padding: 5px;
      // }
      // .result {
      //    flex-wrap: nowrap;
      //    align-items: center;
      //    justify-content: left;

      //    .img {
      //       position: relative;
      //       max-width: 500px;
      //       img {
      //          width: 100%;
      //          height: 100%;
      //          object-fit: cover;
      //       }
      //       .close {
      //          position: absolute;
      //          background: #fff;
      //          top: 0;
      //          right: 8px;
      //          margin: 8px;
      //          border-radius: 50%;
      //          padding: 8px;
      //          img {
      //             width: 1rem;
      //             height: 1rem;
      //             object-fit: cover;
      //          }
      //       }
      //    }
      // }
      .border {
         border: 1px solid #ebebeb;
         border-radius: 4px;
         padding: 5px;

         &.error {
            border: 1px solid #ff0000 !important;
         }
      }

      .upload {
         border-radius: 4px;
         height: 70px;
         position: relative;
         background: linear-gradient(0deg, #f9f9f9, #f9f9f9);
         cursor: pointer;

         #file[type='file'] {
            // display: none;
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
         }

         .label-holder {
            cursor: pointer;
            padding: 10px 0;
            width: 50px;
            height: 50px;
            background: #e1e1e1;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .label-upload {
            cursor: pointer;
            padding: 20px;
            width: fit-content;
         }

         .des {
            font-size: 16px;
            line-height: 20px;
            color: #818085;
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints, 'lg')) {
   .submission {
      &__form {
         &-item {
            margin-bottom: 16px;
         }
      }
   }
}