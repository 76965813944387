@use 'sass:map';

.spacet-shop-catalog-filter {
   margin-top: 8px;
   .filter-head {
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      .filter-head-title {
         color: #252a2b;
         font-size: 16px;
         font-weight: 700;
         line-height: 20px; /* 125% */
         letter-spacing: 0.064px;
      }
      .filter-head-right {
         color: #9f9f9f;
         font-size: 14.22px;
         font-weight: 400;
         line-height: 16px; /* 112.518% */
         letter-spacing: -0.711px;
         display: flex;
         align-items: center;
         cursor: pointer;
         svg {
            width: 16px;
            height: 16px;
            margin-left: 4px;
         }
      }
   }
   .group-filter {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #252a2b1a;
      .form-check {
         position: relative;
         margin-bottom: 16px;
         .circle {
            background-color: #fff;
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            display: none;
            &.active {
               display: flex;
            }
         }
      }
      .label-filter {
         .title {
            font-weight: 500;
            font-size: 1.15rem;
            line-height: 19px;
            color: #252a2b;
            margin-bottom: 16px;
         }
      }
      .form-check-label {
         font-size: 16px;
         line-height: 16px;
         color: #252a2b;
         margin-left: 2px;
         //  padding: 5px 0;
      }
      .form-check-input {
         width: 16px;
         height: 16px;
         &:focus {
            box-shadow: unset;
         }
         &.input-color {
            width: 24px;
            height: 24px;
            margin: 0;
            border-radius: 50%;
            position: relative;
            border: unset;
            &:checked {
               // background-image: unset !important;
               &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1;

                  width: 27px;
                  height: 27px;
                  border: 1px solid #ff6347;
                  padding: 2px;
                  background-image: unset;
                  border-radius: 50%;
               }
            }
         }
         &.input-check {
            border-radius: 50%;
            &:checked {
               border: 2px solid#ff6347;
               background-color: #ff6347;
            }
         }
         &:checked[type="checkbox"] + .fake {
            opacity: 1;
         }
      }
      .fake {
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         opacity: 0;
         background-repeat: no-repeat;
         background-size: 60%;
         background-position: center;
      }
      .form-select {
         option {
            cursor: pointer;
         }
      }

      .form-price {
         input {
            font-size: 14px;
            letter-spacing: -0.28px;
            height: 32px;
            border-radius: 32px;
            border: 1px solid #e2e4eb;
         }
         button {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px; /* 114.286% */
            height: 32px;
            border-radius: 32px;
            background: #000;
         }
      }
   }
   .btn-catalog-nav {
      border: 1px solid #252a2b;
      border-radius: 8px;
      img {
         padding-right: 12px;
      }
   }
   .blur {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: #00000070;
      z-index: 1030;
      &.active {
         display: block;
      }
   }
   .catalog-nav {
      position: fixed;
      width: 300px;
      top: 0;
      left: 0;
      overflow: auto;
      background-color: #ffffff;
      transition: transform 0.4s ease;
      box-shadow: 0 0 10px #00000070;
      z-index: 1100;
      height: 100vh;
      padding: 15px 20px !important;
      transform: translateX(-100%);
      transition: 0.5s;
      &.active {
         transition: transform 0.4s ease;
         transform: translateX(0);
         transition: 0.5s;
      }
   }
   .btn-see-more {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
   }

   input[type="checkbox"] {
      cursor: pointer;
   }
   label {
      cursor: pointer;
   }

   // @media (max-width: map.get($grid-breakpoints, "md")) {
   //    padding: 0;
   //    margin-top: 0px;
   //    .category-filter-wrap {
   //       width: 100%;
   //       padding: 8px 16px;
   //       display: flex;
   //       flex-direction: column;
   //       .category-filter {
   //          width: 100%;
   //          height: 48px;
   //          padding: 12px;
   //          display: flex;
   //          align-items: center;
   //          justify-content: space-between;
   //          border-radius: 10px;
   //          background: var(--gray-white, #fff);
   //       }
   //       .category-filter-chips,
   //       .category-filter-controls {
   //          width: 100%;
   //          display: flex;
   //          justify-content: space-between;

   //          &::-webkit-scrollbar {
   //             display: none;
   //          }
   //          .select-check {
   //             display: flex;
   //             align-items: center;
   //             color: #9f9f9f;
   //             font-size: 12.64px;
   //             line-height: 16px; /* 126.582% */
   //             letter-spacing: -0.253px;
   //             cursor: pointer;

   //             .select-text {
   //                color: #252a2b;
   //                font-size: 12.64px;
   //                line-height: 16px; /* 126.582% */
   //                letter-spacing: -0.253px;
   //                margin-left: 6px;
   //             }
   //             .arrow-down-icon {
   //                width: 8px;
   //                height: 6px;
   //                margin-left: 8px;
   //             }
   //          }
   //          .sort-fill {
   //             display: flex;
   //             align-items: center;
   //             color: #252a2b;
   //             font-size: 12.64px;
   //             line-height: 16px; /* 126.582% */
   //             letter-spacing: -0.253px;
   //             position: relative;
   //             cursor: pointer;
   //             &::before {
   //                content: "";
   //                position: absolute;
   //                left: -16px;
   //                top: 50%;
   //                transform: translateY(-50%);

   //                width: 1px;
   //                height: 24px;
   //                background-color: #e2e4eb;
   //             }

   //             svg {
   //                width: 12px;
   //                height: 12px;
   //                margin-right: 4px;
   //             }
   //          }
   //       }
   //    }
   // }
}

@media (max-width: map.get($grid-breakpoints, "md")) {
   .category-filter-wrap-mb {
      position: sticky;
      top: -1px;
      z-index: 1;

      background: #f5f5fa;

      width: 100%;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;

      &.isSticky {
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      .category-filter {
         width: 100%;
         height: 48px;
         padding: 12px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         border-radius: 10px;
         background: var(--gray-white, #fff);
      }
      .category-filter-controls {
         width: 100%;
         display: flex;
         justify-content: space-between;

         .select-check {
            display: flex;
            align-items: center;
            color: #9f9f9f;
            font-size: 12.64px;
            line-height: 16px; /* 126.582% */
            letter-spacing: -0.253px;
            cursor: pointer;
            position: relative;

            .select-text {
               color: #252a2b;
               font-size: 12.64px;
               line-height: 16px; /* 126.582% */
               letter-spacing: -0.253px;
               margin-left: 6px;
            }
            .arrow-down-icon {
               width: 8px;
               height: 6px;
               margin-left: 8px;
            }
         }
         .sort-fill {
            display: flex;
            align-items: center;
            color: #252a2b;
            font-size: 12.64px;
            line-height: 16px; /* 126.582% */
            letter-spacing: -0.253px;
            position: relative;
            cursor: pointer;
            &::before {
               content: "";
               position: absolute;
               left: -16px;
               top: 50%;
               transform: translateY(-50%);

               width: 1px;
               height: 24px;
               background-color: #e2e4eb;
            }
            &::after {
               content: "";
               position: absolute;
               top: -1px;
               left: -1px;
               width: 8px;
               height: 8px;
               background: $primary;
               border-radius: 50%;
               border: 2px solid #fff;
               display: none;
            }

            svg {
               width: 12px;
               height: 12px;
               margin-right: 4px;
            }

            &.active {
               color: $primary;
               &::after {
                  display: block;
               }
            }
         }
      }
   }
}
