@use 'sass:map';

.landing-page {
   .contact {
      .row {
         max-width: 900px;
      }

      .cover-bg {
         position: absolute;
         height: 50%;
         top: 25%;
         z-index: -1;

         left: 0;
         right: 0;
      }

      .contact-item {
         position: relative;

         &-content {
            z-index: 1;
            border: 1px solid map-get($map: $text-colors, $key: orange);
            padding: 50px 30px 20px;
            background-color: #ffffff;
            width: 100%;
            height: 100%;
         }

         &__icon {
            &::before {
               display: inline-block;
               margin-right: 40px;
            }

            &-facebook::before {
               content: url('/assets/img/landing-page/facebook.svg');
            }

            &-zalo::before {
               content: url('/assets/img/landing-page/zalo.svg');
            }

            &-phone::before {
               content: url('/assets/img/landing-page/phone.svg');
            }

            &-email::before {
               content: url('/assets/img/landing-page/mail.svg');
            }
         }

         &__type {
            font-weight: 900;
            font-size: 32px;
            line-height: 38px;

            color: map-get($map: $text-colors, $key: orange);
         }

         &__value {
            font-size: 24px;
            line-height: 23px;
            color: #000000;
         }

         .contact-item-bg {
            background: #f1ccc7;
            border: 1px solid #ee6c52;
            position: absolute;
            top: 8px;
            left: 8px;
            height: 100%;
            width: 100%;
            display: none;
         }

         &:hover {
            .contact-item-bg {
               display: block;
            }
         }
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
      .contact {
         padding-top: 60px;
      }
   }
}