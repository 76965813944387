.card-feed-item {
   position: relative;
   background: none;

   .img {
      aspect-ratio: 0.75;
      border-radius: 8px;
      overflow: hidden;
   }

   .username {
      position: absolute;
      bottom: 8px;
      left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      margin-bottom: 0px;
      text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      display: inline-table;
   }

   .avatar-socialfeed {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 10;
      width: 24px;
      height: 24px;
      border: 3px solid #ffffff;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      border-radius: 100px;
      object-fit: cover;

      @include media-up(md) {
         top: 4px;
         left: 4px;
         width: 34px;
         height: 34px;
      }
   }

   .btn-follow {
      z-index: 11;
      padding: 2px 6px;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      @include media-up(md) {
         font-size: 14px;
      }
   }

   .following {
      background-color: #0000004D;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      color: #ffffff;

      &:hover,
      &:focus,
      &:active {
         background-color: #0000004D;
         box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
         color: #ffffff;
      }
   }

   .follow {
      background: #ff6347;
      color: #ffffff;
   }

   .description_trend {
      overflow: hidden;
      color: #252A2B;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      word-break: break-word;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin-top: 8px;
   }
}