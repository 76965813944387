@import "../../../styles/variables.scss";
.shop-empty {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   .img {
      width: 114px;
      height: auto;
      @include media-down(md) {
         width: 80px;
      }
      svg {
         width: 100%;
      }
   }
   h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-top: 24px;
      @include media-down(md) {
         font-size: 14px;
         margin-top: 0;
      }
   }
   p {
      font-size: 14px;
      margin-top: 8px;
      font-weight: 400;
      max-width: 240px;
      text-align: center;
      @include media-down(md) {
         font-size: 12px;
      }
   }
}
