@use 'sass:map';
@import "styles/variables.scss";
.grid-media-1,
.grid-media-2-vertical,
.grid-media-2-horizontal,
.grid-media-3-vertical,
.grid-media-3-horizontal,
.grid-media-4-vertical,
.grid-media-4-horizontal,
.grid-media-5 {
   display: grid;
   gap: 3px;
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      aspect-ratio: 1;
      cursor: pointer;
      // max-height: 80vh;
   }
}
.grid-media-1 {
   width: 100%;
   grid-template-columns: 1fr;
   grid-template-rows: minmax(400px, auto);
   img {
      aspect-ratio: auto;
      max-height: 70vh;
   }
}
.grid-media-2-horizontal {
   grid-template-columns: 1fr 1fr;
   img {
      aspect-ratio: auto;
      max-height: 70vh;
   }
}
.grid-media-2-vertical {
   grid-template-columns: 1fr;
   grid-template-rows: minmax(400px, auto) minmax(400px, auto);
   img {
      aspect-ratio: auto;
   }
}

.grid-media-3-horizontal {
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr 1fr;
   .image-item:nth-child(1) {
      grid-area: 1/1/3/2;
   }
}
.grid-media-3-vertical {
   grid-template-columns: 1fr 1fr;
   .image-item:nth-child(1) {
      grid-area: 1/3/2/1;
      aspect-ratio: auto;
   }
}
.grid-media-4-horizontal {
   grid-template-columns: 2fr 1fr;
   grid-template-rows: 1fr 1fr 1fr;
   .image-item:nth-child(1) {
      grid-area: 1/1/4/2;
   }
}
.grid-media-4-vertical {
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-rows: 2fr 1fr;

   .image-item:nth-child(1) {
      grid-area: 1/4/2/1;
      aspect-ratio: auto;
   }
}

.grid-media-5 {
   grid-template-columns: 1fr;
   .grid-media-row-1 {
      display: grid;
      gap: 3px;
      grid-template-columns: 1fr 1fr;
   }
   .grid-media-row-2 {
      display: grid;
      gap: 3px;
      grid-template-columns: 1fr 1fr 1fr;
   }
}
.more-image {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 9;
   background-color: rgba(0, 0, 0, 0.3);
   color: #fff;
   font-size: 28px;
   font-weight: 400;
   line-height: 21px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .grid-media-1 {
      img {
         max-height: 65vh;
      }
   }
   .grid-media-2-horizontal {
      img {
         max-height: 65vh;
      }
   }
   .grid-media-2-vertical {
      img {
      }
   }
}
