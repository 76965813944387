@use "sass:map";
@import "styles/variables.scss";

section.contact-now {
   padding: 16px 0 60px 0;
   .contact-wrap {
      background: map.get($text-colors, "default");
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: none;
      height: 90px;
      .contact-img {
         width: 300px;
         margin-top: -42px;
         margin-left: -70px;
      }

      .contact-content {
         display: flex;
         justify-content: center;
         align-items: center;

         .title {
            color: #fff;
            font-size: 20px;
            line-height: 30px;
            padding-right: 50px;
            margin: 0;
            margin-left: -10px;
         }

         .btn-contact {
            background: #ff6347;

            a,
            span {
               font-weight: 500;
               font-size: 1.25rem;
               line-height: 23px;
               color: #ffffff;
               text-decoration: none;
               padding: 0.688rem;
            }
         }
      }
   }

   // Large devices (desktops, 992px and up)
   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .contact-wrap {
         height: 120px;
         border-radius: 8px;

         .contact-img {
            display: none;
         }

         .contact-content {
            flex-direction: column;

            .title {
               font-size: 1rem;
               padding: 0;
               padding-bottom: 15px;
            }

            .btn-contact {
               width: 100%;
            }
         }
      }
   }

   // X-Large devices (large desktops, 1200px and up)
   @media (max-width: map.get($grid-breakpoints, "xl")) {
      .contact-wrap {
         .contact-img {
            width: 250px;
         }

         .contact-content {
            .title {
               font-size: 16px !important;
               line-height: 25px;
            }

            .btn-contact {
               background: #ff6347;

               a,
               span {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 25px;
                  color: #ffffff;
                  text-decoration: none;
                  padding: 12px;
               }
            }
         }
      }
   }

   @media (max-width: 993px) {
      .contact-wrap {
         .contact-content {
            padding-bottom: 16px;
            .btn-contact {
            }
         }
      }
   }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
   section.contact-now {
      border-bottom: 0px;
      padding: 0px 0px 30px 0px;
   }
}

.btn-submit {
   color: #fff;
   font-size: 20px;
   font-weight: 500;
   line-height: 28px;
   white-space: nowrap;
   overflow: hidden;
   margin-top: 29px;
   height: 46px;
   width: 100%;
   max-width: 256px;
   border: unset;
   display: flex;
   align-items: center;
   justify-content: center;

   border-radius: var(--Border-Radius-borderRadiusLG, 8px);
   background: var(--Orange-orange-500, #ff6347);
   transition: opacity 0.3s ease;
   cursor: pointer;

   &:active {
      opacity: 0.6;
   }

   .tag {
      color: #252a2b;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 4px 8px;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      background: var(--yellow-yellow-500, #ffd630);
      margin-left: 8px;
      position: relative;

      @include media-down(xl) {
         display: none;
      }

      span {
         position: relative;
         z-index: 1;
      }

      &::after {
         content: "";
         position: absolute;
         left: 21px;
         top: 0px;
         transform: skew(131deg, 180deg);
         width: 23px;
         height: 24px;
         background-color: #ffeaad;
         z-index: 0;
      }
   }

   &[disabled] {
      opacity: 0.6;
   }
}
