.container {
   width: 100%;
   margin-left: auto;
   margin-right: auto;

   padding-left: 16px;
   padding-right: 16px;

   @media (max-width: 767px) {
      padding-left: 12px;
      padding-right: 12px;
   }
   &.desktop {
      width: 1182px;
   }
}

@media (min-width: 576px) {
   .container-sm,
   .container {
      max-width: 540px;
      padding-left: 16px;
      padding-right: 16px;
   }
}

@media (min-width: 768px) {
   .container-md,
   .container-sm,
   .container {
      max-width: 720px;
   }
}

@media (min-width: 992px) {
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 960px;
   }
}

// @media (min-width: 1400px) {
//    .container-xxl,
//    .container-xl,
//    .container-lg,
//    .container-md,
//    .container-sm,
//    .container {
//       max-width: 1320px;
//    }
// }

@media (min-width: 1200px) {
   .container-xl,
   .container-lg,
   .container-md,
   .container-sm,
   .container {
      max-width: 1232px;
   }
}
