@use 'sass:map';
@import 'ourDrawing';
@import 'banner';
@import 'step';
@import 'you';
@import 'procedure';
@import 'freeCost';
@import 'sendRequest';

main.connect-service-page {
    font-family: 'SourceSanPro';
    // new
    // height: 100vh;
    // overflow: hidden;
    // max-height: calc(100vh - 116px);

    &.connect-service-page-mb {
        height: unset;
        overflow: unset;
        max-height: unset;
    }

    .highlight_text {
        color: #FF5D00;
    }

    .nav_item {
        display: none;
        position: fixed;
        z-index: 1000;
        top: 20px;
        left: 100px;
    }

    .title {
        font-size: 1.5rem;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
        padding: 50px 0;

        span {
            text-decoration: underline;
        }
    }

    .btn-custom {
        background-color: #FF5D00 !important;
        color: #ffffff;
        border-radius: 10px;
        height: 64px;
        width: 235px;
        padding: 20px 30px;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: url('/assets/img/icon/arrow-right.svg');
            width: 0px;
            height: 24px;
            display: inline-block;
            margin-right: 10px;
            opacity: 0;
            transition: all 0.2s linear;
        }

        &:hover {
            background-color: #ffffff !important;
            border: 1px solid map-get($map: $text-colors, $key: orange);
            color: map-get($map: $text-colors, $key: orange);

            &::before {
                width: 30px;
                opacity: 1;
            }
        }

        &:focus {
            border: none;
        }
    }


    @media (max-width: map.get($grid-breakpoints, 'lg')) {
        .procedure {}
    }

    @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
        .procedure {}
    }
}


.section1 {
    height: 100vh !important;
    text-align: unset !important;
}

[class='fp-watermark'] {
    display: none;
}

[class='fp-right'] {
    display: none;
}