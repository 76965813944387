@use 'sass:map';

.construction-page {
    .outstanding-project {
        padding: 100px 0;
        background-color: #fff;

        .slick-dots {
            bottom: -10%;

            li {
                margin: 0 5px;
                width: 8px;
                height: 8px;

                button {
                    width: 8px;
                    height: 8px;
                    padding: 4px;
                    border-radius: 50%;
                    background-color: #ACACAC;

                    &::before {
                        display: none;
                        color: #ACACAC;

                        width: 8px;
                        height: 8px;
                    }
                }

                &:hover,
                &.slick-active {
                    button {
                        background: #252A2B;
                    }
                }
            }
        }

        .container {
            padding: 0;
        }

        .slider-wrap {
            .slick-slider {
                .slick-arrow {
                    width: 60px;
                    height: 60px;
                    z-index: 100;
                    border-radius: 50%;
                    background: transparent;

                    &::before {
                        content: url(/assets/img/construction-page/prev.png);
                        font-size: unset;
                        opacity: unset;
                        line-height: unset;
                    }
                }

                .slick-prev {
                    top: 50%;
                    left: 20px;
                }

                .slick-next {
                    top: calc(50% - 30px);
                    right: 20px;
                    transform: rotate(180deg);
                }
            }

            .slider-item {
                display: flex !important;
                align-items: center;

                .img {
                    height: 100%;
                    max-width: 50%;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #FBFBFC;
                    padding: 20px;

                    .name {
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 35px;
                        text-align: center;

                        color: #252A2B;
                        margin-bottom: 34px;
                    }

                    .rating {
                        display: flex;
                        font-weight: bold;
                        align-items: center;
                        margin-bottom: 34px;

                        img {
                            margin-right: 4px;
                        }
                    }

                    .description {
                        width: 80%;
                        background: #F5F5F5;
                        padding: 20px;
                        margin-bottom: 34px;
                    }

                    .text {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;

                        color: #252A2B;
                    }
                }
            }
        }
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
        .outstanding-project {
            padding: 32px 0;
            padding-bottom: 0;

            .slick-dots {
                display: none !important;
            }

            .slider-wrap {
                .slick-slider {
                    .slick-arrow {
                        width: 40px;
                        height: 40px;
                        z-index: 100;

                        &::before {
                            content: url(/assets/img/construction-page/prev_mobile.png);
                            width: 100%;
                            height: 100%;
                            font-size: unset;
                            opacity: unset;
                            line-height: unset;
                        }
                    }

                    .slick-prev {
                        top: 30%;
                        left: 22px;
                        transform: rotate(180deg);
                    }

                    .slick-next {
                        top: 30%;
                        right: 22px;
                        transform: rotate(0deg);
                    }
                }

                .slider-item {
                    flex-direction: column-reverse;
                    padding: 0 12px;

                    .img {
                        width: 100%;
                        max-width: unset;
                    }

                    .content {
                        padding: 20px 0;

                        .name {
                            margin-bottom: 20px;
                        }

                        .rating {
                            margin-bottom: 20px;
                        }

                        .description {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}