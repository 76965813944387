@use "sass:map";
@import "../../../styles/variables.scss";

.wrap-address-fixed-top {
   .current-address {
      padding: 12px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%), #e2e4eb;
      display: none;
      cursor: pointer;

      .location-icon {
         margin-right: 4px;
      }

      .name {
         font-size: 14.22px;
         line-height: 20px;
         /* 140.647% */
         letter-spacing: -0.142px;
         display: flex;
         align-items: center;
      }

      .btn-icon {
         width: 20px;
         height: 20px;
         margin-right: -6px;
         background: transparent;
         border: unset;
      }

      .line-bottom {
         width: 100%;
         height: 2px;
         vertical-align: bottom;
      }
   }

   .add-address {
      color: $primary;
      font-size: 14.22px;
      font-weight: 400;
      line-height: 20px;
      /* 140.647% */
      letter-spacing: -0.142px;
      text-align: center;
      width: 100%;
      padding: 12px 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      background: #fff;
   }

   .user-address {
      font-size: 12.64px;
      margin-bottom: 2px;
   }

   .full-address {
      font-size: 12.64px;
      line-height: 16px;
   }
}

.spacet-payment {
   background: #f5f5f5;
   padding-bottom: 16px;

   @include media-down(md) {
      padding-bottom: 0px;
   }

   .spacet-payment-container {
      max-width: $container-width;
      padding-left: $container-padding;
      padding-right: $container-padding;
      padding-top: 24px;
      margin: 0 auto;

      @include media-down(md) {
         padding: 46px 0 0;

         &.isAvailabled {
            padding: 0;
         }
      }
   }

   .gird-container {
      display: grid;
      grid-template-columns: 3fr minmax(420px, 1fr);
      grid-gap: 16px;

      @include media-down(md) {
         grid-template-columns: 1fr;
         grid-template-rows: auto;
         grid-gap: 8px;
      }
   }

   .mb-16 {
      margin-bottom: 16px;
   }

   .left-side {
      .address {
         width: 100%;
         overflow: hidden;

         .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            background: #fff;

            @include media-down(md) {
               height: 44px;
               padding: 12px;
               border-top: 1px solid rgba(0, 0, 0, 0.05);
               background: #fff;
               justify-content: center;
            }

            .title {
               display: flex;
               align-items: center;

               img {
                  margin-right: 6px;
               }
            }

            .add-new {
               color: $primary;
               font-size: 16px;
               font-weight: 400;
               line-height: 16px;
               /* 100% */
               cursor: pointer;

               @include media-down(md) {
                  font-size: 14.22px;
               }
            }
         }

         .main {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            background: #fff;

            .current-user-address {
               padding: 16px 24px 0;

               @include media-down(md) {
                  padding: 12px 12px 0;
                  margin-bottom: -12px;
               }

               .w-name {
                  display: flex;
                  margin-bottom: 8px;

                  @include media-down(md) {
                     margin-bottom: 4px;
                  }

                  .name {
                     font-size: 16px;
                     font-weight: 600;
                     margin-right: 4px;

                     @include media-down(md) {
                        font-size: 14.22px;
                        line-height: 24px;
                        /* 140.647% */
                        letter-spacing: -0.142px;
                     }
                  }

                  .phone {
                     font-size: 16px;
                     font-weight: 400;
                     margin-left: 4px;

                     @include media-down(md) {
                        font-size: 14.22px;
                        line-height: 24px;
                        /* 140.647% */
                        letter-spacing: -0.142px;
                     }
                  }
               }

               .user-address {
                  color: #252a2b;
                  font-size: 14px;
                  margin-bottom: 8px;

                  @include media-down(md) {
                     color: $span;
                     margin-bottom: 0;
                  }
               }

               .full-address {
                  color: #252a2b;
                  font-size: 14px;

                  @include media-down(md) {
                     color: $span;
                  }
               }

               .btn-edit {
                  color: $primary;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 16px;
                  /* 100% */
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  @include media-down(md) {
                     font-size: 12px;
                     height: fit-content;
                  }

                  svg {
                     margin-left: 5px;
                  }
               }
            }

            img {
               width: 100%;
               height: 3px;
               vertical-align: bottom;
            }
         }
      }

      .wrap-order {
         margin-top: 12px;
         background-color: #fff;

         .order-grid {
            display: grid;
            grid-template-columns: minmax(200px, 3fr) 1fr 1fr 2fr;
         }

         .brand-info {
            overflow: hidden;
            color: $span;
            font-size: 14px;
            line-height: 16px;
            /* 114.286% */
            background: #fff;
            padding: 16px 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            .brand-name {
               color: #252a2b;
               font-size: 16px;
               font-weight: 600;
               line-height: normal;
            }
         }

         .order-item {
            background: #fff;
            padding: 16px 24px;

            .info-product {
               display: flex;
               flex-direction: row;

               .img-product {
                  width: 64px;
                  height: 64px;
                  object-fit: cover;
                  object-position: center;
                  margin-right: 16px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                     width: 100%;
                     height: auto;
                  }
               }

               .info {
                  .name {
                     text-overflow: ellipsis;
                     font-size: 16px;
                     line-height: 20px;
                     /* 125% */
                     margin-bottom: 8px;
                  }

                  .variant {
                     color: $span;
                     font-size: 14px;
                     line-height: 16px;
                     /* 114.286% */
                     display: flex;
                     align-items: center;
                  }
               }
            }

            .text {
               font-size: 16px;
               line-height: 16px;
            }

            .count {
               font-size: 14px;
               line-height: 16px;
            }
         }

         .item-bottom {
            padding: 24px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            background: #fff;

            span.total {
               color: $span;
               font-size: 16px;
               line-height: 16px;
            }

            .total-price {
               font-size: 16px;
               font-weight: 700;
               line-height: 16px;
               /* 100% */
               margin-left: 40px;
            }
         }
      }

      .wrap-order-mb {
         padding: 0 12px;
         margin-top: 8px;
         background-color: #fff;

         .wrap-voucher {
            & > div {
               padding: 14px 0;
               border-bottom: 1px solid $border;
               border-top: unset;
            }
         }

         .brand-info {
            overflow: hidden;
            color: $span;
            font-size: 14px;
            line-height: 16px;
            /* 114.286% */
            background: #fff;
            padding: 12px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            .brand-name {
               color: #252a2b;
               font-size: 16px;
               font-weight: 600;
               line-height: normal;
               overflow: hidden;
               text-overflow: ellipsis;
               font-size: 14.22px;
               line-height: 20px;
               /* 140.647% */
               letter-spacing: -0.142px;
            }
         }

         .order-item {
            background: #fff;
            padding: 12px 0;
            border-bottom: 1px solid $border;

            .info-product {
               width: 100%;
               display: flex;
               flex-direction: row;

               .img-product {
                  width: 64px;
                  height: 64px;
                  object-fit: cover;
                  object-position: center;
                  margin-right: 8px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 8px;

                  img {
                     width: 100%;
                     height: auto;
                  }
               }

               .info {
                  flex-grow: 1;
                  margin-bottom: 6px;

                  .name {
                     text-overflow: ellipsis;
                     font-size: 16px;
                     line-height: 20px;
                     /* 125% */
                     margin-bottom: 8px;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     font-size: 14.22px;
                     line-height: 20px;
                     /* 140.647% */
                     letter-spacing: -0.142px;
                     margin-bottom: 4px;
                  }

                  .variant {
                     color: $span;
                     line-height: 16px;
                     /* 114.286% */
                     display: flex;
                     align-items: center;

                     font-size: 11.24px;
                     letter-spacing: -0.112px;
                     margin-bottom: 8px;
                  }
               }

               .price {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20px;
                  /* 125% */
                  letter-spacing: -0.16px;
               }

               .count {
                  color: $span;
                  font-size: 12.64px;
                  line-height: 16px;
                  /* 126.582% */
                  letter-spacing: -0.126px;
               }
            }

            .text {
               font-size: 16px;
               line-height: 16px;
            }

            .count {
               font-size: 14px;
               line-height: 16px;
            }
         }

         .item-bottom {
            padding: 16px 0 16px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            background: #fff;

            span.total {
               color: $span;
               font-size: 12.64px;
               line-height: 16px;
            }

            .total-price {
               font-size: 16px;
               font-weight: 500;
               line-height: 16px;
               /* 100% */
               margin-left: 6px;
            }
         }
      }
   }

   .right-side {
      position: sticky;
      top: 62px;
      height: fit-content;

      .title-info {
         font-size: 18px;
         line-height: 24px;
         margin-bottom: 16px;

         @include media-down(md) {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            padding: 12px 0;
            margin: 0;
         }
      }

      .payment-method {
         padding: 16px;
         background: #fff;
         margin-bottom: 16px;

         @include media-down(md) {
            padding: 8px 16px;
            margin-bottom: 8px;
         }

         .main {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            @include media-down(md) {
               row-gap: 0;
            }

            .payment-item {
               padding: 12px;
               border-radius: 10px;
               border: 1px solid #e2e4eb;
               display: flex;
               justify-content: space-between;

               @include media-down(md) {
                  border: unset;
                  padding: 12px 0;
                  border-radius: 0;

                  &:not(:last-child) {
                     border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                  }
               }

               .payment-left {
                  display: flex;
                  align-items: center;

                  .icon {
                     width: 40px;
                     height: 40px;
                     border-radius: 6px;
                     margin-right: 12px;
                     display: flex;
                     align-items: center;

                     @include media-down(md) {
                        width: 32px;
                        height: 32px;
                     }

                     img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                     }
                  }

                  .payment-type {
                     font-size: 16px;
                     line-height: 20px;
                     margin-bottom: 4px;

                     @include media-down(md) {
                        font-size: 12.64px;
                     }
                  }

                  .payment-des {
                     color: #9f9f9f;
                     font-size: 14px;
                     line-height: 16px;

                     /* 114.286% */
                     @include media-down(md) {
                        font-size: 11.24px;
                     }

                     img {
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                     }

                     a {
                        color: $primary;
                        font-size: 14px;
                        line-height: 16px;
                        /* 114.286% */
                        text-decoration: underline;

                        @include media-down(md) {
                           font-size: 12.64px;
                        }
                     }

                     p {
                        margin: 0;
                     }
                  }
               }
            }
         }
      }

      .discount {
         padding: 12px 0;
         background: #fff;
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-bottom: 1px solid #f5f5fa;

         @include media-down(md) {
         }

         .select-discount {
            color: $primary;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
               margin-left: 6px;
            }
         }

         span {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;

            /* 100% */
            @include media-down(md) {
               font-size: 12.64px;
            }
         }

         .label-right {
            font-size: 14px;

            @include media-down(md) {
               font-size: 12.64px;
            }
         }
      }

      .cart-wrap-coin {
         // padding: 0 16px;
         // background: #fff;
         // margin-bottom: 8px;

         .cal-coin {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-down(md) {
               font-size: 12.64px;
               padding: 12px 0 0;
            }

            .swap-coin {
               font-size: 14px;
               color: $span;

               @include media-down(md) {
                  font-size: 11.24px;
               }
            }
         }

         .use-coin-current {
            padding: 16px 0;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            border-bottom: 1px solid $border;

            @include media-down(md) {
               font-size: 12.64px;
               padding: 12px 0;
            }

            svg {
               margin-right: 6px;
            }
         }
      }

      .summary-box {
         padding: 16px;
         background: #fff;

         @include media-down(md) {
            padding: 12px 16px 16px;
         }

         .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            /* 88.889% */
            padding: 16px 0;

            @include media-down(md) {
               font-size: 14px;
               font-weight: 500;
               padding: 12px 0;
            }
         }

         .info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 0 6px;

            .label {
               font-size: 14px;
               font-weight: 400;
               line-height: 16px;

               /* 100% */
               @include media-down(md) {
                  font-size: 12.64px;
               }
            }

            .price {
               font-size: 14px;
               font-weight: 500;
               line-height: 20px;
               /* 125% */
               letter-spacing: -0.16px;

               @include media-down(md) {
                  font-size: 14.22px;
               }
            }
         }

         .coin-use {
            padding: 8px 0 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border;

            @include media-down(md) {
               font-size: 12.64px;
               padding: 8px 0 12px;
            }

            .value {
               color: $primary;
               font-size: 16px;
               font-weight: 500;
               line-height: 20px;
               /* 125% */
               letter-spacing: -0.16px;

               @include media-down(md) {
                  font-size: 14.22px;
               }
            }
         }

         .total-calculate {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0 8px;

            @include media-down(md) {
               padding: 12px 0 8px;
               border-top: 1px solid $border;
            }

            .label {
               font-size: 14px;
               font-weight: 600;
               line-height: 16px;

               /* 114.286% */
               @include media-down(md) {
                  font-size: 12.64px;
               }
            }

            span {
               color: $span;
               font-size: 12px;
               font-weight: 400;
               line-height: 16px;

               /* 133.333% */
               @include media-down(md) {
                  font-size: 11.24px;
               }
            }

            .value {
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;

               /* 133.333% */
               @include media-down(md) {
                  font-size: 16px;
               }
            }
         }

         .reward-coin {
            color: $span;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
            letter-spacing: -0.12px;
            display: flex;
            justify-content: flex-end;

            .ask-icon {
               width: 16px;
            }
         }
      }

      .wrap-action {
         padding: 16px 0;

         @include media-down(md) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 70px;
            padding: 4px 16px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
         }

         .total-price {
            display: flex;
            flex-direction: column;

            & > span {
               font-size: 14px;
               font-weight: 600;
               margin-right: 6px;
            }

            .price {
               color: $primary;
               font-size: 18px;
               font-weight: 500;
               line-height: 24px;

               & > span {
                  text-decoration: underline;
                  margin-left: 3px;
               }
            }
         }

         button {
            @include media-down(md) {
               border-radius: 8px;
               height: 40px;
               width: 113px;
            }
         }
      }
   }

   .delivery-info {
      padding: 16px 24px;
      background-color: #fff;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.1);

      @include media-down(md) {
         padding: 12px 0;
      }

      .label {
         margin-bottom: 8px;
      }

      .delivery-price {
         font-size: 16px;
         font-weight: 400;
         line-height: 16px;
      }

      .to-address {
         display: flex;
         align-items: center;
         font-size: 14px;
         color: $span;
         line-height: 16px;
      }
   }
}

.dialog-add-discount {
   .modal-body {
      overflow: hidden;
   }

   .dialog-add-discount-body {
      padding: 16px 8px 16px 16px;
      // min-height: calc(700px - 56px);
      // max-height: calc(700px - 56px);
      height: calc(100vh - 97px - 56px - 64px);
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;

      @include media-down(md) {
         padding: 16px 8px 96px 16px;
         min-height: calc(100dvh - 56px);
         max-height: calc(100dvh - 56px);
      }

      // &::-webkit-scrollbar {
      //    width: 5px;
      // }

      // &::-webkit-scrollbar-thumb {
      //    background-color: rgba(0, 0, 0, .3);
      //    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      //    border-radius: 8px;
      // }

      .item-center {
         height: 400px;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
      }

      .winput-search {
         display: flex;
         align-items: center;
         border-radius: 32px;
         background: rgba(226, 228, 235, 0.3);
         position: relative;

         .errorinput {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;

            width: 80%;
            margin: 0 auto;
            padding: 16px 8px;
            background-color: #fff9fa;
            border: 1px solid #ff424f;
            border-radius: 2px;

            font-size: 14px;
            line-height: 16px;

            display: flex;
            align-items: center;
            z-index: 1;

            svg {
               border: 1px solid #ff424f;
               border-radius: 50%;
               margin-right: 8px;
            }
         }

         .input {
            font-size: 16px;
            height: 48px;
            border-radius: 0;
            padding: 12px 16px;
            border: unset;

            ::placeholder {
               color: #9f9f9f;
               font-weight: 300;
            }
         }

         .btn-discount {
            font-size: 12.64px;
            white-space: nowrap;
            min-width: 70px;
         }
      }

      .content {
         display: flex;
         flex-direction: column;
         padding: 16px 0 0;
         margin-top: 16px;
         border-top: 1px solid #eef2f5;
      }

      .search-voucher-result {
         padding: 12px 0 0;
      }

      .no-discount-title {
         font-size: 16px;
         font-weight: 500;
         margin-top: 16px;
      }

      .no-discount-des {
         font-size: 14px;
         margin-top: 4px;
         max-width: 220px;
         text-align: center;
      }

      .voucher-list {
         width: 100%;
         margin-bottom: 12px;

         .loadmore {
            text-align: center;
         }
      }

      .wrap-voucher-item {
         border-radius: 10px;
         background: var(--Mobile, #f5f5fa);
         box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.15);
         margin-bottom: 12px;
         box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.15);
      }

      .voucher-item {
         border-radius: 10px;
         background: #fff;

         .top {
            display: flex;
            justify-content: space-between;
            padding: 8px;
         }

         .voucher-condition {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid var(--gray-gray-200, #eef2f5);
         }
      }

      .voucher-recommend {
         padding: 4px 8px;
      }
   }

   .wrap-action {
      width: 100%;
      padding: 24px;
      display: flex;
      justify-content: center;
      border-top: 1px solid $border;
      background: #fff;

      @include media-down(md) {
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         z-index: 1;
      }

      button {
         width: 100%;
         height: 48px;
         max-width: 327px;
         margin: 0 auto;
      }
   }
}

.dialog-bank-transfer {
   &-body {
      padding: 24px 24px 0;
      background: unset;

      @include media-down(md) {
         padding: 12px;
         background: #f5f5f5;
         overflow: auto hidden;
      }

      .box-qr-code {
         @include media-down(md) {
            padding: 12px;
            border-radius: 8px;
            background: #fff;
            display: flex;
            justify-content: center;
         }
         .qr-code {
            width: fit-content;
            padding: 12px;
            border-radius: 16px;
            background: #eef2f5;
         }
         img {
            width: 300px;
            height: 300px;
            min-width: 300px;
            min-height: 300px;
            border-radius: 12px;
            box-shadow: 0px 10px 35px -10px rgba(37, 42, 43, 0.2);
            @include media-down(md) {
               width: 207px;
               height: 207px;
               min-width: 207px;
               min-height: 207px;
            }
         }
      }
      .bg-note {
         padding: 12px;
         border-radius: 8px;
         background: #f5f5f5;
         margin-left: 12px;
         @include media-down(md) {
            background-color: #fff;
            margin-left: 0;
            margin-top: 8px;
         }
         .count {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            width: 24px;
            min-width: 24px;
            height: 24px;
            border-radius: 4px;
            background: #ff6347;
            margin-right: 8px;
            @include media-down(md) {
               font-style: 14px;
               width: 20px;
               min-width: 20px;
               height: 20px;
            }
         }

         ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
               &:not(:last-child) {
                  margin-bottom: 12px;
               }
            }
         }
      }

      hr {
         margin: 0;
         border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      .wrap-note {
         padding: 12px 0 0;

         .label {
            font-size: 14.22px;
            line-height: 23px;
            /* 161.744% */
            letter-spacing: -0.142px;
            text-decoration: underline;
         }

         .note-text {
            color: $span;
            font-size: 12.64px;
            line-height: 16px;
            letter-spacing: -0.126px;
         }
      }
   }

   .wrap-action {
      padding: 12px 24px 24px;
      display: flex;
      justify-content: center;
      @include media-down(md) {
         width: 100%;
         position: sticky;
         bottom: 0;
         padding: 12px;
         background-color: #fff;
      }
      button {
         width: 300px;
         height: 40px;

         @include media-down(md) {
            width: 100%;
         }
      }
   }
}
