@import "styles//variables.scss";
.construction-page {
   .top-page {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      gap: 0px;
   }
   .block_banner {
      position: relative;
      background: #ffffff;
      padding: 18px 0 0px;
      @include media-down(md) {
         padding: 0;
         margin-bottom: 0;
      }
   }
   .st-form {
      width: 100%;
      max-width: 1200px;
      @include media-down(xl) {
         max-width: 880px;
      }
      @include media-down(md) {
         position: relative;
         padding: 0 10px 10px 10px;
      }
   }
}
