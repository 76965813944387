@import "styles/variables.scss";

.post-creation {
   display: block;
   z-index: 1301;

   @include media-down(md) {
      display: none;
   }

   .close-icon {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 32px;
      height: 32px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 80px;
      background: #F4F4F4;

      svg {
         width: 12px;
         height: 12px;
      }
   }

   .MuiPaper-root {
      width: 100%;
      max-width: 886px;
      border-radius: 10px;
      background: #FAFAFA;
   }

   &__content {
      padding: 64px;
      display: flex;
      flex-direction: row;
   }

   &__left {
      flex: 1 1 50%;
      padding-right: 32px;

      img {
         width: 100%;
         height: auto;
         object-fit: contain;

         border-radius: 16px;
         border: 1px solid rgba(0, 0, 0, 0.10);
         background: #FFF;
         box-shadow: 0px 4px 50px -5px rgba(0, 0, 0, 0.05);
      }

   }

   &__right {
      flex: 1 1 50%;
      padding-left: 32px;

      &__top {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 50px 0 24px;
         border-bottom: 1px dotted rgba(0, 0, 0, 0.20);

         &__title {
            color: #252A2B;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.2px;
         }

         &__icon {
            width: 52px;
            height: 48px;
            object-fit: contain;
         }
      }

      &__bot {
         padding-top: 24px;

         &__title {
            color: #252A2B;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            margin-bottom: 8px;
         }

         &__des {
            color: #252A2B;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
         }

         &__actions {
            margin-top: 56px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
               width: 100%;
               height: 52px;
               border: unset;
               text-align: center;
               font-size: 16px;
               font-weight: 600;
               line-height: 20px;
               cursor: pointer;

               &.other {
                  color: #FF6347;
                  border-radius: 100px;
                  border: 1px solid #FF6347;
                  margin-right: 12px;
                  background: #fff;
               }

               &.create-post {
                  color: #fff;
                  border-radius: 100px;
                  background: #FF6347;
                  margin-left: 12px;
               }
            }
         }
      }

   }

}

.post-creation-mobile {
   display: none;
   z-index: 1301;

   @include media-down(md) {
      display: block;
   }

   .MuiPaper-root {
      width: 100%;
      height: 100%;
      background: #FAFAFA;
   }

   &__content {
      padding: 37px 16px 8px;
      display: flex;
      flex-direction: column;
   }

   &__header {
      padding-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.20);

      &__title {
         color: #252A2B;
         font-size: 20px;
         font-weight: 600;
         line-height: normal;
         letter-spacing: 0.2px;
      }

      &__icon {
         width: 52px;
         height: 48px;
         object-fit: contain;
      }
   }

   &__bot {
      padding-top: 16px;

      &__title {
         color: #252A2B;
         font-size: 14px;
         font-style: normal;
         font-weight: 600;
         line-height: 18px;
         margin-bottom: 8px;
      }

      &__des {
         color: #252A2B;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 18px;
         margin-bottom: 16px;
      }

      &__img {
         width: 100%;
         height: auto;
         object-fit: contain;

         border: 1px solid rgba(0, 0, 0, 0.10);
         background: #FFF;
         box-shadow: 0px 4px 50px -5px rgba(0, 0, 0, 0.05);
      }

      &__actions {
         margin-top: 56px;

         display: flex;
         align-items: center;
         flex-direction: column;

         button {
            width: 100%;
            height: 52px;
            border: unset;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            cursor: pointer;

            &.other {
               color: #666;
               border: unset;
               background: transparent;
               margin-top: 8px;
            }

            &.create-post {
               color: #fff;
               border-radius: 100px;
               background: #FF6347;
            }
         }
      }
   }


}