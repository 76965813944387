@use "sass:map";
@import "styles/variables.scss";

.actual-detail-page {
   @include media-down(md) {
      padding-bottom: $NAVIGATOR_HEIGHT;
   }
   .post-wrap {
      img {
         width: 100% !important;
         height: 100% !important;
         object-fit: cover;
         z-index: 1;
      }
   }

   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   .icon_like {
      width: unset;
      height: unset;
      object-fit: cover;
   }

   // margin: 50px 0;

   .actual-detail {
      margin: 28px 0;

      .hidden-lg {
         display: none;
      }

      .action-follow {
         color: map.get($text-colors, orange);
         background: none;
         outline: none;
         border: none;
      }

      .review {
         // align-items: center;
         margin-top: 30px;
         // padding-bottom: 14px;

         &__header {
            border-top: 1px solid #e8eaed;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            padding: 12px 0;
            gap: 4px;

            &-total {
               color: #ff6347;
            }

            p {
               margin-bottom: 0;
            }
         }

         .avatar {
            min-width: 34px;
            min-height: 34px;
            border: 1px solid #f0f0f0;
            border-radius: 50%;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
         }

         .form {
            position: relative;

            .input,
            .input-custom {
               &.error {
                  input {
                     border: 1px solid #ff6347;
                  }

                  span {
                     font-size: 0.875rem;
                     line-height: 14px;
                     color: #ff6347;
                     // text-align: justify;
                  }
               }

               input {
                  width: 100%;
                  height: 34px;
                  border-radius: 4px;
                  padding: 10px;
                  border: 1px solid #e8eaed;
                  outline: none;
               }

               .btn {
                  position: absolute;
                  top: 1px;
                  right: 1px;
                  height: 32px;
                  background-color: #fff;
                  font-weight: bold;
               }
            }
         }
      }

      .actual-detail-post {
         .top-post {
            //modal review
            .top-img-post {
               position: relative;

               .cover {
                  position: absolute;
                  content: "";
                  z-index: 10;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }

               .rule-tag {
                  position: absolute;
                  width: 108px;
                  height: 40px;
                  right: 12px;
                  top: 2.16%;
                  background: #252a2b;
                  border-radius: 4px;
                  opacity: 1;
                  margin: 12px;
                  padding: 8px;

                  .des {
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 21px;
                     color: #fff;
                     /* identical to box height */

                     text-align: center;
                     text-transform: capitalize;
                  }
               }
            }

            .title {
               text-align: left;
               font-size: 1.5rem;
               margin: 20px 0;
            }

            .title_post {
               margin: 20px 0;
               text-align: left;
               font-size: 1.5rem;
            }

            .user-wrap {
               display: flex;
               align-items: center;
               gap: 8;

               > div.avartar {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background-color: #ddd;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  margin-right: 8px;
               }

               > p,
               &__info {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  color: #252a2b;
                  opacity: 0.7;
                  margin-bottom: 0;
               }

               &__time {
                  font-size: 14px;
               }
            }

            .top-meta-post {
               display: flex;
               justify-content: flex-start;
               align-items: center;

               .meta-item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: max-content;
                  height: 48px;
                  background: #f0f0f0;
                  border-radius: 46px;
                  padding: 14px;

                  img {
                     padding-right: 13px;
                     opacity: 0.7;
                     width: initial;
                  }

                  .des {
                     font-size: 16px;
                     line-height: 16px;
                     margin: 0;
                     color: map.get($text-colors, "default");
                     opacity: 0.7;
                     span {
                        background: unset;
                        margin: 0px;
                     }
                     a {
                        color: map.get($text-colors, "default");
                        text-decoration: none;
                     }
                  }
               }

               span {
                  width: 1px;
                  height: 25px;
                  background: map.get($text-colors, "default");
                  opacity: 0.7;
                  margin: 0 16px;
               }

               .meta-link {
                  a {
                     font-size: 16px;
                     line-height: 16px;
                     color: map.get($text-colors, "default");
                     opacity: 0.7;
                     text-decoration: none;
                  }
               }
            }

            .top-variant-post {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               margin-top: 20px;

               img {
                  width: initial;
               }

               p {
                  margin: 0;
               }

               .meta-item-end {
                  &::before {
                     display: none;
                  }
               }

               .meta-link {
                  a {
                     font-size: 16px;
                     line-height: 16px;
                     color: map.get($text-colors, "default");
                     opacity: 0.7;
                     text-decoration: none;
                  }
               }

               .meta-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  position: relative;

                  // &.bg-gray {
                  //     width: max-content;
                  //     height: 48px;
                  //     background: #f0f0f0;
                  //     border-radius: 46px;
                  //     padding: 14px;
                  // }
                  img {
                     // opacity: 0.8;
                     width: 18px;
                     height: 18px;
                     margin-right: 13px;
                  }

                  .des {
                     font-size: 16px;
                     line-height: 16px;

                     a {
                        color: map.get($text-colors, "default");
                        text-decoration: none;
                     }

                     b {
                        font-weight: 400;
                     }
                     span {
                        background: unset;
                        margin: 0px;
                     }
                  }

                  // &::before {
                  //     position: absolute;
                  //     content: '';
                  //     width: 1px;
                  //     height: 20px;
                  //     background: map.get($text-colors, 'default');
                  //     opacity: 0.7;
                  //     margin: 0 16px;
                  //     right: -18px;
                  // }
               }
            }
         }

         .content-post {
            .des {
               font-weight: 500;
               font-size: 13px;
               line-height: 23px;
               margin: 32px 0;

               strong {
                  font-weight: bold;
               }

               h4 {
                  font-weight: bold;
               }
            }
         }
      }
   }

   // Small devices (landscape phones, 576px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: 0;
      .top-variant-post {
         .meta-item-middle {
            &::before {
               display: none;
            }
         }
      }
      .actual-detail {
         margin: 16px 0 0;
      }
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .hidden-sm {
         display: none;
      }

      .hidden-lg {
         display: block !important;
         margin-top: 28px;
         box-shadow: unset !important;
         box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.02);

         border: 1px solid #dddddd;
         position: static !important;
      }
   }
}
