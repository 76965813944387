.winput-hyper {
   position: relative;
   // overflow: auto;
}
.link-input {
   color: blue;
}
.input-textarea {
   font-weight: 400;
   width: 100%;
   // height: 100%;
   min-height: 120px;
   padding: 16px 4px 16px 4px;
   border: unset;
   &:focus {
      outline: unset;
   }
}
.render-overlay {
   font-weight: 400;
   white-space: pre-wrap;
   overflow-wrap: break-word;
   width: 100%;
   height: 100%;
   min-height: 150px;
   padding: 16px 4px 16px 4px;
   position: absolute;
   left: 0;
   top: 0;
   pointer-events: none;
   z-index: 1;
   a {
      color: #05a;
   }
}
.mentions-tags {
   position: absolute;
   z-index: 1300;
   background: red;
}
