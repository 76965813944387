.product-cardv3 {
   .sale-label {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 1;

      color: #fff;
      font-size: 8px;
      font-weight: 700;
      line-height: 14px;
      border-radius: 4px;
      padding: 2px 4px;
      background-color: $primary;
      border: 1px solid var(--Orange-orange-400, #ff826c);

      display: flex;
      align-items: center;
      svg {
         margin-right: 4px;
      }
   }
}

.product-cardv3.primary {
   transition-duration: 0.2s;
   transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
   position: relative;

   .product-cardv3-info {
      padding: 4px;
      border-radius: 10px;
      display: flex;
      border: 1px solid;
      border-color: transparent;
      background: #fff;
      margin: -4px;
      @include media-down(md) {
         margin: 0;
         padding: 0;
         border: unset;
         background: #fff;
         box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.1);
         border-radius: 13px;
      }
   }

   &.fixedHeight {
      --aspect-ratio: 152%;
      position: relative;
      &::before {
         display: block;
         padding-top: var(--aspect-ratio);
         content: "";
      }
      & > * {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
      }
      @include media-down(md) {
         --aspect-ratio: 170%;
      }
   }

   .product-card-image {
      position: relative;
      width: 100%;
      max-width: 134px;
      overflow: hidden;
      border-radius: 10px;

      .wrap-img {
         aspect-ratio: 1;
         position: relative;
         border-radius: 10px;
         overflow: hidden;
         border: 1px solid #e2e4eb;
         background: #eae9e9;
         &::before {
            content: "";
            display: block;
            padding-top: var(--bs-aspect-ratio);
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      .tag-campain {
         position: absolute;
         left: 0;
         bottom: 0px;
         width: 92%;
         height: 20px;
         object-fit: contain;
      }
      .product-stock-out {
         position: absolute;
         top: 50%;
         left: 50%;
         right: unset;
         bottom: unset;
         transform: translate(-50%, -50%);
         z-index: 1;

         width: 64px;
         height: 64px;
         border-radius: 50%;

         color: #fff;
         font-size: 12px;
         font-weight: 500;
         line-height: 16px;
         background: rgba(37, 42, 43, 0.9);
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }

   &:hover {
      @include media-up(md) {
         --tw-skew-x: 0;
         --tw-skew-y: 0;
         --tw-scale-x: 1;
         --tw-scale-y: 1;
         --tw-rotate: 0;
         --tw-translate-x: 0;
         --tw-translate-y: -1px;
         transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
            scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
         .product-cardv3-info {
            border-color: #ff6347;
            box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
            background: #fff;
         }
      }
      .wrap-img {
         border: unset;
      }
   }

   .product-item-detail {
      width: 100%;
      padding: 8px;
      .name {
         color: #252a2b;
         font-size: 12px;
         font-weight: 400;
         line-height: 16px; /* 126.582% */
         letter-spacing: -0.126px;
         min-height: 34px;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-line-break: 2;
         line-break: 2;
         overflow: hidden;
         text-overflow: hidden;
      }

      .price-wrap {
         display: flex;
         flex-direction: column;
      }

      .price,
      .discount-price {
         color: #ff6347;
         font-size: 16px;
         font-weight: 500;
         line-height: 20px; /* 100% */
         margin-right: 3px;
         white-space: nowrap;
         margin-bottom: 8px;
         @include media-down(md) {
            font-size: 16px;
            margin-bottom: 8px;
         }
         span {
            line-height: 1;
         }
      }

      .wrap-real-price {
         display: flex;
         align-items: center;
      }
      .real-price {
         color: #9f9f9f;
         font-size: 12px;
         font-weight: 400;
         line-height: 12px; /* 100% */
         white-space: nowrap;
         text-decoration-line: strikethrough;
         text-decoration: line-through;
         margin-right: 8px;
      }
      .percent-price {
         color: #ff383b;
         font-size: 12px;
         font-weight: 600;
         width: fit-content;
         min-width: 35px;
         padding: 2px 4px;
         height: 16px;
         display: block;
         white-space: nowrap;
         border-radius: 4px;
         background: #ffe6e8;
         display: flex;
         align-items: center;
         justify-content: center;
         position: relative;
         @include media-down(md) {
            width: fit-content;
            min-width: 26px;
            font-size: 12px;
         }
         &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -5px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 6px solid #ffe6e8;
         }
      }
   }
   .selling-action {
      display: flex;
      align-items: center;
      margin-top: 14px;
      justify-content: space-between;
   }
   .process-selling {
      position: relative;
      width: 100%;
      max-width: 100px;
      height: 14px;
      border-radius: 100px;
      background: #697f90;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      .process-filler {
         content: "";
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         border-radius: 100px;
         background: $primary;
         z-index: 1;
         width: 60%;
      }
      .process-text {
         z-index: 2;
         display: flex;
         align-items: center;
         justify-content: center;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
         svg {
            width: 7px;
            margin-right: 2px;
         }
      }
   }
   .wbutton-buy-now {
      width: 100%;
      max-width: 83px;
      button {
         font-size: 12px;
         font-weight: 500;
         line-height: 16px;
         white-space: nowrap;
         padding: 0 6px;
         width: 100%;
         height: 24px;
         border-radius: 100px;
      }
   }
}
