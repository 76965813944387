.loading {
   opacity: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
   display: flex;
   position: fixed;
   align-items: center;
   justify-content: center;
   background-color: rgba(var(--dark-rgb), 0.2);

   &.active {
      opacity: 1;
      z-index: 1000000;
      background: #fff;
      opacity: 0.3;
   }

   img {
      width: 25px;
      height: 25px;
      transform-origin: center;
      animation-name: spinning;
      animation-duration: 0.4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
   }
}

.layout {
   height: 100%;
   overflow-y: scroll;
   overflow-x: hidden;

   background-color: #f5f7fa;

   .main {
      background-color: #f5f7fa;
   }
}

@keyframes spinning {
   from {
      transform: rotate(0deg);
   }

   to {
      transform: rotate(360deg);
   }
}
