@use "sass:map";
@import "../../styles/variables.scss";

.globalLoading {
   opacity: 0;
   width: 100vw;
   height: 100vh;
   position: fixed;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   display: flex;
   position: fixed;
   align-items: center;
   justify-content: center;
   z-index: -1;
   background-color: rgba(0, 0, 0, 0.3);
   &.active {
      opacity: 1;
      z-index: 1500;
      overflow: hidden;
   }

   img {
      width: 34px;
      height: 34px;
      animation-name: spinning;
      animation-duration: 0.8s;
      color: black;
      // transform-origin: center;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
   }
}
.loading {
   opacity: 0;
   width: 100%;
   height: 100%;
   display: flex;
   position: fixed;
   align-items: center;
   justify-content: center;
   z-index: -1;
   background-color: rgba(0, 0, 0, 0.3);
   // background-color: #F5F5F5;
   &.active {
      opacity: 1;
      z-index: 1500;
   }

   img {
      width: 34px;
      height: 34px;
      animation-name: spinning;
      animation-duration: 0.8s;
      color: black;
      // transform-origin: center;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
   }
}
@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .loading {
      background-color: rgba(238, 238, 239, 0.7);
   }
}

.loading-customv2.primary {
   width: fit-content;
   height: fit-content;
   img {
      animation: 1s infinite linear reverse;
      -webkit-animation: spinning 1s infinite linear reverse;
   }
   &.small img {
      width: 18px;
      height: 18px;
   }
   &.medium img {
      width: 24px;
      height: 24px;
   }
   &.large img {
      width: 32px;
      height: 32px;
   }
}
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
   position: relative;
   width: 5px;
   height: 5px;
   border-radius: 5px;
   background-color: #fff;
   color: #fff;
   animation: dot-flashing 1s infinite linear alternate;
   animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
   content: "";
   display: inline-block;
   position: absolute;
   top: 0;
}
.dot-flashing::before {
   left: -10px;
   width: 5px;
   height: 5px;
   border-radius: 5px;
   background-color: #fff;
   color: #fff;
   animation: dot-flashing 1s infinite alternate;
   animation-delay: 0s;
   // opacity: 0.3;
}
.dot-flashing::after {
   left: 10px;
   width: 5px;
   height: 5px;
   border-radius: 5px;
   background-color: #fff;
   color: #fff;
   animation: dot-flashing 1s infinite alternate;
   animation-delay: 1s;
   // opacity: 0.3;
}

@keyframes dot-flashing {
   0% {
      background-color: #fff;
   }
   50%,
   100% {
      background-color: rgba(255, 255, 255, 0.3);
   }
}
@keyframes spinning {
   from {
      transform: rotate(360deg);
   }

   to {
      transform: rotate(0deg);
   }
}
