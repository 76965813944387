@use 'sass:map';

.landing-page-service-page {
   .procedure {
      padding: 100px 0;
      background-color: #fff;

      .header {

         .description {
            width: 580px;
         }
      }

      .items {
         .item {
            &:not(:last-child) {
               margin-bottom: 20px;
            }

            padding: 16px 27px;
            display: flex;
            align-items: center;
            background: rgba(241, 241, 241, 0.9);
            border: 1px solid #e0e0e0;

            .left-content {
               width: 35%;
               display: flex;
               align-items: center;

               .stt {
                  font-weight: bold;
               }

               .content {
                  margin-left: 40px;

                  .text {
                     font-weight: 500;
                     font-size: 24px;
                     line-height: 28px;
                     color: #252a2b;
                     margin-bottom: 20px;
                  }

                  .time {
                     width: max-content;
                     padding: 8px 12px;
                     background: #8a8a8a;
                     border-radius: 100px;
                     color: #fff;

                     img {
                        margin-right: 8px;
                     }
                  }
               }
            }

            .right-content {
               width: 65%;
               display: flex;
               align-items: center;

               .content {
                  margin-left: 40px;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;

                  color: #252a2b;

                  opacity: 0.9;
               }
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .procedure {
         padding: 32px 12px;

         .header {
            .title {
               margin-bottom: 20px;
            }

            .description {
               margin-bottom: 20px;
               width: unset;
            }
         }

         .container {
            max-width: unset;
         }

         .items::-webkit-scrollbar {
            width: 0 !important;
            display: none;
         }

         .items {
            display: flex;
            overflow: auto;
            // overflow-x: scroll;
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-right: -12px;

            .item {
               &:not(:last-child) {
                  margin-right: 20px;
               }

               &:last-child {
                  margin-right: 12px;
               }

               margin-bottom: 0 !important;
               display: block;

               .left-content {
                  display: block;
                  width: unset;

                  .stt {
                     margin-bottom: 20px;
                  }

                  .content {
                     margin-left: 0;
                     margin-bottom: 20px;
                  }
               }

               .right-content {
                  display: block;
                  width: 220px;

                  .content {
                     margin-left: 0;
                     margin-top: 20px;
                  }
               }
            }
         }
      }
   }
}