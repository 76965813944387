@use 'sass:map';

@import "styles/variables.scss";

.quotation-page {
    padding: 16px 0;
    .question {
        padding-top: 64px;
        border-top: 5px solid #f4f4f4;
    }
    .quotation {
        position: relative;
        padding-bottom: 64px;
        .title_apartment {
            text-align: center;
            font-size: 32px;
            // margin-top: calc(48px - 16px);
            margin-bottom: 16px;
            font-weight: 500;
            line-height: 170%;
        }
        h2 {
            font-size: 24px;
            line-height: 1.7;
        }
        ul {
            line-height: 1.4;
        }
        .options {
            h3 {
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                text-transform: uppercase;
                color: map.get($text-colors, 'default');
            }

            // p {
            //     font-weight: 500;
            //     font-size: 24px;
            //     line-height: 28px;
            //     margin-bottom: 28px;
            //     color: map.get($text-colors, 'default');
            // }

            .option {
                margin: 64px 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 24px;
                .option-item {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background: #ffffff;
                    padding: 28px;
                    box-shadow: 0px 4px 16px rgba(184, 46, 15, 0.12);
                    border-radius: 4px;
                    border: 4px solid #ffffff;
                    &.active {
                        border: 4px solid map.get($text-colors, orange);
                    }

                    .title {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 28px;
                        color: map.get($text-colors, 'default');
                        margin: 0;
                        margin-top: 32px;
                        margin-bottom: 6px;
                    }
                    .description {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        margin-bottom: 0px;
                        text-align: center;
                    }

                    .img-item {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            aspect-ratio: 1;
                        }
                    }
                }
            }
        }

        .img-wrap {
            position: initial;
            top: 180px;

            .main-image {
                position: sticky;
                top: 150px;
                overflow: hidden;
                background-color: #f4f4f4;

                &::after {
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 10;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    z-index: 1;
                }
            }
        }

        .bottom-step-5 {
            bottom: -70px;
        }

        .ratio-1dot7x1 {
            --bs-aspect-ratio: 70%;
        }

        .quotation-step {
            position: initial;
            top: 150px;

            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;

            .text-war {
                font-size: 16px;
                line-height: 24px;
                color: map.get($text-colors, 'default');

                p {
                    // margin-bottom: 14px;
                    font-size: 12px;
                    
                }
            }

            .title-step {
                margin-bottom: 14px;
            }

            .name-type {
                font-size: 1.1215rem;
                font-weight: 500;
                line-height: 19px;
                padding-bottom: 14px;
                margin: 0;
                color: map.get($text-colors, 'default');
                border-bottom: 1px solid #dddddd;
            }

            .step-item {
                padding: 14px 0;
                border-bottom: 1px solid #dddddd;

                p.title-price {
                    font-size: 1.15rem;
                    font-weight: 500;
                    line-height: 19px;
                    margin-bottom: 0;
                    color: map.get($text-colors, 'default');
                }

                span {
                    font-size: 1.5rem;
                    font-weight: 500;
                    line-height: 19px;
                    margin-bottom: 0;
                    color: map.get($text-colors, 'default');
                }
            }

            .selection {
                margin-bottom: 20px;
                position: relative;
                border: 1px solid #252a2b66;
                background-color: #fff;
                padding: 10px;
                cursor: pointer;

                &:hover {
                    border: 1px solid rgba(37, 42, 43, 0.80);
                }

                .text-block_step {
                    margin-left: 15px;

                    .title_step {
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 19px;
                        color: map.get($text-colors, 'default');
                    }

                    .description {
                        font-weight: normal;
                        font-size: 0, 875rem;
                        line-height: 20px;
                        margin: 0;
                    }
                }

                .checked {
                    display: none;
                    top: -1px;
                    left: -1px;
                    position: absolute;
                    background-color: #ff6347;
                    width: 27px;
                    height: 27px;
                    clip-path: polygon(0 0, 0% 100%, 100% 0);

                    img {
                        top: 0;
                        left: 0;
                        width: 70%;
                        height: 45%;
                        margin-bottom: 7px;
                    }
                }

                .image {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    border: 1px solid #252a2b66;
                    width: 53px;
                }

                &.selected {
                    border: 1px solid #ff6347;
                    box-shadow: 0px 0px 11px #00000040;

                    .checked {
                        display: block;
                    }
                }
            }

            .img-size {
                // width: 100%;
                // height: 100%;
                img {
                    width: 100%;
                    // height: auto;
                    object-fit: cover;
                }
            }

            .selector {
                padding: 10px;
                margin-bottom: 10px;
                height: 68px;
                position: relative;
                border: 1px solid #252a2b66;
                background-color: #fff;

                .label {
                    width: 48px;
                    height: 48px;
                    border: 1px solid #252a2b66;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .value {
                    outline: none;
                    border: none;
                    font-size: 1rem;
                    padding-left: 20px;
                }

                .img-select {
                    padding-right: 18px;

                    &.active {
                        transform: rotate(180deg);
                        padding-right: 0;
                        padding-left: 18px;
                    }
                }

                .value-selector {
                    top: calc(100% + 10px);
                    left: 0;
                    display: none;
                    background: #fff;
                    z-index: 100;
                    position: absolute;
                    border: 1px solid #252a2b66;

                    .value {
                        font-size: 1rem;
                        padding: 15px;
                        cursor: pointer;

                        &:hover {
                            background-color: #F4F4F4;
                        }

                        &.selected {
                            background-color: #252a2b;
                            color: #ffffff;
                        }
                    }

                    &.active {
                        display: block;
                    }
                }
            }

            .img-size {
                border: 1px solid #D6D6D6;
                background: #FAFAFA;
            }

            .text-see-more {
                .text {
                    width: max-content;
                    padding-bottom: 10px;
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 19px;
                    color: #666666;
                    border-bottom: 2px solid #e7706e;
                }
            }
        }

        .button-group {
            .btn {
                width: 100%;
                padding: 10px;
                background: #252a2b;
                color: #fff;
                border-radius: unset;

                &:hover {
                    box-shadow: 0px 0px 11px #00000040;
                }
            }

            .btn-next {
                background: #ff6347;
            }
        }

        .request-form {
            .title {
                text-align: center;
                font-size: 24px;
                font-weight: 500;
            }

            .request-form-inner {
                padding-top: 20px;

                form {
                    .form-label {
                        font-weight: normal;
                        font-size: 1.25rem;
                        line-height: 21px;
                    }

                    input {
                        height: 44px;
                        padding: 12px;
                        border-radius: 4px;
                    }

                    .btn {
                        border-radius: unset;
                        width: 100%;
                        height: 44px;
                        background: map.get($text-colors, 'default');
                    }
                }

                .amount {
                    border: 1px solid #aaaaaa;
                    border-radius: 4px;
                    overflow: hidden;

                    .ratio {
                        height: 80px;
                        width: 80px;
                    }

                    .text-block {
                        padding-left: 20px;

                        p {
                            margin: 0;
                        }

                        .description {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 15px;
                            padding: 4px;
                        }
                    }
                }

                .btn-prev {
                    color: #fff;
                    padding: 12px;
                    margin-top: 16px;
                }
            }
        }

        .modal-info-width {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 512px;
            background: #ffffff;
            border-radius: 8px;
            padding: 23px 24px;
            border: 1px solid #dddddd;
            display: none;

            &.open {
                display: block;
            }

            .head-info {
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                color: map.get($text-colors, 'default');
                margin-bottom: 24px;
            }

            .main-info {
                .header {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .border-lr {
                        border-left: 1px solid #dddddd;
                        border-right: 1px solid #dddddd;
                    }

                    .item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        border-bottom: 1px solid #dddddd;
                        height: 100px;

                        .title {
                            font-weight: bold;
                        }

                        span {
                            padding-top: 10px;
                            font-size: 1rem;
                            line-height: 20px;
                            text-align: center;
                            color: #6a433a;
                        }
                    }
                }

                .body {
                    .border-none {
                        border-bottom: none !important;
                    }

                    .td {
                        height: 50px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        border-bottom: 1px solid #dddddd;

                        &.active {
                            background: #fbfbfc;
                        }

                        .border-lr {
                            border-left: 1px solid #dddddd;
                            border-right: 1px solid #dddddd;
                        }

                        .item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;

                            .title {
                                font-weight: bold;
                            }

                            span {
                                font-size: 1rem;
                                text-align: center;
                                color: #6a433a;
                            }
                        }
                    }
                }

                .footer {
                    margin-top: 12px;

                    span {
                        font-size: 1rem;
                        line-height: 20px;
                        color: #6a433a;
                    }
                }
            }
        }
    }

    @media (max-width: map.get($grid-breakpoints, 'md')) {
        .quotation {
            .title_apartment {
                // font-size: 24px;
                margin-bottom: 32px;
                margin-top: calc(32px - 16px);
            }
            .options {
                h3 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #666666;
                }

                p {
                    text-align: left;
                    margin-bottom: 5px;
                }

                .option {
                    margin-top: 44px;
                    margin-bottom: 44px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 16px;
                    .option-item {
                        border: 1px solid #f4f4f4;
                        box-shadow: 0px 4px 16px rgba(184, 46, 15, 0.12);
                        border-radius: 4px;
                        padding: 20px 8px 12px;
                        .title {
                            font-size: 16px;
                            font-weight: 700;
                            margin-bottom: 4px;
                            margin-top: 6px;
                        }
                        .description {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                        }

                        .img-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .svg-img {
                                width: 60px;
                                height: 60px;
                            }
                        }
                    }
                }
            }

            .img-next {
                width: 56px;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    @media (min-width: map.get($grid-breakpoints, 'lg')) {
        .quotation {
            .options {
                .option {
                    .option-item {
                        // transition: border cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
                        border: 4px solid #ffffff;
                        &:hover,
                        &.active {
                            // transition: border cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
                            border: 4px solid map.get($text-colors, orange);
                            // width: calc(100% - 8px);
                        }
                    }
                }
            }
        }
    }
}

.calculator-wrap {
    padding: 0 60px;
    @include media-down(md) {
        padding: 0;
    }
}
