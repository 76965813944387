.modal-badge {
   .MuiPaper-root {
      margin: 0;
      max-width: 328px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.16);
   }

   .modal-header {
      padding: 24px 24px 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
         color: #000;
         text-align: center;
         font-size: 18px;
         font-weight: 700;
         line-height: normal;

         max-width: 270px;
         margin: auto;
         text-align: center;
      }
   }

   .modal-content {
      padding: 8px 24px 24px;

      .text {
         color: #666;
         text-align: center;
         font-size: 16px;
         font-weight: 400;
         line-height: normal;
         margin-bottom: 24px;
      }

      .group-action {
         display: flex;
         flex-direction: column;
         row-gap: 16px;


         .btn {
            font-size: 15px;
            font-weight: 700;
            width: 100%;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
         }

         .btn.btn-primary {
            color: #fff;
            background: #F24F2B;
         }

         .btn.btn-white {
            color: #666;
            border: 1px solid #CCCCCC;
         }
      }
   }
}