@import "styles/variables.scss";

// Feed result components
.search-feed-result {
   width: 100%;
   max-width: 804px;
   margin: 0 auto;
   border-radius: 10px;
   background: #fff;
   box-shadow: 0px 1px 4px -5px rgba(0, 0, 0, 0.1);
   max-height: 302px;

   @include media-up(md) {
      max-height: 355px;
   }

   .search-content {
      margin-left: -4px;
      margin-right: -4px;

      @include media-up(md) {
         margin-left: -9px;
         margin-right: -9px;
      }
   }

   .w-item {
      padding-left: 4px;
      padding-right: 4px;

      @include media-up(md) {
         padding-left: 9px;
         padding-right: 9px;
      }

      & > .MuiSkeleton-root {
         height: 276px;

         @include media-down(md) {
            height: 217px;
         }
      }
   }
}

// Feed result components
.search-account-result {
   width: 100%;
   max-width: 804px;
   margin: auto;
   border-radius: 10px;
   background: #fff;
   box-shadow: 0px 1px 4px -5px rgba(0, 0, 0, 0.1);

   .search-content {
      margin-left: -6px;
      margin-right: -6px;
   }

   .w-item {
      padding-left: 6px;
      padding-right: 6px;
   }
}

// Photo result
.search-photo-result {
   width: 100%;
   max-width: 804px;
   margin: auto;
   border-radius: 10px;
   background: #fff;
   box-shadow: 0px 1px 4px -5px rgba(0, 0, 0, 0.1);

   .search-content {
      margin: 0;

      @include media-down(sm) {
         margin-left: -6px;
         margin-right: -6px;
      }
   }

   .w-item {
      @include media-down(sm) {
         padding-left: 6px;
         padding-right: 6px;
      }
   }
}
