@use 'sass:map';

.landing-page-service-page {
   .slider {
      width: 100%;
      height: 100%;

      .banner {
         position: relative;

         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
         }
      }

      img {
         z-index: 1;
         width: 100%;
         height: 100%;
         object-fit: cover;
         display: inline-block;
      }

      .slick-dots {
         bottom: -25px;

         li {
            margin: 0 5px;

            button {
               width: 6px;
               height: 6px;
               border-radius: 50%;
               border: 2px solid #fff;

               &::before {
                  color: #000;
                  width: 10px;
                  height: 10px;
               }
            }

            &:hover,
            &.slick-active {
               button {
                  background: #fff;
               }
            }
         }
      }
   }
}