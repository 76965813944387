@use "sass:map";

main.review-partner {
   .review-partner-head {
      background: #fbfbfb;
      padding: 24px 0;

      .head-info {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         .name {
            font-size: 1rem;
            line-height: 20px;
            text-align: center;
            margin-top: 24px;
            color: map.get($text-colors, "default");
         }

         .img-logo {
            width: 120px;
            height: 120px;
            border: 0.5px solid #ddd;
            border-radius: 50%;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               border-radius: 50%;
            }
         }
      }

      .head-main {
         display: flex;
         flex-direction: column;

         .title {
            font-weight: 500;
            font-size: 2.375rem;
            line-height: 45px;
            color: map.get($text-colors, "default");
            text-transform: uppercase;
            margin-bottom: 0;
         }

         .main-des {
            padding-top: 24px;

            .des {
               font-size: 1rem;
               line-height: 20px;
               color: map.get($text-colors, "default");
               margin-bottom: 24px;
            }
         }
      }
   }

   .review-partner-main {
      .label {
         font-weight: bold;
         font-size: 1rem;
         line-height: 19px;
         color: map.get($text-colors, "default");
         margin-bottom: 12px;
      }

      .des {
         margin-bottom: 28px;
      }

      .input {
         outline: none;
         height: 60px;
         padding: 20px;

         &.error {
            border: 1px solid #ff0000;
         }

         &::placeholder {
            font-size: 1rem;
            line-height: 20px;
            color: rgba(38, 43, 44, 0.5);
         }

         &:focus {
            outline: none;
            box-shadow: none;
         }
      }

      .textarea {
         height: 160px;
         overflow: auto;
         resize: none;
         padding: 20px;

         &.error {
            border: 1px solid #ff0000;
         }

         &::placeholder {
            font-size: 1rem;
            line-height: 20px;
            color: rgba(38, 43, 44, 0.5);
         }

         &:focus {
            outline: none;
            box-shadow: none;
         }
      }
      small {
         font-size: 13px;
         color: #8a8a8a;
      }

      .MuiRating-root {
         font-size: 3.125rem;
         color: #ff6347;
      }

      .upload-img {
         margin-bottom: 44px;

         .upload-img-wrap {
            border-radius: 4px;
            border: 1px solid #ced4da;

            &.error {
               border: 1px solid #ff0000;
            }

            .result {
               border-radius: 4px;
               align-items: center;
               justify-content: left;

               .img-wrap {
                  position: relative;

                  .img {
                     flex: 1 1;
                     height: 0;
                     padding-bottom: 70%;
                     position: relative;
                     overflow: hidden;

                     img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        z-index: 1;
                     }
                  }

                  .close {
                     position: absolute;
                     background: #fff;
                     top: 0;
                     right: 8px;
                     margin: 8px;
                     border-radius: 50%;
                     width: 40px;
                     height: 40px;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     z-index: 100;
                     cursor: pointer;

                     img {
                        width: 1rem;
                        height: 1rem;
                        object-fit: cover;
                     }
                  }
               }
            }

            .upload {
               border-radius: 4px;
               background: #fff;
               padding: 22px 0;

               #file[type="file"] {
                  display: none;
               }

               .label-upload {
                  cursor: pointer;
                  width: 67px;
                  height: 67px;
                  background: #e1e1e1;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                     width: 31px;
                     object-fit: cover;
                  }
               }

               .des {
                  font-size: 16px;
                  line-height: 20px;
                  color: #818085;
               }
            }
         }
      }

      .btn-review {
         width: 100%;
         background: #252a2b;
         color: #fff;
         padding: 10px 0;
      }
   }

   @media (max-width: map.get($grid-breakpoints, "md")) {
      .review-partner-head {
         background: #fbfbfb;
         padding: 30px 0;
         margin-bottom: 11px;

         .head-main {
            .title {
               text-align: center;
            }

            .main-des {
               text-align: center;
            }
         }
      }
   }
}
