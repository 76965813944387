.schedule-post-dialog {
   // z-index: 1210;
   // position: absolute;
   width: 100%;
   background: #fff;
   .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 400px;
      padding: 24px 16px;
   }
   .wrap-datetime {
      width: 433px;
      height: 340px;
      position: relative;
      overflow: hidden;
   }
   .schedule-date-time-custom {
      // width: 0px;
      height: 0px;
      opacity: 0;
      // display: none;
      position: absolute;
      bottom: 0;
      left: 0;
   }
}
.root-popper-custom {
   .desktop-paper-custom {
      border-radius: 8px;
      border: 1px solid var(--gray-gray-400, #e2e4eb);
      background: var(--Text-Inverted-Primary, #fff);
      box-shadow: 0px 10px 35px -10px rgba(37, 42, 43, 0.2);
   }
   .MuiMultiSectionDigitalClockSection-root {
      &::-webkit-scrollbar {
         display: none;
      }
      &::-webkit-scrollbar-thumb {
         display: none;
      }
   }
   // .MuiMultiSectionDigitalClockSection-root {
   //    display: none;
   // }
}

.schedule-post-section-mb {
   .wrap-calendar {
      padding: 0 12px 16px;
   }
   .schedule-date-time-custom-mb {
      border-radius: 13px;
      background: rgb(238 238 238 / 97%);
      // background-blend-mode: color-dodge, normal;
      // backdrop-filter: blur(40px);
      > div {
         background: unset;
      }
   }
   .actions {
      width: 100%;
      // height: 56px;
      padding: 12px 16px;
      position: fixed;
      bottom: 0;
      border-top: 1px solid var(--gray-gray-400, #e2e4eb);
      background: var(--Background-colorBgBase, #fff);
   }
   .btn-schedule-mb {
   }
}
