@use "sass:map";
@import "styles/variables.scss";

.popover-cart {
   pointer-events: none;
   // position: absolute;
   // right: 0;
   // top: 100%;
   // z-index: 9999;
   // width: 400px;
   // max-height: 600px;
   // padding: 16px;

   // width: 400px;
   // max-height: 600px;
   :global(.MuiPaper-root) {
      max-height: unset;
      border-radius: 10px;
      border: 1px solid #e2e4eb;
      background: #fff;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      pointer-events: auto;
      margin-top: 16px;

      ::-webkit-scrollbar {
         width: 14px;
         background-color: #eef2f5; // bg
      }
      ::-webkit-scrollbar-thumb {
         width: 8px;
         background-color: #dadce3; //
         border-radius: 10px;
         border: 4px solid transparent;
         background-clip: content-box;
      }
      // ::-webkit-scrollbar-track {
      //    width: 14px;
      //    background-color: #eef2f5; // bg
      // }
   }
   .popover-cart-content {
      width: 400px;
      max-height: 600px;
      overflow: hidden auto;
   }
}

.wrap-list-product {
   padding: 12px;
}
.cart-footer {
   position: absolute;
   bottom: 0;
   left: 0;

   width: 100%;
   padding: 16px 12px;
   border-top: 1px solid #e2e4eb;
   background-color: #fff;
}
.empty-cart {
   width: 100%;
   padding: 24px 0 42px;
   min-height: 200px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #fff;
   margin-top: 8px;

   @include media-down(md) {
      padding: 64px 16px;
   }

   .empty-icon {
   }

   .title {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      margin-top: 16px;
   }

   .des {
      color: $span;
      text-align: center;
      font-size: 12.64px;
      line-height: 16px;
      /* 126.582% */
      letter-spacing: -0.126px;
      margin-top: 8px;
      max-width: 220px;

      @include media-down(md) {
         color: #000;
         font-size: 12.64px;
         font-weight: 400;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.126px;
         max-width: 180px;
      }
   }

   button {
      width: fit-content;
      height: 48px;
      padding: 8px 32px;
      margin-top: 32px;

      @include media-down(md) {
         font-size: 14.22px;
         line-height: 16px;
         /* 112.518% */
         letter-spacing: -0.142px;
      }
   }
}

.loading-card {
   min-height: 378px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #fff;

   @include media-down(md) {
      min-height: 420px;
   }
}

.product-list-by-cate {
   padding-bottom: 112px;
}

.checkbox {
   user-select: none;
   position: relative;
   width: 16px;
   height: 16px;
   flex-shrink: 0;
   border-radius: 3px;
   border: 1px solid #e0e0e0;
   text-align: center;

   @include media-down(md) {
      width: 14px;
      height: 14px;
   }

   &.checked {
      background: #ff6347;
      border-color: #ff6347;
   }

   &::before {
      position: absolute;
      content: "";
      height: 7px;
      width: 11px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      border-radius: 2px;
      transform: rotate(-45deg);
      left: 2px;
      top: 2px;

      @include media-down(md) {
         height: 6px;
         width: 8px;
      }
   }
}

.item-center {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.order-product-item {
   margin-bottom: 8px;
   border-radius: 10px;
   border: 1px solid #e2e4eb;
   overflow: hidden;
   @include media-down(md) {
      margin-top: 8px;
   }
}

.brand-info {
   display: flex;
   align-items: center;
   padding: 16px 8px;
   background: #fff;

   .wrap-checkbox {
      margin-right: 10px;
   }

   .brand-name {
      color: $text;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
   }
}

.product-item {
   align-items: center;
   padding: 0 8px;

   .product-item-body {
      width: 100%;
      padding: 16px 0;
      display: flex;
      border-top: 1px solid #e2e4eb;
   }

   .bookmark {
      display: flex;
      cursor: pointer;
      svg {
         width: 14px;
         height: 14px;
      }
   }

   .quantity {
      color: #9f9f9f;
      font-size: 14px;
      line-height: normal;
      text-align: center;
   }

   .action {
      color: $span;
      font-size: 14px;
      line-height: normal;
      text-align: right;
   }

   .product-info {
      width: 100%;
   }

   .wrap-checkbox {
      display: flex;
      justify-content: center;
      margin-right: 8px;
      padding-top: 26px;
   }

   .product-name {
      color: $text;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.064px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
   }

   .img {
      width: 64px;
      height: 64px;
      min-width: 64px;
      margin-right: 8px;
      border-radius: 8px;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   .product-variant {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .label {
         color: $span;
         font-size: 12px;
         line-height: 16px;
         letter-spacing: -0.14px;
         margin-right: 8px;
      }

      .value {
         color: $text;
         font-size: 12px;
         line-height: 16px;
         letter-spacing: -0.14px;

         & > span:not(:first-child) {
            position: relative;
            margin-left: 16px;

            &::before {
               content: "";
               position: absolute;
               top: 50%;
               left: -10px;
               transform: translateY(-50%);

               width: 4px;
               height: 4px;
               background: #acacac;
               border-radius: 50%;
            }
         }
      }
   }

   .price {
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      flex-direction: column;
      line-height: 24px;

      .discount-price {
         color: #ff6347;
         font-size: 16px;
         font-weight: 800;
         line-height: 24px;

         span {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-decoration: underline;
         }
      }

      .real-price {
         color: #9f9f9f;
         font-size: 11px;
         font-weight: 400;
         line-height: 12px;
         letter-spacing: 0.14px;
         text-decoration: line-through;
      }

      .percent-discount {
         color: #ff383b;
         font-size: 11px;
         line-height: 11px;
         text-align: center;
         width: max-content;
         margin-left: 6px;
         height: 16px;
         padding: 2px 4px;
         border-radius: 4px;
         background: rgba(255, 230, 232, 0.5);
      }
   }

   .quantity-box {
      display: flex;
      align-items: center;
      width: 136px;
      height: 32px;

      button.minus-button,
      button.plus-button {
         font-size: 12px;
         display: block;
         border: none;
         outline: none;
         height: 20px;
         width: 20px;
         min-width: 20px;
         background-color: #efefef;
         color: map.get($text-colors, "default");
         padding: 0;
         border: 1px solid rgba(0, 0, 0, 0.05);
         background: #fff;
         border-radius: 4px;

         &[disabled] {
            opacity: 0.3;
         }
      }

      input.quantity-input {
         color: $text;
         font-size: 12px;
         line-height: 12px;
         width: 40px;
         height: 20px;
         outline: none;
         text-align: center;
         border: 1px solid rgba(0, 0, 0, 0.05);
         margin: 0 4px;
         border-radius: 6px;
      }
   }

   .action {
      margin-top: 4px;
      .btn-remove {
         border: unset;
         outline: unset;
         padding: 2px;
         background: unset;
      }
   }
}

.form-check {
   display: flex;
   align-items: center;

   .form-check-input {
      width: 20px;
      height: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      outline: none;
      box-shadow: none;
      float: unset;

      &:checked {
         background-color: #ff6347;
         border: none;
      }

      &:disabled {
         background-color: #e0e0e0;
         cursor: no-drop;
      }
   }
}

// .summary-card-info {
//    position: sticky;
//    top: 74px;
//    height: fit-content;
//    padding: 16px 0 24px;
//    background-color: #fff;

//    h2 {
//       color: #000;
//       font-size: 20px;
//       font-weight: 700;
//       line-height: 24px;
//       /* 120% */
//       margin-bottom: 24px;
//       padding: 0 12px;
//    }

//    .info-item {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       margin-bottom: 10px;
//       padding: 0 12px;
//    }

//    .label {
//       color: #252a2b;
//       font-size: 15px;
//       font-weight: 400;
//       line-height: 22px;
//    }

//    .value {
//       color: #252a2b;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 16px;
//    }

//    .discount-price {
//       color: #ff6347;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 16px;
//    }

//    .coin-value {
//       color: #ff6347;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 16px;
//    }

//    .total-price {
//       color: $text;
//       font-size: 14px;
//       font-weight: 600;
//       line-height: 24px;

//       /* 133.333% */
//       span {
//          color: $text;
//          font-size: 14px;
//          font-weight: 600;
//          line-height: 24px;
//          text-decoration: underline;
//       }
//    }
// }

.coin-availabe {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   background: #fff;
   padding: 16px 12px 16px;

   .coin-label {
      color: $text;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
   }

   .coin-value {
      color: #9f9f9f;
      font-size: 14px;
      line-height: 16px;
   }
}

.use-coin {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0 12px 12px;
   border-bottom: 1px solid $border;

   svg {
      margin-right: 6px;
   }

   .use-coin-label {
      span {
         color: $span;
         font-size: 14px;
         line-height: 16px;
      }
   }
}

.switch_coin {
   .toggle_switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 16px;
   }

   .toggle_switch input[type="checkbox"] {
      display: none;
   }

   .toggle_switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #78788029;
      border-radius: 21px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
   }

   .toggle_switch .switch::before {
      position: absolute;
      content: "";
      left: 1px;
      top: 1px;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease;
   }

   .toggle_switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(14px);
      background-color: #fff;
   }

   .toggle_switch input[type="checkbox"]:checked + .switch {
      background-color: $primary;
   }
}

.card-des {
   color: #697f90;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.14px;
   padding: 0 12px 16px;
   text-align: right;
}

.card-action {
   padding: 0 12px;

   button {
      height: 48px;
   }
}

.discount-code {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   background: #fff;
   padding: 16px 12px 16px;

   .label {
      color: $text;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
   }

   .select-discount {
      color: $primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
         margin-left: 6px;
      }
   }
}

.summary-box {
   position: sticky;
   top: 90px;

   .separate-line {
      background-color: #e6e6e6;
      opacity: 1;
   }

   .voucher-input {
      width: 100%;
      height: 40px;
      padding: 10px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      outline: none;
   }
}

.bonus-point {
   .form-check {
      display: flex;
      align-items: center;

      .checkbox {
         position: relative;
         width: 20px;
         height: 20px;
         border: 1px solid #e0e0e0;
         flex-shrink: 0;
         border-radius: 2px;
         text-align: center;
         margin-right: 8px;

         &::before {
            position: absolute;
            content: "";
            height: 5px;
            width: 9px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 3px;
            top: 3px;
         }
      }

      .form-check-input {
         width: 20px;
         height: 20px;
         border: 1px solid #e0e0e0;
         border-radius: 2px;
         outline: none;
         box-shadow: none;
         float: unset;

         &:checked {
            background-color: #ff6347;
            border: none;
         }

         &:disabled {
            background-color: #e0e0e0;
            cursor: no-drop;
         }
      }

      label.form-check-label {
         margin-left: 18px;
         display: flex;
         align-items: center;

         span {
            padding-left: 8px;
            // font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #666666;

            b {
               color: map.get($text-colors, "default");
               margin-right: 12px;
            }
         }
      }
   }

   .use-point {
      text-align: right;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      color: #e0e0e0;

      &.active {
         color: #ff6347;
      }
   }

   .error-text {
      font-size: 0.875rem;
      line-height: 20px;
      color: #ff0000;
      margin-top: 12px;
      margin-bottom: 0;

      &.error {
         color: #66ac5b;
      }
   }

   .title-bonus-point {
      display: flex;
      align-items: center;

      span {
         padding-left: 8px;
         // font-weight: bold;
         font-size: 16px;
         line-height: 19px;
         color: #666666;

         b {
            color: map.get($text-colors, "default");
            margin-right: 12px;
         }
      }
   }

   .point-input {
      width: 100%;
      height: 40px;
      padding: 10px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      outline: none;
   }

   .bottom-bonus-point {
      span {
         font-size: 16px;
         line-height: 20px;
         color: #ff0000;
      }
   }
}

.apply-voucher-button {
   padding: 10px 20px;
   height: 40px;
   color: #ffffff;
   border: none;
   border-radius: 4px;
   background-color: #ff6347;

   .total-cost {
      font-size: 1.143rem;
      font-weight: 500;
   }

   .pay-button {
      width: 100%;
      border: none;
      color: #ffffff;
      padding: 10px;
      border-radius: 4px;
      background-color: #252a2b;
   }
}

.empty-cart-body {
   margin-top: 100px;
   margin-bottom: 200px;

   .title {
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
   }

   .go-to-shop-button {
      display: block;
      text-align: center;
      text-decoration: none;
      width: 100%;
      color: #ffffff;
      border: none;
      background-color: #252a2b;
      border-radius: 4px;
      padding: 10px;
   }
}
