@use 'sass:map';

.landing-page-service-page {
   .highlight {
      padding: 100px 0;
      background: rgba(241, 241, 241, 0.9);

      .header {}

      .items {
         display: block;

         .item {
            &:not(:last-child) {
               margin-bottom: 50px;
            }

            display: flex;
            align-items: center;

            .image {
               width: calc(50% - 32px);

               &.mobile {
                  display: none;
               }
            }

            .content {
               width: calc(50% - 32px);
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: flex-start;
               opacity: 0.9;

               .name {
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 28px;
                  color: #252A2B;
                  opacity: 0.9;
                  margin-bottom: 24px;

               }

               .text {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  color: #252A2B;

                  opacity: 0.9;
               }
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .highlight {
         padding: 32px 12px;

         .header {
            .title {
               margin-bottom: 20px;
               padding: 0 12px;
            }
         }

         .container {
            max-width: unset;
            padding: 0;
         }

         .items {

            .item {
               display: block;

               .image {
                  width: 100%;
                  margin-bottom: 20px;
                  margin-right: 0 !important;

                  &.desktop {
                     display: none;
                  }

                  &.mobile {
                     display: block;
                  }
               }

               .content {
                  width: 100%;

                  .name {
                     width: 100%;
                     text-align: center;
                  }
               }
            }
         }
      }
   }
}