@use 'sass:map';

.landing-page {
   .referral {
      .block-title {
         padding-right: 80px;
         height: 100%;
      }

      .section-description {
         text-align: left;
      }

      .block-form {
         background: linear-gradient(180deg, #f4efef 0%, #f1ccc7 87.54%);
         padding: 36px 24px;
      }

      &__form {
         background: #ffffff;
         border: 1px solid map-get($map: $text-colors, $key: orange);
         box-shadow: 9.12901px 29.2128px 65.7289px rgba(51, 51, 51, 0.1);
         border-radius: 20px;
         padding: 52px 70px;
      }

      .divider {
         margin-bottom: 48px;
         height: 7px;
         width: 15%;
         min-width: 50px;
         background: map-get($map: $text-colors, $key: orange);
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
      .referral {
         padding: 60px 0px;

         .block-title {
            padding-right: 0;
            height: unset;

            .section-title {
               text-align: center;
            }

            .section-description {
               text-align: center;
            }
         }

         .block-form {
            background: none;
            padding: 0px;
            margin-top: 48px;
         }

         &__form {
            border: 1px solid #aeaeae;
            padding: 0px;
            padding-bottom: 60px;

            &-title {
               text-align: center;
               font-size: 32px;
               line-height: 40px;
               margin-top: 60px;
            }

            &-content {
               padding: 0px 16px;
            }
         }

         .divider {
            height: 2px;
            width: 100%;
            margin-top: 24px;
         }
      }

      .referral.secondary-bg {
         background: linear-gradient(180deg, #f4efef 0%, #f1ccc7 87.54%) !important;
      }
   }
}