// @import 'styles/variables.scss';
@use 'sass:map';
.profile-community-item {
   position: relative;
   margin-bottom: 12px;

   .item-img {
      flex: 1;
      height: 250px;
      padding-bottom: 65%;
      position: relative;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   .item-image {
      flex: 1;
      height: 0;
      overflow: hidden;
      position: relative;
      padding-bottom: 65%;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   .text-block {
      text-align: left;
      bottom: 0;
      color: map-get($text-colors, 'default');
      padding: 10px 0;
      width: 100%;

      .title-item {
         font-size: 1.25rem;
         font-weight: bold;
         margin-bottom: 6px;
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
         @include text-wrap-line(2);
         color: $cl-space-t-dark-black;
         opacity: 0.9;

         a {
            text-decoration: none;
            color: map-get($text-colors, 'default');
         }
      }

      .des-item {
         font-size: 16px;
         line-height: 20px;
         opacity: 0.7;
         width: max-content;
      }

      .action-icon {
         &:before {
            width: 20px;
            height: 20px;
            padding-left: 26px;
         }
      }
   }

   .rule-tag {
      position: absolute;
      width: 88px;
      height: 40px;
      right: 0px;
      top: 0px;
      background: map-get($text-colors, 'default');
      opacity: 0.8;
      padding: 12px;

      .des {
         font-size: 0.875rem;
         line-height: 12px;
         margin: 0;
         color: #fff;
         text-align: center;
         text-transform: capitalize;
      }

      &.active {
         background: #ff6347;
      }
   }
}