@use "sass:map";
// @import 'src/assets/scss/core/variables.scss';
@import "styles/variables.scss";

main.construction-page {
   .procedure {
      .procedure-item {
         position: relative;
         overflow: hidden;
         height: 100%;

         &__image {
            margin-bottom: 32px;
            width: 240px;
            height: 144px;
            overflow: hidden;
            flex-shrink: 0;
         }

         &__content {
            width: 100%;
            height: 100%;
            border: 1px solid #cccccc;
            border-radius: 4px;
            padding: 48px 24px;
            display: flex;
            align-items: center;
            flex-direction: column;

            &--title {
               font-weight: 700;
               font-size: 18px;
               line-height: 21px;
               text-align: center;
               margin-bottom: 28px;
               display: flex;
               align-items: center;
               .step {
                  flex-shrink: 0;
                  color: #fff;
                  background-color: #252a2b;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  margin-right: 6px;
                  font-size: 13px;
               }
            }

            &--des {
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #4c3f37;
               margin-bottom: 0px;
            }
         }
      }
   }

   .review {
      .slider-wrap {
         padding-bottom: 8px;

         .slick-arrow {
            color: $cl-white;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            width: 60px;
            height: 60px;
            z-index: 1;
            padding: 10px;
            // top: 20%;
         }

         .slick-next {
            right: 20px;
         }

         .slick-prev {
            left: 20px;
         }

         .slick-dots {
            bottom: -16px;

            li {
               height: 8px;
               width: 8px;
               margin: 0px 4px;

               button {
                  padding: 0px;
                  height: 8px;
                  width: 8px;

                  &::before {
                     width: 8px;
                     height: 8px;
                     line-height: unset;
                  }
               }
            }
         }

         .slider-item {
            .img {
               .row {
                  height: 100%;
               }
               img {
                  aspect-ratio: 1.6;
                  object-fit: contain;
               }
            }

            .img {
               order: 2;
            }

            .review-detail {
               background: #fbfbfc;
               padding: 48px 12px;
               order: 1;

               &__title {
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 23px;
                  text-align: center;
                  margin-bottom: 28px;
               }

               &__rating {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  margin-bottom: 28px;

                  span {
                     margin-left: 8px;
                     font-weight: 700;
                     font-size: 20px;
                     line-height: 23px;
                  }
               }

               &__des {
                  padding: 20px;
                  background: #f5f5f5;
                  font-weight: 400;
                  margin-bottom: 28px;
                  font-size: 16px;
                  line-height: 24px;

                  img {
                     width: 10px;
                     display: inline-flex;
                     top: 0;
                  }
               }

               &__sub-des {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  margin-bottom: 0px;
               }
            }
         }
      }
   }

   .partner {
      &-list {
         overflow: hidden;
         margin: 0px -8px;

         .slider-item {
            padding-left: 8px;
            padding-right: 8px;
         }
      }

      &-item {
         background: #ffffff;
         border: 1px solid #e5e5e6;
         border-radius: 4px;
         color: #252a2b;
         overflow: hidden;

         .img {
            position: relative;
            --bs-aspect-ratio: 52%;
            .icon-verified {
               position: absolute;
               top: 8px;
               left: 8px;
               height: 16px;
               width: fit-content;
               aspect-ratio: unset;
            }
            img {
               aspect-ratio: 1.92;
            }
         }

         &__content {
            padding: 12px;
            gap: 4px;

            &--lg {
               gap: 8px;
               position: absolute;
               left: 8px;
               bottom: 8px;
               display: flex;
            }
         }

         &__logo {
            background: #e8eaed;
            border: 1px solid #e5e5e6;
            border-radius: 4px;
            width: 50px;
            height: 50px;
            filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
            overflow: hidden;
            flex-shrink: 0;

            img {
               width: 100%;
               height: 100%;
               aspect-ratio: 1 !important;
            }
         }

         &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #252a2b;

            &--name {
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               margin-bottom: 2px;
               color: #252a2b;
               text-shadow: none;
            }

            &--des {
               font-weight: 700;
               font-size: 13px;
               line-height: 15px;
               margin-bottom: 0px;
            }

            &--count {
               display: flex;
               align-items: center;
               gap: 4px;
               margin-top: 5px;
            }

            &--rating {
               padding: 2px 6px;
               gap: 2px;
               background: #f4f4f4;
               border-radius: 3px;
               display: inline-block;
               width: fit-content;
               display: flex;
               align-items: center;

               span {
                  color: #be4b27;
               }

               img {
                  width: 14px;
                  height: 14px;
               }
            }

            &--review {
            }

            &--project {
            }
         }
      }
      .partner-slider {
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   main.construction-page {
      .procedure {
         .procedure-item {
            &__image {
               margin-bottom: 16px;
               width: 200px;
               height: 119px;
            }

            &__content {
               padding: 16px 12px 12px;

               &--title {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 20px;
                  margin-bottom: 16px;
               }

               &--des {
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 15px;
               }
            }
         }

         &-period {
            margin-bottom: 16px;

            .btn-period {
               padding: 10px;
               font-size: 14px;
               line-height: 16px;
               text-align: center;
               border: 1px solid #f4f4f4;
               border-radius: 4px;
               width: 56px;
               position: relative;
               background: #fff;

               &:not(:last-child) {
                  margin-right: 36px;

                  &:after {
                     content: "";
                     border-bottom: 1px solid #e8eaed;
                     width: 36px;
                     position: absolute;
                     top: 50%;
                     right: -36px;
                  }
               }
            }

            .btn-period-active {
               background: #ff6347;
               border: 1px solid #ff6347;
               color: #fff;
            }
         }
      }

      .review {
         .slider-wrap {
            padding-bottom: 0px;

            .slick-arrow {
               width: 40px;
               height: 40px;
               top: 20%;
            }

            .slick-next {
               right: 8px;
            }

            .slick-prev {
               left: 8px;
            }

            .slick-dots {
               display: none !important;
            }

            .slider-item {
               .img {
                  order: 1;
                  img {
                     width: 100%;
                     aspect-ratio: unset;
                     object-fit: cover;
                     height: 250px;
                  }
                  // .row {
                  //    height: 250px;
                  // }
               }

               .review-detail {
                  padding: 12px;
                  order: 2;

                  &__title {
                     font-weight: 700;
                     font-size: 16px;
                     line-height: 19px;
                     text-align: center;
                     margin-bottom: 12px;
                  }

                  &__rating {
                     margin-bottom: 12px;

                     span {
                        margin-left: 8px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                     }
                  }

                  &__des {
                     padding: 8px;

                     img {
                        width: 10px;
                     }
                  }

                  &__sub-des {
                     font-size: 12px;
                     line-height: 14px;
                     margin-bottom: 0px;
                  }
               }
            }
         }
      }

      .partner {
         overflow: hidden;

         &-slider {
         }

         &-item {
            overflow: hidden;
            padding: 0px;
            margin-left: 12px;

            .img {
               img {
               }
            }

            &__content {
               padding: 8px;
            }

            &__logo {
               width: 58px;
               height: 58px;
               filter: none;

               img {
               }
            }

            &__info {
               &--name {
               }

               &--des {
               }
            }
         }
         .partner-slider {
            margin-left: -12px;
            margin-right: -12px;
         }
      }
   }
}
