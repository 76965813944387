@use "sass:map";
@import "styles/variables.scss";

.modal-custom {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1300;

   .MuiPaper-root {
      width: 100%;
      max-width: 100%;
      background: unset;
      overflow: hidden;
      margin: 24px;
      border-radius: 16px;
      background: #fff;
   }

   .img-close {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      img {
         width: 100%;
         height: 100%;
      }
   }
   .modal-header {
      width: 100%;
      padding: 12px 24px;
      min-height: 56px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8eaed;
      position: relative;

      .modal-title {
         color: #252a2b;
         font-size: 18px;
         font-weight: 500;
         line-height: normal;
      }
   }
   .modal-body {
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
   }
}
