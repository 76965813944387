@use 'sass:map';
@import "styles/variables.scss";

.template-ladingpage {
   h2.title {
      margin: 0;
      padding: 50px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.7;
      text-align: center;
      color: map.get($text-colors, "default");
   }

   ul {
      line-height: 1.4;
   }

   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   .slick-dots {
      bottom: 0px;

      li {
         margin: 0;

         button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #c6c6c6;
            opacity: 0.8;
            padding: 3px;
            border: 1px solid #c6c6c6;

            &::before {
               color: transparent;
               width: 10px;
               height: 10px;
            }
         }

         &:hover,
         &.slick-active {
            button {
               background: #ffffff;
               opacity: 1;
            }
         }
      }
   }

   .question {
      .title_question {
         margin: 0;
         margin-top: 50px;
         padding-bottom: 8px;
         font-weight: 500;
         font-size: 24px;
         line-height: 1.7;
         text-align: center;
         color: map.get($text-colors, "default");
      }

      &__description {
         .img-arrow {
            padding-top: 50px;
            padding-bottom: 50px;
            width: 21px;
            height: auto;

            img {
               width: 100%;
               object-fit: cover;
            }
         }

         &-content {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 28px;
            text-align: center;
            text-transform: uppercase;
            color: map.get($text-colors, "default");
         }
      }
   }

   .steps {
      .video {
         margin-top: 50px;
      }
   }

   .orient-detail {
      .detail {
         &__title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            color: map.get($text-colors, "default");
            margin-bottom: 0;
         }

         &__descriptions {
            margin-top: 24px;

            &-item {
               font-size: 1rem;
               line-height: 24px;
               color: map.get($text-colors, "default");
               margin-bottom: 0;
               position: relative;
               padding-left: 22px;

               &::before {
                  position: absolute;
                  content: "";
                  top: 45%;
                  left: 8px;
                  width: 3px;
                  height: 3px;
                  background-color: map.get($text-colors, "default");
                  border-radius: 50%;
               }
            }
         }

         &__img {
            flex: 1;
            height: 0;
            padding-bottom: 50%;
            position: relative;
            overflow: hidden;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               position: absolute;
               z-index: 1;
            }
         }
      }
   }

   .app,
   .template {
      .item {
         text-decoration: none;

         &__thumbnail {
            flex: 1;
            height: 0;
            padding-bottom: 70%;
            position: relative;
            overflow: hidden;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               position: absolute;
               z-index: 1;
            }

            .watermark {
               position: absolute;
               top: 0;
               right: 0;
               z-index: 100;
               width: 40px;
               height: 40px;
               background: rgba(37, 42, 43, 0.8);
               display: flex;
               align-items: center;
               justify-content: center;

               span {
                  font-weight: 400;
                  font-size: 1rem;
                  line-height: 20px;
                  color: #ffffff;
               }
            }
         }

         &__title {
            h3 {
               font-weight: 700;
               font-size: 1rem;
               line-height: 19px;
               color: #252a2b;
               margin-top: 10px;
            }
         }

         &__des {
            p {
               font-weight: 400;
               font-size: 1rem;
               line-height: 20px;
               color: #666666;
               margin-bottom: 0;
               height: 20px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }
         }

         &__type {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 90;

            p {
               margin-top: 4px;
               font-weight: 400;
               font-size: 1rem;
               line-height: 20px;
               color: #666666;
               margin-bottom: 0;
               height: 20px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }

            .type {
               position: relative;
               padding-right: 26px;

               &::before {
                  position: absolute;
                  content: "";
                  top: 50%;
                  right: 10px;
                  width: 3px;
                  height: 3px;
                  background-color: #666666;
                  border-radius: 50%;
                  z-index: 100;
               }
            }
         }
      }

      .social-feed-slider_dt {
         display: flex;

         .social-feed-item {
            width: 100%;
            display: inline-block;
            position: relative;
            background: none;
            padding: 0px 8px;

            .position-relative {
               position: relative !important;

               .img {
                  aspect-ratio: 0.75;
                  border-radius: 8px;
                  overflow: hidden;

                  .img-cover {
                     position: absolute;
                     width: 100%;
                     height: 100%;
                     top: 0;
                     left: 0;
                     z-index: 1;
                  }
               }

               .avatar-socialfeed {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  z-index: 99;
                  width: 52px;
                  height: 52px;
                  border: 3px solid #ffffff;
                  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
                  border-radius: 100px;
               }
            }

            .username {
               position: absolute;
               bottom: 8px;
               left: 8px;
               font-weight: 400;
               font-size: 14px;
               line-height: 16px;
               color: #ffffff;
               margin-bottom: 0px;
               text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
               display: inline-table;
            }

            .btn-follow {
               z-index: 99;
               padding: 2px 6px;
               position: absolute;
               top: 10px;
               right: 10px;
               border-radius: 4px;
               font-weight: 500;
               font-size: 14px;
               line-height: 16px;
            }

            .follow {
               background: #ff6347;
               color: #ffffff;
            }
         }

         margin-left: -8px;
         margin-right: -8px;

         .description_trend {
            overflow: hidden;
            color: #252a2b;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            margin-top: 2px;
         }
      }
   }

   .btn-wrap {
      padding: 50px 0;

      .wrap {
         // &:hover {
         //    button {
         //       opacity: 0.6;

         //       &:hover {
         //          opacity: 1;
         //       }
         //    }
         // }

         button {
            width: max-content;
            padding: 10px 20px;
            font-weight: 400;
            font-size: 1rem;
            line-height: 20px;
            box-shadow: 0px 0px 11px #00000040;
            border-radius: 4px;
            border: none;
            color: #252a2b;
            background: #ffffff;
            outline: none;

            &:hover {
               opacity: 1;
            }

            &.btn-gt-archisketch {
               background: #ff6347;
               color: #ffffff;
               box-shadow: 0px 0px 11px #ff6347;

               &:hover {
                  background-color: #252a2b;
                  box-shadow: 0px 0px 11px #00000040;
               }
            }

            a {
               text-decoration: none;
               font-weight: 400;
               font-size: 1rem;
               line-height: 20px;
               color: #252a2b;
            }
         }
      }
   }
}

.attention {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #f1f1f1e6;
   padding: 0 20px;
   z-index: 9998;
   align-items: center;
   justify-content: center;

   .text-danger {
      color: #ff0000 !important;
      font-size: 14px;
   }

   &__modal {
      background-color: #fff;
      border-radius: 16px;
      padding: 60px 20px 20px;
      position: relative;
      z-index: 9999;

      .close {
         position: absolute;
         top: 0;
         right: 0;
         padding: 20px;
         opacity: 0.6;
         cursor: pointer;

         img {
            width: 28.37px;
            height: 28.37px;
         }
      }

      &-item {
         border: 1px solid #e8eaed;
         padding: 20px;
         transition: all 0.2s linear;

         .img {
            width: 100%;
            transition: all 0.2s linear;

            img {
               width: 100%;
               object-fit: cover;
               border-radius: 8px;
            }
         }

         &-title {
            font-size: 18px;
            font-weight: 700;
         }

         p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
         }
      }

      &-item:hover:not(.selected) {
         .img {
            transform: scale(1.05);
         }
      }

      .selected {
         padding: 18px;
         border: 3px solid #8a8a8a;
      }

      &-des {
         margin-top: 13px;
         margin-bottom: 46px;

         h4 {
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 21px;
            margin-bottom: 0;
            color: map.get($text-colors, "default");
         }

         p {
            margin-bottom: 0;
            font-size: 1rem;
            line-height: 21px;
            color: map.get($text-colors, "default");
         }
      }

      &-button {
         .btn {
            width: max-content;
            padding: 10px 20px;
            font-weight: 400;
            font-size: 1rem;
            line-height: 20px;
            // text-transform: uppercase;
            color: #ffffff;
            background-color: #252a2b;
            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .template-ladingpage {
      img {
         height: unset;
      }
   }

   .social-feed-slider_mb {
      [class*="slick-track"] {
         height: 228px;
      }

      margin-left: -12px;
      margin-right: -12px;

      .social-feed-item {
         background: none;
         padding: 0px;
         padding-left: 12px;

         .position-relative {
            position: relative !important;

            .img_trend {
               aspect-ratio: 0.75;
               border-radius: 8px;
               overflow: hidden;

               img {
                  height: 100%;
               }

               .img-cover {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 1;
               }
            }

            .avatar-socialfeed {
               position: absolute;
               top: 10px;
               left: 10px;
               z-index: 99;
               width: 52px;
               height: 52px;
               border: 3px solid #ffffff;
               filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
               border-radius: 100px;
            }
         }

         .username {
            position: absolute;
            bottom: 8px;
            left: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            margin-bottom: 0px;
            text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            display: inline-table;
         }

         .btn-follow {
            z-index: 99;
            padding: 2px 6px;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
         }

         .follow {
            background: #ff6347;
            color: #ffffff;
         }
      }

      margin-left: -8px;
      margin-right: -8px;

      .description_trend {
         overflow: hidden;
         color: #252a2b;
         font-size: 12px;
         font-weight: 400;
         line-height: 14px;
         text-overflow: ellipsis;
         -webkit-line-clamp: 2;
         word-break: break-word;
         -webkit-box-orient: vertical;
         display: -webkit-box;
         margin-top: 2px;
      }
   }
}
