@import "./variables.scss";

.input-wrap {
   font-size: 14px;
   width: 100%;
   height: 52px;
   padding: 9px 16px;
   background: #fff;
   border: 1px solid #e0e0e0;
   border-radius: 8px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: relative;

   .prefix {
      margin-right: 10px;
   }
   .suffix {
      margin-left: 10px;
   }

   input {
      width: 100%;
      height: 100%;
      padding: 0;
      border: unset;
      outline: unset;
      flex: 1 0 0;
   }

   &.error {
      border: 1px solid #ff6063 !important;
   }
}

.error-text {
   font-size: 0.875rem;
   line-height: 20px;
   color: #ff6063 !important;
   margin-top: 5px;
}

.editor-style {
   font-size: 16px;
   @include media-down(md) {
      font-size: 12.64px;
   }

   img {
      display: block;
      width: 100% !important;
      height: unset !important;
      object-fit: contain !important;
   }

   h1 {
      font-size: 28px;
   }
   h2 {
      font-size: 22px;
   }
   h3 {
      font-size: 19px;
   }
   p {
      font-size: 16px;
      &:last-child {
         padding-bottom: 0;
         margin-bottom: 0;
      }
   }
   @include media-down(md) {
      h1 {
         font-size: 24px;
      }
      h2 {
         font-size: 16px;
      }
      h3 {
         font-size: 14px;
      }
      p {
         font-size: 12.64px;
      }
   }
}

// Empty
.empty-style {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 64px 0;
   @include media-down(md) {
      justify-content: center;
      min-height: calc(100vh - $MENU-HEIGHT-MOBILE - $NAVIGATOR-HEIGHT - 20px);
      border-top: 8px solid $border;
   }
   svg {
      margin-bottom: 16px;
      width: 152.065px;
      height: 138.072px;
      @include media-down(md) {
         margin-bottom: 12px;
      }
   }
   .text {
      color: #252a2b;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      margin-bottom: 16px;
      @include media-down(md) {
         font-size: 12px;
      }
   }
}
