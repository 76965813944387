@use "sass:map";
// @import 'src/assets/scss/core/variables.scss';
@import "styles/variables.scss";

main.home-pagev2 {
   .product-category {
      &-item {
         .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            margin-bottom: 20px;
         }
         .home-catalog-list {
            display: grid;
            gap: 1px;
            grid-template-columns: repeat(8, 1fr);
            padding: 1px;

            .catalog-item {
               padding: 0 4px;
               display: flex;
               flex-direction: column;
               align-items: center;

               .img {
                  width: 96px;
                  height: 96px;
                  border-radius: 12px;
                  background: #eef2f5;
                  padding: 10px;
                  @include media-down(md) {
                     width: 48px;
                     height: 48px;
                     padding: 8px;
                  }
                  img {
                     width: 100%;
                     height: auto;
                     object-fit: contain;
                  }
               }

               &__name {
                  color: #252a2b;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  margin-bottom: 0px;
                  white-space: nowrap;
                  margin-top: 8px;
               }
            }
         }
      }
   }

   .block-sub {
      margin-bottom: 20px;
   }
   .block-sub-title2,
   .title2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0;
      &:hover {
         color: #252a2b;
      }
   }
   .link-more2,
   .viewall2 {
      font-size: 18px;
      line-height: 21px;
      color: #0055aa;
      cursor: pointer;
      svg {
         width: 16px;
         height: 16px;
      }
   }

   .product-horizontal {
      margin-bottom: -16px;
      @include media-down(md) {
         padding: 0 12px;
      }
      .product-slider {
         margin-left: -8px;
         margin-right: -8px;
         @include media-down(md) {
            margin-left: 0px;
            margin-right: 0px;
         }
      }
      .slider-item {
         position: relative;
         background: none;
         padding: 6px 4px 0px 4px;
         @include media-down(md) {
            position: relative;
            background: none;
            padding: 2px;
         }
      }
      .slick-list {
         overflow: unset;
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   main.home-pagev2 {
      .block-sub {
         margin-bottom: 16px;
      }
      .block-sub-title,
      .title {
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
      }
      .link-more,
      .viewall {
         font-size: 14px;
         line-height: 16px;
      }

      .product-category {
         overflow: hidden;

         &-item {
            .title {
               font-size: 16px;
               line-height: 18px;
            }
            .home-catalog-list {
               margin-top: 0;
               padding: 0px;
               grid-template-columns: repeat(4, 1fr);
               .catalog-item {
                  padding: 0 0 12px;
                  &__name {
                     font-weight: 400;
                     font-size: 14px;
                     line-height: 20px;
                     max-width: 80px;
                     white-space: normal;
                     text-align: center;
                  }
               }
            }
         }
      }

      .product-horizontal {
         margin-bottom: 0;
         margin-top: 12px;
      }
   }
}
