@import "styles/variables.scss";

.error-dialog {
   :global(.MuiPaper-root) {
      width: 100%;
      max-width: 360px;
      border-radius: 10px;
      background: #fff;
      margin: 24px;
   }
}

.close-icon {
   position: absolute;
   top: 12px;
   right: 12px;
   width: 20px;
   height: 20px;
   cursor: pointer;

   display: flex;
   text-align: start;
   justify-content: flex-end;

   svg {
      width: 14px;
      height: 14px;
   }
}

.dialog-content {
   padding: 24px 16px 16px;
}

.type-icon {
   text-align: center;
   margin-bottom: 12px;
   svg {
      width: 46px;
      height: 46px;
   }
}

.error-title {
   color: #252a2b;
   text-align: center;
   font-size: 18px;
   font-weight: 700;
   line-height: 24px;
   margin-bottom: 12px;
}

.error-mess {
   color: #252a2b;
   text-align: center;
   font-size: 14.22px;
   font-weight: 400;
   line-height: 22px;
   margin: 0;
   text-align: center;
}

.actions {
   margin-top: 24px;
}

.btn {
   color: #fff;
   font-weight: 500;
   width: 100%;
   height: 42px;
   border-radius: 8px;
   background: #ff6347;
   border: 1px solid #ff6347;
   outline: unset;
}

// .download-drawer-mobile {
//    display: none;

//    @include media-down(md) {
//       display: block;
//    }

//    .MuiPaper-root {
//       border-radius: 16px 16px 0px 0px;
//       background: #FFF;
//    }

//    .drawer-header {
//       padding: 16px;
//       border-bottom: 1px solid rgba(0, 0, 0, 0.10);

//       .drawer-title {
//          color: #252A2B;
//          text-align: center;
//          font-size: 16px;
//          font-weight: 500;
//          line-height: normal;
//          margin-bottom: 8px;
//       }

//       .drawer-description {
//          color: #252A2B;
//          text-align: center;
//          font-size: 14px;
//          font-weight: 400;
//          line-height: normal;
//          opacity: 0.5;
//       }
//    }

//    .drawer-content {
//       padding: 16px;

//       .drawer-item {
//          display: flex;
//          align-items: center;
//          justify-content: space-between;
//          padding: 4px 0;
//          margin-bottom: 16px;

//          &:last-child {
//             margin-bottom: 0;
//          }

//          img {
//             width: 28px;
//             height: 28px;
//             margin-right: 8px;
//          }

//          .text {
//             color: #252A2B;
//             font-size: 14px;
//             font-style: normal;
//             line-height: 20px;
//          }

//          .btn-open-app {
//             color: #FFF;
//             text-align: center;
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 20px;

//             width: 100%;
//             max-width: 117px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             gap: 8px;
//             padding: 8px 16px;
//             white-space: nowrap;
//             border: unset;

//             border-radius: 100px;
//             background: #FF6347;
//             cursor: pointer;
//          }

//          .btn-continue {
//             color: #252A2B;
//             text-align: center;
//             font-size: 14px;
//             font-weight: 600;
//             line-height: 20px;

//             width: 100%;
//             max-width: 117px;
//             padding: 8px 16px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             gap: 8px;
//             white-space: nowrap;
//             border: unset;

//             border-radius: 100px;
//             border: 1px solid rgba(0, 0, 0, 0.10);
//             background: #FFF;
//             cursor: pointer;
//          }
//       }
//    }
// }
