.image-wrapper {
   height: 100%;
   width: 100%;

   // position: relative;
   img {
      object-fit: contain;
      width: 100%;
      height: 100%;
   }
}

.lazyload-placeholder {
   min-height: 300px;
}

.img-error {
   display: block;
   // background-image: url('/assets/img/icon/no-photo-padding.svg');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;

   // &.vertical {
   //    background-image: url('/assets/img/icon/no-data.png');
   // }

   // &.horizontal {
   //    background-image: url('/assets/img/icon/no-image-horizontal.png');
   // }
}

.img-loading {
   display: inline-block;
   position: relative;
   overflow: hidden;
   // background-color: #dddbdd;

   &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
      animation: shimmer 2s infinite;
      content: "";
   }

   @keyframes shimmer {
      100% {
         transform: translateX(100%);
      }
   }
}

.img-empty img {
   max-width: 400px;
   margin: auto;
}
