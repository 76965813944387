.wrap-hastag {
   display: flex;
   flex-direction: column;
   .wrap-hashtag-input {
      display: flex;

      &.error {
         border: 1px solid red;
      }
      .first-hashtag {
         color: #0055aa;
         text-align: center;
         font-size: 14px;
         line-height: normal;
         width: fit-content;
         height: 32px;
         padding: 4px 12px;
         margin-right: 4px;
         height: fit-content;
         border-radius: 20px;
         border: 1px solid rgba(0, 0, 0, 0.1);
         display: flex;
         align-items: center;
         justify-content: center;
         margin-top: -2px;
      }
      .hashtag-input-text {
         color: #0055aa;
         width: 100%;
         padding: 0;
         margin-bottom: 10px;
         border: unset;
         max-height: 300px;
         outline: unset;
         resize: none;

         &:hover,
         &:focus,
         &:valid {
            outline: unset;
            border: unset;
         }
      }
   }

   .error-text {
      margin-top: 0px;
      margin-bottom: 16px;
   }

   .btn-add-hashtag {
      color: #707981;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;

      width: fit-content;
      height: 32px;
      padding: 4px 16px;
      border-radius: 100px;
      background: #f7f7f6;
      border: unset;
   }
}
