@use "sass:map";
@import "styles/variables.scss";
@import "./index.flashSale.scss";
@import "./banner.scss";

main.home-pagev2 {
   .block {
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;
      @include media-down(md) {
         padding: 16px 12px;
         border-radius: 0;
      }
      .title {
         font-size: 24px;
         font-weight: 600;
         line-height: 32px;
         display: flex;
         align-items: center;
         @include media-down(md) {
            font-size: 18px;
            line-height: 24px;
         }

         svg {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            @include media-down(md) {
               width: 24px;
               height: 24px;
               margin-right: 4px;
            }
         }
      }
      .sub-title {
         color: #697f90;
         font-size: 16px;
         line-height: 24px;
         margin-bottom: 16px;
         @include media-down(md) {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 16px;
            margin-top: 4px;
         }
      }
      .block-sub {
         display: flex;
         justify-content: space-between;
         margin-bottom: 8px;
         @include media-down(md) {
            margin-bottom: 0;
         }
      }
      .view-all {
         font-size: 18px;
         color: $primary;
         font-weight: 500;
         line-height: 24px;
         display: flex;
         align-items: center;
         .viewall-text {
            @include media-down(md) {
               display: none;
            }
         }

         .next-icon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            border-radius: 8px;
            background: #ffefed;
            @include media-down(md) {
               width: 24px;
               height: 24px;
            }
         }
      }

      &-header {
         margin-bottom: 4px;

         &__title {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: $cl-space-t-orange;
            margin-bottom: 10px;
            cursor: pointer;
         }

         &__description {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
         }
      }
   }
   .feed-height-init {
      height: 440px;
      @include media-down(md) {
         height: 345px;
      }
   }
   .flash-skale-init {
      height: 334px;
      @include media-down(md) {
         height: 206px;
      }
   }

   .content_end_page {
      .more_content {
         margin-top: 24px;
         .content_item {
            &:not(:last-child) {
               margin-bottom: 24px;
            }
         }
      }

      .content_item {
         .content_title {
            margin-bottom: 12px;
            font-weight: 700;
            font-size: 16px;
            color: #252a2b;
            line-height: 19px;
         }
         .content_description {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #697f90;
            a {
               color: #0055aa;
               text-decoration: underline;
            }
            div {
               margin-top: 6px;
            }
            .btn_see_more {
               display: none;
            }
            h4 {
               // font-weight: 400;
               font-size: 12px;
               line-height: 14px;
            }
         }
      }
   }
   .block_banner {
      padding: 18px 0 0px;
      position: relative;
      background: #ffffff;
      @include media-down(md) {
         padding: 0px;
      }
      .container_custom {
         padding: 0;
         padding-top: 16px;
      }
   }

   .main-category {
      background: #fff;

      @include media-down(md) {
         .carowsel-mb .slick-list {
            overflow: unset;
         }
         ul.slick-dots {
            width: fit-content;
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
            height: 4px;
            border-radius: 16px;
            background-color: #ffefed;
            display: flex !important;
            li {
               margin: 0;
               border-radius: 16px;
               width: 16px;
               height: 4px;
               button {
                  width: 16px;
                  height: 4px;
                  padding: 0;
                  margin: 0;
                  &::before {
                     content: none;
                     display: none;
                  }
               }
               &.slick-active {
                  background: #ff6347;
               }
            }
         }
      }
   }
   .hornor-brand {
      background: #fff;
      padding: 16px 0 24px;
      @include media-down(md) {
         padding: 16px 0 16px;
      }
      .section-title {
         font-size: 24px;
         line-height: 32px;
         font-weight: 600;
         text-align: center;
         margin-bottom: 16px;
         @include media-down(md) {
            font-size: 18px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 12px;
         }
      }
   }
   .spacet-partner {
      margin-top: 12px;
      @include media-down(md) {
         margin-left: -10px;
      }
      .partner-item {
         padding: 20px;
         border-radius: 8px;
         border: 1px solid #f0f0f0;
         background: #fff;
      }
   }
   .main-content {
      padding: 24px 0;
      background: #eef2f5;
      @include media-down(md) {
         padding: 8px 0;
      }
      .custom-container {
         @include media-down(md) {
            padding: 0;
         }
      }
   }
}

.coin-attention {
   position: absolute;
   bottom: -70px;
   left: 50%;
   transform: translateX(-50%) scale(0.7);
   z-index: 3;
   @include media-down(md) {
      transform: translateX(-50%) scale(0.65);
   }

   img.coin-bg {
      width: 574px;
      height: 130px;
      min-width: 574px;
      min-height: 130px;
   }
   .content-absolute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      padding: 10px 32px 22px;

      & > div {
         width: 50%;
      }
   }

   .left,
   .right {
      padding: 20px;
   }
   .label {
      color: #697f90;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;
   }
   .coin-earn-link {
      color: $primary;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
   }

   .arrow-right {
      width: 24px;
      height: 24px;
      margin-left: 6px;
   }

   .coin-icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
   }
   .coin-value {
      color: $primary;
      font-size: 30px;
      font-weight: 600;
      line-height: 40px;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   main.home-pagev2 {
      .block {
         &-header {
            margin-bottom: 0px;

            &__title {
               font-weight: 700;
               font-size: 18px;
               line-height: 21px;
               margin-bottom: 2px;
            }

            &__description {
               font-weight: 400;
               font-size: 16px;
               line-height: 19px;
            }
         }
      }
      .content_end_page {
         .more_content {
            &.see_more {
               display: none;
            }
            &.see_less {
               display: block;
            }
         }
         .content_item {
            .content_description {
               .btn_see_more {
                  display: unset;
                  color: #ff6347;
                  cursor: pointer;
                  &.see_more {
                     &::before {
                        content: "...";
                        color: #000;
                     }
                  }
               }
               .btn_hide_see_more {
                  display: BLOCK;
                  padding: 12px 12px 0;
                  text-align: center;
                  color: #ff6347;
                  cursor: pointer;
                  &.see_more {
                     &::before {
                        content: "...";
                        color: #000;
                     }
                  }
               }
            }
         }
      }
   }
}
