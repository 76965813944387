@import "styles/variables.scss";

.modal-receive {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1300;

   .MuiPaper-root {
      width: fit-content;
      max-width: 100%;
      background: unset;
      border-radius: 0;
      background-color: #fafafa;
      border-radius: 10px;
      width: 100%;
      max-width: 1000px;
      overflow-x: hidden;
      overflow-y: auto;

      @include media-down(md) {
         margin: 0;
         border-radius: 16px;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
      }
   }

   .MuiDialog-container {
      @include media-down(sm) {
         align-items: end;
      }
      .img-close {
         width: 100%;
         height: 100%;
         position: absolute;
         right: 15px;
         top: 15px;
         cursor: pointer;

         width: 32px;
         height: 32px;
         border-radius: 50%;
         background-color: #f4f4f4;
         padding: 10px;

         display: flex;
         align-items: center;
         justify-content: center;
         z-index: 101;

         &:hover {
            background-color: #e1dfdf;
         }

         img {
            width: 12px;
            height: 12px;
            object-fit: cover;
         }
      }
   }

   &.full-height {
      .MuiPaper-root {
         @include media-down(sm) {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
         }
      }
   }

   .dialog-content {
      display: flex;
      flex-direction: row;
      padding: 32px;
      overflow: hidden;
      background-color: #fff;
      @include media-down(md) {
         padding: 12px;
         flex-direction: column;
      }
      .wrap-image-list {
         width: 72px;
         overflow-y: auto;
         overflow-x: hidden;
         margin-right: 8px;
         &::-webkit-scrollbar {
            width: 0;
         }
         @include media-down(md) {
            width: unset;
            overflow-y: hidden;
            overflow-x: auto;
            margin-right: 0;
         }
         .image-list {
            display: flex;
            flex-direction: column;

            @include media-down(md) {
               display: flex;
               flex-direction: row;
            }
            .img-item {
               margin-bottom: 8px;
               border-radius: 8px;
               overflow: hidden;
               cursor: pointer;
               border: 2px solid transparent;
               display: flex;
               justify-content: center;
               align-items: center;
               width: 64px;
               height: 64px;
               min-width: 64px;
               object-fit: contain;
               @include media-down(md) {
                  margin-right: 8px;
                  border: unset;
                  width: 104px;
                  height: 104px;
                  min-width: 104px;
               }

               img {
                  width: 100%;
                  height: auto;
               }
               &.active {
                  border-color: $primary;
                  @include media-down(md) {
                     border-color: unset;
                  }
               }
            }
         }
      }
      .current-image {
         margin-right: 16px;
         @include media-down(md) {
            display: none;
         }
         img {
            width: 400px;
            height: 400px;
            min-width: 400px;
            overflow: hidden;
         }
      }
      .product-info {
         margin-left: 16px;
         @include media-down(md) {
            margin: 0;
         }
         .title {
            color: $text;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px; /* 125% */
            max-width: 90%;
            margin-bottom: 16px;
            @include media-down(md) {
               font-size: 14.22px;
               line-height: 20px; /* 140.647% */
               letter-spacing: -0.142px;
               margin-bottom: 8px;
               font-weight: 400;
            }
         }
         .price {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            line-height: 24px;
            padding-bottom: 16px;
            border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
            @include media-down(md) {
               padding-bottom: 8px;
            }
            .discount-price {
               color: #ff6347;
               font-size: 24px;
               font-weight: 800;
               line-height: 24px; /* 100% */
               margin-right: 8px;
               @include media-down(md) {
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 24px;
               }
               span {
                  text-decoration-line: underline;
                  font-weight: 800;
               }
            }
            .real-price {
               color: #9f9f9f;
               font-size: 16px;
               font-weight: 400;
               line-height: 16px; /* 100% */
               text-decoration: line-through;
               @include media-down(md) {
                  font-size: 11px;
               }
            }
            .percent-discount {
               color: #fff;
               font-size: 14px;
               font-weight: 500;
               line-height: normal;
               letter-spacing: -0.42px;
               text-align: center;
               background-color: #ff6347;
               width: max-content;
               height: 20px;
               border-radius: 8px;
               padding: 2px 6px;
               margin-right: 8px;
               display: flex;
               align-items: center;
               justify-content: center;
               @include media-down(md) {
                  font-size: 12px;
               }
            }
         }
         .color {
            position: relative;
            .name-color {
               width: 100%;
               font-size: 1rem;
               color: map.get($text-colors, "default");
            }
            .variant-color {
               width: 30px;
               height: 30px;
               cursor: pointer;
               appearance: none;
               border: 1px solid;
               border-radius: 50%;
               border-color: #000;

               &:checked[type="radio"] {
                  border: 1px solid;
                  border-radius: 50%;
                  border-color: #000;
               }
               &:checked[type="radio"] + .label-color {
                  opacity: 1;
               }
            }
            .label-color {
               position: absolute;
               height: 24px;
               width: 24px;
               top: 27px;
               left: 27px;
               opacity: 0;
               background-color: gray;
               border-radius: 50%;
            }
         }
         .wrap-attr {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            padding-top: 16px;
            margin-bottom: 42px;
            .wrap-style {
               display: flex;
               flex-direction: column;
               & > div:not(:first-child) {
                  margin-top: 14px;
               }
               &.error {
                  margin-left: -16px;
                  margin-right: -16px;
                  padding: 16px;
                  background: rgba(255, 239, 237, 0.5);
                  .error-text {
                     color: $primary;
                     font-size: 14px;
                     font-weight: 400;
                     line-height: 16px; /* 114.286% */
                     text-align: center;
                     margin-top: 16px;
                  }
               }
            }
            .attr-label {
               color: $span;
               font-size: 14px;
               line-height: 16px; /* 142.857% */
               margin-bottom: 12px;
               display: flex;
               align-items: center;
               position: relative;
               width: fit-content;
               @include media-down(md) {
                  font-size: 12.64px;
                  margin-bottom: 8px;
               }
               .super-label {
                  color: #252a2b;
                  font-size: 11.24px;
                  line-height: 16px; /* 142.349% */
                  letter-spacing: -0.112px;
                  display: block;
                  width: fit-content;
                  height: 20px;
                  padding: 2px 8px;
                  border-radius: 16px;
                  background: rgba(226, 228, 235, 0.4);
                  margin-left: 8px;
               }
            }
            .color-group {
               display: flex;
               flex-direction: column;

               .color-list {
                  display: flex;
                  flex-flow: row wrap;
                  row-gap: 16px;
                  // align-items: center;
                  // overflow-x: auto;
                  // overflow-y: hidden;
               }
               .color-item {
                  border-radius: 50%;
                  margin-right: 16px;
                  border: 2px solid transparent;
                  position: relative;
                  cursor: pointer;

                  .disabled-icon {
                     width: 16px;
                     height: 16px;
                     position: absolute;
                     top: -2px;
                     right: -3px;
                     z-index: 1;
                     border: 1px solid #fff;
                     border-radius: 50%;
                     background: #fff;
                  }
                  .color {
                     width: 26px;
                     height: 26px;
                     border-radius: 50%;
                     border: 1px solid rgba(0, 0, 0, 0.1);
                  }

                  &.active {
                     border: 2px solid #000;
                  }

                  &.disabled {
                     opacity: 0.3;
                     cursor: not-allowed;
                     .color {
                        cursor: not-allowed;
                     }
                  }
               }
            }
            .size-group {
               display: flex;
               flex-direction: column;
               .size-list {
                  display: flex;
                  // align-items: center;
                  // overflow-x: auto;
                  flex-flow: row wrap;
                  row-gap: 8px;
               }
               .size-item {
                  width: max-content;
                  min-width: 67px;
                  height: 32px;
                  border: 1px solid #252a2b;
                  text-align: center;
                  margin-right: 0.5rem;
                  padding: 2px;
                  border-radius: 32px;
                  border: 1px solid #e2e4eb;
                  background: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  white-space: nowrap;
                  cursor: pointer;
                  overflow: hidden;

                  .size {
                     font-size: 1rem;
                     width: 100%;
                     height: 100%;
                     padding: 2px;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                  }
                  &.active {
                     border-width: 1px;
                     border-color: #000;
                  }
                  &.disabled {
                     border: 1px dashed #e2e4eb;
                     background: #fff;
                     cursor: not-allowed;
                     .size {
                        color: #e2e4eb;
                     }
                  }
                  .variant-thumbnail-img {
                     width: auto;
                     height: 100%;
                     margin-right: 4px;
                     margin-left: 0px;
                  }
               }
            }
         }
         .quantity-wrap {
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            padding: 24px 12px;
            margin-top: -24px;
            .quantity-option {
               display: flex;
               align-items: center;
               width: fit-content;
               margin: 0 auto;
               button {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 16px; /* 100% */
                  width: 32px;
                  height: 32px;
                  border-radius: 4px;
                  border: 1px solid #e2e4eb;
                  background: #fff;
               }
               input {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 16px;
                  width: 32px;
                  height: 32px;
                  border: unset;
                  outline: unset;
                  text-align: center;
               }
            }
         }
         .action {
            display: flex;
            text-align: center;
            margin-top: 32px;
            @include media-down(md) {
               margin-top: 10px;
            }
            button {
               font-size: 16px;
               font-weight: 500;
               line-height: 16px; /* 100% */
               white-space: nowrap;
               width: 100%;
               height: 48px;
               border-radius: 40px;
               display: flex;
               align-items: center;
               justify-content: center;
               @include media-down(md) {
                  font-size: 14.22px;
                  font-weight: 600;
                  height: 40px;
               }
            }
            .buy-now {
               color: #fff;
               background: $primary;
            }
         }
      }
   }
}
