.breadcrumb {
   font-size: 14px;
   font-weight: 400;
   margin-bottom: 0px;
   row-gap: 6px;
   overflow-x: auto;
   overflow-y: hidden;
   display: flex;
   flex-flow: nowrap;
   white-space: nowrap;

   scrollbar-width: none;

   &::-webkit-scrollbar {
      display: none;
   }

   @include media-down(md) {
      margin-top: 0;
   }

   .route_item {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;

      svg {
         width: 7px;
         height: 12px;
         margin: 0 12px;

         @include media-down(md) {
            width: 5px;
            height: auto;
            margin: 0 6px;
         }
      }

      .route_name {
         color: #005993;
         cursor: pointer;

         @include media-down(md) {
            font-size: 11.24px;
         }

         &:hover {
            color: #005993;
         }
      }

      &:last-child {
         font-weight: 400;

         .route_name {
            cursor: default;
            color: #000;
         }
      }
   }

}