.product-not-accept {
   .MuiPaper-root {
      max-width: 360px;
   }
   .MuiDialogContent-root {
      padding: 0 24px 24px;
   }

   .description {
      color: #252A2B;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 24px;
   }

   button {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      width: 100%;
      border: none;
      height: 42px;
      padding: 6px 16px;
      border-radius: 8px;
      background-color: #F24F2B;
   }
}