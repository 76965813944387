@import "../../../styles/variables.scss";

.spacet-shop {
   @include media-down(md) {
      padding-bottom: $NAVIGATOR-HEIGHT;
   }
   .spacet-shop-container {
      width: 100%;
      max-width: $container-width;
      padding-left: $container-padding;
      padding-right: $container-padding;
      margin: 0 auto;
      @include media-down(md) {
         padding-left: 0;
         padding-right: 0;
      }
   }
   // .wrap-product-list {
   //    & > div {
   //       border-top: 1px solid rgba(0, 0, 0, 0.1);
   //       padding-bottom: 24px;
   //       padding-top: 32px;
   //    }
   // }
   .wrap-loading-icon {
      margin: 0;
      display: flex;
      padding: 16px;
      justify-content: center;
   }
}
