@use "sass:map";
// @import 'src/assets/scss/core/variables.scss';
@import "styles/variables.scss";

main.home-pagev2 {
   .menu {
      padding-top: 4px !important;

      &-item {
         display: flex;
         flex-direction: column;
         align-items: center;
         color: #252a2b;

         .image {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f2f5fa;
            border-radius: 12px;
            width: 65px;
            height: 65px;
            padding: 2px;

            img,
            picture {
               width: 100%;
            }
         }

         span {
            margin-top: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            max-width: 94px;
            @include media-down(md) {
               max-width: 60px;
            }
         }
      }
   }

   .article {
      overflow: hidden;

      &-list {
         margin-left: -8px;
         margin-right: -8px;
         margin-top: -16px;

         [class^="col-"] {
            padding-left: 8px;
            padding-right: 8px;
            margin-top: 16px;
         }
      }

      &-item {
         .img {
            aspect-ratio: 1.5;
            border-radius: 3px;
            overflow: hidden;
         }

         .title {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #252a2b;
            margin-bottom: 0px;
            margin-top: 12px;
         }

         .category-sub {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
         }
      }
      .social-feed-slider {
         margin-left: -8px;
         margin-right: -8px;
         .description_trend {
            overflow: hidden;
            color: #252a2b;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            margin-top: 2px;
            @include media-down(md) {
               font-size: 14px;
               line-height: 20px;
            }
         }
      }
   }

   .social-feed {
      overflow: hidden;

      &-list {
         overflow: hidden;
         margin: 0px -8px;

         [class^="col-"] {
            padding-left: 8px;
            padding-right: 8px;
         }
      }

      &-item {
         position: relative;
         background: none;
         padding: 0px 8px;
         overflow: hidden;

         .img {
            aspect-ratio: 0.75;
            border-radius: 8px;
            overflow: hidden;
         }

         .username {
            position: absolute;
            bottom: 8px;
            left: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            margin-bottom: 0px;
            text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            display: inline-table;
         }
         .avatar-socialfeed {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 99;
            width: 40px;
            height: 40px;
            border: 3px solid #ffffff;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
            border-radius: 100px;
            object-fit: cover;
            object-position: center;
         }
         .btn-follow {
            z-index: 99;
            padding: 2px 6px;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
         }
         .following {
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            color: #252a2b;
         }
         .follow {
            background: #ff6347;
            color: #ffffff;
         }
      }
   }

   .interior-design {
      .article-item {
         background: #ffffff;
         border: 1px solid #e5e5e6;
         border-radius: 4px;
         overflow: hidden;

         .img {
            aspect-ratio: 1.5;
            border-radius: 4px;
            overflow: hidden;
         }

         &-info {
            padding: 12px 10px;
            text-align: center;

            .title {
               margin: 0px;
               font-weight: 500;
               font-size: 20px;
               line-height: 23px;
            }

            .sub-title {
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               margin-top: 8px;
               margin-bottom: 0px;
               color: #252a2b;
            }
         }
      }
   }

   .magazine {
      .article-item {
         .title-blog {
            margin-top: 2px;
         }
      }
   }

   .slider-image-by-space {
      margin: 0px -6px;
      background: inherit;
      .slider-item {
         padding: 0px 8px;
         .article-item {
            border-radius: 0px;
            border: 1px solid #e5e5e6;
            .img {
               border-radius: 0px;
            }
            .title {
               h3 {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  margin-bottom: 0px;
               }
            }
         }
      }
      .slick-prev,
      .slick-next {
         width: 36px;
         height: 36px;
         z-index: 1;
      }
      .slick-prev {
         left: -8px;
      }
      .slick-next {
         right: -8px;
      }
      .slick-disabled {
         display: none !important;
      }
   }
   .slider-image-by-space_custom {
      margin: 0px -6px;
      background: inherit;
      .slider-item {
         padding: 0px 8px;
         .article-item {
            border-radius: 0px;
            border: 1px solid #e5e5e6;
            .img {
               border-radius: 0px;
            }
            .title {
               h3 {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  margin-bottom: 0px;
               }
            }
         }
      }
      .slick-prev,
      .slick-next {
         width: 36px;
         height: 36px;
         z-index: 1;
      }
      .slick-prev {
         left: 0;
      }
      .slick-next {
         right: 0;
      }
      .slick-disabled {
         display: none !important;
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   main.home-pagev2 {
      .menu {
         padding-top: 0px;
         margin-top: 20px;
         &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #252a2b;

            .image {
               width: 48px;
               height: 48px;
               padding: 0;
            }

            span {
               font-size: 11px;
               line-height: 13px;
            }
         }
      }

      .article {
         &-list {
            margin-left: -6px;
            margin-right: -6px;

            [class^="col-"] {
               padding-left: 6px;
               padding-right: 6px;
            }
         }

         &-item {
            .img {
               aspect-ratio: 1.38;
            }

            .title {
               font-size: 14px;
               line-height: 16px;
               margin-top: 8px;
            }
         }
         .social-feed-slider {
            margin-left: 0;
            margin-right: 0;
         }
         .slider {
            margin-left: -8px;
            margin-right: -8px;
            .slick-prev,
            .slick-next {
               display: none !important;
            }
         }
         .slider-image-by-space {
            margin-left: -8px;
            margin-right: -8px;
            .slick-prev,
            .slick-next {
               display: none !important;
            }
         }
      }

      .social-feed {
         overflow: hidden;

         &-slider {
            .slick-slider {
               // padding-left: 12px;
            }
         }

         &-item {
            position: relative;
            background: none;
            padding: 0px;
            padding-right: 12px;

            .img {
            }

            .username {
               bottom: 8px;
            }
            .avatar-socialfeed {
               top: 8px;
               left: 8px;
               width: 34px;
               height: 34px;
            }
            .btn-follow {
               font-weight: 500;
               font-size: 13px;
               line-height: 15px;
               padding: 2px 4px;
               top: 8px;
               right: 8px;
            }
         }
      }

      .interior-design {
         .article-item {
            overflow: hidden;

            .img {
               aspect-ratio: 1.3;
            }

            &-info {
               text-align: left;

               .title {
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 15px;
               }

               .sub-title {
                  font-size: 12px;
                  line-height: 14px;
                  margin-top: 4px;
               }
            }
         }
      }
   }
}
