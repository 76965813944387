@import "styles/variables.scss";

.feed-result {
   max-width: 590px;
   margin: 0 auto;
   padding-bottom: 24px;

   @include media-down(md) {
      background-color: #e6e7e8;
      padding-bottom: calc((24px + 12px)); // add with border of footer

      &.isEmpty {
         background-color: unset;
      }
   }

   .block-feeds {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include media-down(md) {
         gap: 6px;
      }
   }

   .social-feed-item {
      padding: 0;
      scroll-margin: 120px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 8px;

      @include media-down(md) {
         padding: 12px 0;
         border-radius: unset;
      }
   }

   .social-feed-fake-item {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 12px 0px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
   }

   .search-no-result {
      color: rgba(37, 42, 43, 0.5);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      padding-top: 24px;

   }
}

.slider-social-feed-search {
   .btn-close {
      position: absolute;
      left: 16px;
      top: 16px;
      background: #fff;
      border-radius: 50%;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;

      >* {
         font-size: 20px;
      }
   }

   .social-feed__image {
      position: relative;
      // max-height: 80vh;
      min-height: 150px;
      background-color: var(--palette-grey-1);
      height: 100%;
      width: 100%;
      object-fit: cover;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }

      .tag-count-img {
         position: absolute;
         top: 12px;
         right: 12px;
         background: rgba(0, 0, 0, 0.5);
         border-radius: 100px;
         padding: 4px 6px;
         color: #fff;
         z-index: map-get($map: $zindex, $key: 'sticky');
      }

      .tagging-point {
         width: 14px;
         height: 14px;
         border-radius: 50%;
         background: #ffffff;
         position: absolute;
         z-index: 9;
      }

      .popover {
         position: absolute;
         background-color: transparent;
         display: flex;
         flex-direction: column;
         overflow: hidden;
         z-index: 100;

         .img-default {
            img {
               height: 30px !important;
               width: 30px !important;
            }
         }
      }
   }
}