@use 'sass:map';

.landing-page-service-page {
   .service-service-page {
      padding: 100px 0;
      background: rgba(241, 241, 241, 0.9);

      .header {
         .description {
            max-width: 580px;
         }
      }

      .items {

         .item {
            &:not(:last-child) {
               margin-bottom: 50px;
            }

            display: flex;

            .content {
               margin-left: 64px;
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: flex-start;
               opacity: 0.9;

               .name {
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 28px;
                  text-align: center;
                  color: #252a2b;
                  margin-bottom: 24px;

               }

               .text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  color: #252a2b;
               }
            }

            // img 1
            .block-image {
               background-repeat: no-repeat;
               background-position: center;
               background-size: cover;
               background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_1.png');
               animation: changeImage3 15s infinite;

               img {
                  width: unset;
               }
            }

            @-webkit-keyframes changeImage3 {
               0% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_1.png');
               }

               25% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_5.png');
               }

               50% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_4.png');
               }

               75% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_3.png');
               }

               100% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_2.png');
               }
            }

            @keyframes changeImage3 {
               0% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_1.png');
               }

               25% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_5.png');
               }

               50% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_4.png');
               }

               75% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_3.png');
               }

               100% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_2d_2.png');
               }
            }

            // end img1
            // img 2
            .block-image1 {
               background-repeat: no-repeat;
               background-position: center;
               background-size: cover;
               background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_1.png');
               animation: changeImage1 30s infinite;

               img {
                  width: unset;
               }
            }

            @-webkit-keyframes changeImage1 {
               0% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_1.png');
               }

               10% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_2.png');
               }

               20% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_3.png');
               }

               30% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_4.png');
               }

               40% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_5.png');
               }

               50% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_6.png');
               }

               60% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_7.png');
               }

               70% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_8.png');
               }

               80% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_9.png');
               }

               90% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_10.png');
               }
            }

            @keyframes changeImage1 {
               0% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_1.png');
               }

               10% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_2.png');
               }

               20% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_3.png');
               }

               30% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_4.png');
               }

               40% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_5.png');
               }

               50% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_6.png');
               }

               60% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_7.png');
               }

               70% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_8.png');
               }

               80% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_9.png');
               }

               90% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_3d_10.png');
               }
            }

            // end img2
            // img 3
            .block-image2 {
               background-repeat: no-repeat;
               background-position: center;
               background-size: cover;
               background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_1.png');
               animation: changeImage2 15s infinite;

               img {
                  width: unset;
               }
            }

            @-webkit-keyframes changeImage2 {
               0% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_1.png');
               }

               25% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_2.png');
               }

               50% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_3.png');
               }

               75% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_4.png');
               }

               100% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_5.png');
               }
            }

            @keyframes changeImage2 {
               0% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_1.png');
               }

               25% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_2.png');
               }

               50% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_3.png');
               }

               75% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_4.png');
               }

               100% {
                  background-image: url('https://static.spacet.vn/img/landing-page/interior-design/service_nt_5.png');
               }
            }

            // end img 3
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'lg')) {
      .service-service-page {
         .items {
            .item {
               .block-image {
                  img {
                     width: 277px;
                  }
               }

               .block-image1 {
                  img {
                     width: 277px;
                  }
               }

               .block-image2 {
                  img {
                     width: 277px;
                  }
               }
            }
         }
      }
   }

   @media (max-width: map-get($grid-breakpoints, 'md')) {
      .service-service-page {
         padding: 32px 12px;

         .header {
            text-align: center;

            .title {
               padding: 0 12px;
               margin-bottom: 20px;
            }

            .description {
               margin-bottom: 20px;
               max-width: unset;
            }
         }

         .container {
            padding: 0 0;
            // max-width: un

         }

         .items {
            .item {
               &:not(:last-child) {
                  margin-bottom: 20px;
               }

               display: block;
               background-color: #fff;
               padding: 20px;

               .content {
                  margin-left: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  opacity: 0.9;

                  .name {
                     margin-bottom: 20px;
                     margin-top: 20px;
                  }
               }

               .block-image {
                  img {
                     width: 100%;
                  }
               }

               .block-image1 {
                  img {
                     width: 100%;
                  }
               }

               .block-image2 {
                  img {
                     width: 100%;
                  }
               }
            }
         }
      }
   }


}

@-webkit-keyframes fade {
   0% {
      opacity: 1;
   }

   25% {
      opacity: 1;
   }

   50% {
      opacity: 0;
   }

   75% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes fade {
   0% {
      opacity: 1;
   }

   25% {
      opacity: 1;
   }

   50% {
      opacity: 0;
   }

   75% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}