.img {
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .remove {
        width: 30px;
        height: 30px;
        padding: 8px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0 !important;
        left: unset !important;
        margin: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
