@use 'sass:map';

.landing-page-service-page {
   .slider {
      width: 100%;
      height: 100%;

      .banner {
         position: relative;

         .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
         }
      }

      img {
         z-index: 1;
         width: 100%;
         height: 100%;
         object-fit: cover;
         display: inline-block;
      }
   }
}