@use 'sass:map';

.group-action-post {
   z-index: 1000;

   &-sticky {
      padding: 8px 24px;
      top: 110px;
      position: sticky;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(155, 110, 110, 0.3);
      border-radius: 8px;

      &-item {
         padding: 12px 0;
         &:not(:first-child) {
            border-top: 1px solid #dddddd;
         }
      }
   }

   &-float {
      position: fixed;
      bottom: 8px;
      background: #ffffff;
      box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 12px;
      gap: 14px;
      display: inline-flex;
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;
      z-index: 1000;

      &-item {
      }
   }

   &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .des {
         font-size: 16px;
         color: map.get($text-colors, 'default');
         white-space: nowrap;
         margin: 0;
      }

      .item {
         align-items: center;
         display: flex;
         cursor: pointer;
         text-decoration: none;

         img {
            width: 24px;
            height: 24px;
            object-fit: contain;
         }

         .des {
            font-size: 16px;
            color: map.get($text-colors, 'default');
            padding: 0 10px;
            margin: 0;
            white-space: nowrap;
         }

         &.active,
         &:hover {
            img {
               filter: invert(42%) sepia(100%) saturate(408%) hue-rotate(321deg) brightness(102%) contrast(103%);
            }

            .des {
               color: #ff6347;
            }
         }
      }
   }

   .btn-contact {
      width: 100%;
      background: #ff6347;
      padding: 8px;
      margin-top: 8px;
      margin-bottom: 12px;
      a {
         width: 100%;
         height: 100%;
      }

      span {
         color: #ffffff;
         text-decoration: none;
      }
   }

   .group-action-post-des {
      margin-top: 12px;
      .des {
         font-size: 12px;
         line-height: 14px;
         text-align: justify;
      }
   }

   .chat-position-margin-bottom {
      bottom: 64px;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, 'sm')) {
   .group-action-post {
      &-sticky {
         .modal-bookmark .modal-main {
            bottom: 15%;
         }
      }
   }
}
