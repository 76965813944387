@import "../../../styles/variables.scss";
.newProduct {
   border-top: unset;
   background: $bg;
   @include media-down(md) {
      min-height: calc(100dvh - 90px);
      background-color: #f5f5fa;
   }
   .wrap-sticky {
      @include media-down(md) {
         position: sticky;
         top: 60px;
         z-index: 3;
      }
   }
   .banner {
      width: 100%;
      height: auto;
      max-width: $container-width;
      padding: 16px $container-padding 0;
      margin: 0 auto;
      cursor: pointer;
      @include media-down(md) {
         padding-top: 8px;
         height: 103px;
         overflow: hidden;
         background-color: #fff;
      }
      img {
         width: 100%;
         object-fit: cover contain;
         object-position: center;
         // border-radius: 13px;
         overflow: hidden;
         @include media-down(md) {
            border-radius: 10px;
         }
      }
   }
   .spacet-shop-catalog {
      background-color: $bg;
      padding-top: 32px;
      padding-bottom: 24px;
      @include media-down(md) {
         padding-top: 8px;
         padding-bottom: 0;
      }
   }
}
