@-moz-keyframes spin {
   from {
      -moz-transform: rotate(0deg);
   }
   to {
      -moz-transform: rotate(360deg);
   }
}
@-webkit-keyframes spin {
   from {
      -webkit-transform: rotate(0deg);
   }
   to {
      -webkit-transform: rotate(360deg);
   }
}
@keyframes spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

@keyframes opacityScale {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
      scale: 1;
   }
}

@keyframes opacity {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
