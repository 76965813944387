.modal-create-address {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   overflow: hidden;
   background: #e5e5e57a;
   z-index: 9999;
   form {
      padding: 24px !important;
   }
}
.modal-create-address-content {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   padding: 0 24px;
   max-width: calc(520px + 24px);
   margin: 0 auto;
   .head {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      h3.title {
         font-size: 18px;
         font-style: normal;
         font-weight: 500;
         line-height: 24px;
         margin-bottom: 6px;
      }
      p.des {
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 16px;
         margin-bottom: 0;
      }
   }
   form {
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      padding: 32px 0;
      .error-text {
         color: $primary;
         font-size: 12px;
         font-style: normal;
         font-weight: 400;
         line-height: 16px; /* 133.333% */
         letter-spacing: -0.12px;
         display: flex;
         align-items: center;
      }
      .form-checked {
         display: flex;
         align-items: center;
         margin-top: 10px;
         gap: 7px;
         opacity: 0.3;
         label {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #252a2b;
         }
         .form-check-input {
            border: 1px solid #e0e0e0;
            border-color: #e0e0e0;
            border-radius: 2px;
            width: 15px;
            outline: none;
            box-shadow: none;
            margin: 0;
            &:checked {
               background-color: #ff6347;
               border-color: #ff6347;
            }
         }
      }
      .wrap {
         display: flex;
         align-items: center;
         flex-wrap: nowrap;
         gap: 10px;
      }
      .item {
         width: 100%;
         margin-bottom: 16px;
      }
      .input {
         padding: 9px 14px;
         border: 1px solid #e0e0e0;
         border-radius: 4px;
         outline: none;
         width: 100%;
         font-size: 0.875rem;
         height: 40px;
         border-radius: 8px;
         border: 1px solid #e2e4eb;
         background: #fff;
         &.error {
            border: 1px solid #ff0000;
         }

         &::placeholder {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
         }
      }
      textarea {
         font-size: 0.875rem;
         padding: 9px 14px;
         border: 1px solid #e0e0e0;
         border-radius: 4px;
         outline: none;
         width: 100%;
         height: 120px;
         border-radius: 8px;
         border: 1px solid #e2e4eb;
         background: #fff;
         &.error {
            border: 1px solid #ff0000;
         }

         &::placeholder {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
         }
      }
      .btn-wrap {
         margin-top: 48px;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 8px;
         .btn {
            font-size: 16px;
            height: 40px;
            max-width: 160px;
            border-radius: 30px;
            &.success {
               background: #ff6347 !important;
               border: none;
               color: #fff;
            }
         }
      }
   }
}
.modal-create-address-mb {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   overflow: hidden;
   background: #f5f5fa;
   z-index: 9999;
   padding-top: calc(56px);
}
.modal-create-address-content-mb {
   .modal-header {
      height: 60px;
      display: flex;
      align-items: center;
      background: #fff;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      button.back-icon {
         background: unset;
         border: unset;
         width: 56px;
         height: 100%;
         position: absolute;
         left: 0;
         top: 0;
      }
      .title {
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         width: 100%;
         text-align: center;
      }
      .delete-icon {
         background: unset;
         border: unset;
         width: 56px;
         height: 100%;
         position: absolute;
         right: 0;
         top: 0;
         svg {
            width: 24px;
            height: 24px;
         }
      }
   }
   .main {
      width: 100%;
      height: 100%;
      max-width: calc(520px + 24px);
      padding-top: 4px;
      margin: 0 auto;
      overflow-x: hidden;
      overflow-y: auto;
      border-top: 8px solid #f5f5fa;
      form {
         width: 100%;
         height: 100%;

         .error-text {
            color: $primary;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: -0.12px;
            display: flex;
            align-items: center;
         }
         .form-checked {
            display: flex;
            align-items: center;
            gap: 7px;
            opacity: 0.3;
            padding: 18px 16px;
            background: #fff;
            label {
               font-weight: 400;
               font-size: 14px;
               line-height: 16px;
               color: #252a2b;
            }
            .form-check-input {
               border: 1px solid #e0e0e0;
               border-color: #e0e0e0;
               border-radius: 2px;
               width: 15px;
               outline: none;
               box-shadow: none;
               margin: 0;
               &:checked {
                  background-color: #ff6347;
                  border-color: #ff6347;
               }
            }
         }
         .wrap {
            padding: 16px;
            background: #fff;
            border-bottom: 8px solid $border;
            .head {
               h3.title {
                  font-size: 12.64px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  margin-bottom: 0;
               }
            }
            .item {
               width: 100%;
               &:not(:last-child) {
                  margin-bottom: 12px;
               }
               .input {
                  padding: 9px 0;
                  border: unset;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                  border-radius: 0;
                  outline: none;
                  width: 100%;
                  font-size: 14.22px;
                  height: 40px;
                  background: #fff;
                  &.error {
                     // border: 1px solid #ff0000;
                  }

                  &::placeholder {
                     color: #9f9f9f;
                     font-size: 14px;
                     font-weight: 400;
                     line-height: normal;
                  }
               }
               textarea {
                  font-size: 0.875rem;
                  padding: 9px 14px;
                  border: unset;
                  border-bottom: 1px solid #e0e0e0;
                  border-radius: 0;
                  outline: none;
                  width: 100%;
                  &.error {
                     // border: 1px solid #ff0000;
                  }

                  &::placeholder {
                     color: #9f9f9f;
                     font-size: 14px;
                     font-weight: 400;
                     line-height: normal;
                  }
               }
            }
            .item-phone {
               .phone-code {
               }
               input {
                  // padding-left: 52px;
               }
            }
            .item-area {
               position: relative;
               .arrow-icon {
                  position: absolute;
                  right: 0;
                  top: 20px;
               }
            }
         }
         .btn-wrap {
            padding: 24px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background: #f5f5fa;
            .btn {
               font-size: 16px;
               width: 100%;
               height: 40px;
               border-radius: 30px;
               @include media-down(md) {
                  font-size: 12.64px;
               }
               &.success {
                  background: #ff6347 !important;
                  border: none;
                  color: #fff;
               }
            }
         }
      }
   }
   .fill-district {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #fff;
      &-tabs {
         padding: 0 16px;
         display: flex;
         flex-direction: row;
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
         background: #fff;
         .tabs-name {
            color: #9f9f9f;
            font-size: 12.64px;
            font-weight: 400;
            height: 48px;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 12px;
            cursor: pointer;
            &.active {
               color: #252a2b;
               font-size: 14px;
               font-weight: 600;
               line-height: normal;
               &::after {
                  content: "";
                  position: absolute;
                  bottom: 8px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 16px;
                  height: 3px;
                  background: $primary;
                  border-radius: 4px;
               }
            }
            &:not(.isAvailable) {
               display: none;
            }
         }
      }
      &-list {
         width: 100%;
         height: calc(100vh - 115px);
         display: flex;
         flex-direction: column;
         padding: 0 16px;
         overflow: hidden auto;
         .item {
            font-size: 12.64px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 126.582% */
            letter-spacing: -0.126px;
            padding: 12px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::before {
               content: "";
               position: absolute;
               left: 0;
               width: 3px;
               height: 24px;
               border-radius: 0px 30px 30px 0px;
               background: #ff6347;
               display: none;
            }

            .right-icon {
               display: none;
               svg {
                  width: 14px;
                  height: 10px;
               }
            }

            &.active {
               color: #ff6347;
               .right-icon {
                  display: block;
               }
            }
         }

         .w-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }
}
