@import "../../../styles/variables.scss";

.header-flash-sale {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 56px;
   background-color: rgba(255, 255, 255, 1);
   z-index: 99;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   background: rgba(255, 255, 255, 0.8);
   backdrop-filter: blur(20px);

   .hr-vertical {
      display: block;
      width: 1px;
      height: 16px;
      background-color: #000;
      opacity: 0.3;
      margin-left: 40px;
      margin-right: 22px;
   }

   .shop-menu {
      position: relative;

      .root-menu {
         height: 54px;
         display: flex;
         align-items: center;
         cursor: pointer;
         white-space: nowrap;

         .menu-icon-collapse {
            width: 12px;
            height: 16px;
            margin-right: 8px;
         }

         .arrow-down {
            width: 10px;
            height: 6px;
            margin-left: 8px;
         }
      }
   }

   .flash-menu {
      display: flex;
      align-items: center;
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;

      .icon {
         width: 24px;
         height: 24px;
         margin-right: 4px;
      }
      img.icon {
         width: 20px;
         height: 20px;
      }

      li {
         margin-right: 12px;

         a {
            color: #252a2b;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            padding: 10px 6px;

            display: flex;
            align-items: center;
            cursor: pointer;
         }
      }
   }

   .task-coin {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px 16px;
      border-radius: 30px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.4);
      cursor: pointer;

      .coin-icon {
         width: 24px;
         height: 24px;
         margin-right: 3px;
      }

      span {
         color: #ffc323;
         font-size: 16px;
         font-weight: 700;
         line-height: normal;
         letter-spacing: 0.32px;
      }

      .coin-recever-link {
         color: #ff6347;
         font-size: 16px;
         font-weight: 400;
         line-height: normal;
         text-decoration-line: underline;
         position: relative;
         margin-left: 28px;
         cursor: pointer;

         &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px;

            width: 4px;
            height: 4px;
            background-color: #9f9f9f;
            border-radius: 50%;
         }
      }
   }
}

.dropdown-menu-shop {
   position: absolute;
   top: 46px;
   left: 0;
   z-index: 99;
   padding-top: 14px;
   animation: opacity 0.3s;

   @keyframes opacity {
      0% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }

   .menu-content {
      display: flex;
      flex-direction: row;

      padding: 0 16px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
   }

   .shop-menu-list {
      padding: 24px 0;
      max-height: 86vh;
      overflow-y: auto;

      &::-webkit-scrollbar {
         display: none;
      }
      &::-webkit-scrollbar-thumb {
         display: none;
      }

      .shop-menu-list-content {
         min-width: calc(190px + 24px);
         display: flex;
         flex-direction: column;
         row-gap: 16px;
      }

      &:not(:last-child) {
         .shop-menu-list-content {
            margin-right: 24px;
            padding-right: 24px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
         }
      }
   }

   .shop-menu-item {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .menu-icon {
         width: 32px;
         height: 32px;
         min-width: 32px;
         border-radius: 50%;
         background-color: #fafafc;
         margin-right: 8px;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            width: 80%;
            height: auto;
         }
      }

      .menu-title {
         white-space: nowrap;
         color: #252a2b;
         font-size: 16px;
         font-weight: 400;
         line-height: normal;
      }

      .arrow-right {
         width: 6px;
         height: 12px;
         display: none;
      }
   }

   .shop-menu-item.active,
   .shop-menu-item:hover {
      .arrow-right {
         display: block;
      }

      .menu-title {
         color: #ff6347;
         text-decoration-line: underline;
      }
   }
}

.shop-menu-mb {
   width: 100%;
   height: $MENU-HEIGHT-MOBILE;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 8px 12px 8px;
   border-bottom: 1px solid var(--gray-gray-400, #e2e4eb);
   background: #fff;

   .collapse-icon {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 8px;
      padding-top: 6px;

      svg {
         width: 24px;
         height: 24px;
      }
   }

   .search-icon {
   }

   .wrapinput {
      width: 100%;
      height: 32px;
      padding-left: 28px;
      border-radius: 30px;
      background: #fff;
      position: relative;
      overflow: hidden;
      margin-right: 8px;

      display: flex;
      align-items: center;

      .prefix {
         position: absolute;
         left: 10px;
         top: 50%;
         transform: translateY(-50%);

         width: 12px;
         height: 12px;
         opacity: 0.5;
      }

      span {
         color: #252a2b;
         font-size: 12px;
         font-weight: 400;
         line-height: normal;
         opacity: 0.5;
      }

      input {
         color: #252a2b;
         font-size: 12px;
         font-weight: 400;
         width: 100%;
         height: 100%;
         border: unset;
         outline: unset;

         &:hover,
         &:focus {
            border: unset;
            outline: unset;
         }

         &::placeholder {
            color: #b3afaf;
         }
      }
   }

   .shop-card {
      position: relative;
      cursor: pointer;

      .cartCount {
         color: #fff;
         font-size: 10px;
         font-weight: 500;
         line-height: 9px;
         width: 16px;
         height: 16px;
         border-radius: 50%;
         border-radius: 20px;
         background: #ff6347;
         display: flex;
         align-items: center;
         justify-content: center;

         position: absolute;
         top: 4px;
         right: 2px;
         display: none;
      }

      &.isAvailableCartCount {
         .cartCount {
            display: flex;
         }
      }
   }

   .chat-message {
      cursor: pointer;

      svg {
         width: 32px;
         height: 32px;
      }
   }

   .btn-advise {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      height: 24px;
      padding: 4px 8px;
      border: unset;
      border-radius: var(--Height-controlHeightLG, 40px);
      background: var(--Orange-orange-500, #ff6347);
   }
}

.shop-subheader {
   background: #ff6347;

   .shop-subheader-header {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px 8px;

      .backlink {
         display: flex;
         align-items: center;

         svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
         }

         h1 {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            /* 100% */
            margin: 0;
            margin-left: 8px;
         }
      }

      .wrap-icon-search {
         width: 24px;
         height: 24px;

         img {
            width: 100%;
            height: auto;
         }
      }

      .shop-card {
         margin-left: 8px;
         cursor: pointer;
         position: relative;

         svg {
            width: 20px;
            height: 20px;
         }

         .count {
            color: #ff6347;
            font-size: 8px;
            font-weight: 600;
            line-height: 9px;
            /* 90% */

            position: absolute;
            top: 1px;
            right: -4px;
            width: 14px;
            height: 14px;
            background-color: #fff;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
         }
      }

      .collapse-icon {
         margin-left: 16px;
         cursor: pointer;

         svg {
            width: 24px;
            height: 24px;
         }
      }
   }

   .level4-cate {
      background-color: #fff;

      ul.cate-list {
         padding: 0 16px;
         margin: 0;
         display: flex;
         align-items: center;
         overflow-x: auto;
         overflow-y: hidden;

         li {
            list-style: none;
            display: flex;
            align-items: center;

            .dot-space {
               width: 4px;
               height: 4px;
               margin: 0 8px;
            }

            a {
               color: #252a2b;
               font-size: 14.22px;
               font-weight: 400;
               line-height: 16px;
               /* 112.518% */
               letter-spacing: -0.142px;
               white-space: nowrap;

               height: 48px;
               display: flex;
               align-items: center;
               position: relative;
               opacity: 0.7;
            }
         }

         li.active {
            a {
               color: #ff6347;
               font-weight: 600;
               opacity: 1;

               &::before {
                  content: "";
                  position: absolute;
                  bottom: 8px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 1;

                  width: 18px;
                  height: 4px;
                  background-color: #ff6347;
                  border-radius: 10px;
               }
            }
         }
      }
   }
}

.breadcurms-header {
   width: 100%;
   height: 31px;
   padding: 0 12px;
   display: flex;
   align-items: center;
   flex-direction: row;
   flex-wrap: nowrap;
   border-top: 1px solid rgba(255, 255, 255, 0.2);
   overflow-x: auto;
   overflow-y: hidden;

   a {
      display: flex;
   }

   .route_item {
      color: #0055aa;
      vertical-align: middle;
      height: 32px;
      display: flex;
      align-items: center;

      .arrow-right {
         width: 5px;
         height: 8px;
         margin: 0 6px;
      }

      .route_name {
         color: #000;
         font-size: 11.24px;
         font-weight: 400;
         line-height: 12px;
         letter-spacing: 0.112px;
         white-space: nowrap;
         cursor: pointer;
      }

      &:last-child {
         font-weight: 400;

         .route_name {
            opacity: 0.7;
            color: $primary;
            cursor: default;
         }
      }
   }
}

.back-header {
   height: 56px;
   min-height: 56px;
   padding: 0 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #fff;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 10;

   .review-title {
      color: #252a2b;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      /* 133.333% */
   }

   button.back-icon {
      padding: 0;
      margin: 0;
      border: unset;
      background-color: unset;
      cursor: pointer;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      svg {
         width: 24px;
         height: 24px;
      }
   }
}

.shop-subheader-product-detail {
   width: 100%;
   height: 48px;
   display: flex;
   align-items: center;
   background: #fff;
   padding: 8px 12px;

   .backlink {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .wrap-input-search {
      width: 100%;
      height: 32px;
      padding: 8px 12px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      background: rgba(0, 0, 0, 0.05);

      .prefix {
         width: 12px;
         height: 12px;
         margin-right: 8px;
      }

      .placeholder {
         color: #252a2b;
         font-size: 12.64px;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.126px;
         width: 100%;
         border: unset;
         outline: unset;
         background: unset;

         &:focus,
         &:valid {
            border: unset;
         }
      }
   }

   .shop-card {
      width: 32px;
      height: 32px;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      position: relative;

      svg {
         width: 20px;
         height: 20px;
      }

      .count {
         color: #fff;
         font-size: 10px;
         font-weight: 600;
         line-height: 9px;
         /* 90% */
         width: 16px;
         height: 16px;
         border-radius: 50%;
         border-radius: 20px;
         background: #ff6347;
         display: flex;
         align-items: center;
         justify-content: center;

         position: absolute;
         top: 0;
         right: -1px;
      }
   }
}

.hierarchical-bar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 8px 12px 16px;
   background-color: #fff;
   display: none;

   .item-bar {
      position: relative;
      color: $span;
      font-size: 14.22px;
      font-weight: 400;
      line-height: 16px;

      &.active {
         color: $primary;
         font-weight: 600;

         &::before {
            content: "";
            width: 16px;
            height: 4px;
            background: $primary;
            border-radius: 12px;
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
         }
      }
   }
}
.drawer-backdrop {
   content: "";
   background-color: rgba($color: #000000, $alpha: 0.2);
   width: 100%;
   height: 100vh;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1201;
   opacity: 0;
   transition: 0.2s ease;
   &.showBackdrop {
      opacity: 1;
   }
   &.hideBackdrop {
      pointer-events: none;
   }
}
.drawer-menu-shop {
   --header-height: 53px;
   --root-cate-height: 56px;
   background: #fff;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1201; // because navigator is 1200. we need to overlap navigator
   transform: translateX(-100%);
   transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

   &.showMenu {
      transform: translateX(0);
   }

   .shop-menu-content {
      width: 88vw;
      height: 100vh;
      overflow: hidden;
   }

   .menu-header {
      width: 100%;
      height: var(--header-height);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: #ff6347;

      .close-icon {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right: 0;
         width: 52px;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;

         svg {
            width: 14px;
            height: 14px;
         }
      }

      span {
         color: #fff;
         font-size: 18px;
         font-style: normal;
         font-weight: 600;
         line-height: 24px;
         /* 133.333% */
      }
   }

   ul.sub-category {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);

      li {
         list-style: none;
         padding: 0 8px;

         a {
            color: #9f9f9f;
            font-size: 14.22px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 112.518% */
            letter-spacing: -0.142px;
            white-space: nowrap;
            cursor: pointer;

            height: var(--root-cate-height);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
         }

         &.active {
            a {
               color: #ff6347;
               font-weight: 600;

               &::before {
                  content: "";
                  position: absolute;
                  bottom: 13px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 1;

                  height: 4px;
                  width: 12px;
                  background-color: #ff6347;
                  border-radius: 20px;
               }
            }
         }
      }
   }

   .menu-list-wrap {
      width: 100%;
      min-height: calc(100% - var(--header-height) - var(--root-cate-height));
      margin-top: -1px;
      padding-top: 1px;
      display: grid;
      grid-template-columns: minmax(96px, 96px) 1fr;

      .cate-left {
         width: 100%;
         height: 100%;
         max-width: 96px;
         display: flex;
         flex-direction: column;
         border-top: 1px solid rgba(0, 0, 0, 0.2);
         background: rgba(0, 0, 0, 0.1);

         .cate-item {
            color: #252a2b;
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.253px;
            min-height: 48px;
            padding: 12px 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;

            &:hover {
               font-weight: 600;
               background: #fff;
            }

            &.active {
               color: #252a2b;
               font-weight: 600;
               background: #fff;

               &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);

                  width: 3px;
                  height: 32px;
                  background: #ff6347;
               }
            }
         }
      }

      .right-content {
         height: 100%;
         overflow-y: auto;
         overflow-x: hidden;

         &.isOTHER {
            max-height: calc(100vh - var(--header-height) - var(--root-cate-height));
            // max-height: calc(100vh - 60px - var(--header-height) - var(--root-cate-height));
         }

         &.isIOS {
            max-height: calc(100vh - 84px - var(--header-height) - var(--root-cate-height));
         }

         .submenu {
            padding-top: 12px;

            .submenu-head {
               width: 100%;
               padding: 16px 16px 8px;
               display: flex;
               justify-content: space-between;
               align-items: center;

               .menu-label {
                  color: #252a2b;
                  font-size: 14.22px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 16px;
                  /* 112.518% */
               }

               a {
                  color: #697f90;
                  font-size: 12.64px;
                  font-weight: 400;
                  line-height: 16px;
                  /* 126.582% */
                  letter-spacing: -0.253px;

                  svg {
                     width: 12px;
                  }
               }
            }

            .submenu-list {
               padding: 16px 16px 8px;
               display: flex;
               flex-flow: row wrap;
               row-gap: 16px;

               .submenu-item {
                  padding: 0 8px;
                  max-width: 33.33%;
                  flex: 1 1 33.33%;

                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .submenu-icon {
                     width: 56px;
                     height: 56px;
                     background: #f7f7f7 -3px 14px / 110.714% 55.357% no-repeat;
                     border-radius: 50%;
                     overflow: hidden;

                     display: flex;
                     align-items: center;
                     justify-content: center;

                     img {
                        width: 80%;
                        height: auto;
                        object-fit: contain;
                        object-position: center;
                     }
                  }

                  .label {
                     color: #252a2b;
                     font-size: 11.24px;
                     font-weight: 400;
                     line-height: 16px;
                     /* 142.349% */
                     letter-spacing: 0.112px;
                     margin-top: 8px;
                  }
               }
            }
         }
      }
   }
}

.drawer-menu-shopv2 {
   --header-height: 53px;
   --root-cate-height: 56px;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1201; // because navigator is 1200. we need to overlap navigator
   transform: translateX(-100%);
   transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

   &.showMenu {
      transform: translateX(0);
   }

   &.hideMenu {
   }

   .shop-menu-content {
      width: 88vw;
      height: 100vh;
      overflow: hidden;
      background-color: #fff;
   }

   .menu-header {
      width: 100%;
      height: var(--header-height);
      padding: 0 16px;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;

      .close-icon {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right: 0;
         width: 40px;
         height: 100%;
         display: flex;
         align-items: center;
         // justify-content: center;
         cursor: pointer;

         svg {
         }
      }

      span {
         font-size: 18px;
         font-style: normal;
         font-weight: 600;
         line-height: 24px;
      }
   }

   .wrap-menu-list {
      height: calc(100% - var(--header-height));
      padding-bottom: 100px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #f5f5f5;
   }

   .menu-list-lv0 {
      // padding: 0 16px;
   }

   .menu-item-lv0 {
      height: 40px;
      padding-left: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-bottom: 1px solid var(--gray-gray-400, #e2e4eb);
      .menu-link {
         width: 100%;
         border-right: 1px solid #cecece;
      }
      svg {
         width: 48px;
      }
   }

   .menu-list-lv1 {
      background: var(--Destop, #f5f5f5);
   }

   .menu-item-lv1 {
   }

   .menu-list-n {
   }
   .menu-item-n {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      // padding-right: 16px;
      .menu-link {
         width: 100%;
         border-right: 1px solid #cecece;
      }
      svg {
         width: 48px;
      }
   }
}
