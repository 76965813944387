@use 'sass:map';

.orientation-wrap {
   .step {
      position: relative;

      &::before {
         position: absolute;
         content: '';
         top: 25px;
         height: 1px;
         width: 100%;
         opacity: 0.77;
         transform: translateX(50%);
         border-top: 1px dashed #252a2b;
      }

      &.step-end {
         margin-bottom: 0 !important;

         &::before {
            display: none;
         }
      }

      &.step-icon {
         &::before {
            display: none;
         }
      }

      &__icon {
         width: 50px;
         height: 50px;
         min-width: 50px;
         margin-bottom: 20px;

         img {
            width: 100%;
            height: 100%;
         }
      }

      &__index {
         width: 45px;
         height: 45px;
         min-width: 45px;
         min-height: 45px;
         background: #ff6347;
         border-radius: 50%;
         position: relative;
         box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.26);

         span {
            position: absolute;
            color: #fff;
            font-size: 1.1415rem;
            font-weight: 500;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }

      &__title {
         font-weight: 700;
         font-size: 1rem;
         color: #ff6347;
         line-height: 19px;
         text-transform: uppercase;
      }

      &__des {
         font-weight: 400;
         font-size: 1rem;
         line-height: 20px;
         color: map.get($text-colors, 'default');
      }
   }

   @media (max-width: map.get($grid-breakpoints, 'md')) {
      .step {
         &::before {
            display: none;
         }
      }
   }
}