@use "sass:map";
@import "./variables.scss";

.g-10 {
   --bs-gutter-y: 10px;
   --bs-gutter-x: 10px;
}
.w-fit {
   width: fit-content !important;
}
.h-fit {
   height: fit-content !important;
}

.text {
   color: $text !important;
}
.text-white {
   color: #fff !important;
}

.link {
   color: $link !important;

   &:hover {
      color: inherit;
      text-decoration: none;
   }
}

.text-span,
.span {
   color: $span !important;
}

.text-primary {
   color: $primary !important;
}

.text-secondary {
   color: $secondary !important;
}

.bold {
   font-weight: bold;
}

.no-drag {
   -webkit-user-drag: none;
}

.flex-center {
   display: flex;
   align-items: center;
}

.hide {
   display: none;
}

.show {
   display: block;
}

.pointer {
   cursor: pointer;
}

.underline {
   text-decoration: underline;
}

.center {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.center-absolute {
   @extend .center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.center-horizontal {
   width: 100%;
   display: flex;
   justify-content: center;
}

.center-vertical {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.dot-loading {
   &::after {
      display: inline-block;
      animation: ellipsis 1.25s infinite;
      content: ".";
      width: 1em;
      text-align: left;
   }

   @keyframes ellipsis {
      0% {
         content: ".";
      }

      33% {
         content: "..";
      }

      66% {
         content: "...";
      }
   }
}

.scroll-y {
   overflow-y: auto !important;
}

.scroll-x {
   overflow-x: auto !important;
}

.btn-text {
   border: unset;
   outline: unset;
   background: unset;
}

// background
.bg-gray {
   background-color: #eef2f5;
}
