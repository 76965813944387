@import "../../styles/variables.scss";

.button {
   &.large {
      --bt-height: 52px;
      --bt-font-size: 20px;
   }

   &.medium {
      --bt-height: 40px;
      --bt-font-size: 16px;
   }

   &.small {
      --bt-height: 32px;
      --bt-font-size: 14px;
   }

   font-size: var(--bt-font-size);
   line-height: var(--bt-font-size);
   font-weight: 500;
   text-align: center;
   padding: 8px 16px;
   height: var(--bt-height);
   position: relative;
   border-radius: 30px;
   border-color: transparent;

   @include media-down(md) {
      font-size: 14.22px;
   }

   &[data-loading="true"] {
      opacity: 0.6;
   }

   .circular-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
         color: #000;
      }
   }

   &.bt-text {
      background: transparent;
      padding: 0;
      margin: 0;
      border-radius: unset;
      border: unset;
      outline: unset;
      height: unset;
      width: unset;
      cursor: pointer;

      &[disabled] {
         cursor: default;
         color: #9f9f9f;
      }
   }

   &.bt-primary {
      color: #fff;
      background: $primary;
      border: 1px solid $primary;
      cursor: pointer;

      &:focus,
      &:active,
      &:focus-within {
         color: #fff;
         background: $primary;
      }

      @include media-up(md) {
         &:hover {
            background: rgba($color: $primary, $alpha: 0.9);
         }
      }

      &[disabled] {
         cursor: default;
         background: #9f9f9f;
         border-color: #9f9f9f;
      }
   }

   &.bt-outline-primary {
      color: $primary;
      background: transparent;
      border: 1px solid $primary;
      cursor: pointer;

      &:focus,
      &:active,
      &:focus-within {
         color: $primary;
         background: transparent;
      }

      &[disabled] {
         cursor: default;
         background: #9f9f9f;
         border-color: #9f9f9f;
      }
   }

   &.bt-outline-primary2 {
      color: $primary;
      background: #ffefed;
      border: 1px solid #ffefed;
      cursor: pointer;

      &:focus,
      &:active,
      &:focus-within {
         color: $primary;
         background: transparent;
      }

      &[disabled] {
         cursor: default;
         background: #9f9f9f;
         border-color: #9f9f9f;
      }
   }

   &.gray {
      color: #fff;
      background: #9f9f9f;
   }

   &.bt-outline-white {
      color: $text;
      background: #fff;
      border: 1px solid #e2e4eb;
      cursor: pointer;

      &:focus,
      &:active,
      &:focus-within {
         color: $text;
         background: #fff;
      }

      @include media-up(md) {
         &:hover {
            background: rgba($color: #fff, $alpha: 0.9);
         }
      }

      &[disabled] {
         cursor: default;
         opacity: 0.5;
      }
   }
}
