@use 'sass:map';
@import "styles/variables.scss";

.search-result {
   padding-top: 16px;
   min-height: calc(100vh - 134px);

   @include media-down(md) {
      padding-top: 0;
   }

   &.isEmpty {
      padding-top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .search-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 12px;

      @include media-down(md) {
         padding: 16px 12px 12px;
      }

      .title {
         color: #252A2B;
         font-size: 20px;
         font-weight: 500;
         margin: 0;
      }

      .view-all {
         color: #05A;
         font-size: 14px;
         line-height: 16px;
         font-weight: 500;
      }
   }

   .search-content {
      padding: 0 12px 16px;

      @include media-up(md) {
         padding: 0 16px 16px;
      }
   }

   .search-loading {
      width: 100%;
      padding: 0 9px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;

      @include media-down(md) {
         grid-template-columns: repeat(2, 1fr) 0.5fr;
      }
   }

   .end-page {
      color: rgba(37, 42, 43, 0.50);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      padding-top: calc(24px - var(--bs-gutter-y));
      padding-bottom: calc(24px + 12px);
   }

   .empty-custom {
      padding: 64px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
         width: 73px;
         height: 90px;
      }

      p {
         color: #252A2B;
         font-size: 16px;
         font-weight: 500;
         max-width: 300px;
         margin: 12px auto 0;
         text-align: center;
      }
   }
}