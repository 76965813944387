@use "sass:map";
@import "../../styles/variables.scss";
@import "./dateTimeCustom.scss";

.creation-post-page,
.dialog-creation-post {
   .spacet-main-header {
      box-shadow: none;

      .top {
         height: 44px;
      }

      .title {
         font-weight: 700;
         font-size: 18px;
         line-height: 21px;
         margin-bottom: 0px;
      }
   }

   .select {
      position: relative;

      select {
      }

      img {
         position: absolute;
         right: 12px;
         top: 0px;
         bottom: 0px;
         margin: auto;
      }

      .placeholder {
         font-weight: 400;
         font-size: 16px;
         line-height: 19px;
         color: #252a2b;
         background-color: unset;
         opacity: 0.5;
      }
   }

   .tab-post,
   .tab-qa {
      color: $text;

      &.Mui-selected {
         color: $primary;
      }
   }

   .MuiTabs-indicator {
      background: $primary;
   }

   .guideline {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1c77f3;
   }

   .creation-post {
      .block {
         display: flex;
      }

      .block-images {
         width: 100%;
         display: flex;
         margin-top: 12px;
         position: relative;
         border-radius: 8px;
         overflow: hidden;

         @include media-down(md) {
            border: 1px solid #000;
         }

         .delete-icon {
            position: absolute;
            right: 12px;
            top: 12px;
            cursor: pointer;
         }
      }

      .textarea_custom {
         border: none !important;
         padding: 12px 0px;

         background: #fff;
         height: 100%;
         min-height: 116px;
         outline: none;
         overflow: hidden;
         padding: 12px 0;
         resize: none;
         width: 100%;

         &:hover,
         &:focus-visible {
            outline: none;
            border: none !important;
         }
      }

      .image-upload {
         border-radius: 6px;
         padding: 28px;
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 16px;

         img {
            cursor: pointer;
         }

         .title_input {
            font-size: 14px;
            font-weight: 600;
            margin-top: 16px;
            cursor: pointer;
            color: #111;
         }

         .desc {
            color: #ababab;
            font-size: 12px;
            text-align: center;
         }
      }

      .content {
         padding: 16px 16px;
         display: grid;
         grid-template-columns: 1fr;
         gap: 16px;

         overflow-y: auto;
         overflow-x: hidden;
         min-height: 440px;
         max-height: 62vh;
      }

      form {
         display: block;
         width: 100%;
         max-width: 100%;
         // overflow: hidden;

         .form {
            .control-item {
               &:not(:last-child) {
                  margin-bottom: 16px;
               }

               label {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 19px;
                  margin-bottom: 8px;
                  width: 100%;
               }

               .form-control {
                  height: 36px;
                  width: 100%;
                  border: 1px solid #e8eaed;
                  border-radius: 0px;

                  &::placeholder {
                     font-weight: 400;
                     font-size: 16px;
                     line-height: 20px;
                     color: #8a8a8a;
                  }

                  &:focus {
                     border: 1px solid #e8eaed;
                     outline: none;
                     box-shadow: none;
                  }
               }

               .wrap-input-editor {
                  .hyperlink_custom {
                     padding: 12px 0;
                     min-height: 116px;
                     border: unset;
                     outline: unset;

                     &:hover,
                     &:focus {
                        border: unset;
                        outline: unset;
                     }
                  }
               }

               input {
                  background: #ffffff;
                  padding: 8px 12px;
               }

               .textarea {
                  // height: 144px;

                  textarea {
                     overflow: hidden;
                     height: 100%;
                     resize: none;
                     width: 100%;
                     background: #ffffff;
                     padding: 12px 0;
                     outline: none;
                     min-height: 116px;
                  }
               }
            }
         }

         .intro {
            padding: 16px;
            gap: 8px;
            background: #f5f5f5;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            .title {
               font-weight: 700;
               font-size: 18px;
               line-height: 21px;
               margin-bottom: 8px;
            }

            ul {
               margin-bottom: 8px;
            }

            .reward {
               margin-bottom: 0px;

               a {
                  span {
                     text-decoration-line: underline;
                     color: #00376b;
                  }
               }

               img {
                  width: 22px;
                  height: 22px;
                  margin-right: 2px;
               }
            }
         }
      }

      .error {
         .form-control {
            border: 1px solid #ff0000 !important;
         }

         .text-error {
            color: #ff0000;
            margin-bottom: 0px;
            font-style: italic;
            font-weight: 400;
            margin-top: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
         }
      }

      .upload-image {
         .upload {
            position: relative;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            background: #fff;
            border-radius: 0px;
            border: 1px solid #e8eaed;

            img {
               margin-right: 4px;
            }

            input[type="file"] {
               position: absolute;
               top: 0;
               bottom: 0;
               left: 0;
               right: 0;
               opacity: 0;
            }
         }
      }

      .image-tagging {
         margin-top: 16px;
         position: relative;

         img {
            width: 100%;
            height: auto;
         }

         .noti,
         .instruction {
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
            font-weight: 500;
            font-size: 19px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
            position: absolute;
         }

         .noti {
            top: 12px;
            right: 0px;
            left: 0px;
         }

         .instruction {
            text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
            top: 50%;
            left: 0px;
            right: 0px;
         }

         .btn-tagging {
            z-index: 1;
            position: absolute;
            right: 12px;
            bottom: 12px;
            padding: 6px 12px;
            background: #ff6347;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
         }
      }

      .images {
         margin-top: 16px;
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         gap: 8px;

         .img {
            width: 100%;
            aspect-ratio: 1;
            border-radius: 3px;
            overflow: hidden;

            .remove {
               background: none;
               width: 32px;
               height: 32px;
               top: 0px;
               margin: 0px;
               padding: 0px;
               z-index: 10;
            }
         }
      }

      .submit {
         padding: 10px 20px;
         height: 56px;
         background: #ff6347;
         border-radius: 100px;
         font-weight: 700;
         font-size: 18px;
         line-height: 21px;
         color: #fff;
         border: none;
         outline: none;
         width: fit-content;
         position: absolute;
         bottom: 16px;
         right: 16px;
         z-index: map-get($map: $zindex, $key: "fixed");
      }

      .action {
         background: #fff;
         width: 100%;
         display: flex;
         justify-content: flex-end;
         align-items: center;
         position: absolute;
         bottom: 0px;
         left: 0px;
         right: 0px;
         padding: 12px 16px;
         z-index: 11;

         .submit {
            position: relative;
            bottom: unset;
            right: unset;
         }
      }
   }

   .custom-alert {
      position: fixed;
      top: 30px;
      right: 30px;
      margin-bottom: 16px;
      background-color: #252a2b !important;
      border-radius: 4px !important;

      .MuiAlert-message {
         font-weight: 400;
         font-size: 16;
         line-height: 20px;
         color: #fff;
      }
   }
}

.slick-medias {
   .slick-prev:before,
   .slick-next:before {
      opacity: 1;
   }
}

.dialog-creation-post-medium {
   .MuiDialog-paper {
      width: 90%;
      max-width: 730px;
   }
}

.dialog-creation-post-large {
   .MuiDialog-paper {
      width: 1117px;
      max-width: 90vw;
      max-height: 90vh;
   }
}

.dialog-creation-post {
   .title-dialog {
      text-transform: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #252a2b;
      height: 70px;
      text-align: center;
      padding: 16px 8px;
      border-bottom: 1px solid #e8eaed;
   }

   .MuiDialog-paper {
      // width: 90%;
      // max-width: 730px;
      background: #ffffff;
      border-radius: 24px;
      position: relative;
      transition: width 0.2s ease;
      overflow: hidden;
   }

   .MuiDialogTitle-root {
      padding: 0px;
   }

   .MuiDialogContent-root {
      padding: 0px;
   }

   .MuiTabs-root {
      position: sticky !important;
      top: 0px;
      background: #fff;
      min-height: 70px !important;
      border-bottom: 1px solid #e8eaed;

      .MuiTabs-indicator {
         display: flex;
         justify-content: center;
         background-color: #ff6347;
      }

      .Mui-selected,
      .active .MuiTab-root {
         color: #ff6347;
      }

      .MuiTab-root {
         opacity: 1;
         font-family: "Roboto";
         text-transform: none;
         font-weight: 500;
         font-size: 18px;
         line-height: 21px;
         color: #252a2b;
         min-height: 36px;
         height: 70px;
         width: 230px;
         text-align: center;
         padding: 16px 8px;

         &.Mui-selected {
            color: #ff6347;
         }
      }
   }
}

.dialog-guidline {
   .MuiDialog-paper {
      width: 90%;
      max-width: 568px !important;
      background: #ffffff;
      padding: 0px 0px 16px;
      min-height: 500px;
      height: inherit;
   }

   .MuiDialogTitle-root {
      padding: 0px;

      .dialog-header {
         height: 70px;
         position: sticky;
         top: 0px;
         background: #fff;
         border-bottom: 1px solid #e8eaed;
         position: relative;
         display: flex;
         justify-content: center;
         align-items: center;

         .btn-close {
            position: absolute;
            top: 27px;
            right: 16px;
            width: 16px;
            height: 16px;
            background-size: 16px;
            padding: 0;
         }
      }

      h4 {
         font-weight: 700;
         font-size: 18px;
         line-height: 21px;
         text-align: center;
         margin-bottom: 0px;
      }
   }

   .MuiDialogContent-root {
      padding: 0px;
   }
}

.cta-btn-fixed {
   font-weight: 700;
   font-size: 16px;
   line-height: 19px;
   border-radius: 50%;
   border: none;
   outline: none;
   background: #ff6347;
   box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
   color: #ffffff;
   padding: 14px;
   text-decoration: none;
   height: 55px;
   width: 55px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   bottom: 60px;
   right: 16px;
   z-index: map.get($zindex, "fixed");

   img {
      width: 24px;
      height: 24px;
      object-fit: contain;
   }
}

.editor {
   width: 100%;
   // height: 100px;
   padding: 6px;
   overflow: scroll;

   &[contenteditable][placeholder]:empty:before {
      content: attr(placeholder);
      position: absolute;
      color: gray;
      background-color: transparent;
   }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
   .tab-content {
      // height: 100%;
   }

   .creation-post-page,
   .dialog-creation-post {
      .creation-post {
         .content {
            gap: 12px;
            padding-bottom: 0px;
            padding: 12px;
            --action-height: 112px;
            --header-height: 92px;
            height: calc(100vh - var(--action-height) - var(--header-height) - 65px);
            min-height: unset;
            max-height: calc(100vh - var(--action-height) - var(--header-height) - 65px);
         }

         .action {
            background: #fff;
            width: 100%;
            display: block;
            position: fixed;
            bottom: 0px;
            left: 0px;
            right: 0px;
            padding: 12px;
            z-index: 11;

            .submit {
               position: relative;
               bottom: unset;
               right: unset;
               width: 100%;
               padding: 8px;
               height: 46px;
            }
         }

         form {
            gap: 12px;

            .form {
               .control-item {
                  &:not(:last-child) {
                     margin-bottom: 12px;
                  }
               }
            }

            .intro {
               padding: 12px 8px;
               height: max-content;
            }

            .images {
               margin-top: 8px;
            }

            .custom-alert {
               position: fixed;
               bottom: 0;
               left: 0;
               right: 0;
               height: 52px;
               top: unset;
               margin: 0px;
               background: #252a2b;
               border-radius: 4px;

               .MuiAlert-message {
                  font-weight: 400;
                  font-size: 16;
                  line-height: 20px;
                  color: #fff;
               }
            }
         }
      }
   }

   .dialog-creation-post {
      .MuiTabs-root {
         top: 44px;
         min-height: 36px !important;
         height: 36px;
         overflow-x: scroll;
         z-index: 9;

         .MuiTabs-scroller {
            overflow-x: scroll !important;
         }

         .MuiTab-root {
            height: unset;
            width: unset;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            padding: 8px 12px;
            white-space: nowrap;
         }
      }
   }
}

.dialog-recommand-add-tagging {
   .MuiDialog-paper {
      width: 100%;
      max-width: 343px;
      margin: 16px;
      border-radius: 28px;
      border: 1px solid var(--gray-gray-400, #e2e4eb);
      background: var(--gray-white, #fff);
   }

   .dialog-content {
      position: relative;
      padding: 32px 16px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .icon-recommend-tag {
      width: 250px;
      height: auto;
      margin-bottom: 32px;

      @include media-down(md) {
         width: 194px;
      }
   }

   .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;

      svg {
         width: 32px;
         height: 32px;
      }
   }

   .greet-text {
      font-weight: 700;
      margin-bottom: 12px;
      text-align: center;
      font-size: 18px;

      @include media-down(md) {
         font-size: 16px;
      }
   }

   .des {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 263px;
      width: 100%;
      margin: auto;
      text-align: center;
   }

   .modal-action {
      width: 100%;
      padding: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      margin-top: 24px;

      button {
         width: 100%;
         color: var(--Orange-orange-500, #ff6347);
         text-align: center;
         font-size: 14.22px;
         font-weight: 600;
         line-height: 16px;
         width: 100%;
         padding: 16px;
         border: unset;
         outline: unset;
         background: transparent;
      }
   }
}

.remind-tagging-exit {
   .header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px;

      .title {
         color: var(--gray-gray-900, #252a2b);
         text-align: center;
         font-size: 16px;
         font-weight: 700;
         line-height: 20px;
         /* 125% */
         margin-bottom: 6px;
      }

      .subtitle {
         color: var(--gray-gray-900, #252a2b);
         text-align: center;
         font-size: 14.22px;
         font-weight: 400;
         line-height: 16px;
         /* 112.518% */
         letter-spacing: -0.142px;
      }
   }

   .content {
      padding: 12px 0;

      .btn-el {
         padding: 10px 32px;
         cursor: pointer;

         svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
         }
      }

      .exit-now {
         color: #252a2b;
         font-size: 14.22px;
      }

      .continue-edit {
         color: $primary;
         font-size: 14.22px;
      }
   }
}
