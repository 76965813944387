@use "sass:map";
// @import 'src/assets/scss/core/variables.scss';
@import "styles/variables.scss";

main.construction-page {
   .product-category {
      &-item {
         .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            margin-bottom: 20px;
         }
         .home-catalog-list {
            display: grid;
            background-color: #e8eaed;
            gap: 1px;
            grid-template-columns: repeat(8, 1fr);
            padding: 1px;

            .catalog-item {
               background: #fff;
               padding: 8px;
               display: flex;
               display: flex;
               flex-direction: column;
               align-items: center;

               .img {
                  img {
                     margin-bottom: 8px;
                     width: 65px;
                     height: 65px;
                  }
               }

               &__name {
                  color: #252a2b;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  margin-bottom: 0px;
                  white-space: nowrap;
               }
            }
         }
      }
   }

   // Flash sale
   .flash-sale {
      padding: 0;
   }
   .block-sub {
      margin-bottom: 20px;
   }
   .block-sub-title,
   .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0;
      &:hover {
         color: #252a2b;
      }
   }
   .link-more,
   .viewall {
      font-size: 18px;
      line-height: 21px;
      color: #0055aa;
      cursor: pointer;
      svg {
         width: 16px;
         height: 16px;
      }
   }

   .best-seller {
      margin-bottom: -14px;
      .product-slider {
         margin-left: -8px;
         margin-right: -8px;
      }
      .slider-item {
         position: relative;
         background: none;
         padding: 6px 8px 0;
      }
      .product-item {
         height: 100%;
         color: #252a2b;
         background: #ffffff;
         border: 1px solid #e5e5e6;
         border-radius: 4px;
         overflow: hidden;
         position: relative;
         // padding-bottom: 21px;
         aspect-ratio: 0.54;
         .img {
            position: relative;

            img {
               object-fit: cover;
               aspect-ratio: 0.8;
               max-height: 220px;
            }

            .icon-bookmark {
               cursor: pointer;
               position: absolute;
               top: 12px;
               right: 12px;
               height: 22px;
               width: fit-content;
               z-index: map.get($zindex, "dropdown");

               img {
                  object-fit: contain;
                  width: 24px;
                  height: unset;
               }
            }
         }

         &-detail {
            padding: 12px;

            &__name {
               color: #252a2b;
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               margin-bottom: 12px;
               word-break: break-word;
               height: 38px;
            }

            // &__price-wrap {
            //    display: flex;
            //    align-items: center;
            //    width: 100%;
            // }

            // .price {
            //    color: #ff6347;
            //    font-weight: 700;
            //    font-size: 16px;
            //    line-height: 21px;
            //    white-space: nowrap;
            //    margin-bottom: 0px;
            // }

            // &__discount-price {
            //    color: #000;
            //    white-space: nowrap;
            //    font-size: 14px;
            //    margin-left: 6px;
            //    text-decoration: line-through;
            // }
            .price-wrap {
               display: flex;
               flex-direction: column;
            }

            .price,
            .discount-price {
               color: #ff6347;
               font-size: 16px;
               font-weight: 700;
               line-height: 16px; /* 100% */
               margin-right: 3px;
               white-space: nowrap;
               margin-bottom: 6px;
               font-size: 14.22px;
               margin-bottom: 3px;
            }

            .wrap-real-price {
               display: flex;
               align-items: center;
            }
            .real-price {
               color: #9f9f9f;
               font-size: 12px;
               font-weight: 400;
               line-height: 12px; /* 100% */
               white-space: nowrap;
               text-decoration-line: strikethrough;
               text-decoration: line-through;
               margin-right: 8px;
               font-size: 11px;
               margin-right: 8px;
            }
            .percent-price {
               color: #ff383b;
               font-size: 12px;
               font-weight: 600;
               width: 35px;
               height: 16px;
               display: block;
               white-space: nowrap;
               border-radius: 4px;
               background: #ffe6e8;

               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 8px;
               width: 24px;
               border-radius: 0 4px 4px 0;
               position: relative;
               &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -5px;
                  border-top: 8px solid transparent;
                  border-bottom: 8px solid transparent;
                  border-right: 6px solid #ffe6e8;
               }
            }
         }
      }
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   main.construction-page {
      .block-sub {
         margin-bottom: 16px;
      }
      .block-sub-title,
      .title {
         font-weight: 700;
         font-size: 16px;
         line-height: 19px;
      }
      .link-more,
      .viewall {
         font-size: 14px;
         line-height: 16px;
      }

      .product-category {
         overflow: hidden;

         &-item {
            .title {
               font-size: 16px;
               line-height: 18px;
            }
            .home-catalog-list {
               margin-top: 16px;
               background: #cccccc;
               padding: 0px;
               grid-template-columns: repeat(4, 1fr);
               .catalog-item {
                  background: #fff;
                  padding: 12px 4px;
                  &__name {
                     font-weight: 700;
                     font-size: 10px;
                     line-height: 12px;
                  }
               }
            }
         }
      }
      .best-seller {
         margin-bottom: 0;
      }
      .flash-sale {
         border-bottom: unset;
         .flash-sale__head {
            padding: 0;
            margin-top: 0;
         }
         .flash-sale__content {
            padding-left: 0;
         }
      }

      .best-seller {
         .product-slider {
            overflow: hidden;
            margin-left: 0px;
            margin-right: 0px;
            .slider-item {
               position: relative;
               background: none;
               padding: 2px;
            }
            .product-item {
               aspect-ratio: 0.46;
               .img {
                  img {
                  }

                  .icon-bookmark {
                     top: 8px;
                     right: 8px;
                     height: 18px;
                  }
               }

               &-detail {
                  padding: 4px 8px 12px;

                  &__name {
                     font-weight: 400;
                     font-size: 16px;
                     line-height: 19px;
                     margin-bottom: 6px;
                     height: 38px;
                  }

                  &__price-wrap {
                     width: 100%;
                     flex-direction: column;
                  }

                  &__price {
                     width: 100%;
                     font-weight: 700;
                     font-size: 14px;
                     line-height: 16px;
                  }
                  &__discount-price {
                     width: 100%;
                     margin-left: 0;
                  }
               }
            }
         }
      }
   }
}
