.feed-badge {
   font-size: 11px;
   font-weight: 600;
   line-height: normal;
   white-space: nowrap;

   height: 21px;
   max-width: 140px;
   padding: 4px 8px;
   border-radius: 4px;
   display: flex;
   align-items: center;

   &.creator {
      color: #d98f00;
      background: #fff7e1;
   }
   &.expert {
      color: #008a56;
      background: #eff8f2;
   }
   &.enterprise {
      color: #1e64ec;
      background: #ebf1fe;
   }
}
