.component-review {
   margin-bottom: 12px;

   &__avatar {
      min-width: 34px;
      min-height: 34px;
      border: 1px solid #f0f0f0;
      border-radius: 50%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
   }

   &__title {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 1rem;
      line-height: 19px;
      color: #515151;
      gap: 10px;
      margin-bottom: 10px;

      p {
         margin-bottom: 0;
      }
   }

   &__comment {
      font-weight: 400;
      font-size: 1rem;
      line-height: 20px;
      color: #666666;
      margin-bottom: 12px;
   }

   &__reaction {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;

      &-item {
         cursor: pointer;
         display: flex;
         align-items: center;

         .title {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1rem;
            color: #666666;
         }

         .icon {
            display: flex;
            align-items: center;
            // padding: 2px 0;
            gap: 4px;

            span {
               font-weight: 400;
               font-size: 1rem;
               line-height: 1rem;
               color: #666666;
            }

            img {
               width: 16px;
               height: 17px;
               vertical-align: top !important;
               object-fit: contain;
            }
         }
      }
   }

   &__bottom {
      border-bottom: 1px solid #e8eaed;
   }

   .review {
      &__read-more {
         width: max-content;
         margin-top: -10px;
         cursor: pointer;

         p {
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            color: #ff6347;
         }
      }
   }
}