@use "sass:map";
@import "../../../styles/variables.scss";

.spacet-cart-detail {
   min-height: calc(100vmin - 700px);
   padding-top: 16px;
   padding-bottom: 16px;
   background-color: #f5f5f5;

   @include media-down(md) {
      padding-top: 0;
      padding-bottom: 73px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
   }

   .cart-detail-container {
      max-width: $container-width;
      padding-left: $container-padding;
      padding-right: $container-padding;
      margin: auto;
      padding-bottom: 16px;

      @include media-down(md) {
         width: 100%;
         padding: 0;
      }
   }

   .empty-cart {
      width: 100%;
      min-height: 378px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      margin-top: 8px;

      @include media-down(md) {
         padding: 64px 16px;
      }

      .empty-icon {
      }

      .title {
         font-size: 16px;
         font-weight: 500;
         line-height: normal;
         text-transform: uppercase;
         margin-top: 16px;
      }

      .des {
         color: $span;
         text-align: center;
         font-size: 12.64px;
         line-height: 16px;
         /* 126.582% */
         letter-spacing: -0.126px;
         margin-top: 8px;
         max-width: 220px;

         @include media-down(md) {
            color: #000;
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px;
            /* 126.582% */
            letter-spacing: -0.126px;
            max-width: 180px;
         }
      }

      button {
         width: fit-content;
         height: 48px;
         padding: 8px 32px;
         margin-top: 32px;

         @include media-down(md) {
            font-size: 14.22px;
            line-height: 16px;
            /* 112.518% */
            letter-spacing: -0.142px;
         }
      }
   }

   .loading-card {
      min-height: 378px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      @include media-down(md) {
         min-height: 420px;
      }
   }

   .gird-wrap {
      display: grid;
      grid-template-columns: 2fr minmax(360px, 1fr);
      grid-column-gap: 24px;

      @include media-down(md) {
         display: flex;
      }
   }

   .grid-table {
      display: grid;
      grid-template-columns: 3fr minmax(152px, 1fr) minmax(80px, 1fr);

      .grid-item {
         .left-tabel-head {
            display: flex;
            align-items: center;

            .wrap-checkbox {
               margin-right: 16px;
            }

            .label {
               color: $text;
               font-size: 14px;
               line-height: normal;
            }
         }

         .quantity {
            color: #9f9f9f;
            font-size: 14px;
            line-height: normal;
            text-align: center;
         }

         .action {
            color: $span;
            font-size: 14px;
            line-height: normal;
            text-align: right;
         }
      }
   }

   .heading {
      padding: 16px 24px;
      background: #fff;
      box-shadow: 0px 1px 4px -5px rgba(0, 0, 0, 0.05);
   }

   .product-list-by-cate {
      @include media-down(md) {
         width: 100%;
      }
   }

   .checkbox {
      user-select: none;
      position: relative;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      border-radius: 3px;
      border: 1px solid #e0e0e0;
      text-align: center;

      @include media-down(md) {
         width: 14px;
         height: 14px;
      }

      &.checked {
         background: #ff6347;
         border-color: #ff6347;
      }

      &::before {
         position: absolute;
         content: "";
         height: 7px;
         width: 11px;
         border-left: 2px solid #fff;
         border-bottom: 2px solid #fff;
         border-radius: 2px;
         transform: rotate(-45deg);
         left: 2px;
         top: 2px;

         @include media-down(md) {
            height: 6px;
            width: 8px;
         }
      }
   }

   .item-center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
   }

   .wrap-list-product {
      margin-top: 12px;

      .item-right {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         width: 100%;
         height: 100%;
      }

      .order-product-item {
         margin-top: 12px;
         background-color: #fff;
         border-bottom: 1px solid rgba($span, 0.3);

         @include media-down(md) {
            margin-top: 8px;
         }

         .brand-info {
            display: flex;
            align-items: center;
            padding: 16px 24px;
            background: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            .wrap-checkbox {
               margin-right: 16px;
            }

            .brand-name {
               color: $text;
               font-size: 16px;
               font-weight: 600;
               line-height: normal;
            }
         }

         .product-item {
            align-items: center;
            padding: 16px 24px;

            .product-info {
               display: flex;
            }

            .wrap-checkbox {
               display: flex;
               justify-content: center;
               align-items: center;
               margin-right: 16px;
            }

            .product-name {
               color: $text;
               font-size: 16px;
               font-weight: 400;
               line-height: 20px;
               /* 125% */
               letter-spacing: 0.064px;
               display: -webkit-box;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               overflow: hidden;
               text-overflow: ellipsis;
               margin-bottom: 8px;
               max-width: 209px;
            }

            .img {
               width: 120px;
               height: 120px;
               min-width: 120px;
               margin-right: 16px;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .product-variant {
               display: flex;
               align-items: center;
               margin-bottom: 16px;

               .label {
                  color: $span;
                  font-size: 14px;
                  line-height: 16px;
                  letter-spacing: -0.14px;
                  margin-right: 8px;
               }

               .value {
                  color: $text;
                  font-size: 14px;
                  line-height: 16px;
                  letter-spacing: -0.14px;

                  & > span:not(:first-child) {
                     position: relative;
                     margin-left: 16px;

                     &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -10px;
                        transform: translateY(-50%);

                        width: 4px;
                        height: 4px;
                        background: #acacac;
                        border-radius: 50%;
                     }
                  }
               }
            }

            .price {
               font-size: 1.5rem;
               font-weight: 500;
               display: flex;
               align-items: center;
               line-height: 24px;

               .discount-price {
                  color: #ff6347;
                  font-size: 18px;
                  font-weight: 800;
                  line-height: 24px;
                  margin-right: 8px;

                  span {
                     font-size: 18px;
                     font-weight: 800;
                     line-height: 24px;
                     text-decoration: underline;
                  }
               }

               .real-price {
                  color: #9f9f9f;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 12px;
                  /* 85.714% */
                  letter-spacing: 0.14px;
                  text-decoration: line-through;
                  margin-left: 6px;
               }

               .percent-discount {
                  color: #fff;
                  font-size: 11.24px;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: -0.337px;
                  background-color: #ff6347;
                  width: max-content;
                  min-width: 56px;
                  height: 17px;
                  border-radius: 4px;
                  padding: 2px 4px;
               }
            }

            .quantity-box {
               display: flex;
               align-items: center;
               width: 136px;
               height: 32px;

               button.minus-button,
               button.plus-button {
                  font-size: 1rem;
                  display: block;
                  border: none;
                  outline: none;
                  height: 100%;
                  width: 32px;
                  min-width: 32px;
                  background-color: #efefef;
                  color: map.get($text-colors, "default");
                  padding: 0;
                  border: 1px solid rgba(0, 0, 0, 0.05);
                  background: #fff;

                  &[disabled] {
                     opacity: 0.3;
                  }
               }

               input.quantity-input {
                  color: $text;
                  font-size: 16px;
                  line-height: 16px;
                  letter-spacing: -0.16px;
                  width: 100%;
                  height: 100%;
                  border: none;
                  outline: none;
                  text-align: center;
                  border-top: 1px solid rgba(0, 0, 0, 0.05);
                  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
               }
            }

            .actions {
               display: flex;
               flex-direction: row;
               align-items: center;

               button {
                  width: fit-content;
                  padding: 0;
                  margin: 0;
                  border: unset;
                  outline: unset;
                  background: transparent;
               }

               .custom-bookmark {
                  position: unset;
                  margin-right: 16px;
               }
            }
         }

         .form-check {
            display: flex;
            align-items: center;

            .form-check-input {
               width: 20px;
               height: 20px;
               border: 1px solid #e0e0e0;
               border-radius: 2px;
               outline: none;
               box-shadow: none;
               float: unset;

               &:checked {
                  background-color: #ff6347;
                  border: none;
               }

               &:disabled {
                  background-color: #e0e0e0;
                  cursor: no-drop;
               }
            }
         }
      }
   }

   .summary-card-info {
      position: sticky;
      top: 74px;
      height: fit-content;
      padding: 16px 0 24px;
      background-color: #fff;

      h2 {
         color: #000;
         font-size: 20px;
         font-weight: 700;
         line-height: 24px;
         /* 120% */
         margin-bottom: 24px;
         padding: 0 12px;
      }

      .info-item {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom: 10px;
         padding: 0 12px;
      }

      .label {
         color: #252a2b;
         font-size: 15px;
         font-weight: 400;
         line-height: 22px;
      }

      .value {
         color: #252a2b;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
      }

      .discount-price {
         color: #ff6347;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
      }

      .coin-value {
         color: #ff6347;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
      }

      .total-price {
         color: $text;
         font-size: 14px;
         font-weight: 600;
         line-height: 24px;

         /* 133.333% */
         span {
            color: $text;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-decoration: underline;
         }
      }

      .coin-availabe {
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
         background: #fff;
         padding: 16px 12px 16px;

         .coin-label {
            color: $text;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
         }

         .coin-value {
            color: #9f9f9f;
            font-size: 14px;
            line-height: 16px;
         }
      }

      .use-coin {
         width: 100%;
         padding: 0 12px 12px;
         border-bottom: 1px solid $border;

         svg {
            margin-right: 6px;
         }

         .use-coin-label {
            span {
               color: $span;
               font-size: 14px;
               line-height: 16px;
            }
         }
      }

      .switch_coin {
         .toggle_switch {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 16px;
         }

         .toggle_switch input[type="checkbox"] {
            display: none;
         }

         .toggle_switch .switch {
            position: absolute;
            cursor: pointer;
            background-color: #78788029;
            border-radius: 21px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: background-color 0.2s ease;
         }

         .toggle_switch .switch::before {
            position: absolute;
            content: "";
            left: 1px;
            top: 1px;
            width: 14px;
            height: 14px;
            background-color: #fff;
            border-radius: 50%;
            transition: transform 0.3s ease;
         }

         .toggle_switch input[type="checkbox"]:checked + .switch::before {
            transform: translateX(14px);
            background-color: #fff;
         }

         .toggle_switch input[type="checkbox"]:checked + .switch {
            background-color: $primary;
         }
      }

      .card-des {
         color: #697f90;
         font-size: 12px;
         line-height: 16px;
         letter-spacing: -0.14px;
         padding: 0 12px 16px;
         text-align: right;
      }

      .card-action {
         padding: 0 12px;

         button {
            height: 48px;
         }
      }
   }

   .discount-code {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 16px 12px 16px;

      .label {
         color: $text;
         font-size: 16px;
         font-weight: 400;
         line-height: 16px;
      }

      .select-discount {
         color: $primary;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
         display: flex;
         align-items: center;
         cursor: pointer;

         svg {
            margin-left: 6px;
         }
      }
   }

   .summary-box {
      position: sticky;
      top: 90px;

      .separate-line {
         background-color: #e6e6e6;
         opacity: 1;
      }

      .voucher-input {
         width: 100%;
         height: 40px;
         padding: 10px;
         border: 1px solid #dddddd;
         border-radius: 4px;
         outline: none;
      }

      .bonus-point {
         .form-check {
            display: flex;
            align-items: center;

            .checkbox {
               position: relative;
               width: 20px;
               height: 20px;
               border: 1px solid #e0e0e0;
               flex-shrink: 0;
               border-radius: 2px;
               text-align: center;
               margin-right: 8px;

               &::before {
                  position: absolute;
                  content: "";
                  height: 5px;
                  width: 9px;
                  border-left: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                  left: 3px;
                  top: 3px;
               }
            }

            .form-check-input {
               width: 20px;
               height: 20px;
               border: 1px solid #e0e0e0;
               border-radius: 2px;
               outline: none;
               box-shadow: none;
               float: unset;

               &:checked {
                  background-color: #ff6347;
                  border: none;
               }

               &:disabled {
                  background-color: #e0e0e0;
                  cursor: no-drop;
               }
            }

            label.form-check-label {
               margin-left: 18px;
               display: flex;
               align-items: center;

               span {
                  padding-left: 8px;
                  // font-weight: bold;
                  font-size: 16px;
                  line-height: 19px;
                  color: #666666;

                  b {
                     color: map.get($text-colors, "default");
                     margin-right: 12px;
                  }
               }
            }
         }

         .use-point {
            text-align: right;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            text-align: right;
            color: #e0e0e0;

            &.active {
               color: #ff6347;
            }
         }

         .error-text {
            font-size: 0.875rem;
            line-height: 20px;
            color: #ff0000;
            margin-top: 12px;
            margin-bottom: 0;

            &.error {
               color: #66ac5b;
            }
         }

         .title-bonus-point {
            display: flex;
            align-items: center;

            span {
               padding-left: 8px;
               // font-weight: bold;
               font-size: 16px;
               line-height: 19px;
               color: #666666;

               b {
                  color: map.get($text-colors, "default");
                  margin-right: 12px;
               }
            }
         }

         .point-input {
            width: 100%;
            height: 40px;
            padding: 10px;
            border: 1px solid #dddddd;
            border-radius: 4px;
            outline: none;
         }

         .bottom-bonus-point {
            span {
               font-size: 16px;
               line-height: 20px;
               color: #ff0000;
            }
         }
      }

      .apply-voucher-button {
         padding: 10px 20px;
         height: 40px;
         color: #ffffff;
         border: none;
         border-radius: 4px;
         background-color: #ff6347;
      }

      .total-cost {
         font-size: 1.143rem;
         font-weight: 500;
      }

      .pay-button {
         width: 100%;
         border: none;
         color: #ffffff;
         padding: 10px;
         border-radius: 4px;
         background-color: #252a2b;
      }
   }

   .empty-cart-body {
      margin-top: 100px;
      margin-bottom: 200px;

      .title {
         font-size: 1rem;
         font-weight: 400;
         text-align: center;
      }

      .go-to-shop-button {
         display: block;
         text-align: center;
         text-decoration: none;
         width: 100%;
         color: #ffffff;
         border: none;
         background-color: #252a2b;
         border-radius: 4px;
         padding: 10px;
      }
   }

   .wrap-list-product-mb {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;
      // background-color: #fff;

      .item-right {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         width: 100%;
         height: 100%;
      }

      .order-product-item {
         // padding: 0 8px;
         // border-radius: 10px;
         // border: 1px solid rgba(0, 0, 0, 0.05);
         padding: 0 16px;
         overflow: hidden;
         // border-top: 8px solid $border;
         background-color: #fff;

         .brand-info {
            display: flex;
            align-items: center;
            padding: 12px 0;
            background: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            .wrap-checkbox {
               margin-right: 12px;
            }

            .brand-name {
               color: $text;
               font-size: 12.64px;
               font-weight: 600;
               line-height: 16px;
               margin-right: 12px;
               display: flex;
               align-items: center;
               cursor: pointer;

               span {
                  margin-right: 6px;
               }

               svg {
                  margin-right: 6px;
               }
            }
         }

         .product-item {
            width: 100%;
            align-items: center;
            padding: 16px 0;
            overflow: hidden;

            &:not(:last-child) {
               border-bottom: 1px solid $border;
            }

            .product-info {
               width: 100%;
               display: grid;
               grid-template-columns: 14px 64px calc(100% - 14px - 64px - 8px * 2);
               grid-gap: 8px;
            }

            .wrap-checkbox {
               padding-top: 25px;
            }

            .wrap-product-name {
               width: 100%;
               display: flex;
               justify-content: space-between;
               margin-bottom: 4px;

               .product-name {
                  color: $text;
                  font-size: 14.22px;
                  line-height: 20px;
                  /* 140.647% */
                  font-weight: 400;
                  letter-spacing: -0.142px;
                  height: 20px;
                  white-space: nowrap;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 90%;
                  margin-bottom: 0;
               }

               .bookmark-item-button {
                  position: unset;
                  background: unset;
                  border: unset;
                  padding: 0;
                  margin: 0;
                  margin-right: -6px;
                  height: 20px;
                  display: flex;
                  align-items: center;
               }
            }

            .img {
               width: 64px;
               height: 64px;
               min-width: 64px;
               border-radius: 8px;
               overflow: hidden;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            .product-variant {
               width: fit-content;
               display: flex;
               align-items: center;
               margin-bottom: 12px;

               .label {
                  color: $span;
                  font-size: 11.24px;
                  line-height: 16px;
                  letter-spacing: -0.112px;
                  margin-right: 8px;
               }

               .value {
                  color: $text;
                  font-size: 11.24px;
                  line-height: 16px;
                  letter-spacing: -0.112px;
               }

               svg {
                  width: 8px;
               }
            }

            .price {
               font-size: 1.5rem;
               font-weight: 500;
               display: flex;
               align-items: flex-end;
               flex-direction: column;
               justify-content: flex-end;
               line-height: 24px;

               .discount-price {
                  color: #ff6347;
                  font-size: 18px;
                  font-weight: 800;
                  line-height: 24px;

                  span {
                     font-size: 18px;
                     font-weight: 800;
                     line-height: 24px;
                     text-decoration: underline;
                  }
               }

               .real-price {
                  color: #9f9f9f;
                  font-size: 12.64px;
                  font-weight: 400;
                  line-height: 12px;
                  /* 85.714% */
                  letter-spacing: 0.126px;
                  text-decoration: line-through;
                  margin-right: 8px;
                  order: 1;
               }

               .percent-discount {
                  color: $primary;
                  font-size: 11.24px;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: -0.337px;
                  background-color: #ffe6e8;
                  width: max-content;
                  min-width: 56px;
                  height: 17px;
                  border-radius: 4px;
                  padding: 2px 4px;
                  order: 2;
               }
            }

            .quantity-box {
               display: flex;
               align-items: center;
               width: 92px;
               height: 20px;

               button.minus-button,
               button.plus-button {
                  font-size: 16px;
                  list-style: 16px;
                  display: block;
                  height: 100%;
                  width: 20px;
                  min-width: 20px;
                  background-color: #efefef;
                  padding: 0;
                  border-radius: 4px;
                  border: 1px solid rgba(0, 0, 0, 0.05);
                  background: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &[disabled] {
                     opacity: 0.3;
                  }
               }

               input.quantity-input {
                  color: $text;
                  font-size: 12.64px;
                  line-height: 16px;
                  letter-spacing: -0.126px;
                  width: 100%;
                  height: 100%;
                  margin: 0 6px;
                  border: 1px solid rgba(0, 0, 0, 0.05);
                  background: #fff;
                  text-align: center;
                  border-radius: 4px;
               }
            }
         }
      }

      .remove-cart-item-button {
         width: fit-content;
         background: unset;
         border: unset;
         padding: unset;
         margin: unset;
         margin-right: -6px;
         margin-top: 8px;
      }
   }

   .spacet-cart-detail-discount-mb {
      height: 53px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      background: #fff;
      border-top: 1px solid #eef2f5;
      box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.05);

      span {
         font-size: 12.64px;
         font-weight: 600;
         line-height: 16px;
         /* 126.582% */
      }

      button {
         background: unset;
         border: unset;
         padding: 0;
         margin: 0;
      }
   }

   .spacet-cart-detail-wrap-coin-mb {
      padding: 0 16px;
      background: #fff;
      border-top: 8px solid $border;

      .cal-coin {
         height: 50px;
         max-height: 50px;
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 16px 0;
         border-bottom: 1px solid $border;

         .label-left {
            display: flex;
            align-items: center;

            span:first-child {
               color: $text;
               font-size: 12.64px;
               font-weight: 600;
               line-height: 16px;
               /* 126.582% */
            }

            span:last-child {
               color: $span;
               font-size: 11.24px;
               line-height: 16px;
               /* 142.349% */
               letter-spacing: -0.112px;

               &::before {
                  content: "";
                  padding-left: 8px;
                  margin-left: 8px;
                  height: 16px;
                  border-left: 1px solid #e2e4eb;
               }
            }
         }

         .label-right {
            color: $text;
            font-size: 12.64px;
            line-height: 16px;
            /* 126.582% */
            letter-spacing: -0.126px;
         }
      }

      .use-coin-current {
         width: 100%;
         // height: 50px;
         // max-height: 50px;
         // display: flex;
         // align-items: center;
         // justify-content: space-between;
         padding: 16px 0;

         .coin-availabed {
            font-size: 12.64px;
            line-height: 16px;
            letter-spacing: -0.126px;
            display: flex;
            align-items: center;

            svg {
               margin-right: 6px;
            }
         }
      }
   }

   // List product suggest
   .suggest-list-card {
      background: #fff;

      .suggest-container {
         max-width: $container-width;
         padding-left: $container-padding;
         padding-right: $container-padding;
         margin: auto;

         @include media-down(md) {
            padding: 0 16px;
         }
      }

      .title {
         color: #252a2b;
         font-size: 20px;
         font-weight: 600;
         line-height: 24px;
         /* 120% */
         padding: 24px 0;
         margin: 0;

         @include media-down(md) {
            font-size: 14.22px;
            line-height: 16px;
            /* 112.518% */
            padding: 16px 0;
            font-weight: 500;
         }
      }

      .see-more {
         color: #ff6347;
         font-size: 14px;
         font-weight: 400;
         line-height: 16px;
         letter-spacing: -0.14px;
         width: fit-content;
         height: 40px;
         min-width: 111px;
         border-radius: 8px;
         border: 1px solid #ff6347;
         background: #fff;
         margin: 24px auto 24px;
         transition: 0.2s;

         &:hover {
            color: #fff;
            background: $primary;
         }
      }
   }
}

.task-bar {
   width: 100%;
   height: fit-content;
   position: fixed;
   bottom: 0;
   z-index: 1100;

   display: flex;
   flex-direction: column;
   justify-content: flex-end;

   &.show-info {
      height: 100%;

      .drawer-blur {
         width: 100%;
         height: 100%;
         display: block;
         z-index: -1;
         background: rgba(0, 0, 0, 0.2);
         transition: 0.3s ease;
         position: absolute;
      }

      .drawer-paper {
         border-radius: 20px 20px 0 0;
      }
   }

   .drawer-paper {
      background: #fff;
      // overflow: hidden;
   }

   .drawer-detail-cart {
      width: 100%;

      .detail-detail-header {
         color: #252a2b;
         font-size: 16px;
         font-weight: 500;
         line-height: 16px;
         height: 48px;
         padding: 16px;
         display: flex;
         justify-content: center;
         align-items: center;
         position: relative;
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);

         .close-icon {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);

            background: unset;
            border-radius: unset;
            border: unset;
            margin-right: -4px;
         }
      }

      .drawer-info-item {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 8px 16px;
      }

      .drawer-info-item {
         .label {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
         }

         .value {
            font-size: 16px;
            line-height: 16px;
         }

         .discount-price {
            color: $primary;
            font-size: 16px;
            line-height: 16px;
         }
      }

      .use-coin {
         color: #000;
         font-size: 16px;
         font-weight: 400;
         line-height: 16px;
      }
   }

   .wrap-bottom {
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      .wrap-checkbox {
         display: flex;
         align-items: center;

         .label {
            font-size: 12.64px;
            font-weight: 400;
            line-height: 16px;
            /* 126.582% */
            letter-spacing: -0.126px;
            margin-left: 6px;
         }
      }

      .wrap-amount {
         display: flex;
         align-items: center;

         .amount {
            color: $primary;
            font-size: 18px;
            font-weight: 800;
            line-height: 24px;
            /* 133.333% */
            display: flex;
            align-items: center;

            span {
               text-decoration: underline;
            }

            svg {
               margin-left: 6px;
            }
         }

         .reward-coin {
            color: $text;
            font-size: 11.24px;
            line-height: 12px;
            letter-spacing: 0.112px;
            text-align: end;

            span {
               color: $primary;
               margin-left: 4px;
            }
         }
      }

      .btn-buy-mb {
         font-size: 12.64px;
         font-weight: 600;
         width: 119px;
         height: 40px;
         border-radius: 8px;
         margin-left: 8px;
      }
   }

   .alert-no-product-select {
      position: fixed;
      top: 0px;
      left: 0;
      right: 0;
      padding: 6px 16px;
      z-index: 1101;
      display: none;
      transition: 0.3s ease;

      .des {
         color: #fff;
         text-align: center;
         font-size: 14.22px;
         font-weight: 400;
         line-height: 20px;
         /* 140.647% */
         letter-spacing: -0.142px;

         width: 100%;
         height: 44px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 8px;
         border: 1px solid rgba(255, 255, 255, 0.1);
         background: rgba(111, 111, 111, 0.9);
      }
   }

   // .voucher {
   //    padding: 14px 0;
   //    .voucher-input {
   //       width: 100%;
   //       height: 40px;
   //       padding: 10px;
   //       border: 1px solid #dddddd;
   //       border-radius: 4px;
   //       outline: none;
   //    }
   // }
   // .button-order {
   //    &:disabled {
   //       background-color: #666666;
   //       cursor: no-drop;
   //    }
   // }
   // .apply-voucher-button {
   //    padding: 9px 10px;
   //    display: flex;
   //    align-items: center;
   //    justify-content: center;
   //    width: 100%;
   //    color: #ffffff;
   //    border: none;
   //    border-radius: 4px;
   //    background-color: #ff6347;
   // }
   // .wrap {
   //    display: flex;
   //    align-items: center;
   //    gap: 10px;
   //    .checkbox {
   //       user-select: none;
   //       position: relative;
   //       width: 20px;
   //       height: 20px;
   //       flex-shrink: 0;
   //       border-radius: 4px;
   //       border: 1px solid #e0e0e0;
   //       text-align: center;
   //       &.checked {
   //          background: #ee4d2d;
   //          border-color: #ee4d2d;
   //       }
   //       &::before {
   //          position: absolute;
   //          content: "";
   //          height: 7px;
   //          width: 12px;
   //          border-left: 3px solid #fff;
   //          border-bottom: 3px solid #fff;
   //          border-radius: 2px;
   //          -webkit-transform: rotate(-45deg);
   //          transform: rotate(-45deg);
   //          left: 3px;
   //          top: 4px;
   //       }
   //    }
   // }
   // .form-check {
   //    display: flex;
   //    align-items: center;
   //    span {
   //       padding-top: 3px;
   //       margin-left: 4px;
   //       font-size: 1rem;
   //       line-height: 20px;
   //       color: map.get($text-colors, "default");
   //       &.label-all {
   //          margin-left: 16px;
   //       }
   //    }

   //    .form-check-input {
   //       min-width: 20px;
   //       height: 20px;
   //       border: 1px solid #e0e0e0;
   //       border-radius: 2px;
   //       outline: none;
   //       box-shadow: none;
   //       float: unset;
   //       &:checked {
   //          background-color: #ff6347;
   //          border: none;
   //       }
   //       &:disabled {
   //          background-color: #e0e0e0;
   //          cursor: no-drop;
   //       }
   //    }
   //    label.form-check-label {
   //       margin-left: 22px;
   //       display: flex;
   //       align-items: center;
   //       span {
   //          // font-weight: bold;
   //          font-size: 16px;
   //          line-height: 19px;
   //          color: #666666;
   //          b {
   //             color: map.get($text-colors, "default");
   //             margin-right: 12px;
   //          }
   //       }
   //    }
   // }
   // .wrap-bonus-point {
   //    border-top: 1px dashed #e0e0e0;
   //    border-bottom: 1px dashed #e0e0e0;
   //    padding: 14px 0;
   //    .bonus-point {
   //       display: flex;
   //       align-items: center;
   //       justify-content: space-between;
   //       flex-wrap: nowrap;

   //       .use-point {
   //          text-align: right;
   //          font-weight: bold;
   //          font-size: 18px;
   //          line-height: 21px;
   //          text-align: right;
   //          color: #e0e0e0;
   //          &.active {
   //             color: #ff6347;
   //          }
   //       }
   //       .error-text {
   //          font-size: 0.875rem;
   //          line-height: 20px;
   //          color: #ff0000;
   //          margin-top: 12px;
   //          margin-bottom: 0;
   //          &.error {
   //             color: #66ac5b;
   //          }
   //       }
   //       .title-bonus-point {
   //          display: flex;
   //          align-items: center;
   //          span {
   //             padding-left: 8px;
   //             // font-weight: bold;
   //             font-size: 16px;
   //             line-height: 19px;
   //             color: #666666;
   //             b {
   //                color: map.get($text-colors, "default");
   //                margin-right: 12px;
   //             }
   //          }
   //       }
   //       .point-input {
   //          width: 100%;
   //          height: 40px;
   //          padding: 10px;
   //          border: 1px solid #dddddd;
   //          border-radius: 4px;
   //          outline: none;
   //       }
   //       .bottom-bonus-point {
   //          span {
   //             font-size: 16px;
   //             line-height: 20px;

   //             color: #ff0000;
   //          }
   //       }
   //    }
   // }
   // .wrap-amount {
   //    padding-top: 20px;
   //    padding-bottom: 20px;
   //    justify-content: space-between;
   //    align-items: center;
   //    .item {
   //       display: flex;
   //       justify-content: space-around;
   //       align-items: center;
   //       .label {
   //          font-size: 1rem;
   //          line-height: 20px;
   //          color: map.get($text-colors, "default");
   //          &.bonus {
   //             color: #ea8d58;
   //          }
   //       }
   //       .price {
   //          font-size: 1rem;
   //          line-height: 20px;
   //          color: #f24f2b;
   //          &.total {
   //             font-weight: 500;
   //             font-size: 1.5rem;
   //          }
   //       }
   //    }
   // }
   // @media (max-width: map.get($grid-breakpoints, "md")) {
   //    .form-check {
   //       label.form-check-label {
   //          margin-left: 16px;
   //          span {
   //             b {
   //                color: map.get($text-colors, "default");
   //                margin-right: 12px;
   //             }
   //          }
   //       }
   //       span {
   //          &.label-all {
   //             margin-left: 10px;
   //             font-size: 14px;
   //          }
   //       }
   //    }
   //    .wrap-amount {
   //       .item {
   //          justify-content: flex-end;
   //          margin-bottom: 8px;
   //          gap: 8px;
   //          .label {
   //             font-size: 14px;
   //          }
   //          .price {
   //             font-size: 14px;
   //             &.total {
   //                font-size: 1rem;
   //             }
   //          }
   //       }
   //    }
   // }
}

.model-confirm-delete-cart {
   .modal-content {
      padding: 32px 24px;

      .title {
         text-align: center;
         font-size: 18px;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 8px;
      }

      .des {
         color: #252a2b;
         text-align: center;
         font-size: 14.22px;
         line-height: 16px;
         /* 112.518% */
         letter-spacing: -0.142px;
         margin-bottom: 32px;
      }

      .wrap-button {
         display: flex;
         flex-direction: row;

         button {
            width: 100%;
            height: 40px;

            &:first-child {
               margin-right: 8px;
            }

            &:last-child {
               margin-left: 8px;
            }
         }
      }
   }
}

.tooltip-delete {
   min-width: 240px !important;
   padding: 24px 12px 12px !important;
   background: #fff !important;
   border-radius: 10px !important;
   filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15)) !important;
   left: 11px !important;

   .tooltip-arrow {
      left: -12px !important;
      color: #fff;
   }

   .content-tooltip {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      .title {
         color: $text;
         font-size: 16px;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 8px;
      }

      .des {
         color: $span;
         font-size: 14px;
         line-height: 16px;
         margin-bottom: 16px;
      }

      .wrap-button {
         width: 100%;
         display: flex;
         flex-direction: row;

         button {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            width: 100%;
            height: 40px;
            border-radius: 8px;
            border: 1px solid $primary;

            &.btn-outline {
               color: $primary;
               background-color: transparent;
               margin-right: 8px;
            }

            &.btn-delete {
               color: #fff;
               background: #ff6347;
               margin-left: 8px;
            }
         }
      }
   }
}

// Voucher
.shop-voucher {
   display: flex;
   align-items: center;
   min-height: 54px;
   justify-content: space-between;
   padding: 12px 24px;
   border-top: 1px solid $border;
   border-bottom: 1px solid $border;

   @include media-down(md) {
      padding: 8px 0;
   }
}

.shop-note {
   display: flex;
   align-items: center;
   height: 48px;
   padding: 0px 24px;
   border-top: 1px solid $border;

   @include media-down(md) {
      padding: 0;
   }
}

.voucher-label {
   position: relative;

   &.disabled {
      opacity: 0.6;
   }

   .label-text {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      background-color: var(--voucher-color);
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;

      &.primary {
         color: $primary;
         --voucher-color: #ffefed;
      }

      &.secondary {
         color: #0095ea;
         --voucher-color: #e1f1ff;
      }
   }

   .dot-ef {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: block;
      background: #fff;

      // left
      &:nth-child(2) {
         top: 3px;
         left: -4px;
      }

      &:nth-child(3) {
         top: 11px;
         left: -4px;
      }

      &:nth-child(4) {
         top: 19px;
         left: -4px;
      }

      // right
      &:nth-child(5) {
         top: 3px;
         right: -4px;
      }

      &:nth-child(6) {
         top: 11px;
         right: -4px;
      }

      &:nth-child(7) {
         top: 19px;
         right: -4px;
      }
   }
}
