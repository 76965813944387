@use "sass:map";
@import "styles/variables.scss";

.actual-work-page {
   @include media-down(md) {
      padding-bottom: $NAVIGATOR_HEIGHT;
   }
}
main.actual-page {
   margin: 50px 0;

   .actual-works {
      .btn-actual-nav {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         width: 76px;
         height: 42px;
         background: #ffffff;
         border: 1px solid rgba(37, 42, 43, 0.5);
         gap: 10px;
         display: none;

         span {
            color: map.get($text-colors, "default");
         }
      }

      .actual-bar {
         // position: sticky;
         // top:150px;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         padding: 1.15rem;
         flex-direction: column;

         .item-area {
            height: auto !important;

            .area-option {
               .option {
                  cursor: pointer;
                  margin-bottom: 4px;
                  width: max-content;
                  border-radius: 4px;
                  background: #f5f5f5;
                  padding: 8px;
                  text-align: center;

                  span {
                     white-space: nowrap;
                     font-size: 13px;
                     line-height: 20px;
                  }

                  &:hover {
                     background: #252a2b52;

                     span {
                        color: #fff;
                     }
                  }

                  &.active {
                     background: #ff6347;

                     span {
                        color: #fff;
                     }
                  }
               }
            }

            .detail {
               margin-top: 0px;
            }
         }

         .item-range {
            height: auto;

            .custom-range {
               width: 93%;
               margin: 0 10px;

               .MuiSlider-rail {
                  border: 1px solid #ff6347;
                  background: #fff;
                  opacity: 1;
                  height: 8px;
               }

               .MuiSlider-track {
                  background: #ff6347;
                  border: 1px solid #ff6347;
                  opacity: 1;
                  height: 9px;
               }

               .MuiSlider-thumb {
                  background: #ff6347;
                  opacity: 1;
                  width: 26px;
                  height: 26px;
                  box-shadow: 0px 4px 4px rgba(255, 152, 134, 0.31);
               }
            }
         }

         .actual-bar-item {
            width: 100%;
            border-bottom: 1px solid rgba(37, 42, 43, 0.1);
            color: #252a2ba5;

            label {
               font-weight: 500;
               font-size: 1rem;
               line-height: 16px;
               padding: 16px 0;
               color: #252a2ba5;

               sup {
                  font-size: 0.5rem;
                  color: #252a2ba5;
               }
            }

            select,
            input {
               color: #252a2ba5;

               option {
                  color: #252a2ba5;
               }
            }

            .range-input {
               display: flex;
               align-items: center;

               span {
                  width: 20%;
                  height: 1px;
                  background-color: map.get($text-colors, "default");
                  opacity: 0.1;
                  margin: 0 12px;
               }
            }
         }
      }

      .actual-work {
         .actual-work-item {
            position: relative;
            margin-bottom: 12px;

            .item-img {
               flex: 1;
               height: 0;
               padding-bottom: 65%;
               position: relative;
               overflow: hidden;

               .cover {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 10;
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  z-index: 1;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
               }
            }

            .text-block {
               text-align: left;
               bottom: 0;
               color: map.get($text-colors, "default");
               padding: 10px 0;
               width: 100%;

               .title-item {
                  font-size: 1rem;
                  font-weight: bold;
                  line-height: 19px;
                  margin: 0;
                  margin-bottom: 4px;
                  height: 38px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;

                  a {
                     text-decoration: none;
                     color: map.get($text-colors, "default");
                  }
               }

               .text-block-view {
                  display: flex;
                  align-items: center;

                  span {
                     width: 2px;
                     height: 2px;
                     // left: 12px;
                     top: 6.5px;
                     background: #252a2b;
                     opacity: 0.7;
                  }

                  .des-item {
                     margin: 0 12px;
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     color: #666666;

                     p {
                        font-size: 16px;
                        line-height: 20px;
                        color: #666666;
                     }

                     img {
                        width: 12px;
                        height: 12px;
                        object-fit: contain;
                     }
                  }

                  .item-ml-0 {
                     margin-left: 0 !important;
                  }
               }

               .text-block-des {
                  display: flex;
                  align-items: center;
                  margin-bottom: 4px;

                  .avartar {
                     width: 20px;
                     height: 20px;
                     border-radius: 50%;
                     background-color: #ddd;
                     background-position: center;
                     background-repeat: no-repeat;
                     background-size: cover;
                     margin-right: 8px;
                  }

                  .userName {
                     white-space: nowrap;
                     max-width: 70px;
                     width: max-content;
                     overflow: hidden;
                     text-overflow: ellipsis;
                  }

                  span {
                     width: 2px;
                     height: 2px;
                     left: 53px;
                     top: 6.5px;
                     background: #252a2b;
                     opacity: 0.7;
                     padding: 0 !important;
                  }

                  .des-item {
                     font-size: 0.938rem;
                     margin: 0 !important;

                     sup {
                        font-size: 0.5rem;
                     }
                  }

                  .item-ml-0 {
                     margin-left: 0 !important;
                  }
               }
            }

            .rule-tag {
               position: absolute;
               width: max-content;
               height: 40px;
               right: 0px;
               top: 0px;
               background: map.get($text-colors, "default");
               opacity: 0.9;
               padding: 12px;
               z-index: 1000;

               .des {
                  font-size: 0.875rem;
                  line-height: 12px;
                  margin: 0;
                  /* identical to box height */
                  color: #fff;
                  text-align: center;
                  text-transform: capitalize;
               }

               &.active {
                  background: #ff6347;
               }
            }
         }
      }

      .actual-nav {
         display: none;
         position: fixed;
         width: 300px;
         top: 0;
         left: 0;
         overflow: auto;
         background-color: #ffffff;
         transition: transform 0.4s ease;
         box-shadow: 0 0 10px #00000070;
         z-index: 1100;
         height: 100vh;
         padding: 15px 20px !important;
         transform: translateX(-100%);
         transition: 0.5s;

         &.active {
            display: block;
            transition: transform 0.4s ease;
            transform: translateX(0);
            transition: 0.5s;
         }

         .item-area {
            height: auto !important;

            .area-option {
               .option {
                  cursor: pointer;
                  margin-bottom: 4px;
                  width: max-content;
                  border-radius: 4px;
                  background: #f5f5f5;
                  padding: 8px;
                  text-align: center;

                  span {
                     white-space: nowrap;
                     font-size: 13px;
                     line-height: 20px;
                  }

                  &:hover {
                     background: #252a2b52;

                     span {
                        color: #fff;
                     }
                  }

                  &.active {
                     background: #ff6347;

                     span {
                        color: #fff;
                     }
                  }
               }
            }

            .detail {
               margin-top: 0px;
            }
         }
      }
   }

   // Small devices (landscape phones, 576px and up)
   @media (max-width: map.get($grid-breakpoints, "sm")) {
   }

   // Medium devices (tablets, 768px and up)
   @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: 20px 0;

      .actual-works {
         margin-bottom: 50px;

         .actual-bar {
            display: none;
         }

         .btn-actual-nav {
            display: flex;
            margin-bottom: 20px;
         }

         .actual-work {
            .actual-work-item {
               margin-bottom: 12px;

               .item-img {
                  width: 100%;
                  height: 310px;
               }
            }
         }
      }
   }

   // Large devices (desktops, 992px and up)
   @media (max-width: map.get($grid-breakpoints, "lg")) {
      .actual-works {
         .actual-bar {
            padding: 0;
         }
      }
   }

   // X-Large devices (large desktops, 1200px and up)
   @media (max-width: map.get($grid-breakpoints, "xl")) {
   }

   // XX-Large devices (larger desktops, 1400px and up)
   @media (max-width: map.get($grid-breakpoints, "xxl")) {
   }
}
