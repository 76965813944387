
.progress-bar {
   position: fixed !important;
   top: 0px;
   left: 0px;
   right: 0px;
   z-index: 1200 !important;
   background-color: #ffc5bb !important;

   span {
      background: #ff6347;
   }
}
