@use 'sass:map';

.landing-page {
   .step {
      padding: 60px 0 46px;

      .block-content {
         .row {
            margin-top: 50px;
         }
      }

      .step-item {
         background: #ffffff;
         box-shadow: 9.40398px 30.0927px 67.7087px rgba(51, 51, 51, 0.1);
         padding: 42px;
         height: 300px;
         border-radius: 8px;

         &__number {
            background-color: map-get($map: $text-colors, $key: orange);
            height: 52px;
            width: 52px;
            border-radius: 50%;
            font-weight: 800;
            font-size: 24px;
            line-height: 28px;
            color: #ffffff;
         }

         &__number-bg {
            background: #f2f2f2;
            width: 68px;
            height: 68px;
            border-radius: 50%;
         }

         &__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            margin: 24px 0px;
            text-align: center;
         }

         &__description {
            font-weight: 300;
            font-size: 16px;
            line-height: 23px;
            color: #666666;
            text-align: center;
         }

         &:hover {
            background: #fff8f8;
            box-shadow: 9.40398px 30.0927px 67.7087px rgba(51, 51, 51, 0.1);
         }
      }
   }

   @media (max-width: map.get($grid-breakpoints-max-width, 'md')) {
      .step {
         padding: 0px;
         padding-bottom: 46px;

         .block-content {
            .row {
               margin-top: 0px;
            }
         }

         .block-title {
            background-color: #ffffff;
            padding: 56px 0;
         }

         .step-item {
            &__title {
               font-size: 28px;
               font-weight: 500;
               line-height: 30px;
            }

            &__description {
               font-size: 20px;
            }
         }
      }
   }
}